import React, { useState }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import M from 'materialize-css'

import Button from 'react-materialize/lib/Button';
import Input from "@material-ui/core/Input";
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

import { useForm, Controller } from 'react-hook-form';
import { participateEvent, validateRegistry } from '../../../../../../../../data-store/actions/virtual-events-actions';



const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function AccessCode(props){
  const {codeReceived} = props;
  console.log(codeReceived);
  const dispatch = useDispatch();
  const athleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const classes = useStyles();
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  const currentEventAuthorized = useSelector(state => state.virtualEvents.currentEventAuthorized);
 
 
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    var userData = {
      userID : userID,
      athleteName : athleteAccount.nombres,
      athleteLastName : athleteAccount.apellidos,
      athleteEmail : athleteAccount.correo,
      athleteGender:athleteAccount.genero,
      athleteBirth : athleteAccount.fechaNacimiento,
      athleteTokenPush : (athleteAccount.tokenPush != undefined)?athleteAccount.tokenPush.token:"",
      startLinePhoto:athleteAccount.photoURL,
      finishLinePhoto:athleteAccount.photoURL,
      photos:{
				[Date.now()]:{
					url:athleteAccount.photoURL,
					photoType:'startLine'
				}
			}
    }

      dispatch(validateRegistry(currentEvent, data.eventPassword, userData));
  }

  
	  return (
			<div className={classes.root}>

      <form onSubmit={handleSubmit(onSubmit)} style={{width:"100%"}}>
        <Grid container spacing={3} justify="center" 
              direction="column" alignContent="center" alignItems="center">
            <Grid item xs={6}>
              <Typography gutterBottom variant="h5">
                Ingresa un código de acceso
              </Typography>
              <Typography gutterBottom variant="h6">
                Si el código ya aparece en la casilla solo presiona el botón para continuar.
              </Typography>
              <Typography gutterBottom variant="h6">
                  Si no tienes código de acceso puedes contactar al promotor.
              </Typography>
            </Grid>
            <Grid item xs={6}>
                <input name="eventPassword" style={{fontSize : 60}} defaultValue={(codeReceived != undefined)?codeReceived:""}  ref={register({ required: true })} />
                {errors.eventPassword && <span>Campo requerido</span>}
            </Grid>
            <Grid item xs={6}>
                <Button type="submit" variant="contained" color="primary">
                  Registrarme en evento
                </Button>
            </Grid>
        </Grid>
      </form>
    </div>
	  );
	}
	