import React from 'react';
import { connect } from 'react-redux'
import {eventParticipants,
		registryConfiguration} from '../../../../../data-store/actions/registry-actions';
import ParticipantDetailContainerReports from './ParticipantDetailContainerReports';

import DeleteParticipantButtomDetail from './DeleteParticipantButtomDetail';
import ModCatGenDetail from './ModCatGenDetail';
import EditableInput from '../../../../DynamicInput/EditableInput';
import ParticipanStandarData from './ParticipanStandarData';

class ParticipantDetailContainerView extends React.Component {

	constructor(props){
		super(props)
	}
 
	render() {
		var numero = "No asignado";
		if(this.props.ParticipantDetail.numero != undefined){
			numero = this.props.ParticipantDetail.numero;
		}
  
    
    var style = {};
    if(this.props.ParticipantDetail.borrado){
			style = {backgroundColor:"grey"	}
    }else{
			style = {backgroundColor:"white"
			}
    }
   return (
		<div className="padding overflowTrue" style={style}>
			<div>
			<div className="row">
				<span className="col s8 m8 l8">      
					<h4>Datos del atleta inscrito </h4>
					<p>
						Numero:  <b>{numero}</b>,    interno: <b>{this.props.ParticipantDetailKey}</b>
					</p>
				</span>
				<span id="botonBorrarInscritoCont" className="col s4 m4 l4 center-align">
          <DeleteParticipantButtomDetail
              ParticipantDetail={this.props.ParticipantDetail} 
              ParticipantDetailKey={this.props.ParticipantDetailKey}
              eventDetail={this.props.eventDetail}
              atleteAccount={this.props.atleteAccount}
              userID={this.props.userID}/>
				</span>
				<a className="btn-flat" onClick="modalConfirmacionFuncPrevent(event, 1, '${idRegistro}')">Imprimir</a>
				
			</div>
        <ParticipanStandarData
          ParticipantDetail={this.props.ParticipantDetail} 
          eventDetail={this.props.eventDetail} 
          userID = {this.props.userID}
          atleteAccount = {this.props.atleteAccount}
          ParticipantDetailKey={this.props.ParticipantDetailKey}
          registryConfiguration={this.props.registryConfiguration}
        />
			<div className="row" id="ModCatGenDivCont">
        <ModCatGenDetail ParticipantDetail={this.props.ParticipantDetail}
                          ParticipantDetailKey={this.props.ParticipantDetailKey}
                          eventDetail={this.props.eventDetail}
                          eventSubEvents={this.props.eventSubEvents}
                          registryAutorizedUsers={this.props.registryAutorizedUsers}
                          userID={this.props.userID}
                          atleteAccount={this.props.atleteAccount}/>
			</div>
			<ParticipantDetailContainerReports ParticipantDetail={this.props.ParticipantDetail} 
            ParticipantDetailKey={this.props.ParticipantDetailKey} 
            eventDetail={this.props.eventDetail}
            eventSubEvents={this.props.eventSubEvents}
            registryConfiguration={this.props.registryConfiguration}
            userID = {this.props.userID}
            atleteAccount = {this.props.atleteAccount}/>
			</div>
	</div>);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

const mapStateToProps = (state) => {
	return {
		eventDetail: state.events.eventDetail,
		eventParticipants : state.registry.eventParticipants,
		registryConfiguration : state.registry.registryConfiguration,
    registryAutorizedUsers : state.registry.registryAutorizedUsers,
		eventSubEvents :state.events.eventSubEvents,
		eventSubEventsSnap :state.events.eventSubEventsSnap,
    userID : state.atlete.userID,
    atleteAccount : state.atlete.atleteAccount
  	}
  }
  
const ParticipantDetailContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ParticipantDetailContainerView);
export default ParticipantDetailContainer;
