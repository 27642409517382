import React from 'react';
import EditableInput from '../../../../DynamicInput/EditableInput';
import {StandarInputsData} from '../StandarInputsData';

class ParticipanStandarData extends React.Component {

	render() {
    var jsonCampos = StandarInputsData;

    let nombresApellidosCont;

    var apellidosSeparadosSet = this.props.registryConfiguration.configuracion.separarApellidos;
		  if(apellidosSeparadosSet){
        nombresApellidosCont = (
          <div className="row">
            <EditableInput ParticipantDetail={this.props.ParticipantDetail} 
                eventDetail={this.props.eventDetail} 
                userID = {this.props.userID}
                atleteAccount = {this.props.atleteAccount}
                ParticipantDetailKey={this.props.ParticipantDetailKey}
                inputObject={jsonCampos.nombres}/>
            <EditableInput ParticipantDetail={this.props.ParticipantDetail} 
                eventDetail={this.props.eventDetail} 
                userID = {this.props.userID}
                atleteAccount = {this.props.atleteAccount}
                ParticipantDetailKey={this.props.ParticipantDetailKey}
                inputObject={jsonCampos.apellidoPaterno}/>
            <EditableInput ParticipantDetail={this.props.ParticipantDetail} 
                eventDetail={this.props.eventDetail} 
                userID = {this.props.userID}
                atleteAccount = {this.props.atleteAccount}
                ParticipantDetailKey={this.props.ParticipantDetailKey}
                inputObject={jsonCampos.apellidoMaterno}/>
          </div>);
        }else{
        nombresApellidosCont = (
        <div className="row">
          <EditableInput ParticipantDetail={this.props.ParticipantDetail} 
              eventDetail={this.props.eventDetail} 
              userID = {this.props.userID}
              atleteAccount = {this.props.atleteAccount}
              ParticipantDetailKey={this.props.ParticipantDetailKey}
              inputObject={jsonCampos.nombres}/>
          <EditableInput ParticipantDetail={this.props.ParticipantDetail} 
              eventDetail={this.props.eventDetail} 
              userID = {this.props.userID}
              atleteAccount = {this.props.atleteAccount}
              ParticipantDetailKey={this.props.ParticipantDetailKey}
              inputObject={jsonCampos.apellidos}/>
        </div>);
      }

    
   return (
     <div>
       {nombresApellidosCont}
			<div className="row">
          <EditableInput ParticipantDetail={this.props.ParticipantDetail} 
              eventDetail={this.props.eventDetail} 
              userID = {this.props.userID}
              atleteAccount = {this.props.atleteAccount}
              ParticipantDetailKey={this.props.ParticipantDetailKey}
              inputObject={jsonCampos.correo}/>
          <EditableInput ParticipantDetail={this.props.ParticipantDetail} 
              eventDetail={this.props.eventDetail} 
              userID = {this.props.userID}
              atleteAccount = {this.props.atleteAccount}
              ParticipantDetailKey={this.props.ParticipantDetailKey}
              inputObject={jsonCampos.fechaNacimiento}/>
          <EditableInput ParticipantDetail={this.props.ParticipantDetail} 
              eventDetail={this.props.eventDetail} 
              userID = {this.props.userID}
              atleteAccount = {this.props.atleteAccount}
              ParticipantDetailKey={this.props.ParticipantDetailKey}
              inputObject={jsonCampos.telefono}/>
			</div>
	</div>);
	}
}

export default ParticipanStandarData;
