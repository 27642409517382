import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Checkbox,
  Switch,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import MenuItem from "@material-ui/core/MenuItem";
//import "./FieldCheckbox.css";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    padding: "10px",
    backgroundColor: "red",
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
}));

export default function FieldSwitch(props) {
  const { fieldObject, register, errors } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    optionsArray,
  } = fieldObject;
  const classes = useStyles();
  const [editMode, setEditMode] = useState(true);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  function handleChange(event) {
    console.log(event.target.checked);
    setCurrentValue(event.target.checked);
  }

  return (
    <div style={{ margin: "15px" }}>
      <input
        style={{ display: "none" }}
        type="checkbox"
        name={fieldName}
        ref={register}
        checked={currentValue}
        onChange={handleChange}
      />
      <FormControlLabel
        className={classes.inputLabel}
        value={currentValue}
        control={
          <Switch
            checked={currentValue}
            onChange={handleChange}
            name={fieldName}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label={label}
        labelPlacement="left"
      />

      {errors[fieldName] ? errorMessage : null}
    </div>
  );
}
