import React, { useState } from "react";
import PhotoEditorContainer from "../../../PhotoEditorVirtual/PhotoEditorContainer";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

export default function AtletePhotos(props) {
  const { participant, currentEvent } = props;
  const [isOpen, setIsOpen] = useState(false);

  console.log("participant", participant);
  console.log("participant", currentEvent);

  return (
    <div>
      <Typography gutterBottom variant="h2" component="h1">
        Fotos del atleta
      </Typography>

      <Grid
        container
        spacing={3}
        direction="row"
        justify="space-evenly"
        wrap="wrap"
        alignContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={4}>
          <Paper style={{ padding: 10 }}>
            <Typography gutterBottom variant="h5">
              Foto de la salida
            </Typography>
            <PhotoEditorContainer
              buttomLabel={"Abrir"}
              title={"Editor"}
              participant={participant}
              currentEvent={currentEvent}
              urlPhoto={participant.startLinePhoto}
            />
            <img
              src={participant.startLinePhoto}
              style={{ maxWidth: "100%" }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper style={{ padding: 10 }}>
            <Typography gutterBottom variant="h5">
              Foto de la Meta
            </Typography>
            <PhotoEditorContainer
              buttomLabel={"Abrir"}
              title={"Editor"}
              participant={participant}
              currentEvent={currentEvent}
              urlPhoto={participant.finishLinePhoto}
            />
            <img
              src={participant.finishLinePhoto}
              style={{ maxWidth: "100%" }}
            />
          </Paper>
        </Grid>

        {participant.photos != undefined &&
          Object.values(participant.photos)
            .filter(function (photo) {
              return photo.photoType == "evidence";
            })
            .map((photo, index) => (
              <Grid key={index} item xs={12} sm={4}>
                <Paper style={{ padding: 10 }}>
                  <Typography gutterBottom variant="h5">
                    Foto de evidencia
                  </Typography>
                  <PhotoEditorContainer
                    buttomLabel={"Abrir"}
                    title={"Editor"}
                    participant={participant}
                    currentEvent={currentEvent}
                    urlPhoto={photo.url}
                  />
                  <img src={photo.url} style={{ maxWidth: "100%" }} />
                </Paper>
              </Grid>
            ))}
      </Grid>
    </div>
  );
}
