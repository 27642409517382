import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  uploadEventPhoto,
  updateEventAtribute,
} from "../../../../../../../data-store/actions/virtual-events-actions";
import { useForm } from "react-hook-form";

import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { parseJSON } from "jquery";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function EventPicturesConfig(props) {
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  let myOrganizedVirtualEvents = useSelector(
    (state) => state.virtualEvents.myOrganizedVirtualEvents
  );

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    console.log(data);

    const json = JSON.parse(data.fotosconfig);
    console.log(json);
    const object = { photosConfig: json, frameImage: json.marcos[2].rutaMarco };

    dispatch(updateEventAtribute(userID, currentEvent.eventID, object));
  };
  if (userID != "RHlMQSMvpPTBySpb1m32F196xnk1") {
    return <div></div>;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Configuración de fotos
        </Typography>
      </CardContent>
      <CardActions>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            placeholder="Fotos Configuración"
            name="fotosconfig"
            ref={register({ required: true })}
          />

          <Button type="submit">Guardar Configuración</Button>
        </form>
      </CardActions>
    </Card>
  );
}
