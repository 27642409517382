import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import Login from '../../../Login/Login'

import { uploadUserPhoto } from '../../../../data-store/actions/atlete-actions';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputFileButton from '../VirtualEvents/InputFileButton';


const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function AthletePictures(props){
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const classes = useStyles();
  


  const callback = photo => {
//    for(var i in data.photos){
      dispatch(uploadUserPhoto(userID, photo, "profilePhoto", )) 
  //  }
  }

	if(atleteAccount == null){
		return <Login/>
  }
  

	  return (
          <Card className={classes.root}>
            <CardActionArea>
              <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                          Foto de perfil
                      </Typography>
              </CardContent>
            </CardActionArea>
            <CardMedia
                component="img"
                alt={atleteAccount.nombres +" "+atleteAccount.apellidos}
                height="200"
                image={atleteAccount.photoURL}
                title={atleteAccount.nombres +" "+atleteAccount.apellidos}
              />

            <CardActions>
              <InputFileButton type="image/*" callback={callback} text="Subir Foto"/>
            </CardActions>
          </Card>
	  );
	}
	