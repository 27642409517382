import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Field from "../Fields/Field";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import InputFileButton from "../Fields/InputFileButton1.0";
import { getPhotoFileToUpload } from "../../services/utils";
import * as fb from "../../firebase/firebasejs";

const useStyles = makeStyles((theme) => ({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.primary.main,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
    color: theme.palette.primary.main,
  },
  text: {
    position: "relative",
    paddingRight: 40,
    color: theme.palette.primary.main,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    color: theme.palette.primary.main,
  },
}));

export default function EditableFieldFile(props) {
  const { fieldObject, onSubmit } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    route,
  } = fieldObject;
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [editMode, setEditMode] = useState(false);
  const [urlPhoto, setUrlPhoto] = useState(defaultValue);
  const [uploadingPhotos, setuploadingPhotos] = useState(null);
  const [uploadingPhotosProgress, setuploadingPhotosProgress] = useState(null);

  function onSave(values) {
    setEditMode(false);
    onSubmit(values);
  }

  const callback = (file) => {
    const rutaFoto = route ? route : "fotosVarias/";
    console.log("uploadPhoto");
    if (file) {
      var storageRef = fb.storage.ref();
      var uploadTask = storageRef
        .child(rutaFoto + "/" + Date.now() + "/" + file.name)
        .put(file);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setuploadingPhotos(file.name);
          setuploadingPhotosProgress(progress);

          console.log("Upload is " + progress + "% done");
        },
        function (error) {
          // Handle unsuccessful uploads
          setuploadingPhotos(null);
          setuploadingPhotosProgress(null);
        },
        function () {
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            setuploadingPhotos(null);
            setuploadingPhotosProgress(null);

            setUrlPhoto(downloadURL);
            console.log("File available at", downloadURL);
          });
        }
      );
    }
  };

  return editMode ? (
    <div>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%" }}
      >
        <Card className={classes.root}>
          {urlPhoto}
          <CardActions>
            <InputFileButton
              type="*"
              callback={callback}
              uploadingPhotos={uploadingPhotos}
              uploadingPhotosProgress={uploadingPhotosProgress}
              text="Subir Archivo"
            />
            <Button primary onClick={handleSubmit(onSave)}>
              Guardar Archivo
              <SaveIcon />
            </Button>
          </CardActions>
        </Card>

        <TextField
          margin="none"
          disabled={!editMode}
          error={Boolean(errors[fieldName])}
          defaultValue={urlPhoto}
          value={urlPhoto}
          inputRef={register({ required: required })}
          name={fieldName}
          type="hidden"
          InputProps={{
            className: classes.input,
            inputProps: {
              max: max,
              min: min,
            },
          }}
        />
      </form>
    </div>
  ) : (
    <div>
      <Typography gutterBottom variant={"subtitle2"} component="h2">
        {label}:
      </Typography>
      <Typography
        className={classes.text}
        gutterBottom
        variant={textSize || "h5"}
        component="h2"
        style={{ minHeight: "30px" }}
      >
        {defaultValue}
        <IconButton
          size={
            !textSize ||
            textSize === "h1" ||
            textSize === "h2" ||
            textSize === "h3" ||
            textSize === "h4" ||
            textSize === "h5"
              ? "medium"
              : "small"
          }
          className={classes.iconEdit}
          onClick={() => setEditMode(true)}
        >
          <EditIcon />
        </IconButton>
      </Typography>
    </div>
  );
}
