import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaymentContainer from "./RegistryPayment/PaymentContainer";
import Login from "../../../../../../Login/Login";
import FormRender from "../../../../../../Fields/FormRender";

import { useForm, Controller } from "react-hook-form";
import {
  getUserVirtualEvents,
  saveParticipantData,
} from "../../../../../../../data-store/actions/virtual-events-actions";
import AccessCode from "./AccessCode/AccessCode";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function EndRegistry(props) {
  const { codeReceived } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventAuthorized = useSelector(
    (state) => state.virtualEvents.currentEventAuthorized
  );
  const classes = useStyles();
  if (currentEvent === undefined) {
    return <CircularProgress />;
  }
  if (atleteAccount === null) {
    return <Login />;
  }

  function onSubmit(data) {
    console.log(data);
    dispatch(saveParticipantData(userID, currentEvent.eventID, { form: data }));
  }

  if (
    myVirtualEvents != undefined &&
    myVirtualEvents[currentEvent.eventID] != undefined &&
    myVirtualEvents[currentEvent.eventID].active
  ) {
    return (
      <div className={classes.root}>
        <Grid
          container
          direction="column"
          alignItems="center"
          alignContent="center"
          spacing={3}
        >
          <Grid item xs={12} sm={7}>
            {currentEvent.form === undefined && (
              <Paper className={classes.paper}>
                <h5>No hay pasos adicionales requeridos para el registro</h5>
                <p>Puedes continuar presionando el botón de siguiente</p>
              </Paper>
            )}
            {currentEvent.form !== undefined &&
              myVirtualEvents[currentEvent.eventID].form !== undefined && (
                <Paper className={classes.paper}>
                  <h5>No hay pasos adicionales requeridos para el registro</h5>
                  <p>Puedes continuar presionando el botón de siguiente</p>
                </Paper>
              )}

            {currentEvent.form !== undefined &&
              myVirtualEvents[currentEvent.eventID].form === undefined && (
                <Paper className={classes.paper}>
                  <Typography gutterBottom variant={"h4"} component="h2">
                    Completa el siguiente formulario
                  </Typography>

                  <FormRender
                    callback={onSubmit}
                    formObject={currentEvent.form}
                    submitButtonText={"Guardar Datos"}
                  />
                </Paper>
              )}
          </Grid>
        </Grid>
      </div>
    );
  }

  let paymentView = "";
  if (currentEvent.price > 0) {
    paymentView = <PaymentContainer />;
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AccessCode codeReceived={codeReceived} />
            {paymentView}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
