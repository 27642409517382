import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Drawer from "./Drawer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function HeaderSections(props) {
  const history = useHistory();
  const {
    logo,
    logoHeigthDesktop,
    logoHeigthMobile,
    menu,
    colorTextMenu,
    CTAtext,
    CTALink,
    bgColor,
  } = props.componentConfig;
  const classes = useStyles();

  const handleLinkClick = (url) => {
    console.log("URL", url);
    //setShowMenu(false);
    if (url.startsWith("#")) {
      const section = document.getElementById(url.replace("#", ""));
      if (section instanceof HTMLElement) {
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    } else if (url.startsWith("http")) {
      var win = window.open(url, "_blank");
      win.focus();
    } else {
      history.push(url);
    }
  };
  return (
    <div style={{ backgroundColor: bgColor }}>
      <Hidden only={["md", "lg", "xl"]}>
        <Grid container style={{ width: "100%", padding: 10 }}>
          <Grid item xs={2}>
            <Drawer
              menu={menu}
              colorTextMenu={colorTextMenu}
              bgColor={bgColor}
              callback={handleLinkClick}
            />
          </Grid>
          <Grid className={classes.logo} item xs={6}>
            <img alt="logo" height={logoHeigthMobile} src={logo} />
          </Grid>
          <Grid item xs={4}>
            {CTAtext && (
              <Button
                style={{ marginRight: 0, color: colorTextMenu }}
                onClick={() => handleLinkClick(CTALink)}
                variant="contained"
                color="primary"
              >
                {CTAtext}
              </Button>
            )}
          </Grid>
        </Grid>
      </Hidden>
      <Hidden only={["sm", "xs"]}>
        <Grid container style={{ width: "100%", padding: 20 }}>
          <Grid item xs={3}>
            <Button component={Link} to={"/"}>
              <img alt="logo" height={logoHeigthDesktop} src={logo} />
            </Button>
          </Grid>
          <Grid item xs={7} alignItems="center">
            <Grid
              container
              direction="row"
              justify="space-arround"
              alignItems="center"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              {menu &&
                Object.values(menu)
                  .filter((item) => !item.hidden)
                  .map((item) => {
                    return (
                      <Grid item xs={2} alignContent="center">
                        <Button
                          onClick={() => handleLinkClick(item.url)}
                          color="primary"
                          style={{ color: colorTextMenu }}
                        >
                          {item.text}
                        </Button>
                      </Grid>
                    );
                  })}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            {CTAtext && (
              <Button
                style={{ marginRight: 10, color: colorTextMenu }}
                onClick={() => handleLinkClick(CTALink)}
                variant="contained"
                color="primary"
              >
                {CTAtext}
              </Button>
            )}
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
}

HeaderSections.displayName =
  "Header (Logo a la izquierda menu items al centro)";

export const componentDescription = {
  componentConfig: {
    logo: {
      label: "Logo Para el Header",
      errorMessage: "Campo Requerido",
      fieldName: "logo",
      type: "file",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    logoHeigthDesktop: {
      label: "Alto del logo en pixeles Pantalas grandes",
      errorMessage: "Campo Requerido",
      fieldName: "logoHeigthDesktop",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    logoHeigthMobile: {
      label: "Alto del logo en pixeles Moviles",
      errorMessage: "Campo Requerido",
      fieldName: "logoHeigthMobile",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    menu: {
      label: "Objeto del menú",
      errorMessage: "Campo Requerido",
      fieldName: "menu",
      type: "json",
      defaultValue: {},
      required: true,
      regex: "",
      textSize: "h6",
      inputsArray: {
        text: {
          label: "Texto",
          errorMessage: "Campo Requerido",
          fieldName: "text",
          type: "text",
          defaultValue: "",
          required: true,
          regex: "",
          textSize: "h6",
        },
        url: {
          label: "Url (Agrega # si dirige a la una sección de la misma web)",
          errorMessage: "Campo Requerido",
          fieldName: "url",
          type: "text",
          defaultValue: "",
          required: true,
          regex: "",
          textSize: "h6",
        },
      },
    },
    colorTextMenu: {
      label: "Color de items del menu",
      errorMessage: "Campo Requerido",
      fieldName: "colorTextMenu",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    CTAtext: {
      label: "Texto Call To Action",
      errorMessage: "Campo Requerido",
      fieldName: "CTAtext",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    CTALink: {
      label: "Link del Call to action",
      errorMessage: "Campo Requerido",
      fieldName: "CTALink",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
