import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    textAlign: "left",
    overflowY: "scroll",
    backgroundColor: "#e0e0e0",
  },
}));

export default function DNFAndDQView(props) {
  const { text } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        direction="row"
        style={{
          height: "auto",
          width: "100%",
          padding: 40,
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          style={{ padding: "10px", textAlign: "center" }}
        >
          <Typography variant={"h3"} component="h2">
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
