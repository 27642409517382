import React, { useState }from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { requestTokenGarmin } from '../../../../../data-store/actions/atlete-actions'
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import LogoGarmin from '../GarminAssets/Garmin_Connect_app_1024x1024-02.png'
import LogoDashport from '../../../../../assets/dashport-logos/DASHPORT_03.png'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function GarminRequestTokenButtonView(props){
  const classes = useStyles();
  const [requestToken, setRequestToken] = useState(true);

	const { atleteAccount, requestTokenURLGarmin, sendRequestToken, userID, api } = props;
 
  if(requestTokenURLGarmin == null && requestToken){
    console.log("RequestToken");
    sendRequestToken(userID, api);

    setRequestToken(false);
  }
  const handleClick = () =>{
    window.open(requestTokenURLGarmin,"Garmin","width='90%',height='90%'");
  }
  let buttonRequest;
  if(requestTokenURLGarmin == null){
    buttonRequest = <CircularProgress />;
  }else{
    buttonRequest = (<Button  onClick={() => handleClick()} target="_blank" variant="contained" color="primary" size="large">
            Conectar cuentas
    </Button>)
        
  }

  let apiText = "";
  if(api == "training"){
    apiText = "Conecta Dashport con tu Garmin para enviar entrenamientos o retos a tu reloj";
  }else if(api == "health"){
    apiText = "Conecta tu Garmin con tu cuenta de Dashport para sincronizar retos o entrenamientos";
  }
	  return (
      <Grid container justify="center" style={{textAlign:"center"}} spacing={3}>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          <p>{apiText} </p>
        </Grid>
        <Grid item xs={4} >
          <Avatar variant="square" src={LogoGarmin}/>
        </Grid>
        <Grid item xs={4} >
          <ArrowForwardIcon color="primary" />
        </Grid>
        <Grid item xs={4} >
          <Avatar variant="square" src={LogoDashport}/>
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          {buttonRequest}
        </Grid>
      </Grid>
	  );
	}
	

	const mapDispatchToProps = (dispatch) => {
		return {
      sendRequestToken : (userID, api) =>{
        dispatch(requestTokenGarmin(userID, api))
      }
		}
	}
	
	const mapStateToProps = (state) => {
		return {		
			eventDetail: state.events.eventDetail,
			eventParticipants : state.registry.eventParticipants,
      atleteAccount : state.atlete.atleteAccount,
      userID : state.atlete.userID,
      requestTokenURLGarmin : state.atlete.requestTokenURLGarmin
		}
		}
		
	const GarminRequestTokenButton = connect(
		mapStateToProps,
		mapDispatchToProps
	)(GarminRequestTokenButtonView);
	
	export default GarminRequestTokenButton;
