import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import Login from '../../../../../../Login/Login'

import { useForm, Controller } from 'react-hook-form';
import { uploadPhoto, getUserVirtualEvents } from '../../../../../../../data-store/actions/virtual-events-actions'
import MyEvidenceTime from './MyEvidenceTime';
import InputFileButton from '../../../InputFileButton';



const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function MyEvidencePictures(props){
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const myVirtualEvents = useSelector(state => state.virtualEvents.myVirtualEvents);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  const classes = useStyles();
  
  const thisVirtualEvent = myVirtualEvents[currentEvent.eventID];
  var photo =[];
  if(thisVirtualEvent.photos != undefined){
    photo = Object.values(thisVirtualEvent.photos).filter(obj => {
      return obj.photoType === "evidence"
    })
  }
 
  const callback = photo => {
      dispatch(uploadPhoto(userID, currentEvent.eventID,  photo, "evidence", currentEvent )) 
  }

	if(atleteAccount == null){
		return <Login/>
  }
  
  if(myVirtualEvents == undefined || currentEvent == undefined){
    return <CircularProgress/>
  }
  if(currentEvent == {}){
    return <h1>Usuario no autorizado para este evento</h1>
  }
	  return (
      <Grid container 
      direction="column"
      justify="center"
      alignItems="center" spacing={3}>
          <Grid item xs={12} >
            <h5>Sube tus fotografías como evidencia del reto cumplido</h5>
            <p>Puedes omitir este paso si tienes tu garmin conectado</p>
          </Grid>
          <Grid item xs={12} >
            {photo.map((img, key) => 
              <img key={key} src={img.url} height="150" style={{margin :2}}/>
            )}
          </Grid>
          <Grid item xs={12} >
          </Grid>
          <Grid item xs={12} >
            <p>Selecciona la foto de evidencia</p>
            <InputFileButton type="image/*" callback={callback} text="Subir Foto"/>
          </Grid>
          <Grid item xs={12} >
          </Grid>
          <Grid item xs={12} >
            <MyEvidenceTime/>
          </Grid>
      </Grid>
	  );
	}
	