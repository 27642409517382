import { tiempoASegundos, segundosATiempo } from '../../../../services/utils';

export const calcularGraficaResultados = (resultadoCorredor, resultadosCarrera) => {

    console.log(resultadosCarrera);

    // inicia calculo de promedio y ganador de la categoria del atleta.
      var tiempoGanadorCat = Infinity;
      var cantidadCorredoresCat = 0;
      var tiempoAcumuladoCat = 0;
    
      resultadosCarrera
        .filter(function (resultado) { //Solo se toman en cuenta los corredores de la misma rama
          return (
              resultado.mod === resultadoCorredor.mod
          &&  resultado.cat === resultadoCorredor.cat
          &&  resultado.gen === resultadoCorredor.gen
          &&  resultado.pG > 0
          );
        }).forEach(function (resultado) {
    
          var tiempoOficialCat = tiempoASegundos(resultado.to);
          
          cantidadCorredoresCat += 1;
          tiempoAcumuladoCat += tiempoOficialCat;
          tiempoGanadorCat = Math.min(tiempoGanadorCat, tiempoOficialCat);
    
        });
    
        var tiempoPromedioCat = cantidadCorredoresCat > 0 ? tiempoAcumuladoCat / cantidadCorredoresCat : NaN;
    // Finaliza calculo de promedio y ganador de la categoria del atleta.
    
    // inicia calculo de promedio y ganador General del evento en la modalidad del atleta.
      var tiempoGanadorMod = Infinity;
      var cantidadCorredoresMod = 0;
      var tiempoAcumuladoMod = 0;
    
      resultadosCarrera
        .filter(function (resultado) { //Tomamos todos los corredores de la misma modalidad
          return (
              resultado.Mod === resultadoCorredor.Mod
              &&  resultado.pG > 0
            );
        }).forEach(function (resultado) {
    
          var tiempoOficialMod = tiempoASegundos(resultado.to);
          
          cantidadCorredoresMod += 1;
          tiempoAcumuladoMod += tiempoOficialMod;
          tiempoGanadorMod = Math.min(tiempoGanadorMod, tiempoOficialMod);
    
        });
        var tiempoPromedioMod = cantidadCorredoresMod > 0 ? tiempoAcumuladoMod / cantidadCorredoresMod : NaN;
    // Finaliza calculo de promedio y ganador General del evento en la modalidad del atleta.
    
    // inicia calculo de promedio y ganador por rama y modalida del evento en la modalidad y rama del atleta.
      var tiempoGanadorGen = Infinity;
      var cantidadCorredoresGen = 0;
      var tiempoAcumuladoGen = 0;
    
      resultadosCarrera
        .filter(function (resultado) { //Tomamos todos los corredores de la misma modalidad
          return (
              resultado.mod === resultadoCorredor.mod
          &&  resultado.gen === resultadoCorredor.gen
          &&  resultado.pG > 0
          );
        }).forEach(function (resultado) {
    
          var tiempoOficialGen = tiempoASegundos(resultado.to);
          
          cantidadCorredoresGen += 1;
          tiempoAcumuladoGen += tiempoOficialGen;
          tiempoGanadorGen = Math.min(tiempoGanadorGen, tiempoOficialGen);
    
        });
        var tiempoPromedioGen = cantidadCorredoresGen > 0 ? tiempoAcumuladoGen / cantidadCorredoresGen : NaN;
    // inicia calculo de promedio y ganador por rama y modalida del evento en la modalidad y rama del atleta.
    
  
    console.log(tiempoGanadorCat);
    return {
        labels: [resultadoCorredor.nombre,'Ganador Categoría', 'Promedio Categoría','Ganador Rama','Promedio Rama','Ganador General','Promedio General'],
        datasets: [
          {
            label: 'Resultados',
            fillColor: 'rgba(239,65,35,0.8)',
            strokeColor: 'rgba(239,65,35,1)',
            pointColor: 'rgba(239,65,35,1)',
            pointStrokeColor: '#ef4123',
            pointHighlightFill: '#ef4123',
            pointHighlightStroke: 'rgba(239,65,35,1)',
            data: [tiempoASegundos(resultadoCorredor.to), tiempoGanadorCat , tiempoPromedioCat,tiempoGanadorGen , tiempoPromedioGen, tiempoGanadorMod, tiempoPromedioMod],
            backgroundColor: 'rgba(239,65,35,0.7)'
          }
        ]
      }
    }
