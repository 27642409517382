import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { uploadEventPhoto } from '../../../../../../../data-store/actions/virtual-events-actions';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputFileButton from '../../../../VirtualEvents/InputFileButton';


const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function EventPictures(props){
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  let myOrganizedVirtualEvents = useSelector(state => state.virtualEvents.myOrganizedVirtualEvents);

  const classes = useStyles();
  


  const callback = photo => {
      dispatch(uploadEventPhoto(userID,currentEvent.eventID, photo, "eventPhoto", myOrganizedVirtualEvents)) 
  }
  
 	  return (
          <Card className={classes.root}>
            <CardActionArea>
            {currentEvent.eventPhoto != undefined &&  <CardMedia
                component="img"
                alt={currentEvent.eventName}
                height="200"
                image={currentEvent.eventPhoto}
                title={currentEvent.eventName}
              />}

              <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                          Foto del evento
                      </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <InputFileButton type="image/*" callback={callback} text="Subir Foto"/>
            </CardActions>
          </Card>
	  );
	}
	