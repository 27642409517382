import React from "react";
import { connect } from "react-redux";
import NextEventsCard from "./NextEventsCard";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../../../AppContainer";
import { Typography } from "@material-ui/core";

const NextEventsListView = ({ events }) => (
  <AppContainer>
    <Typography color="primary" variant={"h2"} component="h2">
      Próximos Eventos
    </Typography>

    <Grid
      container
      direction="row"
      justify="left"
      alignItems="left"
      spacing={2}
    >
      {events.map((evento) => (
        <NextEventsCard key={evento.iDEvento} evento={evento} />
      ))}
    </Grid>
  </AppContainer>
);

const eventsFbToArray = (events) => {
  var eventosArray = [];
  var statusEvento = 2;
  for (var i in events) {
    if (events[i].eventoDisponible == statusEvento) {
      eventosArray.push(events[i]);
    }
  }
  eventosArray.sort(
    (a, b) => new Date(a.fechaEvento) - new Date(b.fechaEvento)
  );
  return eventosArray;
};

const mapStateToProps = (state) => {
  return {
    events: eventsFbToArray(state.events.events),
  };
};

const NextEventsList = connect(mapStateToProps)(NextEventsListView);

export default NextEventsList;
