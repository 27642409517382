import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Login from "../../../../../../Login/Login";
import Typography from "@material-ui/core/Typography";
import IconReaction from "../../../Chat/IconReaction";

//import Grid3d from '../../3dView/src/Grid3d';

import AthleteAvatarModal from "../atleteDetail/AthleteAvatarModal";

import Tooltip from "@material-ui/core/Tooltip";
import PaginationComponent from "../../Pagination/PaginationComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "white",
  },
}));

export default function StartLine(props) {
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(
    (state) => state.virtualEvents.currentEventParticipants
  );

  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const [page, setPage] = useState(1);
  const itemsPerPage = 100;

  const goalime = myVirtualEvents[currentEvent.eventID];

  const classes = useStyles();
  if (atleteAccount == null) {
    return <Login />;
  }

  function callBackPage(page) {
    setPage(page);
    console.log(page);
  }

  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  if (currentEvent == {}) {
    return <h1>Usuario no autorizado para este evento</h1>;
  }
  let participantsArray = Object.keys(currentEventParticipants);
  let startPictures = startPicturesFunc(currentEventParticipants);
  let startPicturesPage = paginate(startPictures, itemsPerPage, page);

  function startPicturesFunc() {
    let startPicturesTemp = [];
    for (var i in currentEventParticipants) {
      var participant = currentEventParticipants[i];
      var photoFinal = participant.startLinePhoto;
      startPicturesTemp.push(participant);
    }
    return startPicturesTemp;
  }
  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="h5" component="h2">
        {participantsArray.length == 1
          ? " Un atleta ya listo"
          : participantsArray.length + " atletas que registraron la salida"}
      </Typography>
      {/* <Grid3d style={{height:500}}/> */}
      <PaginationComponent
        callBackPage={callBackPage}
        totalItems={startPictures.length}
        itemsPerPage={100}
      />

      <Grid container direction="row" wrap="wrap" spacing={3}>
        {startPicturesPage.map((participant, key) => (
          <AthleteAvatarModal
            key={key}
            participant={participant}
            currentEvent={currentEvent}
            type="start"
          />
        ))}
      </Grid>
    </div>
  );
}
