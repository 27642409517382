import {
  tiempoASegundos,
  helperMultisort,
  calcularEdad,
  fecha,
} from "../../../../../services/utils";

export function useCalculateAbsolutePositions(
  results,
  onlyFinishers,
  currentEvent
) {
  for (let i in results) {
    if (results[i].finishTime != undefined || results[i].finishTime != null) {
      let segundos = tiempoASegundos(results[i].finishTime);
      results[i].segundos = segundos;
    } else {
      if (onlyFinishers) {
        delete results[i];
      } else {
        results[i].finishTime = "00:00:00";
        results[i].segundos = 0;
      }
    }
  }

  if (currentEvent.eventType && currentEvent.eventType === "byTime") {
    results = helperMultisort(results, ["segundos"], ["DESC"]);
  } else {
    results = helperMultisort(results, ["segundos"], ["ASC"]);
  }

  let positionAbsolute = 0;
  let positionAbsoluteGender = {
    M: 0,
    F: 0,
  };

  for (let e in results) {
    if (results[e].segundos !== 0) {
      const category = getCategory(results[e], currentEvent);

      positionAbsolute++;
      positionAbsoluteGender[results[e].athleteGender]++;
      results[e].positionAbsolute = positionAbsolute;
      if (
        positionAbsoluteGender[
          results[e].athleteGender + "-category_" + category.codCat
        ] == undefined
      ) {
        positionAbsoluteGender[
          results[e].athleteGender + "-category_" + category.codCat
        ] = 0;
      }
      positionAbsoluteGender[
        results[e].athleteGender + "-category_" + category.codCat
      ]++;

      results[e].positionAbsoluteGender =
        positionAbsoluteGender[results[e].athleteGender];
      results[e].positionAbsoluteCategory =
        positionAbsoluteGender[
          results[e].athleteGender + "-category_" + category.codCat
        ];
      results[e].category = category.category;
    } else {
      results[e].positionAbsolute = 0;
      results[e].positionAbsoluteGender = 0;
      results[e].positionAbsoluteCategory = 0;
    }
  }
  return results;
}
// ############################################################################
export function useCalculateRelativePositions(
  results,
  onlyFinishers,
  currentEvent
) {
  for (let i in results) {
    if (
      (results[i].goalTime != undefined || results[i].goalTime != null) &&
      (results[i].finishTime != undefined || results[i].finishTime != null)
    ) {
      let indice =
        tiempoASegundos(results[i].finishTime) /
        tiempoASegundos(results[i].goalTime);
      results[i].indice = indice;
    } else {
      if (onlyFinishers) {
        delete results[i];
      } else {
        results[i].finishTime = "00:00:00";
        results[i].goalTime =
          results[i].goalTime === undefined || results[i].goalTime === undefined
            ? "00:00:00"
            : results[i].goalTime;
        results[i].indice = 100;
      }
    }
  }

  if (currentEvent.eventType && currentEvent.eventType === "byTime") {
    results = helperMultisort(results, ["indice"], ["DESC"]);
  } else {
    results = helperMultisort(results, ["indice"], ["ASC"]);
  }

  let positionRelative = 0;
  let positionRelativeGender = {
    M: 0,
    F: 0,
  };

  for (let e in results) {
    if (results[e].indice !== 100) {
      const category = getCategory(results[e], currentEvent);

      positionRelative++;
      positionRelativeGender[results[e].athleteGender]++;
      if (
        positionRelativeGender[
          results[e].athleteGender + "-category_" + category.codCat
        ] == undefined
      ) {
        positionRelativeGender[
          results[e].athleteGender + "-category_" + category.codCat
        ] = 0;
      }
      positionRelativeGender[
        results[e].athleteGender + "-category_" + category.codCat
      ]++;

      results[e].positionRelative = positionRelative;
      results[e].positionRelativeGender =
        positionRelativeGender[results[e].athleteGender];
      results[e].positionRelativeCategory =
        positionRelativeGender[
          results[e].athleteGender + "-category_" + category.codCat
        ];
      results[e].category = category.category;
    } else {
      results[e].positionRelative = 0;
      results[e].positionRelativeGender = 0;
      results[e].positionRelativeCategory = 0;
    }
  }
  return results;
}
// ##########################################
function getCategory(participant, currentEvent) {
  // let positionAbsoluteCategory = {
  //   M: {
  //     category_1: { name: "Única", codCat: 1, ageStart: 0, ageEnd: 200 },
  //   },
  //   F: {
  //     category_1: { name: "Única", codCat: 1, ageStart: 0, ageEnd: 200 },
  //   },
  // };

  if (currentEvent.categories != undefined) {
    let categoriesGender = currentEvent.categories[participant.athleteGender];
    const age = calcularEdad(
      participant.athleteBirth,
      fecha(currentEvent.eventStart.seconds * 1000),
      true
    );
    for (var i in categoriesGender) {
      if (
        categoriesGender[i].ageStart <= age &&
        categoriesGender[i].ageEnd >= age
      ) {
        return {
          category: categoriesGender[i].name,
          codCat: categoriesGender[i].codCat,
        };
      }
    }
    return { category: "Otra", codCat: 100 };
  } else {
    return { category: "Única", codCat: 1 };
  }
}
