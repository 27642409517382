import React from "react";
import { connect } from "react-redux";
import {
  detalleEvento,
  resultadosEvento,
  resultReportType,
  getPuntosControl,
  configuracionEvento,
  eventConvocatory,
  eventSubEvents,
  customStrings,
} from "../../../data-store/actions/events-actions";
import { registryConfiguration } from "../../../data-store/actions/registry-actions";
import WhiteContainer from "../Modals/WhiteContainer";

import "materialize-css/dist/css/materialize.min.css";
import { Link } from "react-router-dom";
import Convocatoy from "./Convocatory/Convocatory";
import RegistryContainerUI from "./Registry/RegistryContainerUI";
import RegistryContainerPublic from "./Registry/RegistryContainerPublic";
import BuscadorRegistry from "./Registry/BuscadorRegistry";
import FullScreenDialog from "../Modals/FullScreenDialog";
import FormDialog from "../Modals/FormDialog";
import { formatEventDate } from "../../../services/utils";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import RegistryContainerNew from "./RegistryNew/RegistryContainerNew";
import AppContainer from "../../../AppContainer";
import EventRegistryPublic from "./EventRegistryPublic";

class NextEventShowView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var element;
    let publicRegistry = (
      <FullScreenDialog
        buttomLabel={"Inscripciones"}
        title={"Inscripciones"}
        report={<RegistryContainerPublic match={this.props.match} />}
        BarButtons={null}
      />
    );

    let convocatoriaShow;
    if (
      this.props.eventDetail != undefined &&
      this.props.eventDetail.convocatoria
    ) {
      convocatoriaShow = (
        <FullScreenDialog
          buttomLabel={"Convocatoria"}
          title={"Convocatoria"}
          report={<Convocatoy />}
          BarButtons={null}
        />
      );
    }

    if (this.props.evento != undefined) {
      element = (
        <Container maxWidth="sm">
          <Card>
            <CardMedia
              component="img"
              alt={"Logo evento:" + this.props.evento.nombreEvento}
              height="auto"
              image={this.props.evento.logoEvento}
              title={"Logo evento:" + this.props.evento.nombreEvento}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {this.props.evento.nombreEvento}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <p>
                  Fecha: {formatEventDate(this.props.evento.fechaEvento)}, a las{" "}
                  {this.props.evento.horaEvento}
                </p>
                <p>
                  Lugar: {this.props.evento.lugarEvento},{" "}
                  {this.props.evento.ciudadEvento},{" "}
                  {this.props.evento.paisEvento}
                </p>
              </Typography>
            </CardContent>
            <CardActions>
              {this.props.eventDetail && (
                <EventRegistryPublic
                  eventIdDashport={"evento_" + this.props.eventDetail.iDEvento}
                />
              )}
              {convocatoriaShow}
            </CardActions>
          </Card>
        </Container>
      );
    } else {
      element = <LinearProgress />;
    }

    return <AppContainer> {element}</AppContainer>;
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  return {
    match: match,
    eventDetailSend: dispatch(detalleEvento(match.params.EventoIndex)),
    eventConvocatorySend: dispatch(eventConvocatory(match.params.EventoIndex)),
    eventSubEventsSend: dispatch(eventSubEvents(match.params.EventoIndex)),
    customStringsSend: dispatch(customStrings(match.params.EventoIndex)),
    eventConfigurationSend: dispatch(
      configuracionEvento(match.params.EventoIndex)
    ),
    registryConfigurationSend: dispatch(
      registryConfiguration(match.params.EventoIndex)
    ),
  };
};

const mapStateToProps = (state, { match }) => {
  var EventoIndex = match.params.EventoIndex;
  var evento;
  if (state.events.events != undefined) {
    evento = state.events.events[EventoIndex];
  } else {
    evento = undefined;
  }
  return {
    evento: evento,
    eventDetail: state.events.eventDetail,
    eventConfiguration: state.events.eventConfiguration,
    eventConvocatory: state.events.eventConvocatory,
    eventSubEvents: state.events.eventSubEvents,
    atleteAccount: state.atlete.atleteAccount,
    customStrings: state.events.customStrings,
  };
};

const NextEventShow = connect(
  mapStateToProps,
  mapDispatchToProps
)(NextEventShowView);

export default NextEventShow;
