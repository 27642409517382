

export const RegistryConfirmationData = {
  es: {
    preNombre: {
      1: "Felicidades",
      2: "Felicidades",
      3: "Felicidades",
      4: "Con el siguiente codigo de suscripcipcion podrás compeltar el proceso de registro"
    },
    postNombre: {
      1: "completaste exitosamente el proceso de inscripción en el evento",
      2: "Ya registramos tu datos para participar en el evento",
      3: "Ya recibimos el pago y completaste exitosamente el proceso de inscripción en el evento",
      4: "Datos del evento"
    },
    postEvento: {
      1: "",
      2: "",
      3: "",
      4: ""
    },
    Numero: "Número de inscripción",
    Distancia: "Evento",
    Rama: "Rama",
    Categoria: "Categoría",
    tituloDatos: "Datos inscripción",
    botonLink: "Consulta los datos del evento aquí",
    detalleOrden: "Detalle de la compra",
    Producto: "Producto",
    Descripcion: "Descripción",
    Cantidad: "Cantidad",
    Precio: "Precio",
    LinkYQRSeguimiento: "Link y QR de seguimiento",
  },
  en: {
    preNombre: {
      1: "Congratulation",
      2: "Congratulation",
      3: "Congratulation"
    },
    postNombre: {
      1: "completaste exitosamente el proceso de inscripción en el evento",
      2: "Ya registramos tu datos para participar en el evento",
      3: "Ya recibimos el pago y completaste exitosamente el proceso de inscripción en el evento"
    },
    postEvento: {
      1: "",
      2: "A continuación mostramos la ficha para realizar el pago",
      3: ""
    },
    Numero: "Number",
    Distancia: "Event",
    Rama: "Rama",
    Categoria: "Category",
    tituloDatos: "Registry Data",
    botonLink: "check details here",
    detalleOrden: "order Detail",
    Producto: "Product",
    Descripcion: "Description",
    Cantidad: "Quatity",
    Precio: "Price",
    LinkYQRSeguimiento: "Link and QR code",
  }
}

