import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import StartLinePictures from "./StartLinePictures";
import StartLineParticipants from "./StartLineParticipants";
import MyGoalTimeCard from "./MyGoalTimeCard";
import Typography from "@material-ui/core/Typography";
import GarminRequestTokenButton from "../../../../GarminApi/GarminRequestApi/GarminRequestTokenButton";

import Login from "../../../../../../Login/Login";

import { useForm, Controller } from "react-hook-form";
import { getUserVirtualEvents } from "../../../../../../../data-store/actions/virtual-events-actions";

import ReactAudioPlayer from "react-audio-player";
import StartLinePicturesDefault from "./StartLinePicturesDefault";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function StartLine(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);

  const classes = useStyles();
  if (atleteAccount == null) {
    return <Login />;
  }

  if (myVirtualEvents == undefined || currentEvent == undefined) {
    return <CircularProgress />;
  }
  if (currentEvent == {}) {
    return <h1>Usuario no autorizado para este evento</h1>;
  }

  let garminView = "";
  if (
    atleteAccount.garmin == undefined ||
    atleteAccount.garmin["health"] === undefined ||
    (atleteAccount.garmin && atleteAccount.garmin["health"] === null)
  ) {
    garminView = (
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper}>
          <h5>
            Para evidenciar el reto con tu reloj garmin, debes conectar tu
            cuenta antes de iniciar el reto
          </h5>
          <GarminRequestTokenButton api="health" />;
        </Paper>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} sm={6}>
          <MyGoalTimeCard />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StartLinePictures />
        </Grid>
        <Grid item xs={12} sm={12}>
          <StartLinePicturesDefault />
        </Grid>
        {garminView}

        {currentEvent.startAudio != undefined && (
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5">
              Audio de salida
            </Typography>

            <ReactAudioPlayer src={currentEvent.startAudio} autoPlay controls />
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <StartLineParticipants />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
