import React from 'react';
import { connect } from 'react-redux'
import {eventParticipants,
		registryConfiguration} from '../../../../../data-store/actions/registry-actions';
import {completeNull, modalidadCateogoriaNameByCode} from '../../../../../services/utils';
import IncomeTableCont from './IncomeTableCont';
import DynamicInputs from '../../../../DynamicInput/DynamicInputs';
import DownloadButtomAllData from '../DownloadData/DownloadButtomAllData';

class IncomeView extends React.Component {

	constructor(props){
		super(props)
		this.state = {
			filter : 1,
			quantity : 50
		}
		
    }

		camposAdicionalesModalidad = () => {

			var camposAdicionalesModalidad = [];
			var camposAdicionalesModalidadHTML = [];
		
			var formulario = this.props.registryConfiguration.formulario;
			for(var i in formulario){
				if(formulario[i].tipoCampo != "titulo"){
					camposAdicionalesModalidad.push(formulario[i]);
					camposAdicionalesModalidadHTML.push(<th key={formulario[i].campo} data-field={formulario[i].etiqueta}>{formulario[i].etiqueta}</th>);
				}
			}
		
			var modalidadesBuscar = this.props.eventSubEvents;
		
			var arrayCampoModalidad = [];
		
			for(var i in modalidadesBuscar){
				var modalidadCampos = modalidadesBuscar[i].configModalidad.camposAdicionalesModalidad;
				if(modalidadCampos != undefined){
					for(var e in modalidadCampos){
						var campos = modalidadCampos[e];
						for(var f in campos){
							if(campos[f].tipoCampo != "titulo"){
								if(!arrayCampoModalidad.includes(campos[f].campo)){
									arrayCampoModalidad.push(campos[f].campo);
									camposAdicionalesModalidad.push(campos[f]);
									camposAdicionalesModalidadHTML.push(<th key={i+e+f+"CA"} data-field={campos[f].etiqueta}>{campos[f].etiqueta}</th>);
									console.log("Campos "+campos[f].campo);
								}
							}
						}
					}
				}
			}
		
			return {camposAdicionalesModalidad : camposAdicionalesModalidad,
				camposAdicionalesModalidadHTML : camposAdicionalesModalidadHTML};
		}
		
		productosAdicionalesModalidadTitulo = () => {

			var productosAdicionalesModalidadTitulo = [];
			var productosAdicionalesModalidadTituloHTML = [];
		
			var modalidadesBuscar = this.props.eventSubEvents;
			var arrayCodigoProducto = [];
		
			for(var i in modalidadesBuscar){
				var modalidadProductos = modalidadesBuscar[i].configModalidad.productosAdicionales;
				if(modalidadProductos != undefined){
					for(var e in modalidadProductos){
						if(!arrayCodigoProducto.includes(modalidadProductos[e].codigoProducto)){
							arrayCodigoProducto.push(modalidadProductos[e].codigoProducto);
							productosAdicionalesModalidadTitulo.push(modalidadProductos[e]);

							productosAdicionalesModalidadTituloHTML.push(<th key={i+e+"cantidad"} data-field={`${modalidadProductos[e].nombreProducto}-Cantidad`}>{modalidadProductos[e].nombreProducto} - Cantidad</th>);
							productosAdicionalesModalidadTituloHTML.push(<th key={i+e+"precio"} data-field={`${modalidadProductos[e].nombreProducto}-Precio`}>{modalidadProductos[e].nombreProducto} - Precio</th>);
							productosAdicionalesModalidadTituloHTML.push(<th key={i+e+"total"} data-field={`${modalidadProductos[e].nombreProducto}-Total`}>{modalidadProductos[e].nombreProducto} - Total</th>);
							var formularioProducto = modalidadProductos[e].formularioProducto;
							if(formularioProducto != undefined){
								for(var f in formularioProducto){
									productosAdicionalesModalidadTituloHTML.push(<th key={i+e+f+"input"} data-field={formularioProducto[f].etiqueta}>{formularioProducto[f].etiqueta}</th>);
								}
							}
				
							console.log(JSON.stringify(modalidadProductos[e]));
						}
					}
				}
			}
		
			return {productosAdicionalesModalidadTitulo:productosAdicionalesModalidadTitulo,
				productosAdicionalesModalidadTituloHTML:productosAdicionalesModalidadTituloHTML};
		}

		getValue = (valor, inputObject, valid) => {

			this.setState({[inputObject.iDCampo]: valor});
		}
		getMoreData = () => {
			this.setState({quantity: this.state.quantity + 200});
			console.log(this.state.quantity);
		}
	
	render() {
		const filterSelect = {
			"campo" : 1,
			"etiqueta" : "Filtrar Registros",
			"iDCampo" : "filter",
			"longitud" : 100,
			"observacion" : "",
			"tipoCampo" : "Select",
			"validacion" : "Requerido",
			"valor" : [
				{
					"texto": "Inscripciones completas",
					"valor": 1
				},
				{
					"texto": "Registros incompletos o borrados",
					"valor": 2
				},
				{
					"texto": "Ver todos los registros",
					"valor": 3
				}
			],
			"anchoCampo":"s12 m12 l12"
		  }

		var tituloTablaIngresos;
		var perfilUsuarioEvento = this.props.registryAutorizedUsers[this.props.userID].perfil; 
		if(perfilUsuarioEvento.localeCompare('MasterCliente') == 0 || 
		perfilUsuarioEvento.localeCompare('MasterOrganizador') == 0 || 
		perfilUsuarioEvento.localeCompare('master') == 0){
		  tituloTablaIngresos = <div><h5> Listado de inscritos</h5>
		  <p> Inlcuye ingresos cobrados en linea y en puntos de venta. Reporte disponible solo para usuarios Master</p></div>;
		}else{
		  tituloTablaIngresos = ( 
		  <div>
			  <h5> Lista de inscritos</h5>
			  <p> Nota: Reporte de ingresos registrados con tu nombre de usuario</p>
				<DownloadButtomAllData eventParticipants={this.props.eventParticipants}
					reportType={3}
					eventDetail={this.props.eventDetail}
					registryAutorizedUsers={this.props.registryAutorizedUsers}
					userID={this.props.userID}
					registryConfiguration={this.props.registryConfiguration}
					eventSubEvents={this.props.eventSubEvents}/>
			</div>);

		}


		var camposAdicionales = this.camposAdicionalesModalidad();

		var productosAdicionales = this.productosAdicionalesModalidadTitulo();

		var apellidosSeparadosSet = this.props.registryConfiguration.configuracion.separarApellidos;
		var apellidos = [];
		  if(apellidosSeparadosSet){
			apellidos.push(<th key={`itemPatTitle`} data-field="Apellidos">Apellido Paterno</th>);
			apellidos.push(<th key={`itemMatTitle`} data-field="Apellidos">Apellido Materno</th>);
		  }else{
			apellidos.push(<th key={`itemApTitle`} data-field="Apellidos">Apellidos</th>);
		  }
	
		  // TODO: filtro por modalidad, rama, categoria.
		  // TODO agregar vista de cambio a peso.
		  
		 

		return (
		<div className="">
			{tituloTablaIngresos}
			<div className="col s12">
				<DynamicInputs key={123213123} inputObject={filterSelect} 
					actualValue={this.state.filter} 
					callbackchange={this.getValue}/>
				<button onClick={() => this.getMoreData()} style={{width:"100%"}} className="btn waves-effect waves-light" type="submit" name="action">
					Mostrar registros anteriores
				</button>
			</div>
			
            <table className="card-panel bordered highlight">
                <thead>
					<tr key={0}>
						<th data-field="Numero">Numero</th>
						<th data-field="Nombre">Nombre</th>
						{apellidos}
						<th data-field="Correo">Correo</th>
						<th data-field="Telefono">Teléfono</th>
						<th data-field="Modalidad">Modalidad</th>
						<th data-field="Rama">Rama</th>
						<th data-field="Categoria">Categoria</th>
						<th data-field="UsuarioRegistra">Usuario</th>
						<th data-field="PrecioCobrado">Precio cobrado</th>
						<th data-field="Fecha Registro">Fecha Registro</th>
						{camposAdicionales.camposAdicionalesModalidadHTML}
						{productosAdicionales.productosAdicionalesModalidadTituloHTML}
						<th data-field="Estatus">Estatus</th>
					</tr>
                </thead>
					<IncomeTableCont camposAdicionales={camposAdicionales} 
					productosAdicionales={productosAdicionales} filter={this.state.filter}
					quantity={this.state.quantity}/>
            </table>
		</div>
        );
	}
  }

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

const mapStateToProps = (state) => {
	return {
		eventParticipants : state.registry.eventParticipants,
		eventSubEvents :state.events.eventSubEvents,
		registryConfiguration : state.registry.registryConfiguration,
        registryAutorizedUsers : state.registry.registryAutorizedUsers,
		userID : state.atlete.userID,
		eventDetail: state.events.eventDetail,
		atleteAccount : state.atlete.atleteAccount
	}
  }
  
const Income = connect(
	mapStateToProps,
	mapDispatchToProps
)(IncomeView);
export default Income;
