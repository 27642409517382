import React from 'react';
import { connect } from 'react-redux'
import {Button, Input, Icon, Row} from 'react-materialize';
import { fechahora } from '../../../../../services/utils';
import { db } from '../../../../../firebase/firebasejs';
import M from 'materialize-css'

class AddAutorizedUserView extends React.Component {
    // recibe iduser
    constructor(props){
        super(props)
        this.state = {
            correo:"",
            perfil:""
        }
    }

    getValue(e){
		var name = e.target.name;
		var value = e.target.value;
			this.setState({[name]: value});
	}

 
    sendForm = (e) => {
        e.preventDefault();
        var datosAutorizado = {
            correo: this.state.correo,
            perfil: this.state.perfil,
            fechaAutoriza: fechahora(),
            userIdAuth: this.props.userID,
            status:""
          };
          console.log(datosAutorizado);
        
          // Get a key for a new Post.
          var newPostKey = db.ref().child(`/eventosCompletos/${this.props.EventoIndex}/autorizacionOrganizador/`).push().key;
        
          // Write the new post's data simultaneously in the posts list and the user's post list.
        
          var Ref = db.ref(`/eventosCompletos/${this.props.EventoIndex}/autorizacionOrganizador/${newPostKey}/status/`);
          Ref.on('value', function(snapshot) {
            var status = snapshot.val();
            if(status == "ok"){
              M.toast('Agregado exitosamente', 2000);
            }else if(status == "" || status == null){
             
            }else{
              M.toast( status, 2000);
            }
          });
        
          var updates = {};
          updates[`/eventosCompletos/${this.props.EventoIndex}/autorizacionOrganizador/${newPostKey}/`] = datosAutorizado;
          return db.ref().update(updates);
      
    }
   
    
    render(){
    
        return (
            <div className="row">
                <h5>Agregar usuarios autorizados</h5>
                    <Input name="correo" onChange={e => this.getValue(e)} type="email" label="Correo Eletrónico" error="Ingresa una dirección de correo válida" validate s={6} />
                <Input name="perfil" onChange={e => this.getValue(e)} s={6} type='select' label="Perfil" defaultValue='inscriptor'>
									<option value='inscriptor'>Punto de venta</option>
									<option value='MasterOrganizador'>Master Organizador</option>
									<option value='MasterCliente' >Master Cliente</option>
					</Input>
                <div className="input-field col s6">
                    <a onClick={e => this.sendForm(e)} className="waves-effect waves-light btn">
                        <i className="material-icons right">
                            send
                        </i>
                        autorizar
                    </a>
                </div>
            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
	return {
        }
        
	}

const mapStateToProps = (state) => {
	return {
		EventoIndex: "evento_"+state.events.eventDetail.iDEvento,
        userID : state.atlete.userID
	}
  }
  
const AddAutorizedUser = connect(
	mapStateToProps,
	mapDispatchToProps
)(AddAutorizedUserView);

export default AddAutorizedUser;