import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
  buttons2: {
    margin: 30,
    width: "250px",
    color: "white",
  },
}));

export default function EmptySpace(props) {
  const { bgColor, height } = props.componentConfig;

  //trackEvent({ event: { event: "PageView", page: eventId } });

  return (
    <div style={{ height: height || "auto", backgroundColor: bgColor }}></div>
  );
}

EmptySpace.displayName = "Espacio vacío para posicionar otras secciones";

export const componentDescription = {
  componentConfig: {
    bgColor: {
      label: "Color de Fondo",
      errorMessage: "",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    height: {
      label: "Alto de la sección",
      errorMessage: "",
      fieldName: "height",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
