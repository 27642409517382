import React from 'react';

const DynamicInputTitle = ({inputObject}) => {

  var CustomTag = inputObject.valor;
      return  (<div className={"col s12 m12 l12"}>
                <CustomTag>{inputObject.etiqueta}</CustomTag>
              </div>);

}

export default DynamicInputTitle;
