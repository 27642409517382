import React from 'react';
import { connect } from 'react-redux'
import {eventParticipants,
        registryConfiguration} from '../../../../../data-store/actions/registry-actions';
import AutorizedUsers from './AutorizedUsers';
import AddAutorizedUser from './AddAutorizedUser';
import DeletedParticipantNumber from './DeleteParticipantsTable/DeletedParticipantList';
import ChangesList from './ChangesTable/ChangesList';
import DownloadButtomAllData from '../DownloadData/DownloadButtomAllData';

class RegistryConfigurationContainerView extends React.Component {

	constructor(props){
		super(props)
    }

	render() {
		console.log(this.props.registryAutorizedUsers);
        return (
		<div>
			<DownloadButtomAllData eventParticipants={this.props.eventParticipants}
						reportType={1}
						eventDetail={this.props.eventDetail}
						registryAutorizedUsers={this.props.registryAutorizedUsers}
						userID={this.props.userID}
						registryConfiguration={this.props.registryConfiguration}
						eventSubEvents={this.props.eventSubEvents}/>

			<AddAutorizedUser/>
			<AutorizedUsers registryAutorizedUsers={this.props.registryAutorizedUsers}/>
			<DeletedParticipantNumber eventParticipants={this.props.eventParticipants}
										userID={this.props.userID}
										atleteAccount={this.props.atleteAccount}
										eventDetail={this.props.eventDetail}/>
			<ChangesList eventParticipants={this.props.eventParticipants}
										userID={this.props.userID}
										atleteAccount={this.props.atleteAccount}
										eventDetail={this.props.eventDetail}/>
		</div>
        );
	}
  }

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

const mapStateToProps = (state) => {
	return {
		eventParticipants : state.registry.eventParticipants,
		eventSubEvents :state.events.eventSubEvents,
		registryConfiguration : state.registry.registryConfiguration,
        registryAutorizedUsers : state.registry.registryAutorizedUsers,
		userID : state.atlete.userID,
		eventDetail: state.events.eventDetail,
		atleteAccount : state.atlete.atleteAccount
		}
  }
  
const RegistryConfigurationContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(RegistryConfigurationContainerView);
export default RegistryConfigurationContainer;
