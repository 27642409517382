import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

//import { uploadUserPhoto } from "../../../../data-store/actions/atlete-actions";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import InputFileButton from "./InputFileButton1.1";
import { getPhotoFileToUpload } from "../../services/utils";
import * as fb from "../../firebase/firebasejs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
}));

export default function FieldFile(props) {
  const { fieldObject, register, errors } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    route,
  } = fieldObject;

  const { callbackPhoto } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [urlPhoto, setUrlPhoto] = useState("");
  const [uploadingPhotos, setuploadingPhotos] = useState(null);
  const [uploadingPhotosProgress, setuploadingPhotosProgress] = useState(null);

  const callback = (file) => {
    const rutaFoto = route ? route : "fotosVarias/" + Date.now();
    console.log("uploadPhoto");
    if (file) {
      var storageRef = fb.storage.ref();
      var uploadTask = storageRef.child(rutaFoto + "/" + file.name).put(file);
      uploadTask.on(
        "state_changed",
        function(snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setuploadingPhotos(file.name);
          setuploadingPhotosProgress(progress);

          console.log("Upload is " + progress + "% done");
        },
        function(error) {
          // Handle unsuccessful uploads
          setuploadingPhotos(null);
          setuploadingPhotosProgress(null);
        },
        function() {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            setuploadingPhotos(null);
            setuploadingPhotosProgress(null);

            setUrlPhoto(downloadURL);
            console.log("File available at", downloadURL);
          });
        }
      );
    }
  };

  return (
    <Card className={classes.root}>
      <CardActions>
        {urlPhoto}

        <InputFileButton
          className={classes.inputLabel}
          type="*"
          callback={callback}
          uploadingPhotos={uploadingPhotos}
          uploadingPhotosProgress={uploadingPhotosProgress}
          text="Subir Archivo"
        />
      </CardActions>
      <TextField
        disabled={false}
        defaultValue={urlPhoto}
        value={urlPhoto}
        inputRef={register({ required: required })}
        name={fieldName}
        type="hidden"
      />
    </Card>
  );
}
