import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Paper } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventIcon from "@material-ui/icons/Event";
//import UserPaymentMethodsAdd from "../UserProfile/UserPaymentMethodsAdd";
import CardPaymentList from "./CardPaymentList";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Conekta from "../../../../../conekta/ConektaHelper";
import { showSnackbar } from "../../../../../data-store/actions/organizers-actions";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useTracking } from "react-tracking";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function OxxoPayment(props) {
  const {
    callback,
    order,
    test,
    currency,
    currencyBase,
    currentOrder,
    eventDetail,
  } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const registryParticipantKey = useSelector(
    (state) => state.registry.registryParticipantKey
  );

  const { trackEvent } = useTracking();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [sending, setSending] = useState(false);

  // const [cardNumber, setCardNumber] = useState(null);
  // const [cardOwner, setCardOwner] = useState(null);

  function generateOxxoPayment() {
    setSending(true);
    var validarConekta = true;

    let production = true;

    if (test) {
      production = false;
    }
    Conekta.initConekta(production);

    var amount = 0;
    var items = order;
    var conektaItems = [];
    for (var i in items) {
      var price = items[i].unit_price;
      price =
        Math.round(parseFloat(price * currencyBase.cambioAPeso) * 100) / 100;

      var item = {
        name: items[i].name,
        quantity: items[i].quantity,
        unit_price: Math.round(price * 100),
      };

      amount =
        Math.round(
          (parseFloat(amount) +
            parseFloat(price) * parseFloat(items[i].quantity)) *
            100
        ) / 100;
      console.log("amount " + amount);
      conektaItems.push(item);
    }

    var jsonDatos = {
      correo: currentOrder.correo,
      token: "",
      nombres: currentOrder.nombres + " " + currentOrder.apellidos,
      telefono: currentOrder.telefono,
      tipoPago: "oxxo_pay",
      inscritoKey: registryParticipantKey,
      eventoIndex: "evento_" + eventDetail.iDEvento,
      last4: "",
      brand: "",
      amount: amount,
      jsonItems: conektaItems,
    };

    console.log(jsonDatos);

    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    axios
      .post(
        "https://us-central1-kmetasfirebase.cloudfunctions.net/conektaCargoV2",
        jsonDatos,
        headers
      )
      .then((res) => {
        console.log("data", res);
        dispatch(showSnackbar(true, "Pago exitoso ", 4000));
        callback(true, res.data);

        const tkl = query.get("tkl");

        trackEvent({
          eventName: "evento_" + eventDetail.iDEvento,
          typeEvent: "Purchase",
          params: {
            label: "Purchase",
            tkl: tkl || "direct",
            order: order,
            eventName: eventDetail.nombreEvento,
            facebookPixel: eventDetail.facebookPixel || false,
            gaId: eventDetail.gaId || false,
          },
        });

        setSending(false);
      })
      .catch((error) => {
        console.log("errorOxxo", error);
        setSending(false);
        var errorTexto = "";
        if (error.responseJSON !== undefined) {
          for (var i in error.responseJSON.details) {
            errorTexto =
              errorTexto + error.responseJSON.details[i].message + " - ";
          }
        }
        //callback(false);
        dispatch(
          showSnackbar(true, "Error procesando el pago: " + errorTexto, 2000)
        );
      });
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      >
        <Typography color="primary" component="h1" variant="h3">
          OXXO Pay
        </Typography>
        <Typography
          style={{ padding: "10px" }}
          color="primary"
          component="h1"
          variant="subtitle1"
        >
          Presione el siguiente botón para generar la ficha de pago, esta
          contiene un número de referencia el cuál se deberá suministrar al
          cajero del oxxo al que asista.
        </Typography>
        <Typography
          style={{ padding: "10px" }}
          color="primary"
          component="h1"
          variant="subtitle1"
        >
          La ficha tiene una vigencia de <b>48 horas</b>, de no realizarse el
          pago en ese periodo el registro quedará eliminado y deberá hacer un
          nuevo registro.
        </Typography>
        <Typography
          style={{ padding: "10px" }}
          color="primary"
          component="h1"
          variant="subtitle1"
        >
          Al momento de realizar el pago recibirá una notificación vía correo
          electrónico con la confirmación de su inscripción y número asignado
        </Typography>
      </Grid>

      <Button
        onClick={() => generateOxxoPayment()}
        variant="contained"
        color="primary"
        style={{
          height: "150px",
          width: "100%",
          fontSize: "18px",
          margin: "10px",
        }}
      >
        Generar ficha de pago en oxxo
      </Button>
      {sending && (
        <Grid item xs={12} sm={12}>
          <LinearProgress />
        </Grid>
      )}
    </Grid>
  );
}
