import * as types from "../actions/action-types";

const initialState = [];
export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_VIRTUAL_EVENT:
      return Object.assign({}, state, {
        createVirtualEvent: action.createVirtualEvent,
      });
    case types.REGISTRY_VIRTUAL_EVENT:
      return Object.assign({}, state, {
        registryVirtualEvent: action.registryVirtualEvent,
      });
    case types.USER_MY_ORGANIZED_VIRTUAL_EVENTS:
      var addOrganizedEventId = action.addOrganizedEventId;
      var addOrganizedEvent = action.addOrganizedEvent;
      addOrganizedEvent.id = addOrganizedEventId;
      return Object.assign({}, state, {
        myOrganizedVirtualEvents: Object.assign(
          {},
          state.myOrganizedVirtualEvents,
          {
            [addOrganizedEventId]: addOrganizedEvent,
          }
        ),
      });
    case types.USER_MY_VIRTUAL_EVENTS:
      var addEventId = action.addEventId;
      var addEvent = action.addEvent;
      addEvent.id = addEventId;
      return Object.assign({}, state, {
        myVirtualEvents: Object.assign({}, state.myVirtualEvents, {
          [addEventId]: addEvent,
        }),
      });
    case types.CURRENT_EVENT:
      return Object.assign({}, state, {
        currentEvent: action.currentEvent,
        currentEventReq: action.currentEventReq,
      });
    case types.CURRENT_EVENT_REQUESTED:
      return Object.assign({}, state, {
        currentEventReq: action.currentEventReq,
      });
    case types.AUTHORIZED_EVENTS_LIST:
      return Object.assign({}, state, {
        authorizedEventsList: action.authorizedEventsList,
      });
    case types.RESET_CURRENT_EVENT:
      return Object.assign({}, state, {
        currentEvent: action.currentEvent,
        currentEventReq: action.currentEventReq,
        currentEventParticipants: action.currentEventParticipants,
        eventPass: action.eventPass,
        currentEventParticipantsRequested:
          action.currentEventParticipantsRequested,
      });
    case types.CURRENT_EVENT_AUTHORIZED:
      return Object.assign({}, state, {
        currentEventAuthorized: action.currentEventAuthorized,
      });
    case types.CURRENT_EVENT_PARTICIPANTS:
      var participantId = action.participantId;
      var participant = action.participant;
      participant.id = participantId;
      return Object.assign({}, state, {
        currentEventParticipants: Object.assign(
          {},
          state.currentEventParticipants,
          {
            [participantId]: participant,
          }
        ),
      });
    case types.CURRENT_EVENT_PARTICIPANTS_REQUEST:
      return Object.assign({}, state, {
        currentEventParticipantsRequested:
          action.currentEventParticipantsRequested,
      });
    case types.EVENT_PASS_REQUEST:
      return Object.assign({}, state, {
        eventPassRequest: action.eventPassRequest,
      });
    case types.EVENT_PASS:
      var eventPassId = action.eventPassId;
      var eventPass = action.eventPass;
      eventPass.id = eventPassId;
      return Object.assign({}, state, {
        currentEventPass: Object.assign({}, state.currentEventPass, {
          [eventPassId]: eventPass,
        }),
      });
    case types.UPLOADING_PHOTOS:
      return Object.assign({}, state, {
        uploadingPhotos: action.uploadingPhotos,
        uploadingPhotosProgress: action.uploadingPhotosProgress,
      });
    default:
      return state;
  }
}
