import React from 'react';
import {RestarTiempos, TiempoAPasoMinxK} from '../../../../../../../services/utils';


const AtleteControls = ({resultado}) => {

    var Parciales = resultado.parciales;

    var parcialesArray = [];
    
    var TiempoAnterior='00:00:00';
    var KilómetroAnterior = 0;

    var DifTiempo;
    var PasoParcial;

    if(Parciales != undefined){
        Parciales.forEach(value => {
          if(value.pCat != 500 && value.kpc !=500 && value.kpc !=0){
            DifTiempo = RestarTiempos(TiempoAnterior, value.tkpc);
            PasoParcial = TiempoAPasoMinxK(DifTiempo, value.kpc - KilómetroAnterior);
      
            parcialesArray.push(<tr key={resultado.num - value.kpc}>
                <td>{KilómetroAnterior} - {value.kpc}</td>
                <td>{value.tkpc}</td>
                <td>{DifTiempo}</td>
                <td>{PasoParcial}</td>
                </tr>)
            TiempoAnterior = value.tkpc;
            KilómetroAnterior = value.kpc;
      
          }
        });
      }
    console.log(resultado.to);
    DifTiempo = RestarTiempos(TiempoAnterior, resultado.to);
    PasoParcial = TiempoAPasoMinxK(DifTiempo, resultado.mod - KilómetroAnterior);
    parcialesArray.push(<tr  key={resultado.num - 0}>
                <td>{KilómetroAnterior} - {resultado.mod}</td>
                <td>{resultado.to}</td>
               <td>{DifTiempo}</td>
                <td>{PasoParcial}</td>
                </tr>);

    let parcialesView = parcialesArray.map(parcial => parcial);

        return (
        <div>
            <div className="row">
              <div className="col s10 m10 l10">
              <h5>Parciales</h5>
              </div>
            </div>
            <div className="row">
            <div className="col s12 m8 offset-m2 z-depth-2">
                    <table className="bordered centered" id="DetalleComp">
                    <thead>
                        <tr>
                        <th scope="col">Kilómetro</th>
                        <th scope="col">Tiempo</th>
                        <th scope="col">Dif. Min.</th>
                        <th scope="col">Paso (min/K)</th>
                        </tr>
                    </thead>
                    <tbody id="ListaDetalles">
                        {parcialesView}
                    </tbody>
                    </table>
                </div>
          </div>
        </div>
       )
}


export default AtleteControls;
