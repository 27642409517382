import { combineReducers } from "redux";
import events from "./events-reducer";
import virtualEvents from "./virtual-events-reducer";
import atlete from "./atlete-reducer";
import registry from "./registry-reducer";
import chat from "./chat-reducer";
import organizer from "./organizer-reducer";

export default combineReducers({
  events,
  atlete,
  registry,
  chat,
  virtualEvents,
  organizer,
});
