import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { fechahora } from "../../../../../services/utils";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import LogoDashport from "../../../../../assets/backgroundPhotos/fotoPrincipal_Dashport_bajaResolucion.jpg";

import Login from "../../../../Login/Login";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function VirtualEventsOrganizerCard(props) {
  const { currentEvent, callbackView, showButtons } = props;
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  let myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );

  const classes = useStyles();

  if (athleteAccount == null) {
    return <Login />;
  }

  if (myVirtualEvents === undefined) {
    myVirtualEvents = {};
  }

  const image =
    currentEvent.eventPhoto === undefined
      ? LogoDashport
      : currentEvent.eventPhoto;

  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        alt={currentEvent.eventName}
        height="140"
        image={image}
        title={currentEvent.eventName}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Nombre : {currentEvent.eventName}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Fecha de inicio :{" "}
          {fechahora(
            currentEvent.eventStart != undefined
              ? currentEvent.eventStart.seconds
              : 0
          )}
          <br />
          Fecha de fin :{" "}
          {fechahora(
            currentEvent.eventEnd != undefined
              ? currentEvent.eventEnd.seconds
              : 0
          )}
          <br />
          Distancia : {currentEvent.distance} kilómetros
          <br />
        </Typography>
      </CardContent>
      <CardActions>
        {showButtons && (
          <div>
            <Button
              onClick={() => callbackView("config")}
              size="small"
              color="primary"
            >
              Configuración del reto
            </Button>
            <Button
              onClick={() => callbackView("participants")}
              size="small"
              color="primary"
            >
              Participantes
            </Button>
            {/* <Button onClick={() => callbackView("comunication")} size="small" color="primary">
            Comunicación
          </Button> */}
          </div>
        )}
      </CardActions>
    </Card>
  );
}
