import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { timestampToDate } from "../../../../services/utils";
import CircularProgress from "@material-ui/core/CircularProgress";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import LogoDashport from "../../../../assets/backgroundPhotos/fotoPrincipal_Dashport_bajaResolucion.jpg";

import Login from "../../../Login/Login";

import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function VirtualEventsCardFull(props) {
  const { currentEvent } = props;
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  let myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const classes = useStyles();

  if (athleteAccount == null) {
    return <Login />;
  }

  if (myVirtualEvents === undefined) {
    myVirtualEvents = {};
  }
  if (currentEvent === undefined) {
    return <CircularProgress />;
  }

  const image =
    currentEvent.eventPhoto === undefined
      ? LogoDashport
      : currentEvent.eventPhoto;
  return (
    <Card className={classes.root}>
      <CardMedia
        component="img"
        alt={currentEvent.eventName}
        height="auto"
        image={image}
        title={currentEvent.eventName}
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography gutterBottom variant="h5" component="h2">
              Nombre : {currentEvent.eventName}
            </Typography>
            <Typography gutterBottom variant="h6" component="h2">
              Descripción : {currentEvent.eventDescription}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Fecha de inicio :{" "}
              {timestampToDate(currentEvent.eventStart.seconds * 1000)}
              <br />
              Fecha de fin :{" "}
              {timestampToDate(currentEvent.eventEnd.seconds * 1000)}
              <br />
              Distancia : {currentEvent.distance} kilómetros
              <br />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <CardActions>
        {/* <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button> */}
      </CardActions>
    </Card>
  );
}
