import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
//import userMenuSections from "../../constants/UserMenuSections";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Paper, Typography } from "@material-ui/core";
//import InlineSvg from "../InlineSvg";

const useStyles = makeStyles((theme) => ({
  banner: {
    margin: 20,
    width: "80%",
  },
  list: {
    width: 250,
  },
  drawerHeaderText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 16,
  },
  fullList: {
    width: "auto",
  },
  fullHeight: {
    position: "relative",
    height: "100%",
    paddingBottom: 60,
  },
  logoContainer: {
    width: "50%",
    padding: 30,
    display: "flex",
    alignItems: "start",
    justifyContent: "flex-end",
  },
  imageContainer: {
    width: "50%",
    padding: 20,
  },
}));

export default function Drawer(props) {
  const history = useHistory();

  const { menu, colorTextMenu, bgColor, callback } = props;
  const classes = useStyles();
  let banner1, banner2;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [url, setUrl] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const selectedMenu = menu;

  const list = (anchor) => (
    <div
      style={{ backgroundColor: bgColor }}
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={classes.fullHeight}>
        {selectedMenu
          ? Object.values(selectedMenu)
              .filter((item) => !item.hidden)
              .map((item, i) => (
                <ListItem
                  key={i}
                  button
                  onClick={() => callback(item.url)}
                  divider
                >
                  <ListItemText>
                    <Typography
                      style={{ color: colorTextMenu }}
                      color="secondary"
                    >
                      {item.text}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))
          : null}
      </List>
      <Divider />
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: bgColor,
        height: "100%",
      }}
    >
      {selectedMenu && (
        <React.Fragment>
          <Button onClick={toggleDrawer("left", true)}>
            <MenuIcon />
          </Button>
          <SwipeableDrawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
            PaperProps={{
              style: {
                backgroundColor: bgColor,
                height: "100%",
              },
            }}
          >
            <Paper className={classes.list} elevation={0}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  backgroundColor: bgColor,
                }}
              >
                <div className={classes.imageContainer}>
                  {/* <img
                  src={
                    athleteAccount?.photoURL
                      ? athleteAccount.photoURL
                      : imageDefault
                  }
                  style={{
                    width: "100%",
                    margin: "auto",
                    borderRadius: "50%",
                    marginTop: 30,
                  }}
                /> */}
                </div>
                <div className={classes.logoContainer}>
                  <img src={""} style={{ width: "80%", margin: 0 }} />
                </div>
              </div>
              <div className={classes.drawerHeaderText}>
                <div>
                  {/* <InlineSvg
                  url={}
                  height={"18px"}
                  width={"18px"}
                  margin="10px"
                /> */}
                </div>
              </div>
              <Divider />
              {list("left")}
            </Paper>
          </SwipeableDrawer>
        </React.Fragment>
      )}
    </div>
  );
}
