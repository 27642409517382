import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import StartLine from "./VirtualEventMenu/StartLine/StartLine";

import StepperEvent from "./StepperEvent";

import LoginContainerVirtualEvents from "../../../../Login/LoginContainerVirtualEvents";

import { useForm, Controller } from "react-hook-form";
import {
  getEventData,
  getUserVirtualEvents,
  getEventParticipants,
  resetLastEvent,
} from "../../../../../data-store/actions/virtual-events-actions";
import {
  sendVirtualEventMessage,
  getVirtualEventsMessages,
} from "../../../../../data-store/actions/chat-actions";

import EndRegistry from "./VirtualEventMenu/EndRegistry/EndRegistry";
import VirtualEventsCard from "../VirtualEventsCardFull";
import ChatCustom from "../Chat/ChatCustom";
import AppContainer from "../../../../../AppContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  menu: {
    flex: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 50,
    width: 100,
  },
}));

export default function SpecificVirtualEventCotainer(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const eventID = match.params.eventID;
  const codeReceived = match.params.codeReceived;
  const registryEventID = match.params.registryEventID;
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventReq = useSelector(
    (state) => state.virtualEvents.currentEventReq
  );
  const currentEventParticipants = useSelector(
    (state) => state.virtualEvents.currentEventParticipants
  );
  const currentEventParticipantsRequested = useSelector(
    (state) => state.virtualEvents.currentEventParticipantsRequested
  );

  console.log("id", eventID);
  useEffect(() => {
    console.log("useEffect", eventID);
    console.log("useEffect11", userID);

    if (userID != undefined) {
      console.log("useEffect", eventID);
      console.log("useEffectCurrent", currentEventReq);
      if (currentEventReq != eventID) {
        console.log("useEffectcall If", eventID);
        dispatch(getEventData(eventID, myVirtualEvents, userID));
      }

      if (myVirtualEvents == undefined && userID != undefined) {
        console.log("getUserVirtualEvents", eventID);
        dispatch(getUserVirtualEvents(userID));
      }
      if (
        currentEvent != undefined &&
        myVirtualEvents != undefined &&
        myVirtualEvents[eventID] != undefined &&
        myVirtualEvents[eventID].active &&
        currentEventParticipantsRequested != eventID
      ) {
        console.log("getEventParticipants", eventID);
        dispatch(getEventParticipants(eventID, userID));
        dispatch(getVirtualEventsMessages(userID, currentEvent.eventID, false));
      }

      // return () => {
      //   unsubscribe && unsubscribe();
      // };
    }
  }, [myVirtualEvents, userID, currentEvent]);

  const classes = useStyles();

  const { control, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  if (atleteAccount == null) {
    return <LoginContainerVirtualEvents />;
  }
  if (currentEvent == undefined) {
    return (
      <AppContainer>
        <CircularProgress />
      </AppContainer>
    );
  } else if (JSON.stringify(currentEvent) === JSON.stringify({})) {
    return (
      <AppContainer>
        <h4>
          El Id del evento ingresado no existe o el evento ya no está abierto al
          público
        </h4>
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <VirtualEventsCard currentEvent={currentEvent} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChatCustom
              buttonType="block"
              writeAvailable={false}
              buttonText="Mensajes del Organizador"
              topics={["all"]}
              title="Mensajes del Organizador"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChatCustom
              buttonType="block"
              writeAvailable={true}
              buttonText="Chatea con el organizador"
              topics={[userID + "-organizer", "organizer", userID]}
              title="Organizador"
            />
          </Grid>
          <Grid item xs={12}>
            <StepperEvent codeReceived={codeReceived} />
          </Grid>
        </Grid>
        <div style={{ fontFamily: "Forza", color: "white" }}>Dashport</div>
        <div style={{ fontFamily: "digital-7Final", color: "white" }}>
          Dashport
        </div>
        <div
          style={{ fontFamily: "MavenPro-VariableFont_wght", color: "white" }}
        >
          Dashport
        </div>
      </div>
    </AppContainer>
  );
}
