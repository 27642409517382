import * as types from "../actions/action-types";

const initialState = [];
export default function atleteReducer(state = initialState, action) {
  switch (action.type) {
    case types.ORGANIZER_WEB:
      return Object.assign({}, state, {
        organizerWeb: action.organizerWeb,
      });
    case types.EVENT_WEB_PUBLIC:
      return Object.assign({}, state, {
        eventWebPublic: action.eventWebPublic,
      });
    case types.SHOW_SNACKBAR:
      return Object.assign({}, state, {
        snackbar: action.snackbar,
      });
    case types.ORGANIZER_EVENTS:
      return Object.assign({}, state, {
        organizerEvents: action.organizerEvents,
      });

    default:
      return state;
  }
}
