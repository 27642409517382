import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { fechahora } from "../../../../../../../services/utils";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";

import PhotoEditorContainer from "../../../PhotoEditorVirtual/PhotoEditorContainer";
import AtleteDetailContainer from "./AtleteDetailContainer";
import ChatCustom from "../../../Chat/ChatCustom";

import IconReaction from "../../../Chat/IconReaction";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  large: {
    width: 130,
    height: 130,
    margin: 5,
  },
}));

export default function AthleteAvatarModal(props) {
  const { currentEvent, participant, type } = props;

  const userID = useSelector((state) => state.atlete.userID);
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (participant == undefined) {
    return <div></div>;
  }

  if (type == "result") {
    return (
      <div>
        <Avatar
          alt=""
          onClick={handleClickOpen}
          src={participant.finishLinePhoto}
          className={classes.large}
        />
        {/* <ChatCustom buttonType="block" writeAvailable={true} 
                      buttonText="" 
                      topics={[participant.id, userID, "private"]} 
                      title={participant.athleteName}/>
      <IconReaction reaction="letsgo" 
                topics={[participant.id, "startLinePhoto"]}/> */}

        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"lg"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Grid container style={{ minHeight: 400 }}>
              <Grid item>
                <AtleteDetailContainer
                  participant={participant}
                  currentEvent={currentEvent}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return (
    <div>
      <Avatar
        alt=""
        onClick={handleClickOpen}
        src={
          type == "start"
            ? participant.startLinePhoto
            : participant.finishLinePhoto
        }
        className={classes.large}
      />
      {/* <ChatCustom buttonType="block" writeAvailable={true} 
                      buttonText="",
                      between={[participant.id, userID]} 
                      topics={[participant.id,"private"]} 
                      title={participant.athleteName}/>
      <IconReaction reaction="letsgo" 
                topics={[participant.id, "startLinePhoto"]}/> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography gutterBottom variant="h3">
            {participant.athleteName + " " + participant.athleteLastName}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              <Paper>
                <Grid item xs={12} className={classes.paper}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {type == "start" ? "Tiempo Objetivo" : "Tiempo Final"}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.paper}>
                  <div style={{ fontSize: 70 }}>
                    {type == "start"
                      ? participant.goalTime == undefined ||
                        participant.goalTime == null
                        ? "Objetivo no definido"
                        : participant.goalTime
                      : participant.finishTime == undefined ||
                        participant.finishTime == null
                      ? "Tiempo no definido"
                      : participant.finishTime}
                  </div>
                </Grid>
                <Grid item xs={12} className={classes.paper}>
                  {/* <Button onClick={() => resetGoal()}>
              Cambiar Meta
            </Button> */}
                </Grid>
              </Paper>
              <Grid item xs={12} className={classes.paper}>
                <Paper style={{ padding: 10 }}>
                  <PhotoEditorContainer
                    buttomLabel={"Abrir Editor"}
                    title={"Editor"}
                    participant={participant}
                    currentEvent={currentEvent}
                    urlPhoto={
                      type == "start"
                        ? participant.startLinePhoto
                        : participant.finishLinePhoto
                    }
                  />

                  <img
                    style={{ maxWidth: "100%" }}
                    src={
                      type == "start"
                        ? participant.startLinePhoto
                        : participant.finishLinePhoto
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

//   <HtmlTooltip key={key}
//   title={
//     <React.Fragment>
//       <Typography color="inherit">{participant.athleteName +" "+ participant.athleteLastName}</Typography>
//       {participant.goalTime}
//     </React.Fragment>
//   }
// >
//     <Avatar alt="Remy Sharp" src={participant.img} className={classes.large} />

// </HtmlTooltip>
//     //   <img src={participant.img} alt={participant.athleteName +" "+ participant.athleteLastName} />
//     //   <GridListTileBar
//     //     title={participant.athleteName +" "+ participant.athleteLastName}
//     //     subtitle={<span>{participant.goalTime}</span>}
//     //     actionIcon={
//     //       <IconButton className={classes.icon}>
//     //         <PanToolIcon />
//     //       </IconButton>
//     //     }
//     //   />
//     // </GridListTile>

// <Card className={classes.root}>
// <CardActionArea>
//   <CardMedia
//     component="img"
//     alt={participant.athleteName +" "+ participant.athleteLastName}
//     height="200"
//     image={participant.startLinePhoto}
//     title={participant.athleteName +" "+ participant.athleteLastName}
//   />
//   <CardContent>
//   <Grid container spacing={3}>
//     <Grid item xs={12} sm={12}>
//           <Typography gutterBottom variant="h5" component="h2">
//             {participant.athleteName +" "+ participant.athleteLastName}
//           </Typography>
//           <Typography variant="body2" color="textSecondary" component="p">
//           {(type == "start")?
//             (participant.goalTime == undefined || participant.goalTime == null)?"Objetivo no definido":"Objetivo :"+participant.goalTime
//             :
//             participant.finishTime
//           }
//           </Typography>
//       </Grid>
//     </Grid>
//   </CardContent>
// </CardActionArea>
// <CardActions>
//   {/* <Button size="small" color="primary">
//     Share
//   </Button>
//   <Button size="small" color="primary">
//     Learn More
//   </Button> */}
// </CardActions>
// </Card>
