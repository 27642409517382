import React from 'react';
import { connect } from 'react-redux'
import { segundosATiempo } from '../../../../../../../services/utils';
import {calcularGraficaResultados} from './alteteDetailLogic';
import {HorizontalBar} from 'react-chartjs-2';

const AtleteChartsView = ({resultado, eventResults}) => {
  var data = calcularGraficaResultados(resultado, eventResults);

  const options = {
    scales: {
      xAxes: [{
          ticks: {
            callback: function(value) { 
                return segundosATiempo(value); 
            }
        }
      }]
    },  
    tooltips: {
      // Disable the on-canvas tooltip
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          console.log(tooltipItem);
            var label = segundosATiempo(tooltipItem.xLabel);
            return label;
        }
    }
    },
    maintainAspectRatio:false
  }
  
  console.log(data);

       return (
      <div>
        <div className="row">
          <div className="col s10 m10 l10">
          <h5>Gráficas del atleta</h5>
          </div>
        </div>
        <div className="row">
          <div className="col s10 m10 l10">
                <HorizontalBar
                    data={data}
                    height={300}
                    options={options}
                  />
          </div>
        </div>
      </div>
  )
}

const mapDispatchToProps = (dispatch) => {
	return {
		}
	}

const mapStateToProps = (state) => {
  
	return {
    eventResults : state.events.eventResults
	}
}
  
const AtleteCharts = connect(
	mapStateToProps,
	mapDispatchToProps
)(AtleteChartsView);

export default AtleteCharts;