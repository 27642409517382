import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import { useForm, Controller } from "react-hook-form";
import {
  updateEventAtribute,
  createEventPass,
  updateEventPass,
} from "../../../../data-store/actions/virtual-events-actions";
import { requestActivitiesAppTraking } from "../../../../data-store/actions/atlete-actions";
import MapContainerTrakingApp from "./MapContainerTrakingApp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function TrakingAppContainer(props) {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const activitiesAppTraking = useSelector(
    (state) => state.atlete.activitiesAppTraking
  );

  useEffect(() => {
    console.log("trakingApp");
    if (userID != undefined) {
      dispatch(requestActivitiesAppTraking(userID));
    }
  }, []);

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Mapa recorrido
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MapContainerTrakingApp activity={activitiesAppTraking} />
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
