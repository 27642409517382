import React from "react";
import Paper from "@material-ui/core/Paper";

import AthleteAvatarModal from "../atleteDetail/AthleteAvatarModal";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

export default function ResultsPodiumItem(props) {
  const { participant, position, currentEvent } = props;

  let positionheight = {
    1: 350,
    2: 300,
    3: 250,
  };
  let positionText = {
    1: "Primer Lugar",
    2: "Segundo Lugar",
    3: "Tercer Lugar",
  };

  return (
    <Grid container alignItems="center" alignContent="center">
      <Paper
        style={{
          padding: 10,
          height: positionheight[position],
          textAlign: "center",
        }}
      >
        <Grid item xs={12}>
          <AthleteAvatarModal
            participant={participant}
            currentEvent={currentEvent}
            type="result"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            {participant != undefined
              ? participant.athleteName + " " + participant.athleteLastName
              : ""}
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            {positionText[position]}
          </Typography>
        </Grid>
      </Paper>
    </Grid>
  );
}
