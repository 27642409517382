import React from 'react';

const DashboardExtraInputs = ({jsonAdicionales}) => {

  var Adicionales = [];
  var data = {    
    labels: [],
    series: []
};
  for (var e in jsonAdicionales){
    console.log("resumen ad");
    
    var campo = jsonAdicionales[e].etiqueta;
    var objeto = jsonAdicionales[e].valores;
    var suma = jsonAdicionales[e].suma;
    console.log("resumen suma" + suma);
    console.log("resumen ad" + campo);
    console.log("resumen ad" + JSON.stringify(objeto));

    var adicionalesData = [];
    for (var f in objeto){
      var title = (f === "false")?"no":f;
      var title = (f === "true")?"si":f;
      adicionalesData.push(<div key={e+f+"item"} className="col s2 center-align"> 
                              {title}
                               <br/> 
                              <b>
                                {objeto[f]}
                              </b>
                                ({Math.round((objeto[f]/suma)*10000)/100}%)
                            </div>);
      data.labels.push(f);
      data.series.push(objeto[f]);
        console.log("resumen "+campo+" "+f +" "+objeto[f]);
      }
      Adicionales.push(<div key={e} className="row">
                        <h5>
                          {campo}
                        </h5>
                        {adicionalesData}
                      </div>);

  }

    return (			
        <div className="row z-depth-2 padding">
          <div className="col s12">
            {Adicionales}
          </div>
        </div>);
}

export default DashboardExtraInputs;
