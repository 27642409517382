import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import MenuItem from "@material-ui/core/MenuItem";
//import "./FieldCheckbox.css";

const useStyles = makeStyles((theme) => ({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "black",
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
    color: "black",
  },
  text: {
    position: "relative",
    paddingRight: 40,
    color: "black",
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    color: "black",
    padding: "10px",
    backgroundColor: "red",
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
}));

export default function FieldRadioButton(props) {
  const { fieldObject, register, errors } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    optionsArray,
  } = fieldObject;
  const classes = useStyles();
  const [editMode, setEditMode] = useState(true);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  function handleChange(event) {
    console.log(event.target.value);
    setCurrentValue(event.target.value);
  }

  return (
    <div>
      <input
        style={{ display: "none" }}
        type="text"
        name={fieldName}
        ref={register}
        value={currentValue}
      />
      <FormControl component="fieldset">
        <FormLabel className={classes.inputLabel} component="legend">
          {label}
        </FormLabel>
        <RadioGroup
          className={classes.inputLabel}
          aria-label={fieldName}
          name={fieldName}
          value={currentValue}
          onChange={handleChange}
        >
          {optionsArray.map((option) => (
            <FormControlLabel
              className={classes.inputLabel}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {errors[fieldName] ? errorMessage : null}
    </div>
  );
}
