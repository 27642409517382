import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import M from 'materialize-css'
import { fechahora } from '../../../../services/utils'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import VirtualEventsCard from './VirtualEventsCard'

import { Link } from 'react-router-dom';

import Login from '../../../Login/Login'
import Typography from '@material-ui/core/Typography';

import { useForm, Controller } from 'react-hook-form';
import { participateEvent } from '../../../../data-store/actions/virtual-events-actions';
import { resetLastEvent } from '../../../../data-store/actions/virtual-events-actions'



const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function MyVirtualEvents(props){
  const dispatch = useDispatch();
  const athleteAccount = useSelector(state => state.atlete.atleteAccount);
  let myVirtualEvents = useSelector(state => state.virtualEvents.myVirtualEvents);
  const userID = useSelector(state => state.atlete.userID);
	const classes = useStyles();
 
  const { control, handleSubmit, errors } = useForm();


  useEffect(() => {
      dispatch(resetLastEvent())
  }, []);

	if(athleteAccount == null){
		return <Login/>
  }

  if(myVirtualEvents === undefined){
    myVirtualEvents ={}
  }


	  return (
			<div className={classes.root}>
          <Typography gutterBottom variant="h5" component="h2">
              Mis eventos virtuales
          </Typography>
      <Grid container spacing={3} justify="space-between" direction="row">
            {Object.keys(myVirtualEvents).map((keyName, i) => (
                <Grid key={i} item xs={12} sm={6}>
                <Link to={"/retos/"+keyName}>
                    <VirtualEventsCard currentEvent={myVirtualEvents[keyName].eventData}/>
                  </Link>
                  </Grid>
            ))}
      </Grid>
    </div>
	  );
	}
	