import React from "react";
import { Input, Icon, Row } from "react-materialize";
import Button from "@material-ui/core/Button";
import { useForm } from "react-hook-form";

import { auth } from "../../firebase/firebasejs";
import M from "materialize-css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AppContainer from "../../AppContainer";

function PassworRestore() {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    auth.sendPasswordResetEmail(data.correo).then(
      () => {
        // Email sent.
        M.toast("Se ha enviado un correo a tu cuenta", 4000);
      },
      (error) => {
        // An error happened.
        M.toast("Error en correo", 4000);
      }
    );
    console.log("rec contrasena");
  };

  return (
    <AppContainer>
      <div>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <Paper style={{ padding: "20px" }}>
                <h4>Recuperar Contraseña</h4>
                <label>Correo</label>
                <input
                  name="correo"
                  type="email"
                  ref={register({ required: true })}
                />
                {errors.correo && "Campo requerido"}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                >
                  Enviar correo de recuperación
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </div>
    </AppContainer>
  );
}

export default PassworRestore;
