import React from "react";
import { connect } from "react-redux";
import { atletePhotosFunc } from "../../../../data-store/actions/atlete-actions";
import { eventPhotoConfiguration } from "../../../../data-store/actions/events-actions";

import AtleteControls from "./AtleteControls";
import AtleteCharts from "./AtleteCharts";
import AtletePhotos from "./AtletePhotos";
import { puntoFotograficoPrevRuta } from "../../../../firebase/firebasejs";
import AtleteVideo from "./AtleteVideo";

class AtleteReportsContainerView extends React.Component {
  //({eventDetail, resultado, atletePhotos, eventConfiguration})
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  //                        <AtleteCharts resultado={this.props.resultado}/>

  render() {
    console.log("atletePhotosFunc", this.props.atletePhotos);
    return (
      <div className="row">
        <div className="col s12">
          <AtleteVideo
            resultado={this.props.resultado}
            eventDetail={this.props.eventDetail}
          />
          <AtletePhotos
            resultado={this.props.resultado}
            atletePhotos={this.props.atletePhotos}
          />

          {this.props.eventConfiguration.timingConfig &&
          this.props.eventConfiguration.timingConfig.hideControls ? (
            <div></div>
          ) : (
            <AtleteControls
              resultado={this.props.resultado}
              puntosControl={this.props.puntosControl}
            />
          )}

          <AtleteCharts resultado={this.props.resultado} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (
  dispatch,
  { resultado, eventDetail, eventConfiguration }
) => {
  var puntoFotografico = 1;
  var eventoIndex = `evento_${eventDetail.iDEvento}`;
  var fechaHoraMeta = resultado.fhm;
  return {
    atletePhotos: dispatch(
      atletePhotosFunc(eventoIndex, fechaHoraMeta, puntoFotografico)
    ),
    eventPhotoConfiguration: dispatch(eventPhotoConfiguration(eventoIndex)),
  };
};

const mapStateToProps = (state) => {
  console.log(state);

  const eventConfiguration = {
    cronometrajeDisponible: true,
    eventoPublico: true,
    fotografiasDisponibles: true,
    inscripcionesDisponibles: false,
    resultadosDisponibles: true,
    tiempoRealDisponible: true,
  };

  // TODO: cambiar state.events.eventConfiguration  colocar esto y borrar la variable
  // regresar la vista de graficas a su lugar, la tenfo arriba comentada.

  return {
    atletePhotos: state.atlete.atletePhotos,
    eventDetail: state.events.eventDetail,
    eventConfiguration: state.events.eventConfiguration,
    puntosControl: state.events.puntosControl.val(),
  };
};

const AtleteReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AtleteReportsContainerView);

export default AtleteReportsContainer;
