import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { helperMultisort } from "../../../../../../../services/utils";

import AthleteAvatarModal from "../atleteDetail/AthleteAvatarModal";

import PaginationComponent from "../../Pagination/PaginationComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "white",
  },
  large: {
    width: 150,
    height: 150,
    margin: 5,
  },
}));

export default function FinishLineParticipants(props) {
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(
    (state) => state.virtualEvents.currentEventParticipants
  );

  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const [page, setPage] = useState(1);
  const itemsPerPage = 100;

  const goalime = myVirtualEvents[currentEvent.eventID];

  const classes = useStyles();

  function callBackPage(page) {
    setPage(page);
    console.log(page);
  }

  function paginate(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  let participantsArray = Object.keys(currentEventParticipants);
  let participantsCount = 0;
  let startPictures = startPicturesFunc(currentEventParticipants);
  let startPicturesPage = paginate(startPictures, itemsPerPage, page);
  function startPicturesFunc() {
    let startPicturesTemp = [];
    for (var i in currentEventParticipants) {
      if (
        currentEventParticipants[i].finishTime != undefined ||
        currentEventParticipants[i].finishTime != null
      ) {
        participantsCount++;
        var participant = currentEventParticipants[i];
        var photoFinal = participant.finishLinePhoto;
        startPicturesTemp.push(participant);
      }
    }

    return helperMultisort(startPicturesTemp, ["finishTime"], ["ASC"]);
  }
  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="h5" component="h2">
        {participantsCount == 1
          ? " Un atleta ya terminó"
          : participantsCount + " atletas ya terminaron"}
      </Typography>

      <PaginationComponent
        callBackPage={callBackPage}
        totalItems={startPictures.length}
        itemsPerPage={100}
      />

      <Grid container direction="row" wrap="wrap">
        {startPicturesPage.map((participant, key) => (
          <AthleteAvatarModal
            key={key}
            participant={participant}
            currentEvent={currentEvent}
            type="finish"
          />
        ))}
      </Grid>
    </div>
  );
}
