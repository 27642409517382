import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function GridBoxInfo(props) {
  const {
    boxesArray,
    boxXs,
    boxSm,
    textColor,
    borderColor,
    bgColor,
    imageSize,
    boxHeight,
  } = props.componentConfig;
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="space-around"
      style={{ backgroundColor: bgColor, padding: 20 }}
    >
      {boxesArray &&
        boxesArray
          .filter((item) => !item.hidden)
          .map((box, i) => (
            <Grid key={i} item xs={boxXs} sm={boxSm} style={{ padding: 5 }}>
              <Box
                borderColor={borderColor}
                border={2}
                style={{ padding: 20, height: boxHeight }}
              >
                <div
                  style={{
                    width: "100%",
                    alignContent: "left",
                    alignItems: "left",
                  }}
                >
                  {box.image && (
                    <img
                      src={box.image}
                      style={{ display: "block", margin: "0" }}
                      alt={box.alt}
                      height={`${imageSize}px`}
                    />
                  )}
                </div>

                <Typography
                  variant="body2"
                  style={{
                    color: textColor,
                    textAlign: "justify",
                    marginTop: 20,
                  }}
                >
                  {box.text}
                </Typography>
              </Box>
            </Grid>
          ))}
    </Grid>
  );
}

GridBoxInfo.displayName = "Cuadros informativos con texto e imagen 2";

export const componentDescription = {
  componentConfig: {
    boxesArray: {
      label: "Lista de Secciones",
      errorMessage: "Campo Requerido",
      fieldName: "boxesArray",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      inputsArray: {
        alt: {
          label: "Alt imagen",
          errorMessage: "Campo Requerido",
          fieldName: "alt",
          type: "text",
          defaultValue: "",
          required: true,
          regex: "",
          textSize: "h6",
        },
        image: {
          label: "Imagen",
          errorMessage: "Campo Requerido",
          fieldName: "image",
          type: "photo",
          defaultValue: "",
          required: false,
          regex: "",
          textSize: "h6",
        },
        text: {
          label: "Texto",
          errorMessage: "Campo Requerido",
          fieldName: "text",
          type: "text",
          defaultValue: "",
          required: true,
          regex: "",
          textSize: "h6",
        },
      },
    },
    boxXs: {
      label: "Ancho de cada item en pantallas pequeñas (Entero del 1 al 12)",
      errorMessage: "Campo Requerido",
      fieldName: "boxXs",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      min: 1,
      max: 12,
    },
    boxSm: {
      label: "Ancho de cada item en pantallas grandes (Entero del 1 al 12)",
      errorMessage: "Campo Requerido",
      fieldName: "boxSm",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      min: 1,
      max: 12,
    },
    image: {
      label: "Imagen",
      errorMessage: "Campo Requerido",
      fieldName: "image",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    textColor: {
      label: "Color del texto",
      errorMessage: "Campo Requerido",
      fieldName: "textColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    borderColor: {
      label: "Color del Borde",
      errorMessage: "Campo Requerido",
      fieldName: "borderColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    imageSize: {
      label: "Tamaño de la imágen (ejm 100px)",
      errorMessage: "Campo Requerido",
      fieldName: "imageSize",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    boxHeight: {
      label: "Tamaño del recuadro (ejm 100px)",
      errorMessage: "Campo Requerido",
      fieldName: "boxHeight",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
