import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import { useForm, Controller } from 'react-hook-form';
import { updateEventAtribute } from '../../../../../../../data-store/actions/virtual-events-actions';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function EventGeneralData(props){
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  let myOrganizedVirtualEvents = useSelector(state => state.virtualEvents.myOrganizedVirtualEvents);

  const [saveIcon , setSaveIcon] = useState({
    eventName : false,
    eventDescription : false,
    eventStart : false,
    eventEnd : false,
    distance : false
  });
  const classes = useStyles();
  

  const { register, handleSubmit, errors, getValues, control } = useForm({
    defaultValues :{
      eventName : currentEvent.eventName,
      eventDescription : currentEvent.eventDescription,
      eventStart: currentEvent.eventStart,
      eventEnd : currentEvent.eventEnd,
      distance : currentEvent.distance
    }
  });

  const onSubmit = data => {
    console.log(data);
    var object = { [data.atribute] : data.value}
      dispatch(updateEventAtribute(userID,currentEvent.eventID, object)) 
  }
  const setEventEnded = (value) =>{
      var object = { eventEnded : value};
      dispatch(updateEventAtribute(userID,currentEvent.eventID, object)) 
  }
  const setAdmitResults = (value) =>{
      var object = { admitResults : value};
      dispatch(updateEventAtribute(userID,currentEvent.eventID, object)) 
  }
  const saveAttribute = (attribute) =>{
    var object = { [attribute] : getValues(attribute)};
    dispatch(updateEventAtribute(userID,currentEvent.eventID, object));
    setSaveIcon({...saveIcon, [attribute] : false })
  }
  const setSaveIncon = (attribute) => {
    setSaveIcon({...saveIcon, [attribute] : true })
  }

	  return (
          <Card className={classes.root}>
              <CardContent>
                <form onSubmit={handleSubmit(onSubmit)} style={{width:"100%"}}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h5" component="h2">
                        Detalle del evento
                      </Typography>
                    </Grid>

                    <Grid item xs={10}>
                      <label>Nombre del evento</label>
                      <input name="eventName" onChange={() => setSaveIncon("eventName")} ref={register({ required: true, maxLength: 50 })} />
                      {errors.eventName && <span>Campo requerido</span>}
                    </Grid>
                    <Grid item xs={2}>
                    {saveIcon["eventName"] && 
                      <IconButton primary onClick={() => saveAttribute("eventName")}>
                      <SaveIcon fontSize="large"/>
                    </IconButton>
                    }
                    </Grid>

                    <Grid item xs={10}>
                      <label>Descripción del evento</label>
                      <input name="eventDescription" onChange={() => setSaveIncon("eventDescription")} ref={register({required: true})} />
                      {errors.eventDescription && <span>Campo requerido</span>}
                    </Grid>
                    <Grid item xs={2}>
                    {saveIcon["eventDescription"] && 
                      <IconButton primary onClick={() => saveAttribute("eventDescription")}>
                        <SaveIcon fontSize="large"/>
                      </IconButton>
                    }
                    </Grid>

                    <Grid item xs={10}>
                    <label>Fecha de inicio</label>
                    <input type="datetime-local" placeholder="date" name="eventStart" ref={register} />
                      {errors.eventStart && <span>Campo requerido</span>}
                    </Grid>
                    <Grid item xs={2}>
                    {saveIcon["eventStart"] && 
                      <IconButton primary onClick={() => saveAttribute("eventStart")}>
                        <SaveIcon fontSize="large"/>
                      </IconButton>
                    }
                    </Grid>

                    <Grid item xs={10}> 
                    <label>Fecha de finalización</label>
                    <input type="datetime-local" placeholder="date" name="eventEnd" ref={register} />
                      {errors.eventEnd && <span>Campo requerido</span>}
                    </Grid>
                    <Grid item xs={2}>
                    {saveIcon["eventEnd"] && 
                      <IconButton primary onClick={() => saveAttribute("eventEnd")}>
                        <SaveIcon fontSize="large"/>
                      </IconButton>
                    }
                    </Grid>

                    <Grid item xs={10}>
                      <label>Distancia</label>
                      <input name="distance" type="number" step="0.01" onChange={() => setSaveIncon("distance")} ref={register({required: true})} />
                      {errors.distance && <span>Campo requerido</span>}
                    </Grid>
                    <Grid item xs={2}>
                    {saveIcon["distance"] && 
                      <IconButton primary onClick={() => saveAttribute("distance")}>
                        <SaveIcon fontSize="large"/>
                      </IconButton>
                    }
                    </Grid>
                </Grid>
                </form>

                    <Grid item xs={12}>
                    <Typography gutterBottom variant="h6" component="h2">
                       Registro de resultados {(currentEvent.admitResults)?"PERMITIDOS":"BLOQUEADOS"}
                    </Typography>
                      <Button variant="contained" onClick={() => setAdmitResults(!currentEvent.admitResults)} color="primary">
                        {(currentEvent.admitResults)?"Bloquer Registro":"Permitir Registro"}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h6" component="h2">
                        Estatus del evento {(currentEvent.eventEnded)?"CERRADO":"ABIERTO"}
                      </Typography>
                      <Button variant="contained" onClick={() => setEventEnded(!currentEvent.eventEnded)} color="primary">
                        {(currentEvent.eventEnded)?"Abrir Evento":"Cerrar Evento"}
                      </Button>
                    </Grid>

              </CardContent>
            <CardActions>

            </CardActions>
          </Card>
	  );
	}
	