import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import Login from "../../../../Login/Login";
import VirtualEventsOrganizerCard from "./VirtualEventsOrganizerCard";

import { resetLastEvent } from "../../../../../data-store/actions/virtual-events-actions";

import { useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function MyOrganizedVirtualEvents(props) {
  const dispatch = useDispatch();
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  let myOrganizedVirtualEvents = useSelector(
    (state) => state.virtualEvents.myOrganizedVirtualEvents
  );
  const userID = useSelector((state) => state.atlete.userID);
  const classes = useStyles();

  const { control, handleSubmit, errors } = useForm();

  useEffect(() => {
    dispatch(resetLastEvent());
  }, []);

  if (athleteAccount == null) {
    return <Login />;
  }

  if (myOrganizedVirtualEvents == undefined) {
    myOrganizedVirtualEvents = {};
  }

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant="h5" component="h2">
        Eventos virtuales Organizados por mí
      </Typography>
      <Grid container spacing={3}>
        {Object.keys(myOrganizedVirtualEvents)
          .sort((a, b) => a.eventStart > b.eventStart)
          .map((keyName, i) => (
            <Grid item xs={12} sm={6}>
              <Link to={"/retos-organizador/" + keyName}>
                <VirtualEventsOrganizerCard
                  showButtons={false}
                  currentEvent={myOrganizedVirtualEvents[keyName]}
                />
              </Link>
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
