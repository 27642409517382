import React from 'react';
import Message from '@material-ui/icons/Message';

const RequiredMessage = ({message}) =>{
  if(message != ""){
    return (<div style={{fontSize:"12px", color:"red"}}>{message}<Message/></div>)
  }
  return null
} 
export default RequiredMessage;
