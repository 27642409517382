import React from "react";

export default function LoadFonts() {
  return (
    <div>
      <span
        style={{ fontFamily: "BebasNeue-Regular", color: "white", fontSize: 1 }}
      >
        a
      </span>
      <span style={{ fontFamily: "BelizioBold", color: "white", fontSize: 1 }}>
        a
      </span>
      <span
        style={{ fontFamily: "ScoutCond-Bold", color: "white", fontSize: 1 }}
      >
        a
      </span>
      <span
        style={{ fontFamily: "MinionPro-Regular", color: "white", fontSize: 1 }}
      >
        a
      </span>
      <span
        style={{
          fontFamily: "Metronic_Slab_Pro_Black",
          color: "white",
          fontSize: 1,
        }}
      >
        a
      </span>
      <span
        style={{
          fontFamily: "CheddarGothicRough-Regular",
          color: "white",
          fontSize: 1,
        }}
      >
        a
      </span>

      <span style={{ fontFamily: "Forza", color: "white", fontSize: 1 }}>
        a
      </span>
      <span style={{ fontFamily: "Maven", color: "white", fontSize: 1 }}>
        a
      </span>
      <span
        style={{
          fontFamily: "digital-7Final",
          color: "white",
          fontSize: 1,
        }}
      >
        a
      </span>
      <span
        style={{
          fontFamily: "Redig-Regular",
          color: "white",
          fontSize: 1,
        }}
      >
        a
      </span>
    </div>
  );
}
