import React from 'react';
import EditableInput from '../../../../DynamicInput/EditableInput';

const AditionalInputsAndProductsDetail = ({ParticipantDetail, registryConfiguration, eventSubEvents, eventDetail, ParticipantDetailKey, userID, atleteAccount}) => {

    console.log(registryConfiguration);

    const camposAdicionalesModalidad = () => {

        var camposAdicionalesModalidad = [];
        var camposAdicionalesModalidadHTML = [];
    
        var formulario = registryConfiguration.formulario;
        for(var i in formulario){
            if(formulario[i].tipoCampo != "titulo"){
                camposAdicionalesModalidad.push(formulario[i]);
                camposAdicionalesModalidadHTML.push(
                    <EditableInput key={i+"CA"} 
                        ParticipantDetail={ParticipantDetail} 
                        userID = {userID}
                        atleteAccount = {atleteAccount}
                        eventDetail={eventDetail} 
                        ParticipantDetailKey={ParticipantDetailKey}
                        inputObject={formulario[i]}/>);
            }
        }
    
        var modalidadesBuscar = eventSubEvents;
    
        var arrayCampoModalidad = [];
    
        for(var i in modalidadesBuscar){
            var modalidadCampos = modalidadesBuscar[i].configModalidad.camposAdicionalesModalidad;
            if(modalidadCampos != undefined){
                for(var e in modalidadCampos){
                    if(modalidadesBuscar[i].ramaCat["rama_"+ParticipantDetail.rama]
                    != undefined && modalidadesBuscar[i].ramaCat["rama_"+ParticipantDetail.rama]
                    .categorias["categoria_"+ParticipantDetail.categoria] != undefined && modalidadesBuscar[i].ramaCat["rama_"+ParticipantDetail.rama]
                    .categorias["categoria_"+ParticipantDetail.categoria]
                    .camposAdicionalesKey != undefined 
                    && modalidadesBuscar[i].ramaCat["rama_"+ParticipantDetail.rama]
                    .categorias["categoria_"+ParticipantDetail.categoria]
                    .camposAdicionalesKey == e){
                        var campos = modalidadCampos[e];
                        console.log(e);
                        for(var f in campos){
                            if(campos[f].tipoCampo != "titulo"){
                                if(!arrayCampoModalidad.includes(campos[f].campo)){
                                    arrayCampoModalidad.push(campos[f].campo);
                                    camposAdicionalesModalidad.push(campos[f]);

                                    camposAdicionalesModalidadHTML.push(
                                        <EditableInput key={i+e+f+"CA"} 
                                            ParticipantDetail={ParticipantDetail} 
                                            userID = {userID}
                                            atleteAccount = {atleteAccount}
                                            eventDetail={eventDetail} 
                                            ParticipantDetailKey={ParticipantDetailKey}
                                            inputObject={campos[f]}/>)
                                    console.log("Campos "+campos[f].campo);
                                }
                            }
                        }
                    }
                }
            }
        }
    
        return {camposAdicionalesModalidad : camposAdicionalesModalidad,
            camposAdicionalesModalidadHTML : camposAdicionalesModalidadHTML};
    }
    
    const productosAdicionalesModalidadTitulo = () => {

        var productosAdicionalesModalidadTitulo = [];
        var productosAdicionalesModalidadTituloHTML = [];
    
        var modalidadesBuscar = eventSubEvents;
        var arrayCodigoProducto = [];
        console.log(modalidadesBuscar);
    
        for(var i in modalidadesBuscar){
            var modalidadProductos = modalidadesBuscar[i].configModalidad.productosAdicionales;
            if(modalidadProductos != undefined){
                for(var e in modalidadProductos){
                    if(!arrayCodigoProducto.includes(modalidadProductos[e].codigoProducto)){
                        arrayCodigoProducto.push(modalidadProductos[e].codigoProducto);
                        productosAdicionalesModalidadTitulo.push(modalidadProductos[e]);

                        productosAdicionalesModalidadTituloHTML.push(
                            <h6 key={i+e+"cantidad"} 
                                data-field={`${modalidadProductos[e].nombreProducto}-Cantidad`}>
                                {modalidadProductos[e].nombreProducto}
                            </h6>);
                        var formularioProducto = modalidadProductos[e].formularioProducto;
                        if(formularioProducto != undefined){
                            for(var f in formularioProducto){
                                productosAdicionalesModalidadTituloHTML.push(
                                <EditableInput key={i+"CA"} 
                                    ParticipantDetail={ParticipantDetail} 
                                    userID = {userID}
                                    atleteAccount = {atleteAccount}
                                    eventDetail={eventDetail} 
                                    ParticipantDetailKey={ParticipantDetailKey}
                                    inputObject={formularioProducto[f]}/>);
                            }
                        }
            
                        console.log(JSON.stringify(modalidadProductos[e]));
                    }
                }
            }
        }
    
        return {productosAdicionalesModalidadTitulo:productosAdicionalesModalidadTitulo,
            productosAdicionalesModalidadTituloHTML:productosAdicionalesModalidadTituloHTML};
    }



    var camposAdicionales = camposAdicionalesModalidad().camposAdicionalesModalidadHTML;
    var productosAdicionales = productosAdicionalesModalidadTitulo().productosAdicionalesModalidadTituloHTML;
// TODO: agregar el areglo de productos adicionales cuando tenga la vista lista de cada producto 
// y cuando se puede hacer el pago de la diferencia de precio al a gregar o quitar un producto.
    console.log(productosAdicionales);
    var tabla = 
    (<div className="row">
    <hr/>
        <div className="card-panel col s12 m12 l12">
            {camposAdicionales}
        </div>
    </div>);

  return tabla;
}

export default AditionalInputsAndProductsDetail;
