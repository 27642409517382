import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { helperMultisort } from "../../../../../../../services/utils";
import { CSVLink, CSVDownload } from "react-csv";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import ImageIcon from "@material-ui/icons/Image";
import M from "materialize-css";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ShareIcon from "@material-ui/icons/Share";

import { useForm, Controller } from "react-hook-form";
import {
  updateEventAtribute,
  createEventPass,
  updateEventPass,
} from "../../../../../../../data-store/actions/virtual-events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function EventAccess(props) {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const currentEventPass = useSelector(
    (state) => state.virtualEvents.currentEventPass
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(
    (state) => state.virtualEvents.currentEventParticipants
  );

  const [showAllPass, setShowAllPass] = useState(10);

  const { register, handleSubmit, errors } = useForm();

  const classes = useStyles();
  const onSubmit = (data) => {
    console.log(data);

    if (countGeneratedCodes(data).count <= currentEvent.accessCodeAdmited) {
      dispatch(
        createEventPass(currentEvent.eventID, data, currentEventPass, userID)
      );
    } else {
      M.toast(
        "El límite de accesos permitidos es de " +
          currentEvent.accessCodeAdmited,
        4000
      );
    }
  };

  const countGeneratedCodes = (data) => {
    var count = { count: 0, current: 0, create: 0 };
    // contar los ya existentes
    for (var i in currentEventPass) {
      count.count = count.count + currentEventPass[i].uses;
      count.current = count.current + currentEventPass[i].uses;
    }

    // contar el intento
    for (var i = 0; i < data.codeQuantity; i++) {
      count.count = count.count + data.codeQuantityUses / 1;
      count.create = count.create + data.codeQuantityUses / 1;
    }
    return count;
  };
  const handleShare = (code) => {
    console.log("share", code);
    let urlToShare =
      "https://dashport.run/retos/" + currentEvent.eventID + "/code/" + code;
    if (navigator.share) {
      navigator
        .share({
          title: "Dashport",
          text: currentEvent.eventName,
          url: urlToShare,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      const el = document.createElement("textarea");
      el.value = urlToShare;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      M.toast(
        "Código copiado en el porta papeles, puedes pegarlo donde gustes compartir ",
        4000
      );
    }
  };
  const handleDelete = (code) => {
    console.log("delete", code);
    var uses = code.uses;
    var used = code.used != undefined ? code.used.length : 0;
    if (used < uses) {
      console.log("queda usos todavía");
      var object = { active: !code.active };
      dispatch(updateEventPass(currentEvent.eventID, code.code, object));
    } else {
      console.log("Código agotado no se puede liberar");
      return false;
    }
  };
  let eventPass = [];
  if (currentEventPass != undefined) {
    eventPass = helperMultisort(
      Object.values(currentEventPass),
      ["date"],
      ["DESC"]
    );
  }

  const csvDataFunc = () => {
    var csvData = [];
    var eventPass = { ...currentEventPass };
    for (var i in eventPass) {
      eventPass[i].usedCount =
        eventPass[i].used != undefined ? eventPass[i].used.length : 0;
      eventPass[i].active = eventPass[i].active ? "SI" : "NO";
      eventPass[i].usedText = JSON.stringify(eventPass[i].used);
      let urlToShare =
        "https://dashport.run/retos/" +
        currentEvent.eventID +
        "/code/" +
        eventPass[i].code;
      csvData.push({
        ...eventPass[i],
        url: urlToShare,
      });
    }
    return csvData;
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Accesos al reto
        </Typography>
      </CardContent>
      <CardActions>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <label>Cantidad de Códigos</label>
              <input
                name="codeQuantity"
                type="number"
                style={{ fontSize: 20 }}
                ref={register({ required: true, min: 1, max: 500 })}
              />
              {errors.codeQuantity && <span>Campo requerido</span>}
            </Grid>
            <Grid item xs={4}>
              <label>Usos por Código</label>
              <input
                name="codeQuantityUses"
                type="number"
                style={{ fontSize: 20 }}
                ref={register({ required: true, min: 1, max: 1000 })}
              />
              {errors.codeQuantityUses && <span>Campo requerido</span>}
            </Grid>
            <Grid item xs={4}>
              <Button type="submit" variant="contained" color="primary">
                Generar Códigos
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h5" component="h2">
                Lista de códigos
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {Object.values(eventPass)
                  .slice(0, showAllPass)
                  .map((code) => (
                    <ListItem
                      key={code.code}
                      style={{
                        backgroundColor: code.active ? "white" : "grey",
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar onClick={() => handleDelete(code)}>
                          <DeleteIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          code.code +
                          " " +
                          (code.active ? "" : " - CÓDIGO AGOTADO O ELIMINADO")
                        }
                        secondary={
                          (code.used == undefined ? 0 : code.used.length) +
                          " veces usado de " +
                          code.uses
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => handleShare(code.code)}
                          edge="end"
                          aria-label="delete"
                        >
                          <ShareIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
              <Button onClick={() => setShowAllPass(1000000)}>
                Ver todos los códigos
              </Button>
              <CSVLink data={csvDataFunc()}>
                <Button>Descargar Lista</Button>
              </CSVLink>
            </Grid>
          </Grid>
        </form>
      </CardActions>
    </Card>
  );
}
