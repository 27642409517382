import React from 'react';
import BackForwardButton from '../Forms/BackForwardButton';
import ConektaCard from './ConektaCard';
import OxxoPay from './OxxoPay';
import PaymentDetailRegistryForm from '../Forms/PaymentDetail/PaymentDetailRegistryForm';

class PaymentContainer extends React.Component {
  constructor(props){
    super(props)
        this.state = {
        }

}

  render(){
  return (<div className="row">
   
    <h5>Pago Container</h5>
    <PaymentDetailRegistryForm
        removeItemOrder={this.props.removeItemOrder}
        registrytype={this.props.registryType}
        order={this.props.athleteData.order}
        monedaAUsar={this.props.athleteData.monedaAUsar}
        monedaBase={this.props.athleteData.monedaBase}
      />
    <ConektaCard 
      athleteData={this.props.athleteData} 
      test={this.props.test}
      registryParticipantData={this.props.registryParticipantData}
      registryParticipantKey={this.props.registryParticipantKey}
      eventDetail={this.props.eventDetail}
      backForward={this.props.backForward}
      setPaymentMethod={this.props.setPaymentMethod}

      />
    <OxxoPay 
      athleteData={this.props.athleteData} 
      test={this.props.test}
      registryParticipantData={this.props.registryParticipantData}
      registryParticipantKey={this.props.registryParticipantKey}
      eventDetail={this.props.eventDetail}
      backForward={this.props.backForward}
      setPaymentMethod={this.props.setPaymentMethod}
      />
              
  </div>);
  }
}

export default PaymentContainer;
