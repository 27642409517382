import React, {useState} from 'react';
import PhotoEditorContainer from '../../../PhotoEditorVirtual/PhotoEditorContainer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { segundosATiempo } from '../../../../../../../services/utils'


import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import TodayIcon from '@material-ui/icons/Today';
import TimerIcon from '@material-ui/icons/Timer';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SpeedIcon from '@material-ui/icons/Speed';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import PoolIcon from '@material-ui/icons/Pool';
import AtleteGarminDetailItem from './AtleteGarminDetailItem'
import AtleteGarminLaps from './AtleteGarminLaps';

export default function AtleteGarminDetail(props){
    const { participant, currentEvent} = props;
    const [isOpen, setIsOpen] = useState(false)

    if(participant.garminActivitySummary == undefined){
        return <div></div>
      }
      var summary = participant.garminActivitySummary;

      const iconsArray = {
        RUNNING:<DirectionsRunIcon />,
        CYCLING:<DirectionsBikeIcon />,
        SWIMMING:<PoolIcon />
      }
    
     return (
            <Grid container spacing={3} direction="row" justify="space-evenly" 
                    wrap="wrap" alignContent="center" alignItems="flex-start">
                <Grid item xs={12} sm={4}>
                <Paper style={{padding:10}}>
                    <Typography gutterBottom variant="h2" component="h1">
                        Datos de Garmin
                    </Typography>

                <List aria-label="garmin-activities">
                    {/* <AtleteGarminDetailItem text={"Actividad"} value={summary.activityType} icon={(iconsArray[summary.activityType] != undefined)?iconsArray[summary.activityType] : <AccessibilityIcon/>}/> */}
              
                    <AtleteGarminDetailItem text={"Distancia"} value={"Kilómetros: " +summary.distanceInMeters/1000} icon={<SpaceBarIcon/>}/>
                    <AtleteGarminDetailItem text={"Tiempo"} value={segundosATiempo(summary.durationInSeconds)} icon={<TimerIcon/>}/>
                    <AtleteGarminDetailItem text={"Calorias"} value={summary.activeKilocalories} icon={<FastfoodIcon/>}/>
                    <AtleteGarminDetailItem text={"Ritmo Cardiaco"} value={"Promedio por minuto: " + summary.averageHeartRateInBeatsPerMinute} icon={<FavoriteIcon/>}/>
                    <AtleteGarminDetailItem text={"Velocidad"} value={"Promedio en metros por segundo: " + summary.averageSpeedInMetersPerSecond} icon={<SpeedIcon/>}/>
                    </List>
                 </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AtleteGarminLaps participant={participant}  currentEvent={currentEvent} />

                </Grid>
            </Grid>
    )
}
