import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ImageGallery from "react-image-gallery";
import "../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Gallery(props) {
  const {
    photosArray,
    originalHeight,
    showThumbnails,
    thumbnailPosition,
    showFullscreenButton,
    autoPlay,
    showNav,
    bgColor,
  } = props.componentConfig;

  const images =
    photosArray === ""
      ? []
      : photosArray
          .filter((item) => !item.hidden)
          .map((box, i) => ({
            original: box.original,
            thumbnail: box.original,
            originalTitle: box.originalTitle,
          }));

  // const images = [
  //   {
  //     original: "https://picsum.photos/id/1018/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1018/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1015/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1015/250/150/",
  //   },
  //   {
  //     original: "https://picsum.photos/id/1019/1000/600/",
  //     thumbnail: "https://picsum.photos/id/1019/250/150/",
  //   },
  // ];
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justify="space-between"
      style={{ backgroundColor: bgColor }}
    >
      <Grid item xs={12} sm={12}>
        <ImageGallery
          items={images}
          showThumbnails={showThumbnails}
          thumbnailPosition={thumbnailPosition || "bottom"}
          showFullscreenButton={showFullscreenButton}
          autoPlay={autoPlay}
          showNav={showNav}
          originalHeight={originalHeight}
          fullscreen={true}
        />
      </Grid>
    </Grid>
  );
}

Gallery.displayName = "Galería de fotos o slider";

export const componentDescription = {
  componentConfig: {
    photosArray: {
      label: "Lista de Secciones",
      errorMessage: "Campo Requerido",
      fieldName: "photosArray",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      inputsArray: {
        original: {
          label: "Imagen",
          errorMessage: "Campo Requerido",
          fieldName: "original",
          type: "photo",
          defaultValue: "",
          required: false,
          regex: "",
          textSize: "h6",
        },
        originalTitle: {
          label: "Titulo para la foto (Opcional)",
          errorMessage: "Campo Requerido",
          fieldName: "originalTitle",
          type: "text",
          defaultValue: "",
          required: false,
          regex: "",
          textSize: "h6",
        },
        alt: {
          label: "Alt imagen",
          errorMessage: "Campo Requerido",
          fieldName: "alt",
          type: "text",
          defaultValue: "",
          required: false,
          regex: "",
          textSize: "h6",
        },
      },
    },
    originalHeight: {
      label: "Tamaño de la imágen (ejm 100px)",
      errorMessage: "Campo Requerido",
      fieldName: "originalHeight",
      type: "text",
      defaultValue: "100px",
      required: true,
      regex: "",
      textSize: "h6",
    },
    showThumbnails: {
      label: "Mostrar miniaturas",
      errorMessage: "Campo Requerido",
      fieldName: "showThumbnails",
      type: "switch",
      defaultValue: false,
      required: true,
      regex: "",
      textSize: "h6",
    },
    thumbnailPosition: {
      label: "Posición de las minuaturas",
      errorMessage: "Campo Requerido",
      fieldName: "thumbnailPosition",
      type: "select",
      defaultValue: "bottom",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "top",
          label: "Arriba",
        },
        {
          value: "right",
          label: "Derecha",
        },
        {
          value: "bottom",
          label: "Abajo",
        },
        {
          value: "left",
          label: "Izquierda",
        },
      ],
    },
    showFullscreenButton: {
      label: "Botón para Pantalla completa",
      errorMessage: "Campo Requerido",
      fieldName: "showFullscreenButton",
      type: "switch",
      defaultValue: false,
      required: true,
      regex: "",
      textSize: "h6",
    },
    autoPlay: {
      label: "Activar la transición de imagenes",
      errorMessage: "Campo Requerido",
      fieldName: "autoPlay",
      type: "switch",
      defaultValue: false,
      required: true,
      regex: "",
      textSize: "h6",
    },
    showNav: {
      label: "Mostrar botones de navegación",
      errorMessage: "Campo Requerido",
      fieldName: "showNav",
      type: "switch",
      defaultValue: false,
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
