import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { helperMultisort } from "../../../../../../../services/utils";
import Avatar from "@material-ui/core/Avatar";

import ResultsPodiumItem from "./ResultsPodiumItem";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import {
  useCalculateRelativePositions,
  useCalculateAbsolutePositions,
} from "../../../VirtualEventsHooks/VirtualEventsHooks";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ResultsPodium(props) {
  const { currentEventParticipants, currentEvent } = props;

  const classes = useStyles();

  let results = [];
  if (currentEventParticipants != undefined) {
    results = Object.values(currentEventParticipants);
  }

  results = useCalculateAbsolutePositions(results, true, currentEvent);
  let femenil = results.filter((result) => {
    return result.athleteGender === "F";
  });
  let varonil = results.filter((result) => {
    return result.athleteGender === "M";
  });

  femenil = helperMultisort(femenil, ["finishTime"], ["ASC"]);
  varonil = helperMultisort(varonil, ["finishTime"], ["ASC"]);
  console.log(results);

  if (!currentEvent.eventEnded) {
    return <div></div>;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          Ganadoras Femenil
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="flex-end"
        >
          <Grid item xs={4} sm={3}>
            <ResultsPodiumItem
              participant={femenil[2]}
              position={3}
              currentEvent={currentEvent}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <ResultsPodiumItem
              participant={femenil[0]}
              position={1}
              currentEvent={currentEvent}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <ResultsPodiumItem
              participant={femenil[1]}
              position={2}
              currentEvent={currentEvent}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h4">
          Ganadores Varonil
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="flex-end"
        >
          <Grid item xs={4} sm={3}>
            <ResultsPodiumItem
              participant={varonil[2]}
              position={3}
              currentEvent={currentEvent}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <ResultsPodiumItem
              participant={varonil[0]}
              position={1}
              currentEvent={currentEvent}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <ResultsPodiumItem
              participant={varonil[1]}
              position={2}
              currentEvent={currentEvent}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
