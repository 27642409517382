import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
//import { updateUserAtribute } from "../../../data-store/actions/atlete-actions";
import { Paper } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventIcon from "@material-ui/icons/Event";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function UserPaymentMethodsAdd() {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    const expirationDate =
      (data.month < 10 ? "0" + data.month : data.month) + "/" + data.year;
    const object = {
      paymentMethods: {
        [data.cardNumber]: {
          cardOwner: data.cardOwner,
          cardNumber: data.cardNumber,
          expirationDate: expirationDate,
        },
      },
    };
    // TODO: mostrar confirmación y cerrar
    //dispatch(updateUserAtribute(userID, object));
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        style={{
          height: "80vh",
          width: "60vh",
          padding: "20px",
        }}
      >
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%" }}
        >
          <Grid item xs={12} sm={12}>
            <Typography color="primary" variant="h5">
              Nombre del propietario
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={errors.cardOwner ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              defaultValue={
                athleteAccount.nombres + " " + athleteAccount.apellidos
              }
              error={Boolean(errors.cardOwner)}
              fullWidth
              inputRef={register({ required: true })}
              name="cardOwner"
            />
            <Typography color="primary" variant="h5">
              Número de tarjeta
            </Typography>
            <TextField
              style={{ width: "100%" }}
              helperText={errors.cardNumber ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.cardNumber)}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 10,
              })}
              name="cardNumber"
            />
            <Typography color="primary" variant="h5">
              Fecha de vencimiento
            </Typography>
            <TextField
              label="Mes"
              style={{ width: "50%" }}
              helperText={errors.month ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <EventIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              defaultValue={1}
              error={Boolean(errors.month)}
              fullWidth
              inputRef={register({ required: true, min: 1, max: 12 })}
              name="month"
              type="number"
            />
            <TextField
              label="Año"
              style={{ width: "50%" }}
              helperText={errors.year ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <EventIcon style={{}} />
                  </InputAdornment>
                ),
              }}
              defaultValue={2021}
              error={Boolean(errors.year)}
              fullWidth
              inputRef={register({ required: true, min: 2020, max: 2050 })}
              name="year"
              type="number"
            />
            <Typography color="primary" variant="h5">
              Código de Seguridad
            </Typography>

            <TextField
              label="CVC"
              style={{ width: "100%" }}
              helperText={errors.cvc ? "Campo requerido" : null}
              InputProps={{
                style: {
                  marginBottom: 15,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <VerifiedUserIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              defaultValue={0}
              error={Boolean(errors.cvc)}
              fullWidth
              inputRef={register({ required: true, min: 0, max: 9999 })}
              name="cvc"
              type="number"
            />
            <Button
              type="submit"
              style={{ width: "100%" }}
              color="primary"
              variant="contained"
            >
              Realizar Pago
            </Button>
            <Grid item xs={12} sm={12} style={{ margin: "20px" }}>
              <Typography variant="p"></Typography>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Paper>
  );
}
