import React from 'react';
//import '../Modals/atleteDetailCss.css';
import {Modal} from 'react-materialize';

class AtleteDetailsModal extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			open : true
		}

		//this.state = {animation:"", show:true}
	}
	componentDidMount(){
		//this.setState({animation:"OpenAtlete"})
	}
	render(){
		const options = {
			dismissible: false,
			};
		return (
			<Modal className="sizeModal" open={this.props.show} options={options}>
						<span onClick={(e) => this.props.onClose(e, null, null)} className="btn-floating cerrarModal">
							<i className="material-icons">
								close
							</i>
						</span>


					{this.props.report}
			</Modal>
		)
	}
}
export default AtleteDetailsModal;
