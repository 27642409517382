import * as types from "./action-types";
import * as fb from "../../firebase/firebasejs";
import firebase from "firebase";
import { fechahoraNew } from "../../services/utils";
import { detalleEvento, eventSubEventsRegistry } from "./events-actions";

export function eventParticipants(eventoIndex) {
  console.log("Participantes o inscritos Evento evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.inscritos}${fb.listadoInscritos}`
  );
  return (dispatch) => {
    ref.once("value", (snapshot) => {
      console.log(snapshot.val());
      console.log("events participan value");
      dispatch({
        type: types.EVENT_PARTICIPANTS,
        eventParticipants: snapshot.val(),
      });
    });

    ref.on("child_changed", (snapshot) => {
      console.log(snapshot.val());
      console.log("child chaged");
      dispatch({
        type: types.EVENT_PARTICIPANTS_CHANGED,
        eventParticipantsChanged: snapshot.val(),
        eventParticipantsChangedKey: snapshot.key,
      });
    });
    ref.limitToLast(1).on("child_added", (snapshot) => {
      console.log(snapshot.val());
      console.log("child added");
      dispatch({
        type: types.EVENT_PARTICIPANTS_CHANGED,
        eventParticipantsChanged: snapshot.val(),
        eventParticipantsChangedKey: snapshot.key,
      });
    });
    ref.on("child_removed", (snapshot) => {
      console.log(snapshot.val());
      console.log("child removed");
      dispatch({
        type: types.EVENT_PARTICIPANTS_REMOVED,
        eventParticipantsChanged: snapshot.val(),
        eventParticipantsChangedKey: snapshot.key,
      });
    });
  };
}
export function registryConfiguration(eventoIndex) {
  console.log("Participantes o inscritos Evento evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.InscripcionesConfiguracion}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.REGISTRY_CONFIGURATION,
        registryConfiguration: snapshot.val(),
      });
    });
  };
}
export function registryAutorizedUsers(eventoIndex) {
  console.log("usuarios autorizados o inscritos Evento evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.OganizadoresEvento}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.REGISTRY_AUTORIZED_USERS,
        registryAutorizedUsers: snapshot.val(),
      });
    });
  };
}

export function resetRegistryParticipantData() {
  console.log("reset");
  return (dispatch) => {
    dispatch({
      type: types.RESET_REGISTRY_PARTICIPANT_DATA,
      registryParticipantData: null,
      registryParticipantKey: null,
      registryError: null,
    });
  };
}

export function registryParticipantDataFunc(
  eventoIndex,
  atleteData,
  formaDeInscripcionparam,
  userID
) {
  //resetRegistryParticipantData();
  console.log("Guardar Datos de inscripcion");

  if (userID === undefined) {
    atleteData.usuarioRegistra = "No registrado";
  } else {
    atleteData.usuarioRegistra = userID;
  }
  atleteData.fechaRegistro = fechahoraNew();
  atleteData.serverTime = firebase.database.ServerValue.TIMESTAMP;

  // 1 Inscripcion en linea
  // 2 Inscricppcion en punto de venta
  // 3 inscripcion por codigo

  if (formaDeInscripcionparam === 1) {
    atleteData.tipoRegistro = formaDeInscripcionparam;
    atleteData.statusPago = {
      status: "pendiente",
      formaInscripcion: "en linea",
      formaPago: "",
      fechaPago: "",
      serverTime: firebase.database.ServerValue.TIMESTAMP,
      idOperacion: 0,
    };
  } else if (formaDeInscripcionparam === 2) {
    atleteData.tipoRegistro = formaDeInscripcionparam;
    atleteData.statusPago = {
      status: "ok",
      formaInscripcion: "punto de Venta",
      formaPago: "efectivo",
      fechaPago: fechahoraNew(),
      serverTime: firebase.database.ServerValue.TIMESTAMP,
      idOperacion: 0,
    };
  } else if (formaDeInscripcionparam === 3) {
    atleteData.tipoRegistro = formaDeInscripcionparam;
    atleteData.statusPago = {
      status: "ok",
      formaInscripcion: "Codigo de inscripción",
      formaPago: "efectivo",
      fechaPago: fechahoraNew(),
      serverTime: firebase.database.ServerValue.TIMESTAMP,
      idOperacion: 0,
    };
  } else if (formaDeInscripcionparam === 4) {
    atleteData.tipoRegistro = 2; // seteo a 2 para ver enviar correo como ya pagado
    atleteData.statusPago = {
      status: "ok",
      formaInscripcion: "en linea",
      formaPago: "efectivo",
      fechaPago: fechahoraNew(),
      serverTime: firebase.database.ServerValue.TIMESTAMP,
      idOperacion: 0,
    };
  }

  var route = `${fb.EventosCompletos}${eventoIndex}/${fb.inscritos}${fb.listadoInscritos}`;
  var inscritoKey = fb.db.ref().child(route).push().key;

  var updates = {};

  updates[`${route}${inscritoKey}/`] = atleteData;

  var ref = fb.db.ref(`${route}${inscritoKey}/`);
  return (dispatch) => {
    fb.db
      .ref()
      .update(updates)
      .then(function () {
        ref.on("value", (snapshot) => {
          console.log(snapshot.val());
          dispatch({
            type: types.REGISTRY_PARTICIPANT_DATA,
            registryParticipantData: snapshot.val(),
            registryParticipantKey: snapshot.key,
            registryError: null,
          });
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.REGISTRY_PARTICIPANT_DATA,
          registryParticipantData: null,
          registryParticipantKey: null,
          registryError: error,
        });
      });
  };
}
export function registryFormDataFunc(eventoIndex, formId, formData) {
  //resetRegistryParticipantData();
  console.log("Guardar Datos formulario");

  var route = `${fb.EventosCompletos}${eventoIndex}/${fb.formsData}/${formId}/`;
  var itemKey = fb.db.ref().child(route).push().key;

  var updates = {};
  formData.date = new Date().getTime();

  updates[`${route}${itemKey}/`] = formData;

  var ref = fb.db.ref(`${route}${itemKey}/`);
  return (dispatch) => {
    fb.db
      .ref()
      .update(updates)
      .then(function () {
        ref.on("value", (snapshot) => {
          console.log(snapshot.val());
          dispatch({
            type: types.REGISTRY_FORM_DATA,
            registryFormData: snapshot.val(),
            registryFormKey: snapshot.key,
            registryError: null,
          });
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.REGISTRY_FORM_DATA,
          registryFormData: null,
          registryFormKey: null,
          registryError: error,
        });
      });
  };
}

export function getallDataForConfirmationFunc(eventoIndex, inscritoKey) {
  // 	//resetRegistryParticipantData();
  // 	console.log("get inscritos data para confirmación");
  console.log("inscritoKey", inscritoKey);
  console.log("inscritoKey", eventoIndex);
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.InscripcionesConfiguracion}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.REGISTRY_CONFIGURATION,
        registryConfiguration: snapshot.val(),
      });
      return fb.db
        .ref(
          "/eventosCompletos/" +
            eventoIndex +
            "/inscritos/listadoInscritos/" +
            inscritoKey
        )
        .once("value")
        .then((snap) => {
          console.log("registryParticipantData", snap.val());
          dispatch({
            type: types.REGISTRY_PARTICIPANT_DATA,
            registryParticipantData: snap.val(),
            registryParticipantKey: snap.key,
            registryError: null,
          });
          return fb.db
            .ref(
              "/eventosCompletos/" +
                eventoIndex +
                "/inscripcionesConfiguracion/"
            )
            .once("value");
        })
        .then((snap) => {
          dispatch({
            type: types.REGISTRY_CONFIGURATION,
            registryConfiguration: snap.val(),
          });
        });
    });
  };

  //   return db.ref('/eventosCompletos/'+eventoIndex+"/inscritos/listadoInscritos/"+inscritoKey).once('value')
  // 	.then(snap => {
  // 	  jsonData.datos = snap.val();
  //   return db.ref('/eventosCompletos/'+eventoIndex+'/inscripcionesConfiguracion/').once('value');
  // 	}).then(snap => {
  // 	  jsonData.inscripcionesConfiguracion = snap.val();
  // 	  JSONFormInscripcion = jsonData.inscripcionesConfiguracion;
  // 	  setBaseCurrency();

  //   return db.ref('/eventosCompletos/'+eventoIndex+'/detalleEvento/').once('value');
  // 	}).then(snap => {
  // 	  jsonData.detalleEvento = snap.val();
  //   return db.ref('/eventosCompletos/'+eventoIndex+'/modalidades/').once('value');
  // 	}).then(snap => {
  // 	  jsonData.modalidades = snap.val();

  // 	  return;
  // 	}).then(()=>{
  // 		if(descarga){
  // 		  console.log("descargar automatico");
  // 		  return setTimeout(()=> { return downloadDeslindePDFHTML(jsonData);},100)
  // 		  //return downloadDeslindePDF(jsonData);
  // 		}else {
  // 		  return;
  // 		}
  // 	});
}
export function getallDataForConfirmationFuncGeneric(eventoIndex, inscritoKey) {
  // 	//resetRegistryParticipantData();
  // 	console.log("get inscritos data para confirmación");
  console.log("inscritoKey", inscritoKey);
  console.log("inscritoKey", eventoIndex);
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.InscripcionesConfiguracion}`
  );
  return (dispatch) => {
    dispatch(detalleEvento(eventoIndex));
    dispatch(eventSubEventsRegistry(eventoIndex));
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.REGISTRY_CONFIGURATION,
        registryConfiguration: snapshot.val(),
      });
      return fb.db
        .ref(
          "/eventosCompletos/" +
            eventoIndex +
            "/inscritos/listadoInscritos/" +
            inscritoKey
        )
        .once("value")
        .then((snap) => {
          console.log("registryParticipantData", snap.val());
          dispatch({
            type: types.REGISTRY_PARTICIPANT_DATA,
            registryParticipantData: snap.val(),
            registryParticipantKey: snap.key,
            registryError: null,
          });
          return fb.db
            .ref(
              "/eventosCompletos/" +
                eventoIndex +
                "/inscripcionesConfiguracion/"
            )
            .once("value");
        })
        .then((snap) => {
          dispatch({
            type: types.REGISTRY_CONFIGURATION,
            registryConfiguration: snap.val(),
          });
        });
    });
  };

  //   return db.ref('/eventosCompletos/'+eventoIndex+"/inscritos/listadoInscritos/"+inscritoKey).once('value')
  // 	.then(snap => {
  // 	  jsonData.datos = snap.val();
  //   return db.ref('/eventosCompletos/'+eventoIndex+'/inscripcionesConfiguracion/').once('value');
  // 	}).then(snap => {
  // 	  jsonData.inscripcionesConfiguracion = snap.val();
  // 	  JSONFormInscripcion = jsonData.inscripcionesConfiguracion;
  // 	  setBaseCurrency();

  //   return db.ref('/eventosCompletos/'+eventoIndex+'/detalleEvento/').once('value');
  // 	}).then(snap => {
  // 	  jsonData.detalleEvento = snap.val();
  //   return db.ref('/eventosCompletos/'+eventoIndex+'/modalidades/').once('value');
  // 	}).then(snap => {
  // 	  jsonData.modalidades = snap.val();

  // 	  return;
  // 	}).then(()=>{
  // 		if(descarga){
  // 		  console.log("descargar automatico");
  // 		  return setTimeout(()=> { return downloadDeslindePDFHTML(jsonData);},100)
  // 		  //return downloadDeslindePDF(jsonData);
  // 		}else {
  // 		  return;
  // 		}
  // 	});
}
