import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {helperMultisort, segundosATiempo, fechahora} from '../../../../../../../services/utils';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm } from 'react-hook-form';
import {sendVirtualEventMessage, getVirtualEventsMessages} from '../../../../../../../data-store/actions/chat-actions'


const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  menu: {
    flex:1,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:50,
    width:100,
  },
}));

export default function Messages(props){
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const myVirtualEvents = useSelector(state => state.virtualEvents.myVirtualEvents);
  let myOrganizedVirtualEvents = useSelector(state => state.virtualEvents.myOrganizedVirtualEvents);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(state => state.virtualEvents.currentEventParticipants);
  const chatVirtualEvent = useSelector(state => state.chat.chatVirtualEvent);

  const classes = useStyles();
  useEffect(() => {
    console.log("getMessages");
        dispatch(getVirtualEventsMessages(userID, currentEvent.eventID))
  },[]);

 
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    console.log(data);
    var message = {
      text:data.text,
      organizer:true,
      userIDCreate : userID,
      date : Math.round(Date.now()/1000)
    }

    dispatch(sendVirtualEventMessage(userID, currentEvent.eventID, message))
  }

  var chatVirtualEventArray = helperMultisort(Object.values(chatVirtualEvent), ["date"], ["DESC"]);
	  return (
			<div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper style={{padding:10}}>
          <form onSubmit={handleSubmit(onSubmit)}>
          <h5>Escibe un mensaje para los atletas</h5>
            <input name="text" ref={register} />
            <Button type="submit" >enviar </Button>
          </form>
        </Paper>
        
        </Grid>
        <Grid item xs={12}>
          {chatVirtualEventArray.map((message, i) =>
          <Paper style={{padding:5, margin:5}}>
            <h5 key={i}>{message.text}</h5>
            <span>{fechahora(message.date)}</span>
          </Paper>)}
        </Grid>
      </Grid>
    </div>
	  );
	}
	