import * as Banner from "./sections/Banner/Banner";
import * as EmptySpace from "./sections/EmptySpace/EmptySpace";
import * as HeaderLanding from "./sections/HeaderLanding/HeaderLanding";
import * as HeaderSections from "./sections/HeaderSections/HeaderSections";
import * as Image from "./sections/Image/Image";
import * as Text from "./sections/Text/Text";
import * as Title from "./sections/Title/Title";
import * as GridBoxInfo from "./sections/GridBoxInfo/GridBoxInfo";
import * as GridBoxPrice from "./sections/GridBoxPrice/GridBoxPrice";
import * as GridInfo from "./sections/GridInfo/GridInfo";
import * as Footer from "./sections/Footer/Footer";
import * as EventWeb from "./sections/EventWeb/EventWeb";
import * as TimeRemaining from "./sections/TimeRemaining/TimeRemaining";
import * as Form from "./sections/Form/Form";
import * as NotFoundDefault from "./sections/NotFound/NotFoundDefault";
import * as EventRegistry from "./sections/EventRegistry/EventRegistry";
import * as SocialMedia from "./sections/SocialMedia/SocialMedia";
import * as Gallery from "./sections/Gallery/Gallery";
import * as DownloadFileButtom from "./sections/DownloadFileButtom/DownloadFileButtom";
import * as GetView from "./sections/GetView/GetView";
import * as EventsList from "./sections/EventsList/EventsList";
import * as YoutubeVideo from "./sections/YoutubeVideo/YoutubeVideo";

/**
 * @typedef {Object} NDFComponentType
 * @property {JSX.Element} default
 * @property {Object} componentDescription
 *
 * @type {Object.<string, NDFComponentType>}
 */

const Components = Object.freeze({
  banner: Banner,
  emptySpace: EmptySpace,
  //headerLanding: HeaderLanding,
  headerSections: HeaderSections,
  image: Image,
  youtubeVideo: YoutubeVideo,
  text: Text,
  title: Title,
  gridBoxInfo: GridBoxInfo,
  gridBoxPrice: GridBoxPrice,
  gridInfo: GridInfo,
  footer: Footer,
  eventWeb: EventWeb,
  notFoundDefault: NotFoundDefault,
  timeRemaining: TimeRemaining,
  form: Form,
  eventRegistry: EventRegistry,
  socialMedia: SocialMedia,
  gallery: Gallery,
  downloadFileButtom: DownloadFileButtom,
  getView: GetView,
  eventsList: EventsList,
});

export const Descriptions = Object.keys(Components).reduce(
  (acc, ndfComponentKey) => {
    const ndfComponent = Components[ndfComponentKey];
    const ndfDescription = ndfComponent?.componentDescription;
    if (ndfDescription) {
      acc[ndfComponentKey] = ndfDescription;
    }

    return acc;
  },
  {}
);

export default Components;
