import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/Filter';


const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, frames, resultado, callback } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
    console.log(value);
    callback(value);
  };

  let framesView;
  if(frames != undefined){
    framesView = frames.map((frame, index) => (
      <ListItem button onClick={() => handleListItemClick(frame.rutaMarco)} key={index}>
        <ListItemAvatar>
          <img src={frame.rutaMarco} style={{height:'50px'}}/>
        </ListItemAvatar>
        <ListItemText primary={frame.descripcion} />
      </ListItem>
    ))

  }
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Selecciona un marco</DialogTitle>
      <List>
        {framesView}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function FiltersDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { frames, resultado, callback } = props;
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <IconButton aria-label="framesFilters" edge="end" color="inherit">
                <FilterIcon  onClick={handleClickOpen}/>
      </IconButton>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} 
      frames={frames} resultado={resultado} callback={callback} />
    </div>
  );
}