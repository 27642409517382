import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function OxxoConfirmation(props) {
  const { responseData } = props;
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid item xs={12} sm={12}>
        <Typography
          color="primary"
          gutterBottom
          variant={"subtitle"}
          component="h6"
        >
          Felicidades completaste con exito el registro de datos.
        </Typography>
        <Typography
          color="primary"
          gutterBottom
          variant={"subtitle"}
          component="h4"
        >
          A continuación se muestra la ficha para pago en oxxo, de igual manera
          la recibiras en tu correo electrónico.
        </Typography>

        <Typography
          color="primary"
          gutterBottom
          variant={"subtitle"}
          component="h6"
        >
          La ficha de pago tiene una vigencia de <b>48 horas</b>, de no
          realizarse el pago en ese periodo el registro y número asignado
          quedará eliminado
        </Typography>

        <div class="opps">
          <div class="opps-header">
            <div class="opps-reminder">
              Ficha digital. No es necesario imprimir.
            </div>
            <div class="opps-info">
              <div class="opps-brand">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/oxxo_brand%2Foxxopay_brand.png?alt=media&token=a855a8cf-817f-4036-a34a-ddf926e54fad"
                  alt="OXXOPay"
                />
              </div>
              <div class="opps-ammount">
                <Typography
                  color="primary"
                  gutterBottom
                  variant={"subtitle"}
                  component="subtitle1"
                >
                  Monto a pagar
                </Typography>
                <Typography
                  color="primary"
                  gutterBottom
                  variant={"subtitle"}
                  component="h2"
                >
                  {" "}
                  ${responseData.charges.data[0].amount / 100} <sup>MXN</sup>
                </Typography>
                <Typography
                  color="primary"
                  gutterBottom
                  variant={"subtitle"}
                  component="subtitle1"
                >
                  OXXO cobrará una comisión adicional al momento de realizar el
                  pago.
                </Typography>
              </div>
            </div>
            <div class="opps-reference">
              <Typography
                color="primary"
                gutterBottom
                variant={"subtitle"}
                component="h6"
              >
                Referencia
              </Typography>
              <Typography
                color="primary"
                gutterBottom
                variant={"subtitle"}
                component="h2"
              >
                {responseData.charges.data[0].payment_method.reference}
              </Typography>
            </div>
          </div>
          <div class="opps-instructions">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle"}
              component="h2"
            >
              Instrucciones{" "}
            </Typography>

            <ol class="opps1">
              <li>
                <Typography
                  color="primary"
                  gutterBottom
                  variant={"subtitle"}
                  component="subtitle1"
                >
                  Acude a la tienda OXXO más cercana.{" "}
                  <a
                    href="https://www.google.com.mx/maps/search/oxxo/"
                    target="_blank"
                  >
                    Encuéntrala aquí
                  </a>
                  .
                </Typography>
              </li>
              <li>
                <Typography
                  color="primary"
                  gutterBottom
                  variant={"subtitle"}
                  component="subtitle1"
                >
                  Indica en caja que quieres ralizar un pago de{" "}
                  <strong>OXXOPay</strong>.
                </Typography>
              </li>
              <li>
                <Typography
                  color="primary"
                  gutterBottom
                  variant={"subtitle"}
                  component="subtitle1"
                >
                  Dicta al cajero el número de referencia en esta ficha para que
                  tecleé directamete en la pantalla de venta.
                </Typography>
              </li>
              <li>
                <Typography
                  color="primary"
                  gutterBottom
                  variant={"subtitle"}
                  component="subtitle1"
                >
                  Realiza el pago correspondiente con dinero en efectivo.
                </Typography>
              </li>

              <li>
                <Typography
                  color="primary"
                  gutterBottom
                  variant={"subtitle"}
                  component="subtitle1"
                >
                  Al confirmar tu pago, el cajero te entregará un comprobante
                  impreso.{" "}
                  <strong>
                    En el podrás verificar que se haya realizado correctamente.
                  </strong>{" "}
                  Conserva este comprobante de pago.
                </Typography>
              </li>
            </ol>
            <div class="opps-footnote">
              <Typography
                color="primary"
                gutterBottom
                variant={"subtitle"}
                component="h6"
              >
                Al completar estos pasos recibirás un correo de{" "}
                <strong>Dashport</strong> confirmando tu pago.
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
