import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import AthleteProfileCard from "./AthleteProfile/AthleteProfileCard";

import Login from "../../Login/Login";
import GarminActivitiesContainer from "./GarminApi/GarminActivitiesContainer";
import AppContainer from "../../../AppContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

function AthletesCotainerView(props) {
  const classes = useStyles();
  const { atleteAccount } = props;

  if (atleteAccount == null) {
    return <Login />;
  }

  return (
    <AppContainer>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AthleteProfileCard />
          </Grid>
          {/* <Grid item xs={12}>
          <Paper className={classes.paper}>
            <GarminActivitiesContainer/>
          </Paper>
        </Grid> */}
        </Grid>
      </div>
    </AppContainer>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventParticipants: state.registry.eventParticipants,
    atleteAccount: state.atlete.atleteAccount,
  };
};

const AthletesCotainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AthletesCotainerView);

export default AthletesCotainer;
