import React, { useState }from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import {Line} from 'react-chartjs-2';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { requestActivitiesGarmin } from '../../../../data-store/actions/atlete-actions'

//import activities from './5kdeJuli.json'
import activitiesLocal from './OwenTraining.json'

import HeartRate from './HeartRate';
import StepsPerMinute from './StepsPerMinute';
import MapContainer from './MapContainer';
import GarminActivitiesList from './GarminActivitiesList';
import Watts from './Watts';
import BikeCadence from './BikeCadence';
import MetricsChart from './MetricsChart';
import HeartRateZonesChart from './HeartRateZonesChart';
import PowerZonesChart from './PowerZonesChart';


const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },table: {
    minWidth: 650,
  },
}));



function GarminActivitiesContainerView(props){
  const classes = useStyles();
  const [requestToken, setRequestToken] = useState(true);

	const { atleteAccount, activitiesGarmin, sendRequestActivities, userID } = props;
 

  if(activitiesGarmin == undefined || activitiesGarmin == null || activitiesGarmin == []){
    return (      <Grid container justify="center" style={{textAlign:"center"}} spacing={3}>
    <Grid item xs={12}  style={{textAlign:"center"}}>
      <h5>Lista de actividades</h5>
    </Grid>
    <Grid item xs={12}  style={{textAlign:"center"}}>
    Vacio
    </Grid>
  </Grid>
)
  }else{

    var activity = activitiesGarmin[7];

	  return (
      <div>
      <Grid container justify="center" style={{textAlign:"center"}} spacing={3}>
        <Grid item xs={12}  style={{textAlign:"center", with:3000}}>
          <Watts activity={activity} atleteAccount={atleteAccount}/>
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          <PowerZonesChart activity={activity} atleteAccount={atleteAccount}/>
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          <HeartRateZonesChart activity={activity} atleteAccount={atleteAccount}/>
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          {/* <MetricsChart activity={activity}/> */}
          
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
        </Grid>

        <Grid item xs={12}  style={{textAlign:"center"}}>
          {/* <MapContainer activity={activity}/> */}
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          {/* <BikeCadence activity={activity}/> */}
        </Grid>
        <Grid item xs={6}  style={{textAlign:"center"}}>
          {/* <StepsPerMinute activity={activity}/> */}
        </Grid>
        <Grid item xs={6}  style={{textAlign:"center"}}>
          {/* <HeartRate activity={activity} /> */}
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          {/* <GarminActivitiesList activity={activity}/> */}
        </Grid>
      </Grid>
      </div>
	  );
	}

  }


	

	const mapDispatchToProps = (dispatch) => {
		return {
      sendRequestActivities : (userID) =>{
        dispatch(requestActivitiesGarmin(userID))
      }

		}
	}
	
	const mapStateToProps = (state) => {
		return {		
			eventDetail: state.events.eventDetail,
			eventParticipants : state.registry.eventParticipants,
      atleteAccount : state.atlete.atleteAccount,
      userID : state.atlete.userID,
      activitiesGarmin : state.atlete.activitiesGarmin
		}
		}
		
	const GarminActivitiesContainer = connect(
		mapStateToProps,
		mapDispatchToProps
	)(GarminActivitiesContainerView);
	
	export default GarminActivitiesContainer;
