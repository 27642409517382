import React from 'react';

const PaymentDetail = ({ParticipantDetail}) => {

    var pago = ParticipantDetail.statusPago;
    var tabla;
    if(pago.idOperacion == undefined || pago.idOperacion ==0){
      // no descarga detalle de orden por que pago en punto de venta efectivo
      tabla = (<div className="row">
              <span className="col s4 m4 l4 center-align"><b>Fecha de pago </b><br/>{pago.fechaPago}</span>
              <span className="col s4 m4 l4 center-align"><b>Forma de inscripcion </b><br/>{pago.formaInscripcion}</span>
              <span className="col s4 m4 l4 center-align"><b>Forma de pago </b><br/>{pago.formaPago}</span>
            </div>);
    }else{
      // descarga detalle de orden
  
      tabla = (<div className="row">
                <span className="col s3 m3 l3 center-align"><b>Fecha de pago </b><br/>{pago.fechaPago}</span>
                <span className="col s3 m3 l3 center-align"><b>Forma de inscripcion </b><br/>{pago.formaInscripcion}</span>
                <span className="col s3 m3 l3 center-align"><b>forma de pago </b><br/>{pago.formaPago}</span>
                <span className="col s3 m3 l3 center-align"><b>id Operación</b><br/>{pago.idOperacion}</span>
            </div>);
    }
    return tabla;
}

export default PaymentDetail;
