import React from 'react';
import { connect } from 'react-redux'
import {detalleEvento, 
        resultadosEvento, 
        resultReportType, 
        getPuntosControl,
        configuracionEvento, 
        eventConvocatory, 
        eventSubEvents} from '../../../../data-store/actions/events-actions';
import {eventParticipants,
        registryConfiguration,
        registryAutorizedUsers} from '../../../../data-store/actions/registry-actions';
import FormsContainerUI from './Forms/FormsContainerUI';
import Convocatory from '../Convocatory/Convocatory';

import RegistryConfigurationContainer from './Configuration/RegistryConfigurationContainer';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import DashboardContainer from './Dashboard/DashboardContainer';
import Income from './ParticipantsLists/Income';
import Container from '@material-ui/core/Container';

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
  }
  
  TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
  };
  

class RegistryContainerView extends React.Component {
    state = {
        value: 0,
      };
    
      handleChange = (event, value) => {
        
        this.setState({ value });
      };
    
      render() {
        const { value } = this.state;
        let arrayTabs = [];
        let contentTabs = [];

        let userProfile = null;
        var convocatoriaShow;
        var tabConfig;

        if(this.props.eventDetail != undefined && this.props.eventDetail.convocatoria){
          arrayTabs.push(<Tab key="tab-Convocatoria" label="Convocatoria" />);
          contentTabs.push(<TabContainer key="contTab-Convocatoria"><Convocatory/></TabContainer>)
        }

        arrayTabs.push(<Tab key="tab-Formulario" label="Formulario" />);
        contentTabs.push(<TabContainer key="contTab-Formulario"><FormsContainerUI/></TabContainer>)
        arrayTabs.push(<Tab key="tab-Ingresos" label="Ingresos" />);
        contentTabs.push(<TabContainer key="contTab-Ingresos"><Income/></TabContainer>)
        arrayTabs.push(<Tab key="tab-Dashboard" label="Dashboard" />);
        contentTabs.push(<TabContainer key="contTab-Dashboard"><DashboardContainer/></TabContainer>)


        console.log(this.props.userID);
        console.log(this.props.registryAutorizedUsers);
        if(this.props.registryAutorizedUsers != undefined 
            && this.props.registryAutorizedUsers[this.props.userID] != undefined
            && this.props.registryAutorizedUsers[this.props.userID].perfil != undefined){

                userProfile = this.props.registryAutorizedUsers[this.props.userID].perfil;

            if(userProfile.localeCompare('MasterOrganizador') == 0 || userProfile.localeCompare('master') == 0){
              arrayTabs.push(<Tab key="tab-Configuración" label="Configuración" />);
              contentTabs.push(<TabContainer key="contTab-Configuración"><RegistryConfigurationContainer/></TabContainer>)
            }
        }

    let element;
    if(userProfile == null || userProfile ==  undefined){
      return element = (
        <div className="row">
            <div className="col s12 m8 offset-m2 l8 offset-l2">
            <h3>Cargando Datos ...</h3>
            </div>
        </div>);
    }else{
      return element = (
        <Container maxWidth="lg">
              <Tabs className='z-depth-1 red-text' value={value} onChange={this.handleChange}>
                {arrayTabs}
              </Tabs>
            {value === 0 && contentTabs[value]}
            {value === 1 && contentTabs[value]}
            {value === 2 && contentTabs[value]}
            {value === 3 && contentTabs[value]}
            {value === 4 && contentTabs[value]}
            {value === 5 && contentTabs[value]}
        </Container>
        )
    }

    }

    }
  

const mapDispatchToProps = (dispatch, {match}) => {
	return {
        eventParticipants : dispatch(eventParticipants(match.params.EventoIndex)),
        registryConfiguration : dispatch(registryConfiguration(match.params.EventoIndex)),
        registryAutorizedUsersSend :dispatch(registryAutorizedUsers(match.params.EventoIndex))
		}
	}

const mapStateToProps = (state, {match}) => {
	return {
		eventDetail: state.events.eventDetail,
		eventConfiguration : state.events.eventConfiguration,
		eventConvocatory :state.events.eventConvocatory,
        eventSubEvents :state.events.eventSubEvents,
        eventParticipants : state.registry.eventParticipants,
        registryConfiguration : state.registry.registryConfiguration,
        registryAutorizedUsers : state.registry.registryAutorizedUsers,
        userID : state.atlete.userID
	}
  }
  
const RegistryContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(RegistryContainerView);



export default  RegistryContainer;
