import React, { useState } from "react";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Components from "../LandingComponents";

import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function LayoutResolver({ page }) {
  const classes = useStyles();

  console.log(page);
  const defaultWidth = { xs: 12, sm: 6 };
  const widthList = {
    1: { xs: 12, sm: 12 },
    2: { xs: 12, sm: 6 },
    3: { xs: 12, sm: 4 },
    4: { xs: 12, sm: 3 },
    5: { xs: 6, sm: 6 },
    6: { xs: 6, sm: 3 },
  };
  if (page) {
    const innerComponents = (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            textAlign: "center",
            backgroundRepeat: "repeat",
            backgroundPosition: "left top",
            backgroundSize: "100% auto",
            backgroundImage: `url(${
              page.miniTheme ? page.miniTheme.backgroundImage : ""
            })`,
          }}
        >
          {page.views &&
            page.views.map((item, i) => {
              if (Components[item.component] === undefined) {
                return <div></div>;
              }

              const SpecificComponent = Components[item.component].default;

              const width = item.componentConfig.width || 1;

              if (item.componentConfig.hide) {
                return <Grid key={i} item xs={12} sm={12}></Grid>;
              }

              return (
                <Grid
                  key={i}
                  id={
                    item.componentConfig.sectionID ||
                    Math.floor(Math.random() * 10000)
                  }
                  item
                  xs={
                    widthList[width] && widthList[width].xs
                      ? widthList[width].xs
                      : defaultWidth.xs
                  }
                  sm={
                    widthList[width] && widthList[width].sm
                      ? widthList[width].sm
                      : defaultWidth.sm
                  }
                  style={{
                    width: "100%",
                    padding: item.componentConfig.padding
                      ? item.componentConfig.padding
                      : 0,
                  }}
                >
                  {SpecificComponent ? (
                    <SpecificComponent componentConfig={item.componentConfig} />
                  ) : null}
                </Grid>
              );
            })}
        </Grid>
      </div>
    );

    if (page.miniTheme) {
      const { miniTheme } = page;
      return (
        <ThemeProvider
          theme={(theme) => {
            const primaryColor =
              miniTheme.primary || theme.palette.primary.main;
            const secondaryColor =
              miniTheme.secondary || theme.palette.secondary.main;
            const textPrimaryColor =
              miniTheme.text || theme.palette.text.primary || "#000";
            const backgroundColor =
              miniTheme.background ||
              theme.palette.background.default ||
              "#FFFFF";
            const fontFamily =
              miniTheme.fontFamily ||
              theme.typography.fontFamily ||
              "MavenProRegular, MavenProBold,Helvetica, Arial";

            return createMuiTheme({
              ...theme,
              typography: {
                fontFamily: fontFamily,
              },
              palette: {
                background: {
                  default: backgroundColor,
                },
                primary: {
                  main: primaryColor,
                },
                secondary: {
                  main: secondaryColor,
                },
                text: {
                  primary: textPrimaryColor,
                },
              },
            });
          }}
        >
          <Box bgcolor="background.default">{innerComponents}</Box>
        </ThemeProvider>
      );
    }

    return innerComponents;
  }

  return null;
}
