import React, { useState }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import M from 'materialize-css'
import Typography from '@material-ui/core/Typography';

import { Link, useHistory } from 'react-router-dom';

import Login from '../../../Login/Login'
import Button from 'react-materialize/lib/Button';
import Input from "@material-ui/core/Input";
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';

import { useForm, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function ViewVirtualEvent(props){
  const dispatch = useDispatch();
  let history = useHistory();

  const athleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
	const classes = useStyles();
 
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    history.push(data.eventID+"/")
  }

	if(athleteAccount == null){
		return <Login/>
  }
  
	  return (
			<div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="h2">
            Buscar evento virtual por ID
          </Typography>
          
          <form onSubmit={handleSubmit(onSubmit)} style={{width:"100%"}}>
              
              <input name="eventID" ref={register({ required: true, maxLength: 20 })} />
              {errors.eventID && <span>Campo requerido</span>}
              <Button type="submit" variant="contained" color="primary">
                Ingresar al evento
              </Button>
            </form>
        </Grid>
      </Grid>
    </div>
	  );
	}
	