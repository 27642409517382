import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function NotFoundDefault(match) {
  const organizerWeb = useSelector((state) => state.organizer.organizerWeb);

  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "black", flex: 1, height: "1000px" }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <Grid
          item
          style={{ width: "auto", margin: "50px", marginTop: "10%" }}
          alignItems="center"
          alignContent="center"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_02.png?alt=media&token=9a8d9344-2458-4af5-8a54-bc2d7fc6eeb5"
            alt="dashport"
            height={200}
          />
          <Typography variant="h2" style={{ color: "white" }}>
            PÁGINA NO ENCONTRADA
          </Typography>{" "}
          <Typography variant="h5" style={{ color: "white" }}>
            Intenta una ruta diferente
          </Typography>{" "}
        </Grid>
      </Grid>
    </div>
  );
}
