import * as types from "./action-types";
import * as fb from "../../firebase/firebasejs";
import { store } from "../configureStore";

export function initEvents() {
  const ref = fb.db.ref(fb.EventosLista);
  return (dispatch) => {
    ref
      .orderByChild("iDEvento")
      .startAt(5)
      .on("value", (snapshot) => {
        dispatch({
          type: types.INIT_EVENTS,
          events: snapshot.val(),
        });
      });
  };
}

export function detalleEvento(eventoIndex) {
  console.log("detalle evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.DetalleEvento}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.EVENT_DETAIL,
        eventDetail: snapshot.val(),
      });
    });
  };
}
export function configuracionEvento(eventoIndex) {
  console.log("configuracion evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Configuracion}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.EVENT_CONFIGURATION,
        eventConfiguration: snapshot.val(),
      });
    });
  };
}

export function resultadosEvento(eventoIndex) {
  console.log("detalle evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Resultados}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      let data = snapshot.val();
      let ResultadosEvento = [];
      for (let indice in data) {
        ResultadosEvento.push(data[indice]);
      }
      dispatch({
        type: types.EVENT_RESULTS,
        eventResults: ResultadosEvento,
      });
    });
  };
}

export function resultadosEventoNew(eventoIndex) {
  console.log("detalle evento");
  var ref = fb.db.ref(`${fb.EventosCompletos}/${eventoIndex}/${fb.Resultados}`);
  return (dispatch) => {
    ref.once("value", (snapshot) => {
      console.log("results", snapshot.val());
      dispatch({
        type: types.EVENT_RESULTS,
        eventResults: Object.values(snapshot.val()),
      });
    });

    ref.on("child_changed", (snapshot) => {
      console.log("results", snapshot.val());
      const dato = snapshot.val();
      const eventResults = store.getState().events.eventResults;

      var ResultadosEvento = [];
      for (var i in eventResults) {
        if (eventResults[i].num === dato.num) {
          ResultadosEvento.push(dato);
        } else {
          ResultadosEvento.push(eventResults[i]);
        }
      }
      console.log("child chaged");
      dispatch({
        type: types.EVENT_RESULTS,
        eventResults: ResultadosEvento,
      });
    });
    ref.on("child_added", (snapshot) => {
      console.log("results", snapshot.val());
      const dato = snapshot.val();
      let eventResults = store.getState().events.eventResults;
      console.log("results1", eventResults);

      if (eventResults) {
        var ResultadosEvento = eventResults;

        console.log("results1", ResultadosEvento);
        ResultadosEvento.push(dato);

        console.log("child added");
        dispatch({
          type: types.EVENT_RESULTS,
          eventResults: ResultadosEvento,
        });
      }
    });
    ref.on("child_removed", (snapshot) => {
      console.log("results", snapshot.val());
      const dato = snapshot.val();
      const eventResults = store.getState().events.eventResults;

      var ResultadosEvento = [];
      for (var i in eventResults) {
        if (eventResults[i].num !== dato.num) {
          ResultadosEvento.push(eventResults[i]);
        }
      }

      console.log("child removed");
      dispatch({
        type: types.EVENT_RESULTS,
        eventResults: ResultadosEvento,
      });
    });
  };
}
export function getPuntosControl(eventoIndex) {
  console.log("detalle evento" + eventoIndex);
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.PuntosDeControl}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.EVENT_POINTS_CONTROLS,
        puntosControl: snapshot,
      });
    });
  };
}
export function eventPhotoConfiguration(eventoIndex) {
  console.log("Configuracion de fotos" + eventoIndex);
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.fotosEventoRuta}${fb.configuracionFotosRuta}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_PHOTOS_CONFIGURATION,
        eventPhotoConfiguration: snapshot.val(),
      });
    });
  };
}
export function eventConvocatory(eventoIndex) {
  console.log("Convatoria Evento evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Convocatoria}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_CONVOCATORY,
        eventConvocatory: snapshot.val(),
      });
    });
  };
}
export function eventSubEvents(eventoIndex) {
  console.log("Convatoria Evento evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.Modalidades}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_SUBEVENTS,
        eventSubEvents: snapshot.val(),
        eventSubEventsSnap: snapshot,
      });
    });
  };
}
export function eventSubEventsRegistry(eventoIndex) {
  console.log("Convatoria Evento evento");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.ModalidadesInscripciones}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_SUBEVENTS_REGISTRY,
        eventSubEventsRegistry: snapshot.val(),
        eventSubEventsRegistrySnap: snapshot,
      });
    });
  };
}

export function juecesEvento(eventoIndex) {
  console.log("jueces Evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.JuecesEvento}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.EVENT_USERS,
        eventUsers: snapshot.val(),
      });
    });
  };
}
export function customStrings(eventoIndex) {
  console.log("Convatoria Evento evento");
  const ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.customStrings}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.CUSTOM_STRINGS,
        customStrings: snapshot.val(),
      });
    });
  };
}

//#################################################################
export function getCustomViews(eventoIndex) {
  console.log("Get cutom views");
  var ref = fb.db.ref(
    `${fb.EventosCompletos}/${eventoIndex}/${fb.CustomViews}`
  );
  return (dispatch) => {
    ref.on("value", (snapshot) => {
      dispatch({
        type: types.CUSTOM_VIEWS,
        customViews: snapshot.val(),
      });
    });
  };
}
