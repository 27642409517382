import React from 'react';
import {Line} from 'react-chartjs-2';
import {helperMultisort} from '../../../../services/utils';

const Watts = ({activity, atleteAccount}) => {
// https://medium.com/critical-powers/formulas-from-training-and-racing-with-a-power-meter-2a295c661b46
  if(activity == undefined || activity == null){    
    return <h5>Vacio</h5>
  }

  var dataDispersion = {
    datasets: [
      {label: 'Watts', data: [], borderColor:"red", pointRadius:0, borderWidth:0.3 },
      {label: 'NP', data: [], borderColor:"blue", pointRadius:0, borderWidth:1 },
      {label: 'AvgMax20Min', data: [], borderColor:"grey", pointRadius:0, borderWidth:1 },
    ],
    labels: []
}

var activityData = activity.samples//.slice(0, 500);

var normalizeData = potenciaNormalizada(activityData);
var avgMaxPower20minVar = avgMaxPower20min(activityData)

  for (var i in activityData){
    var actualPower = activityData[i].powerInWatts;
//    var normalizeValue = (actualPower / normalizeData.avgData) * normalizeData.avgNormalizada;

    dataDispersion.labels.push(Math.round((activityData[i].timerDurationInSeconds)/60));
    dataDispersion.datasets[0].data.push(actualPower);
    dataDispersion.datasets[1].data.push(normalizeData);
    dataDispersion.datasets[2].data.push(avgMaxPower20minVar);
  }

  console.log()
var options = {
  maintainAspectRatio:true
}
var NP = normalizeData;
var FTP = atleteAccount.training.FTP;
var segs = activity.summary.durationInSeconds;
var IF = NP / FTP;
  return (			
    <div className="row z-depth-2 padding">
      <h5>Watts</h5>
      <p>NP:{NP}</p>
      <p>AvgMax:{avgMaxPower20minVar})</p>
      <p>FTP:{FTP}</p>
      <p>IF = NP / FTP:{NP / FTP}</p>
      <p>TSS:{(segs * NP * IF) / (FTP * 3600) * 100}</p>
        <div className="col s12">
              <Line
                data={dataDispersion}
                options={options}
              />
        </div>
    </div>);
}

export default Watts;


//##############################################################################

const potenciaNormalizada = (activityData) =>{
  // potencia normalizada
  // paso 1: promedio los valores del 1 al 30, luego del valor 2 al 31, luego del 3 al 32...
  // paso 2: ese promedio de 30 valores los elevo a la 4ta potencia
  // paso 3: sumo todos esos resultados y le saco la raiz cuarta
  // paso 4: cada valor de la lista lo divido entre ese valor y lo multiplico por su valor en promedio de los 30 segundos

  var sumAVGUp4 = 0;
  const segundoMuestra = 30;

var list = {};
  var i,j,data30,chunk = segundoMuestra;
  for (i=0,j=activityData.length - segundoMuestra; i<j; i++) {

      data30 = activityData.slice(i,i+chunk);

      const sum = data30.reduce((acc, b) => { return acc + b.powerInWatts}, 0);
      const avg = (sum / data30.length) || 0;

      var potencia = Math.pow(avg, 4);
      sumAVGUp4 = sumAVGUp4 + potencia;

  }
  return Math.pow((sumAVGUp4 / (activityData.length - segundoMuestra)), 1/4) ;
}

// ###########################################################################
const avgMaxPower20min = (activityData) =>{
  // potencia max promedio 20 minutos


  // ordernar los 20 min de mas potencia;

  // var copyData = activityData.slice(0, activityData.length);
  // var maxPowerList = helperMultisort(copyData, ["powerInWatts"], ["DESC"]);
  // var newArray = maxPowerList.slice(0,1200);
  // const sum = newArray.reduce((acc, b) => { return acc + b.powerInWatts}, 0);
  // const avg = (sum / newArray.length) || 0;


  var segundoMuestra = 1200;
  var avgMax = 0;
  var i,j,data30,chunk = segundoMuestra;
  for (i=0,j=activityData.length - segundoMuestra; i<j; i++) {

      data30 = activityData.slice(i,i+chunk);
      const sum = data30.reduce((acc, b) => { return acc + b.powerInWatts}, 0);
      const avg = (sum / data30.length) || 0;
      if(avg > avgMax){
        avgMax = avg;
      }
 
    }

  return avgMax;
}




//##############################################################################
const potenciaNormalizada2 = (activityData) =>{
  // potencia normalizada
  // paso 1: promedio los valores del 1 al 30, luego del valor 2 al 31, luego del 3 al 32...
  // paso 2: ese promedio de 30 valores los elevo a la 4ta potencia
  // paso 3: sumo todos esos resultados y le saco la raiz cuarta
  // paso 4: cada valor de la lista lo divido entre ese valor y lo multiplico por su valor en promedio de los 30 segundos

  var sumAVGUp4 = 0;
  var x = 0;

var list = {};
  var i,j,data30,chunk = 30;
  for (i=0,j=activityData.length - 30; i<j; i++) {

      data30 = activityData.slice(i,i+chunk);
      // do whatever
      console.log("data30");
      console.log(data30);
      const sum = data30.reduce((acc, b) => { return acc + b.powerInWatts}, 0);
      console.log("sum");
      console.log(sum);
      const avg = (sum / data30.length) || 0;
      console.log("avg");
      console.log(avg);

      var potencia = Math.pow(avg, 4);
      console.log(potencia);
      sumAVGUp4 = sumAVGUp4 + potencia;
      x++;

  }
  console.log("potencia normalizada");
  console.log(x+" " + activityData.length -30);

  console.log(Math.pow((sumAVGUp4 / activityData.length -30), 1/4));
  return Math.pow((sumAVGUp4 / activityData.length -30), 1/4) ;
}
