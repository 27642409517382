import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
    backgroundColor: theme.palette.background.default,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
}));

export default function CurrencyPayment(props) {
  const {
    callback,
    currentCurrency,
    currencyBase,
    registryConfiguration,
  } = props;
  const classes = useStyles();

  if (registryConfiguration.configuracion.moneda === undefined) {
    return <div></div>;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ width: "100%" }}
    >
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant={"h4"}
          align="center"
          component="h2"
          color="primary"
        >
          Moneda oficial del evento: {currencyBase.texto} ({currencyBase.codigo}
          )
        </Typography>
        <Typography
          gutterBottom
          variant={"subtitle1"}
          align="center"
          component="h5"
          color="secondary"
        >
          Selecciona la moneda que deseas usar
        </Typography>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <List style={{ width: "100%" }}>
          {Object.values(registryConfiguration.configuracion.moneda).map(
            (moneda, index) => {
              console.log("moneda", moneda);
              const actual =
                currentCurrency.codigo === moneda.codigo
                  ? "Moneda actual: "
                  : "";
              if (moneda) {
                return (
                  <ListItem
                    className={classes.inputLabel}
                    onClick={() => callback(moneda)}
                  >
                    <ListItemIcon>
                      <CreditCardIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.inputLabel}
                      primary={
                        actual + moneda.texto + " (" + moneda.codigo + ")"
                      }
                      secondary={
                        <Typography
                          variant={"subtitle2"}
                          component="h5"
                          color="secondary"
                        >
                          Tipo de cambio: {moneda.tipoCambio}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction className={classes.inputLabel}>
                      {currentCurrency.codigo !== moneda.codigo && (
                        <IconButton
                          edge="end"
                          aria-label="this"
                          onClick={() => callback(moneda)}
                        >
                          <SendIcon color="secondary" />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }
            }
          )}
        </List>
      </Grid>
      <Divider />
    </Grid>
  );
}
