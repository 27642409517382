import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Loading(match) {
  return (
    <div style={{ flex: 1, height: "1000px" }}>
      <LinearProgress color="primary" />
      <Grid
        container
        direction="column"
        alignItems="center"
        alignContent="center"
      >
        <Grid
          item
          style={{ width: "auto", margin: "50px", marginTop: "10%" }}
          alignItems="center"
          alignContent="center"
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_02.png?alt=media&token=9a8d9344-2458-4af5-8a54-bc2d7fc6eeb5"
            alt="dashport"
            height={300}
          />
        </Grid>
      </Grid>
    </div>
  );
}
