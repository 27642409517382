import React from "react";
import Grid from "@material-ui/core/Grid";

import { formatEventDate } from "../../../services/utils";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { Link } from "react-router-dom";

const PastEventsCard = ({ evento }) => (
  <Grid item xs={12} sm={4}>
    <Card>
      <CardActionArea
        component={Link}
        to={
          evento.showWeb
            ? "/evento_" + evento.iDEvento
            : `/resultados/evento_${evento.iDEvento}`
        }
      >
        <CardMedia
          component="img"
          alt={"Logo evento:" + evento.nombreEvento}
          height="250"
          image={evento.logoEvento}
          title={"Logo evento:" + evento.nombreEvento}
        />
        <CardContent style={{ height: "250px" }}>
          <Typography gutterBottom variant="h5" component="h2">
            {evento.nombreEvento}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <p>
              Evento Realizado el {formatEventDate(evento.fechaEvento)}, a las{" "}
              {evento.horaEvento}
            </p>
            <p>
              en {evento.lugarEvento}, {evento.ciudadEvento},{" "}
              {evento.paisEvento}
            </p>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {evento.showWeb ? (
          <Button
            color="primary"
            component={Link}
            to={"/evento_" + evento.iDEvento}
          >
            Web del evento
          </Button>
        ) : (
          <Button
            size="large"
            color="primary"
            component={Link}
            to={`/resultados/evento_${evento.iDEvento}`}
          >
            Resultados
          </Button>
        )}
      </CardActions>
    </Card>
  </Grid>
);

export default PastEventsCard;
