import React, { useState }from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { requestActivitiesGarmin, requestDeregistrationGarmin } from '../../../../../data-store/actions/atlete-actions'
import Avatar from '@material-ui/core/Avatar';
import LogoGarmin from '../GarminAssets/Garmin_Connect_app_1024x1024-02.png'
import LogoDashport from '../../../../../assets/dashport-logos/DASHPORT_03.png'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import GarminRequestTokenButton from '../GarminRequestApi/GarminRequestTokenButton';


const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function GarminAthorizedHealthAPIView(props){
  const classes = useStyles();
  const [requestToken, setRequestToken] = useState(true);

	const { atleteAccount, activitiesGarmin, sendRequestActivities, userID, requestDeregistration, activitiesGarminLoading } = props;
 
  const handleClick = () => {
    sendRequestActivities(userID, "health");
  }
  const handleClickDeregistration = () =>{
    requestDeregistration(userID, "health");
  }

  if(atleteAccount.garmin == undefined || atleteAccount.garmin["health"] === undefined ||atleteAccount.garmin && atleteAccount.garmin["health"] === null){
    return <GarminRequestTokenButton api="health"/>;
  }

  let buttonDelete = (<Button  onClick={() => handleClickDeregistration()} color="primary" >
                      Eliminar conexión a Garmin
                    </Button>)
  if(activitiesGarminLoading){
    buttonDelete = <CircularProgress/>;
  } 
                

	  return (
      <Grid container justify="center" style={{textAlign:"center"}} spacing={3}>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          <p>Tu usuario ya está conectado con Garmin Challenges</p>
        </Grid>
        <Grid item xs={4} >
          <Avatar variant="square" src={LogoGarmin}/>
        </Grid>
        <Grid item xs={4} >
          <ArrowForwardIcon color="primary" />
        </Grid>
        <Grid item xs={4} >
          <Avatar variant="square" src={LogoDashport}/>
        </Grid>
        {/* <Grid item xs={12}  style={{textAlign:"center"}}>
          <Button  onClick={() => handleClick()} variant="contained" color="primary" size="large">
            Descargar actividades
          </Button>
        </Grid> */}
        <Grid item xs={12}  style={{textAlign:"center"}}>
          {buttonDelete}
        </Grid>
      </Grid>
	  );
	}
	

	const mapDispatchToProps = (dispatch) => {
		return {
      sendRequestActivities : (userID, api) =>{
        dispatch(requestActivitiesGarmin(userID, api))
      },
      requestDeregistration : (userID, api) =>{
        dispatch(requestDeregistrationGarmin(userID, api))
      }

		}
	}
	
	const mapStateToProps = (state) => {
		return {		
			eventDetail: state.events.eventDetail,
			eventParticipants : state.registry.eventParticipants,
      atleteAccount : state.atlete.atleteAccount,
      userID : state.atlete.userID,
      activitiesGarmin : state.atlete.activitiesGarmin,
      activitiesGarminLoading : state.atlete.activitiesGarminLoading
		}
		}
		
	const GarminAthorizedHealthAPI = connect(
		mapStateToProps,
		mapDispatchToProps
	)(GarminAthorizedHealthAPIView);
	
	export default GarminAthorizedHealthAPI;
