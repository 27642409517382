import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
  },
});

export default function EditableFieldArray(props) {
  const { fieldObject, onSubmit } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    inputsArray,
  } = fieldObject;
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [editMode, setEditMode] = useState(false);

  function onSave(values) {
    setEditMode(false);
    onSubmit(values);
  }

  return editMode ? (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
    >
      <TextField
        variant="outlined"
        margin="none"
        fullWidth
        label={label}
        disabled={!editMode}
        helperText={errors[fieldName] ? errorMessage : null}
        error={Boolean(errors[fieldName])}
        defaultValue={defaultValue}
        inputRef={register({ required: required })}
        name={fieldName}
        type={max !== undefined || min !== undefined ? "number" : undefined}
        InputProps={{
          className: classes.input,
          inputProps: {
            max: max,
            min: min,
          },
          startAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.iconSave}
                onClick={handleSubmit(onSave)}
              >
                <SaveIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  ) : (
    <div>
      <Typography
        className={classes.text}
        gutterBottom
        variant={"subtitle2"}
        component="h2"
      >
        {label}:
      </Typography>
      <Typography
        className={classes.text}
        gutterBottom
        variant={textSize || "h5"}
        component="h2"
        style={{ minHeight: "30px" }}
      >
        {defaultValue}
        <IconButton
          size={
            !textSize ||
            textSize === "h1" ||
            textSize === "h2" ||
            textSize === "h3" ||
            textSize === "h4" ||
            textSize === "h5"
              ? "medium"
              : "small"
          }
          className={classes.iconEdit}
          onClick={() => setEditMode(true)}
        >
          <EditIcon />
        </IconButton>
      </Typography>
    </div>
  );
}
