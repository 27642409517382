// Initialize Firebase

import M from "materialize-css";
import { modalidadCateogoriaNameByCode } from "../services/utils";
import * as firebase from "firebase";
import "firebase/firestore";

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAtcbYiS6l_5yaNkACPUyvK8CcoIENc3Vk",
  authDomain: "kmetasfirebase.firebaseapp.com",
  databaseURL: "https://kmetasfirebase.firebaseio.com",
  projectId: "kmetasfirebase",
  storageBucket: "kmetasfirebase.appspot.com",
  messagingSenderId: "574644644844",
  appId: "1:574644644844:web:d03014ed12cc7a8414aec9",
  measurementId: "G-HQSV62Y2Q8",
});

const organizersDB = firebase.initializeApp(
  {
    databaseURL: "https://organizers-dashport.firebaseio.com/",
  },
  "organizersDB"
);
export const analytics = firebase.analytics();

// const messaging = initializedFirebaseApp.messaging();
// messaging.usePublicVapidKey(
//   "BGolS3hABr2Nq2pcNR7lRMUW9R1Zu8RFdDDvHFdf_4Cg7zflQSiFQuYnPD3NWqwu8Yj2nognOVvJhZ19pJn3WAs"
// );

// export { messaging };

export let db = initializedFirebaseApp.database();
export let dbOrganizers = firebase.database(organizersDB);

export let firestore = initializedFirebaseApp.firestore();

export let dbRef = initializedFirebaseApp.database;

export let auth = initializedFirebaseApp.auth();
export const CustomViews = "customViews/";

// export const uiConfig = {
//   // Popup signin flow rather than redirect flow.
//   signInFlow: "popup",
//   // We will display Google and Facebook as auth providers.
//   signInOptions: [
//     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//     firebase.auth.FacebookAuthProvider.PROVIDER_ID,
//     {
//       provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
//       requireDisplayName: true,
//     },
//   ],
//   callbacks: {
//     // Avoid redirects after sign-in.
//     signInSuccessWithAuthResult: (result) => console.log(result),
//   },
// };

function saveAthenticatedInfo(result) {
  let userProfile = result.additionalUserInfo.profile;
  let uid = result.additionalUserInfo.user.uid;
  let isNewUser = result.additionalUserInfo.isNewUser;
  // if (isNewUser) {
  //   let today = fechaDiagonal(0);
  //   let competidorData = {
  //     apellidos: data.apellidos,
  //     correo: data.correo,
  //     fechaNacimiento: data.fechaNacimiento,
  //     genero: data.genero,
  //     nombres: data.nombres,
  //     telefono: data.telefono,
  //     perfil: 2,
  //     fechaRegistro: today,
  //     viaRegistro: "web",
  //   };

  //   let newUserRef = firestore.collection("users").doc(uid);
  //   return newUserRef.set(competidorData);
  // }
}
// export const logInFacebook = () => {
//   const facebookProvider = new firebase.auth.FacebookAuthProvider();
//   auth
//     .signInWithPopup(facebookProvider)
//     .then((result) => {
//       console.log("autenticado ", result);
//       console.log("autenticado ", result.user);
//     })
//     .catch((err) => {
//       console.log(" No autenticado ", err);
//     });
// };
// export const logInGoogle = () => {
//   const googleProvider = new firebase.auth.GoogleAuthProvider();
//   auth
//     .signInWithPopup(googleProvider)
//     .then((result) => {
//       console.log("autenticado ", result);
//       console.log("autenticado ", result.user);
//     })
//     .catch((err) => {
//       console.log(" No autenticado ", err);
//     });
// };

// Get a reference to the storage service, which is used to create references in your storage bucket
export let storage = initializedFirebaseApp.storage();

// Create a storage reference from our storage service
export let storageRef = storage.ref();

//globales
export const Competidores = "competidores/";
export const EventosAutorizadosRuta = "eventosAutorizados/";
export const EventosAutorizadosJuezRuta = "eventosAutorizadosJuez/";

export const EventosCompletos = "eventosCompletos/";
export const EventosLista = "eventosLista/";

//Especificas por evento
// publicas
export const Configuracion = "configuracion/";
export const Convocatoria = "convocatoria/";
export const DetalleEvento = "detalleEvento/";
export const Resultados = "resultados/";
export const Modalidades = "modalidades/";
export const ModalidadesInscripciones = "modalidadesInscripcion/";
export const FotosEvento = "fotosEvento/";
export const CompetidoresEvento = "competidoresEvento/";
export const PuntosDeControl = "puntosDeControl/";
export const Ruta = "ruta/";
export const InscripcionesConfiguracion = "inscripcionesConfiguracion/";
export const OganizadoresEvento = "oganizadoresEvento/";
export const customStrings = "customStrings/";

export const fotosEventoRuta = "fotosEvento/";
export const configuracionFotosRuta = "configuracionFotos/";
export const fotosRuta = "fotos/";
export const puntoFotograficoPrevRuta = "puntoFotografico_";

// privadas
export const JuecesEvento = "juecesEvento/";
export const RegistrosJuez = "registrosJuez/";
export const ResultadosEnProceso = "resultadosEnProceso/";

// eventos Virtuales
export const users = "users";
export const virtualEvents = "virtualEvents";
export const participants = "participants";
export const trakingGPSParticipants = "trakingGPSParticipants";
export const myVirtualEvents = "myVirtualEvents";
export const myOrganizedVirtualEvents = "myOrganizedVirtualEvents";
export const eventPass = "eventPass";
export const activities = "activities";
export const activityDetails = "activityDetails";
export const traking = "traking";

// chat
export const chatVirtualEvent = "chatVirtualEvent";

let eventosTB = db.ref(EventosCompletos);

// seccion para nuevos inscritos.
export const inscritos = "inscritos/";
export const formsData = "formsData/";
export const folios = "folios/";
export const listadoInscritos = "listadoInscritos/";
export const resumenInscritos = "resumenInscritos/";
export const codigosInscritos = "codigosInscritos/";

// Event WEB
export const eventWeb = "web/";

export const borrarInscritoFB = (
  eventoIndex,
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount,
  borrado
) => {
  let action;

  if (borrado) {
    action = 1;
  } else {
    console.log(borrado);
    action = 2;
  }

  let changesRegistry = changesRegistryFunc(
    inscritoKey,
    ParticipantDetail,
    userID,
    atleteAccount,
    action,
    ""
  );

  let updates = {};
  updates[
    "eventosCompletos/" +
      eventoIndex +
      "/inscritos/listadoInscritos/" +
      inscritoKey +
      "/borrado/"
  ] = borrado;
  updates[
    "eventosCompletos/" +
      eventoIndex +
      "/inscritos/listadoInscritos/" +
      inscritoKey +
      "/changesRegistry/"
  ] = changesRegistry;
  return db
    .ref()
    .update(updates)
    .then((snap) => {
      console.log(borrado);
    });
};

//##################################################################################
export const cambiarModGenCatFB = (
  eventSubEvents,
  JSONCambios,
  eventoIndex,
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount
) => {
  let modCatJson = modalidadCateogoriaNameByCode(
    eventSubEvents,
    JSONCambios.modalidad,
    JSONCambios.categoria,
    JSONCambios.genero
  );
  let modCatJsonAnt = modalidadCateogoriaNameByCode(
    eventSubEvents,
    JSONCambios.modalidadAnterior,
    JSONCambios.categoriaAnterior,
    JSONCambios.generoAnterior
  );

  let updates = {};
  if (JSONCambios.modalidadAnterior != JSONCambios.modalidad) {
    let valorAnterior = modCatJsonAnt.modalidad;
    let newValue = modCatJson.modalidad;
    let campo = "modalidad";
    console.log("cambio" + campo);
    let description = `Cambio Modalidad ${valorAnterior} a ${newValue}`;
    let action = 8;
    let changesRegistry = changesRegistryFunc(
      inscritoKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      action,
      description
    );
    console.log(changesRegistry);
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/${campo}/`
    ] = JSONCambios.modalidad;
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/changesRegistry/`
    ] = changesRegistry;

    M.toast(description, 4000);
  }
  if (JSONCambios.categoriaAnterior != JSONCambios.categoria) {
    let valorAnterior = modCatJsonAnt.categoria;
    let newValue = modCatJson.categoria;
    let campo = "categoria";
    console.log("cambio" + campo);
    let description = `Cambio categoria ${valorAnterior} a ${newValue}`;
    let action = 8;
    let changesRegistry = changesRegistryFunc(
      inscritoKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      action,
      description
    );
    console.log(changesRegistry);
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/${campo}/`
    ] = JSONCambios.categoria;
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/changesRegistry/`
    ] = changesRegistry;
    M.toast(description, 4000);
  }
  if (JSONCambios.generoAnterior != JSONCambios.genero) {
    let valorAnterior = JSONCambios.generoAnterior;
    let newValue = JSONCambios.genero;
    let campo = "rama";
    console.log("cambio" + campo);
    let description = `Cambio genero ${valorAnterior} a ${newValue}`;
    let action = 8;
    let changesRegistry = changesRegistryFunc(
      inscritoKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      action,
      description
    );
    console.log(changesRegistry);
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/${campo}/`
    ] = JSONCambios.genero;
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/changesRegistry/`
    ] = changesRegistry;
    M.toast(description, 4000);
  }

  return db
    .ref()
    .update(updates)
    .then((snap) => {
      M.toast("Cambios Guardados", 4000);
    });
};
//##################################################################################
export const inputChanges = (
  eventoIndex,
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount,
  inputObject,
  newValue
) => {
  //  function saveChange(event, id, titulo,
  //campo, valorAnterior, idRegistro, tipoCampo, eventoIndex){

  let campo = inputObject.iDCampo;
  let etiqueta = inputObject.etiqueta;
  let valorAnterior = ParticipantDetail[campo];
  console.log(valorAnterior);

  let cambio = false;
  if (inputObject.tipoCampo.localeCompare("checkbox") == 0) {
    cambio = newValue != valorAnterior;
  } else {
    cambio = newValue.localeCompare(valorAnterior) != 0;
  }

  if (cambio) {
    let jsonAction = {
      correo: 3,
      nombres: 4,
      apellidos: 5,
      telefono: 6,
      fechaNacimiento: 7,
      modalidad: 8,
      rama: 9,
      categoria: 10,
    };

    let action = jsonAction[campo];
    if (action == undefined) {
      action = 11;
    }

    let description = `Cambio ${etiqueta} de ${valorAnterior} a ${newValue}`;
    let changesRegistry = changesRegistryFunc(
      inscritoKey,
      ParticipantDetail,
      userID,
      atleteAccount,
      action,
      description
    );

    let updates = {};
    updates[
      `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/${campo}/`
    ] = newValue;
    updates[
      "eventosCompletos/" +
        eventoIndex +
        "/inscritos/listadoInscritos/" +
        inscritoKey +
        "/changesRegistry/"
    ] = changesRegistry;

    return db
      .ref()
      .update(updates)
      .then((snap) => {
        M.toast(description, 4000);
      });
  } else {
    M.toast("No hubo cambios", 4000);
  }
};
//##################################################################################
export const deleteParticipantNumber = (
  eventoIndex,
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount
) => {
  let action = 20;
  let description = `Liberar folio ${ParticipantDetail.numero}`;

  let changesRegistry = changesRegistryFunc(
    inscritoKey,
    ParticipantDetail,
    userID,
    atleteAccount,
    action,
    description
  );

  console.log(eventoIndex);
  console.log(inscritoKey);
  console.log(ParticipantDetail.numero);

  let updates = {};
  updates[
    `/eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/numero/`
  ] = "Liberado";
  updates[
    `/eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/liberado/`
  ] = true;
  updates[
    `eventosCompletos/${eventoIndex}/inscritos/listadoInscritos/${inscritoKey}/changesRegistry/`
  ] = changesRegistry;
  updates[
    `eventosCompletos/${eventoIndex}/inscritos/folios/num_${ParticipantDetail.numero}/`
  ] = null;
  return firebase.database().ref().update(updates);
};
//##################################################################################
export const changesRegistryFunc = (
  inscritoKey,
  ParticipantDetail,
  userID,
  atleteAccount,
  action,
  description
) => {
  let timestamp = new Date().getTime();
  console.log(inscritoKey);
  let changesRegistry = ParticipantDetail.changesRegistry;
  if (changesRegistry == undefined) {
    changesRegistry = {};
    ParticipantDetail.changesRegistry = {};
  }
  let cambios = {
    iduser: userID,
    nombresUser: atleteAccount.nombres,
    apellidosUser: atleteAccount.apellidos,
    date: timestamp,
    action: action,
    description: description,
  };

  changesRegistry[timestamp + "_ts"] = cambios;
  ParticipantDetail.changesRegistry[timestamp + "_ts"] = cambios;
  return changesRegistry;
};
