import React from 'react';
import DynamicInputs from '../../../../DynamicInput/DynamicInputs'; 

class AditionalInputsAtleteDataFormByModCat extends React.Component {
  constructor(props){
    super(props);

  }


  getValue = (valor, inputObject, valid) => {
    this.props.update(valor, inputObject, valid);
    this.setState({[inputObject.iDCampo]: valor});
    }

  render(){

    let aditionalInputs = [];
    if(this.props.modalidad != undefined && this.props.categoria != undefined){
      var camposAdicionalesKey = "";
      if(this.props.modalidad > 0 && this.props.categoria > 0){
        if(this.props.eventSubEvents["modalidad_"+this.props.modalidad].
        ramaCat["rama_"+this.props.rama].
        categorias["categoria_"+this.props.categoria].camposAdicionalesKey != undefined){
          console.log(" si tiene campos");
          camposAdicionalesKey = this.props.eventSubEvents["modalidad_"+this.props.modalidad].
          ramaCat["rama_"+this.props.rama].
          categorias["categoria_"+this.props.categoria].camposAdicionalesKey;
        }
      

        var camposAdicionalesArray = this.props.eventSubEvents["modalidad_"+this.props.modalidad]
        .configModalidad.camposAdicionalesModalidad[camposAdicionalesKey];
 
      for(var i in camposAdicionalesArray){
        let inputValue = "";
        if(this.props.atleteAccount[camposAdicionalesArray[i].iDCampo] != undefined){
            inputValue = this.props.atleteAccount[camposAdicionalesArray[i].iDCampo]
        }else{
           inputValue = null
        }
  
        aditionalInputs.push(
          <DynamicInputs key={i} inputObject={camposAdicionalesArray[i]} 
            actualValue={inputValue} 
            validate={this.props.validate}
            callbackchange={this.props.update}
            />
        );
  
      }
    }
    }

    return (<div className="row">
              {aditionalInputs}
            </div>
    );
  }
}

export default AditionalInputsAtleteDataFormByModCat;
