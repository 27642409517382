/**
 * Created by avaldez on 12/20/16.
 */
import React from 'react';


	const AboutUs = () => (
		<div>
			<h5 className="white-text">Nosotros</h5>
			<p className="grey-text text-lighten-4 left-align">
			Dashport empezó con la firme convicción de que podíamos integrar la tecnología y el 
			deporte, con el fin de que cada vez mas personas realicen actividades físicas.
			</p>
			<p className="grey-text text-lighten-4 left-align">
			Actualmente nos dedicamos a eso, desarrollamos herramientas tecnológicas que, 
			de una u otra manera, están contribuyendo a aumentar la cantidad de personas que 
			practican actividades físicas de forma habitual.
			</p>
		</div>
	);



export default AboutUs;
