import React from "react";
import { useSelector } from "react-redux";
import YouTube, { YouTubeProps } from "react-youtube";
import { Typography } from "@material-ui/core";

export default function AtleteVideo({ eventDetail, resultado }) {
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );

  const videos =
    eventConfiguration.timingConfig &&
    eventConfiguration.timingConfig.eventVideos
      ? Object.values(eventConfiguration.timingConfig.eventVideos)
      : [];

  const horaMeta = resultado.fhm.split(" ");
  let horaMetaNew = new Date(horaMeta[0] + "T" + horaMeta[1]).getTime();

  const YouTubeProps = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  let videosNew = [];
  if (videos.length >= 1) {
    for (var i in videos) {
      let startDate = videos[i].startTime.split(" ");
      let startDateNew = new Date(startDate[0] + "T" + startDate[1]).getTime();

      let endDate = videos[i].endTime.split(" ");
      let endDateNew = new Date(endDate[0] + "T" + endDate[1]).getTime();

      const timeBeforeFinish = 5;
      const secondInVideoToShow =
        Math.round((horaMetaNew - startDateNew) / 1000) - timeBeforeFinish;

      const video = {
        ...videos[i],
        seconds: secondInVideoToShow,
        props: {
          width: "100%",
          playerVars: {
            autoplay: 0,
            start: secondInVideoToShow,
          },
        },
      };
      if (
        !videos[i].hide &&
        startDateNew <= horaMetaNew &&
        endDateNew >= horaMetaNew
      ) {
        videosNew.push(video);
      }
    }
  }

  if (videos.length >= 1) {
    return (
      <div>
        <hr />
        <div className="row">
          <div className="col s10 m10 l10">
            <Typography variant={"h4"} color="primary">
              Video de la meta
            </Typography>
            <Typography variant={"h6"} color="primary">
              (Inicia 5 segundos antes de tu hora de llegada a meta)
            </Typography>
          </div>
        </div>
        <div className="row">
          <div className="col s12 m12 l12">
            {Object.values(videosNew).length >= 1 &&
              Object.values(videosNew).map((video) => (
                <div>
                  {video.title && (
                    <Typography variant={"h4"} color="primary">
                      Video de la meta
                    </Typography>
                  )}
                  <YouTube videoId={video.videoId} opts={video.props} />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
  return <div></div>;
}
