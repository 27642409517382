import React, { useState }from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { requestActivitiesGarmin } from '../../../../data-store/actions/atlete-actions'

import activities from './5kdeJuli.json'

const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },table: {
    minWidth: 650,
  },
}));



export default function GarminActivitiesList(props){
  const classes = useStyles();
  const [requestToken, setRequestToken] = useState(true);

	const { atleteAccount, activity, sendRequestActivities, userID } = props;
 
  let activities = <h5>Vacio</h5>;
  if(activity == undefined || activity == null){
    activities = <h5>Vacio</h5>;
  }
  
  if(activity == undefined){
    return (      <Grid container justify="center" style={{textAlign:"center"}} spacing={3}>
    <Grid item xs={12}  style={{textAlign:"center"}}>
      <h5>Lista de actividades</h5>
    </Grid>
    <Grid item xs={12}  style={{textAlign:"center"}}>
    Vacio
    </Grid>
  </Grid>
)
  }

	  return (
      <Grid container justify="center" style={{textAlign:"center"}} spacing={3}>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          <h5>Lista de actividades</h5>
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Hora</TableCell>
                      <TableCell align="right">Latitud</TableCell>
                      <TableCell align="right">Longitud</TableCell>
                      <TableCell align="right">Elevación en metros</TableCell>
                      <TableCell align="right">Ritmo Cardiaco</TableCell>
                      <TableCell align="right">Velocidad m/s</TableCell>
                      <TableCell align="right">Pasos por minuto</TableCell>
                      <TableCell align="right">Distancia</TableCell>
                      <TableCell align="right">Watts</TableCell>
                      <TableCell align="right">Bike Cadence in RPM</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activity.samples.map((row) => (
                      <TableRow key={row.startTimeInSeconds}>
                        <TableCell component="th" scope="row">{row.startTimeInSeconds}</TableCell>
                        <TableCell align="right">{row.latitudeInDegree}</TableCell>
                        <TableCell align="right">{row.longitudeInDegree}</TableCell>
                        <TableCell align="right">{row.elevationInMeters}</TableCell>
                        <TableCell align="right">{row.heartRate}</TableCell>
                        <TableCell align="right">{row.speedMetersPerSecond}</TableCell>
                        <TableCell align="right">{row.stepsPerMinute}</TableCell>
                        <TableCell align="right">{row.totalDistanceInMeters}</TableCell>
                        <TableCell align="right">{row.powerInWatts}</TableCell>
                        <TableCell align="right">{row.bikeCadenceInRPM}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

        </Grid>
      </Grid>
	  );
	}
	