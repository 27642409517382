import React from 'react';
import {Input, Icon} from 'react-materialize';
import Button from 'react-materialize/lib/Button';
import RequiredMessage from '../RequiredMessage';

class DynamicInputSelect extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      update:false
    }
  }



  setValue = (value) => {
    console.log("Set value" + this.props.inputObject.iDCampo);
    console.log(value);
    var e = {target:{
      value:value,
      name:this.props.inputObject.iDCampo
    }};
    if(this.state.update){
      console.log("setValue true");
      this.props.getValue(e);
      this.setState({update : false});
    }
  }

    validate = () =>{

      // if(this.props.inputObject.validacion == "Requerido"){
      //   if(this.state.value != undefined 
      //     && this.state.value != null
      //     && this.state.value != ""){
      //       alert("ok");  

      //     }else{
      //       alert("campo requerido");  
      //     }
      // }
    }

  render(){
    var anchoCampo = (this.props.inputObject.anchoCampo == undefined)?"s12 m6 l6":this.props.inputObject.anchoCampo;
    let requerido = (this.props.inputObject.validacion == "Requerido")?"*":"";
    let s = 12;

    let inputByType;
    var selectOptions = [];
    if(this.props.inputObject.selectLabel != undefined){
      selectOptions.push(<option key={0} value={0} disabled={false}>{this.props.inputObject.selectLabel}</option>);
    }else{
      selectOptions.push(<option key={0} value={0} disabled={false}>Selecciona una Opción</option>);
    }

    this.props.inputObject.valor.map((v,i) => {
          selectOptions.push(<option key={i+1} value={v.valor} disabled={v.disabled}>{v.texto}</option>);
      });
      var valueLabel = (this.props.inputObject.valor[this.props.value] != undefined)?this.props.inputObject.valor[this.props.value].texto:"vacio";
      var value = (this.props.inputObject.valor[this.props.value] != undefined)?this.props.inputObject.valor[this.props.value].valor:0;
      if(selectOptions.length <= 0){
        // TODO,esta parte no logro que funciones bien, 
        inputByType = (
          <div className={`col ${anchoCampo}`}>
            <div>{this.props.inputObject.etiqueta}</div>
            <h5>{valueLabel}</h5>
          </div>
        );
        this.setValue(value);

      }else{
        inputByType = (
          <div className={`col ${anchoCampo}`}>
          <Input s={s} 
              onBlur={(e)=>this.validate(e)}
              onChange={e => this.props.getValue(e)} 
              type='select' 
              label={this.props.inputObject.etiqueta +" "+requerido} 
              defaultValue={this.props.value}>
            {selectOptions}
          </Input>
          {this.props.buttonSave}
          <RequiredMessage message={this.props.message}/>
          </div>
        );
  
      }


      return inputByType;
  
  }
}

export default DynamicInputSelect;
