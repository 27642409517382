import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import MenuItem from "@material-ui/core/MenuItem";
import ReactHookFormSelect from "./ReactHookFormSelect";

const useStyles = makeStyles({
  iconSave: {},
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
  },
});

export default function EditableFieldSelect(props) {
  const { fieldObject, onSubmit } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    optionsArray,
  } = fieldObject;
  const classes = useStyles();
  const { register, handleSubmit, errors, control } = useForm();
  const [editMode, setEditMode] = useState(false);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  function onSave(data) {
    setEditMode(false);
    onSubmit({ [fieldName]: data[fieldName] });
  }

  function handleChange(event) {
    setCurrentValue(event.target.value);
  }
  return editMode ? (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
    >
      <Grid container direction="row">
        <Grid item xs={10}>
          <ReactHookFormSelect
            id={fieldName}
            name={fieldName}
            label={label}
            control={control}
            defaultValue={defaultValue}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            style={{ width: "100%" }}
            required={required}
          >
            {optionsArray.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            className={classes.iconSave}
            onClick={handleSubmit(onSave)}
          >
            <SaveIcon />
          </IconButton>
        </Grid>
      </Grid>
    </form>
  ) : (
    <div>
      <Typography
        className={classes.text}
        gutterBottom
        variant={"subtitle2"}
        component="h2"
      >
        {label}:
      </Typography>
      <Typography
        className={classes.text}
        gutterBottom
        variant={textSize || "h5"}
        component="h2"
        style={{ minHeight: "30px" }}
      >
        {optionsArray.filter((value) => value.value === defaultValue)[0] ===
        undefined
          ? ""
          : optionsArray.filter((value) => value.value === defaultValue)[0]
              .label}

        <IconButton
          size={
            !textSize ||
            textSize === "h1" ||
            textSize === "h2" ||
            textSize === "h3" ||
            textSize === "h4" ||
            textSize === "h5"
              ? "medium"
              : "small"
          }
          className={classes.iconEdit}
          onClick={() => setEditMode(true)}
        >
          <EditIcon />
        </IconButton>
      </Typography>
    </div>
  );
}
