import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import PhotoEditorContainer from "../../../PhotoEditorVirtual/PhotoEditorContainer";

import Login from "../../../../../../Login/Login";

import { useForm, Controller } from "react-hook-form";
import {
  uploadPhoto,
  getUserVirtualEvents,
  saveUploadedPhoto,
} from "../../../../../../../data-store/actions/virtual-events-actions";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import MyGoalTime from "./MyGoalTime";
import InputFileButton from "../../../InputFileButton";
import { CardActions } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
    height: 280,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function StartLinePictures(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(
    (state) => state.virtualEvents.currentEventParticipants
  );

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const thisVirtualEvent = myVirtualEvents[currentEvent.eventID];
  var photoURL = atleteAccount.photoURL;
  if (thisVirtualEvent.startLinePhoto != undefined) {
    photoURL = thisVirtualEvent.startLinePhoto;
  }

  const participant = currentEventParticipants[userID];

  const callback = (photo) => {
    dispatch(
      uploadPhoto(
        userID,
        currentEvent.eventID,
        photo,
        "startLine",
        currentEvent
      )
    );
  };

  if (atleteAccount == null) {
    return <Login />;
  }

  if (myVirtualEvents == undefined || currentEvent == undefined) {
    return <CircularProgress />;
  }
  if (currentEvent == {}) {
    return <h1>Usuario no autorizado para este evento</h1>;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-around">
          <Grid item xs={5}>
            <Typography gutterBottom variant="h5" component="h5">
              Sube tu fotografía en la linea de salida
            </Typography>
            <InputFileButton
              type="image/*"
              callback={callback}
              text="Subir Foto"
            />
          </Grid>
          <Grid item xs={5}>
            <CardMedia
              component="img"
              alt={currentEvent.eventName}
              image={photoURL}
              title={currentEvent.eventName}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
