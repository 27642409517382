import React from "react";
import { connect } from "react-redux";
import { helperMultisort } from "../../../services/utils";
import {
  ResultadosGanadores,
  ResultadosGenerales,
  ResultadosRama,
  ResultadosCategoria,
  controlesArrayFunc,
  ImprimirDatos,
} from "./reportsLogic";
import {
  detalleEvento,
  resultadosEvento,
  getPuntosControl,
} from "../../../data-store/actions/events-actions";

import "materialize-css/dist/css/materialize.min.css";
import Row from "../reports/RowResultReport";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

import Background from "../../../assets/backgroundPhotos/fondogris_Dashport.jpg";

const ResultsReportView = ({
  reportResultType,
  controles,
  eventResults,
  eventDetail,
  puntosControl,
}) => {
  var element = "";

  if (eventDetail != undefined && (puntosControl != undefined || !controles)) {
    var iDEvento = eventDetail.iDEvento;

    let checkControls = false;
    if (controles && puntosControl != undefined) {
      checkControls = true;
    }
    if (!controles) {
      checkControls = true;
    }
    if (eventResults != undefined && checkControls) {
      var resultadosOrdendos;
      var controlesArray;
      if (controles) {
        controlesArray = controlesArrayFunc(puntosControl);
      }

      if (reportResultType == 4) {
        resultadosOrdendos = ResultadosGanadores(eventResults);
      } else if (reportResultType == 1) {
        resultadosOrdendos = ResultadosGenerales(eventResults);
      } else if (reportResultType == 2) {
        resultadosOrdendos = ResultadosRama(eventResults);
      } else if (reportResultType == 3) {
        resultadosOrdendos = ResultadosCategoria(eventResults);
      } else {
        resultadosOrdendos = eventResults;
      }

      var filas = ImprimirDatos(
        resultadosOrdendos,
        reportResultType,
        false,
        controles,
        iDEvento
      );

      element = (
        <Table
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
          }}
          id="Tabla-1"
          className="bordered centered highlight"
        >
          <TableHead></TableHead>
          <TableBody id="Resultados">
            {filas.map((data, index) => (
              <Row
                key={index}
                data={data}
                controlesArray={controlesArray}
                controles={controles}
              />
            ))}
          </TableBody>
        </Table>
      );
    } else {
      element = "no";
    }
  }

  return element;
};

const mapDispatchToProps = (dispatch, { match }) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventResults: state.events.eventResults,
    puntosControl: state.events.puntosControl,
    eventConfiguration: state.events.eventConfiguration,
  };
};

const ResultsReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsReportView);

export default ResultsReport;
