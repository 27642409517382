import React from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './reportsCss.css';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const style = {
    fontStyle : 'bold',
    fontSize : '60px',
    color:'white'
}
const styleTitle = {
    backgroundColor : '#ef4123'
}

const TitleResultReport = ({data, controlesArray, controles}) => {
// data = {typeRow:1, reportType:tipoReporte , mod:Mod, cat:Cat, gen:Gen, ab:false}

var reportType = data.reportType;

    let filasAdicionales = 0;

    if(controles && controlesArray["modalidad_"+data.codMod] != undefined){
        filasAdicionales = controlesArray["modalidad_"+data.codMod].tamano;
    }

    var GenTexto = (data.gen == 'F')?'Femenil':'Varonil';

    if(data.gen == 'F'){
        GenTexto = 'Femenil';
    }else if(data.gen == 'V'){
        GenTexto = 'Varonil';
    }else if(data.gen == 'M'){
        GenTexto = 'Mixto';
    }

    var element;
var filasFinal = 11 + filasAdicionales;
    if(reportType == 1){
//        <tr><td colSpan={filasFinal}>Resultados Generales Modalidad: {Mod}K </td></tr>
//        <tr><td colSpan={filasFinal}>Resultados Generales Modalidad: {Mod}K </td></tr>
        element = (
            <TableRow style={styleTitle} >
                <TableCell style={style} colSpan={filasFinal}>
                    <h5>Resultados Generales Modalidad: {data.mod}K </h5>
                </TableCell>
            </TableRow>
            );
    }else if(reportType == 2){
        // <tr><td colSpan={filasFinal}>Modalidad: {Mod}K - Rama {GenTexto} </td></tr>
        element = (
            <TableRow style={styleTitle} >
                <TableCell style={style} colSpan={filasFinal}>
                    <h5>Modalidad: {data.mod}K - Rama {GenTexto}</h5>
                </TableCell>
            </TableRow>
        );
    }else if(reportType == 3 || reportType == 4){

        if(data.ab){
            element = (
                <TableRow style={styleTitle} >
                    <TableCell style={style} colSpan={filasFinal}>
                        <h5>Modalidad: {data.mod}K - Rama {GenTexto} - Categoría Absolutos</h5>
                    </TableCell>
                </TableRow>
            );
        }else{
            element = (
                <TableRow style={styleTitle} >
                    <TableCell style={style} colSpan={filasFinal}>
                        <h5>Modalidad: {data.mod}K - Rama {GenTexto} - Categoría {data.cat}</h5>
                    </TableCell>
                </TableRow>
            );
        }
    }
   
    return element;
}

export default TitleResultReport;
