import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getallDataForConfirmationFunc } from "../../../../../data-store/actions/registry-actions";
import RegistryConfirmation from "./RegistryConfirmation";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    margin: "0px",
  },
  paper: {
    padding: "0px",
    backgroundColor: "#283747",
  },
  color: {
    padding: "0px",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function RegistryConfirmationPublic(props) {
  const { event, registry } = props;
  const dispatch = useDispatch();

  //const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const registryParticipantDataConfirm = useSelector(
    (state) => state.registry.registryParticipantData
  );
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const classes = useStyles();

  useEffect(() => {
    dispatch(getallDataForConfirmationFunc(event, registry));
  }, []);

  if (
    !registryParticipantDataConfirm ||
    !eventSubEventsRegistry ||
    !registryConfiguration
  ) {
    return (
      <Typography color="primary" component="h1" variant="h6">
        Cargando...
      </Typography>
    );
  }
  return (
    <RegistryConfirmation
      registryParticipantDataConfirm={registryParticipantDataConfirm}
      registryParticipantKeyConfirm={registry}
      registryConfiguration={registryConfiguration}
      eventSubEventsRegistry={eventSubEventsRegistry}
      eventDetail={eventDetail}
      download={true}
      tipo={1}
    />
  );
}
