import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useTracking } from "react-tracking";
import LayoutResolver from "./LayoutResolver";
import { Helmet } from "react-helmet";
import AppContainer from "../../../AppContainer";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export function resolvePage(route, organizerWeb, EventoIndex) {
  console.log("match", route);

  if (route === undefined) {
    return organizerWeb.containers.home;
  }
  if (route === "web") {
    return {
      views: [
        {
          component: "eventWeb",
          componentConfig: {
            eventIndex: EventoIndex,
          },
        },
      ],
    };
  }
  if (route.indexOf("evento_") > -1) {
    console.log("route", route);
    return {
      views: [
        {
          component: "eventWeb",
          componentConfig: {
            eventIndex: route,
          },
        },
      ],
    };
  }

  if (organizerWeb.containers[route] !== undefined) {
    return organizerWeb.containers[route];
  } else {
    if (organizerWeb.containers.notFound) {
      return organizerWeb.containers.notFound;
    } else {
      return {
        views: [
          {
            component: "notFoundDefault",
            componentConfig: {
              eventIndex: "",
            },
          },
        ],
      };
    }
  }
}

export default function LayoutContainer({ match }) {
  console.log("match", match);
  const { route, EventoIndex } = match.params;
  const organizerWeb = useSelector((state) => state.organizer.organizerWeb);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const classes = useStyles();
  const { trackEvent } = useTracking();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const tkl = query.get("tkl");

  useEffect(() => {
    if (eventDetail) {
      trackEvent({
        eventName: "evento_" + eventDetail.iDEvento,
        typeEvent: "PageView",
        params: {
          label: "ViewLanding",
          tkl: tkl || "direct",
          eventName: eventDetail.nombreEvento,
          facebookPixel: eventDetail.facebookPixel || false,
          gaId: eventDetail.gaId || false,
        },
      });
    }
  }, [eventDetail]);

  if (!organizerWeb && route !== "web" && route.indexOf("evento_") === -1) {
    return (
      <AppContainer>
        <h1>Cargando...</h1>
      </AppContainer>
    );
  }
  let pageToShow = resolvePage(route, organizerWeb, EventoIndex);
  console.log(organizerWeb);

  if (pageToShow) {
    return (
      <div>
        <Helmet>
          <title>
            {organizerWeb !== undefined
              ? organizerWeb.title || window.location.hostname
              : ""}
          </title>
          <link
            rel="shortcut icon"
            href={organizerWeb !== undefined ? organizerWeb.icon || "" : ""}
          />
        </Helmet>

        <LayoutResolver page={pageToShow} />
      </div>
    );
  }

  return null;
}
