import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import { segundosATiempo } from "../../../../../services/utils";
import { saveGarminActivity } from "../../../../../data-store/actions/virtual-events-actions";
import {
  requestActivityGarminDetail,
  requestActivitiesGarminUser,
} from "../../../../../data-store/actions/atlete-actions";
import Typography from "@material-ui/core/Typography";

import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import PoolIcon from "@material-ui/icons/Pool";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function GarminActivitiesCard(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  let activitiesGarmin = useSelector((state) => state.atlete.activitiesGarmin);

  useEffect(() => {
    console.log("GarminActivitiesCard");
    if (userID != undefined) {
      console.log(activitiesGarmin);
      dispatch(requestActivitiesGarminUser(userID, currentEvent.eventStart));
    }
  }, []);

  const iconsArray = {
    RUNNING: <DirectionsRunIcon />,
    CYCLING: <DirectionsBikeIcon />,
    SWIMMING: <PoolIcon />,
    LAP_SWIMMING: <PoolIcon />,
  };
  activitiesGarmin =
    activitiesGarmin != undefined ? Object.values(activitiesGarmin) : [];

  const handleClick = (activity) => {
    console.log(segundosATiempo(activity.durationInSeconds));
    console.log(userID);
    console.log(currentEvent.eventID);

    dispatch(
      saveGarminActivity(
        userID,
        currentEvent.eventID,
        segundosATiempo(activity.durationInSeconds),
        activity
      )
    );
  };

  return (
    <div className={classes.root}>
      <List aria-label="garmin-activities">
        {JSON.stringify(activitiesGarmin) == JSON.stringify([]) && (
          <Typography gutterBottom variant="h5">
            No hay actividades desde el inicio del evento
          </Typography>
        )}
        {activitiesGarmin.map((activity, key) => (
          <ListItem key={key} button>
            <ListItemAvatar>
              {iconsArray[activity.activityType] != undefined ? (
                iconsArray[activity.activityType]
              ) : (
                <AccessibilityIcon />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                "Tiempo : " + segundosATiempo(activity.durationInSeconds)
              }
              secondary={
                "Distancia : " +
                Math.round(activity.distanceInMeters / 1000) +
                " " +
                (Math.round(activity.distanceInMeters / 1000) >=
                  currentEvent.distance - 0.1)
                  ? ""
                  : "Distancia inferior al reto"
              }
            />
            <ListItemSecondaryAction>
              {Math.round(activity.distanceInMeters / 1000) >=
              currentEvent.distance - 0.1 ? (
                <Button onClick={() => handleClick(activity)}>Aplicar</Button>
              ) : (
                <Button>No Aplicable</Button>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
