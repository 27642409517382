import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Paper } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventIcon from "@material-ui/icons/Event";
//import UserPaymentMethodsAdd from "../UserProfile/UserPaymentMethodsAdd";
import CardPaymentList from "./CardPaymentList";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { showSnackbar } from "../../../../../data-store/actions/organizers-actions";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    color: theme.palette.primary.main,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
}));

export default function CodePayment(props) {
  const { callback, callbackCode, currency, currentOrder, eventDetail } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const registryParticipantKey = useSelector(
    (state) => state.registry.registryParticipantKey
  );

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [sending, setSending] = useState(false);
  // const [cardNumber, setCardNumber] = useState(null);
  // const [cardOwner, setCardOwner] = useState(null);

  function onSubmit(data) {
    // setCardNumber(data.cardNumber);
    // setCardOwner(data.cardOwner);
    setSending(true);
    console.log("data", data);
    // console.log("data", cardNumber);
    // console.log("data", sending);

    var order = { registryAmountToPay: 0, totalAmountToPay: 0 };

    currentOrder.order.map((item) => {
      if (item.code === "insc") {
        order.registryAmountToPay =
          Math.round(
            parseFloat(item.unit_price) * parseFloat(item.quantity) * 100
          ) / 100;
      }
      if (item.code !== "comision") {
        order.totalAmountToPay =
          Math.round(
            (parseFloat(order.totalAmountToPay) +
              parseFloat(item.unit_price) * parseFloat(item.quantity)) *
              100
          ) / 100;
      }
    });

    var jsonDatos = {
      code: data.code,
      eventoIndex: "evento_" + eventDetail.iDEvento,
      totalAmountToPay: order.totalAmountToPay,
      registryAmountToPay: order.registryAmountToPay,
      userId: userID || "",
      email: currentOrder.correo,
      inscritoKey: "",
      subEvent: {
        modalidad: currentOrder.modalidad,
        rama: currentOrder.genero,
        categoria: currentOrder.categoria,
      },
    };

    console.log(jsonDatos);

    var headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };
    axios
      .post(
        "https://us-central1-kmetasfirebase.cloudfunctions.net/discountCode",
        jsonDatos,
        headers
      )
      .then((res) => {
        console.log("confirmSubEvent", res.data);

        if (res.data.valid) {
          dispatch(showSnackbar(true, res.data.text, 4000));
          const item = {
            code: "códigoDescuento",
            description: "Código de descuento",
            extraData: {
              code: jsonDatos.code,
            },
            name: "Código de descuento",
            precioKey: "",
            quantity: 1,
            unit_price: parseInt(res.data.object.amount) * -1,
          };
          callbackCode(true, item, true);
        } else {
          dispatch(showSnackbar(true, res.data.text, 4000));
        }
        setSending(false);
      })
      .catch((error) => {
        console.log(error);
        setSending(false);
        //callback(false);
        dispatch(
          showSnackbar(true, "Código inválido, intente de nuevo: ", 4000)
        );
      });
    // $.ajax({
    //   type: 'POST',
    //   url: 'https://us-central1-kmetasfirebase.cloudfunctions.net/discountCode',
    //   crossDomain: true,
    //   data: jsonDatosCode,
    //   dataType: 'json',
    //   success: function(res, textStatus, jqXHR) {
    //     send = false;// seteo a false para que pueda intentar de nuevo
    //     var value = responseData.someKey;
    //       console.log("exito" + responseData+" "+textStatus);
    //       console.log(JSON.stringify(responseData));

    //       if(responseData.valid){
    //         var nombreProducto = "Código de descuento";
    //         var descripcionProducto = "Código de descuento";
    //         var precioProducto = parseInt(responseData.object.amount) * -1;
    //         console.log(responseData.object.amount);
    //         console.log(precioProducto);
    //         Materialize.toast(responseData.text, 4000);
    //         $(`#actionArea-${codigoProducto}`).html("Código Aplicado");
    //         agregarProductoNuevo(event, convertAmount(precioProducto), codigoProducto, nombreProducto, descripcionProducto,"",TipoInscripcion,1, tipoProducto);
    //       }else{
    //         Materialize.toast(responseData.text, 4000);
    //       }

    //   },
    //   error: function (responseData, textStatus, errorThrown) {
    //     send = false;// seteo a false para que pueda intentar de nuevo
    //     console.log("data "+JSON.stringify(responseData));

    //     Materialize.toast(`Código inválido, intente de nuevo`, 4000);
    //   }
    // });
  }
  function checkCode() {
    return currentOrder.order.filter((a) => a.code === "códigoDescuento")
      .length > 0
      ? true
      : false;
  }
  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      ></Grid>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: "100%" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          alignItems={"left"}
          style={{ margin: "40px" }}
        >
          <Typography color="primary" variant="h5">
            Si cuentas con un código de descuento ingresalo aquí
          </Typography>
          <Typography color="primary" variant="subtitle1">
            Una vez aplicado el código debes continuar el proceso presionando el
            botón que se muestra abajo
          </Typography>

          <TextField
            className={classes.text}
            label="Código de descuento"
            style={{ width: "100%" }}
            helperText={errors.securityCode ? "Campo requerido" : null}
            InputProps={{
              className: classes.input,

              style: {
                marginBottom: 15,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <VerifiedUserIcon color="primary" />
                </InputAdornment>
              ),
            }}
            defaultValue={""}
            error={Boolean(errors.securityCode)}
            fullWidth
            inputRef={register({ required: true })}
            name="code"
            type="text"
          />
          {sending && (
            <Grid item xs={12} sm={12}>
              <LinearProgress />
            </Grid>
          )}
          {!sending && !checkCode() && (
            <Button
              type="submit"
              style={{ width: "100%" }}
              color="primary"
              variant="contained"
            >
              Aplicar el código
            </Button>
          )}
          {!sending && checkCode() && (
            <Typography color="primary" variant="h5">
              Código aplicado
            </Typography>
          )}
        </Grid>
      </form>
    </Grid>
  );
}
