import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';


import Login from '../../../../../../Login/Login'

import { useForm, Controller } from 'react-hook-form';
import { saveFinishTime } from '../../../../../../../data-store/actions/virtual-events-actions'

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    margin:10
  },
  media: {
    height: 70,
  },
  content:{
    height: 150,
    flex:1,
    flexDirection:"row",
    flexWrap:"wrap",
    alignContent:"center",
    alignItems:"center"
  },
  cardHeader:{
    backgroundColor: "red",
    color:"white"
  },
  time:{
    flex:0.3,
  },
  paper: {
    textAlign: 'center'
  }
});

export default function MyEvidenceTime(props){
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const myVirtualEvents = useSelector(state => state.virtualEvents.myVirtualEvents);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  const classes = useStyles();
  

	if(atleteAccount == null){
		return <Login/>
  }
  
  if(myVirtualEvents == undefined || currentEvent == undefined){
    return <CircularProgress/>
  }
  if(currentEvent == {}){
    return <h1>Usuario no autorizado para este evento</h1>
  }
	  return (

      <Grid container
      direction="row"
      justify="space-around"
      alignItems="center">
      <Paper style={{padding:40}}>
          <Grid item sm={12} className={classes.paper}>
                <Typography gutterBottom variant="h5" component="h2">
                  Mi tiempo
                </Typography>
          </Grid>
          <Grid item sm={12} className={classes.paper}>
            <div style={{fontSize:70}}>
              {(myVirtualEvents[currentEvent.eventID].finishTime == undefined)?"00:00:00":myVirtualEvents[currentEvent.eventID].finishTime}
            </div>
          </Grid>
          <Grid item sm={12} className={classes.paper}>
          </Grid>
        </Paper>
      </Grid>

  );
	}
	