import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";

import SimpleDialogDemo from "../../OrganizerWeb/LayoutContainers/SimpleDialog";
//import LoginForm from "../Login/LoginForm";
import EventRegistryPublicForm from "../../OrganizerWeb/sections/EventRegistry/EventRegistryPublicForm";
import {
  configuracionEvento,
  detalleEvento,
  eventSubEventsRegistry,
} from "../../../data-store/actions/events-actions";
import { registryConfiguration } from "../../../data-store/actions/registry-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    margin: "0px",
  },
  paper: {
    padding: "0px",
    backgroundColor: "#283747",
  },
  color: {
    padding: "0px",
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function EventRegistryPublic(props) {
  const {
    eventIdDashport,
    title,
    textBottom,
    name,
    eventLogo,
    image,
    renderType,
  } = props;
  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const registry = query.get("registry");
  const event = query.get("event");
  const download = query.get("download");

  //const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistryData = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfigurationData = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    console.log("getRegistryDATA", eventIdDashport);
    if (eventIdDashport) {
      console.log("getRegistryDATA", eventIdDashport);
      dispatch(detalleEvento(eventIdDashport));
      dispatch(eventSubEventsRegistry(eventIdDashport));
      dispatch(registryConfiguration(eventIdDashport));
    }
    if (registry && eventIdDashport === event) {
      console.log("registry", registry);
      setShowConfirmation(true);
    }
  }, [eventIdDashport, registry]);

  if (
    eventSubEventsRegistryData === undefined ||
    Object.values(eventSubEventsRegistryData).length === 0 ||
    registryConfigurationData === undefined
  ) {
    return <div></div>;
  }

  function openEditMode(result) {
    console.log("Open edit mode");
  }
  function openModal() {
    console.log("Open registry");
    setOpen(true);
  }
  return (
    <div>
      {" "}
      {eventDetail.showWeb ? (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={"/evento_" + eventDetail.iDEvento}
        >
          Web del evento
        </Button>
      ) : (
        <SimpleDialogDemo fullScreen={false} buttonText={"Inscripciones"}>
          <EventRegistryPublicForm eventIdDashport={eventIdDashport} />
        </SimpleDialogDemo>
      )}
    </div>
  );
}
