import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import EventConfig from "./EventConfig/EventConfig";
import EventComunication from "./EventComunication/EventComunication";
import EventParticipants from "./Participants/EventParticipants";

import Login from "../../../../../Login/Login";

import { useForm } from "react-hook-form";
import {
  getEventData,
  getUserOrganizedVirtualEventsOLD,
  getEventParticipants,
  getEventPass,
} from "../../../../../../data-store/actions/virtual-events-actions";
import { getVirtualEventsMessages } from "../../../../../../data-store/actions/chat-actions";
import VirtualEventsOrganizerCard from "../VirtualEventsOrganizerCard";
import AppContainer from "../../../../../../AppContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  menu: {
    flex: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 50,
    width: 100,
  },
}));

console.log(window);

if (window.unsubscribeEventParticipants) {
  console.log(window.unsubscribeEventParticipants());
}

export default function ChallengesOrganizerConfig(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const eventID = match.params.eventID;
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  let myOrganizedVirtualEvents = useSelector(
    (state) => state.virtualEvents.myOrganizedVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventReq = useSelector(
    (state) => state.virtualEvents.currentEventReq
  );
  const currentEventParticipantsRequested = useSelector(
    (state) => state.virtualEvents.currentEventParticipantsRequested
  );
  const eventPassRequest = useSelector(
    (state) => state.virtualEvents.eventPassRequest
  );
  const messagesRequested = useSelector(
    (state) => state.chat.messagesRequested
  );

  const [view, setView] = useState(<EventConfig eventID={eventID} />);

  console.log("id", eventID);
  useEffect(() => {
    console.log("user efect", eventID);
    if (userID != undefined) {
      if (myOrganizedVirtualEvents === undefined && userID != undefined) {
        console.log("getUserOrganizedVirtualEvents");
        dispatch(getUserOrganizedVirtualEventsOLD(userID));
      }
      if (
        currentEventReq != eventID &&
        myOrganizedVirtualEvents != undefined &&
        userID != undefined
      ) {
        dispatch(getEventData(eventID, myOrganizedVirtualEvents, userID));
      }
      if (currentEvent !== undefined) {
        if (messagesRequested !== eventID) {
          dispatch(
            getVirtualEventsMessages(userID, currentEvent.eventID, true)
          );
        }
        if (currentEventParticipantsRequested !== eventID) {
          dispatch(getEventParticipants(eventID, userID));
        }
        if (eventPassRequest !== eventID) {
          dispatch(getEventPass(eventID));
        }
      }
    }
  }, [myOrganizedVirtualEvents, userID, currentEvent]);

  const classes = useStyles();

  const { control, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  const callbackView = (view) => {
    if (view === "participants") {
      setView(<EventParticipants eventID={eventID} />);
    } else if (view === "config") {
      setView(<EventConfig eventID={eventID} />);
    } else if (view === "comunication") {
      setView(<EventComunication eventID={eventID} />);
    }
  };
  if (atleteAccount == null) {
    return <Login />;
  }

  if (currentEvent == undefined) {
    return <CircularProgress />;
  } else if (JSON.stringify(currentEvent) === JSON.stringify({})) {
    return <h4>El id del evento ingresado no existe</h4>;
  }

  return (
    <AppContainer>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <VirtualEventsOrganizerCard
              showButtons={true}
              currentEvent={currentEvent}
              callbackView={callbackView}
            />
          </Grid>
          <Grid item xs={12}>
            {view}
          </Grid>
        </Grid>
      </div>
    </AppContainer>
  );
}
