import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getallDataForConfirmationFuncGeneric } from "../../../../../data-store/actions/registry-actions";

import RegistryConfirmation from "./RegistryConfirmation";
import AppContainer from "../../../../../AppContainer";
import { Typography } from "@material-ui/core";

export default function RegistryConfirmationPublic({ match }) {
  const dispatch = useDispatch();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const registry = query.get("registry");
  const event = query.get("event");
  const download = query.get("download");

  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const registryParticipantData = useSelector(
    (state) => state.registry.registryParticipantData
  );
  const registryParticipantKey = useSelector(
    (state) => state.registry.registryParticipantKey
  );

  useEffect(() => {
    if (registry && event) {
      dispatch(getallDataForConfirmationFuncGeneric(event, registry));
    }
    console.log("useEfect");
  }, [registry, event]);

  if (
    !eventSubEventsRegistry ||
    !registryConfiguration ||
    !registryParticipantData ||
    !registryParticipantKey ||
    !eventDetail
  ) {
    return (
      <AppContainer>
        <Typography gutterBottom variant={"h3"} component="h2">
          Cargando
        </Typography>
      </AppContainer>
    );
  }
  return (
    <AppContainer>
      <RegistryConfirmation
        registryParticipantDataConfirm={registryParticipantData}
        registryParticipantKeyConfirm={registryParticipantKey}
        registryConfiguration={registryConfiguration}
        eventSubEvents={eventSubEventsRegistry}
        eventDetail={eventDetail}
        download={download}
        tipo={1}
      />
    </AppContainer>
  );
}
