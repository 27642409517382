import React from "react";
import { connect } from "react-redux";
import {
  detalleEvento,
  resultadosEvento,
  resultReportType,
  getPuntosControl,
  configuracionEvento,
  eventConvocatory,
  eventSubEventsRegistry,
} from "../../../../data-store/actions/events-actions";

class ModalidadesRenderView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const renderModalidades = (ModalidadesEvento) => {
      let modalidadesRender = [];
      modalidadesRender.push(<h4>Modalidades y categorías</h4>);
      for (var i in ModalidadesEvento) {
        var ramaCat = ModalidadesEvento[i].ramaCat;
        var ramasCount = Object.keys(ramaCat).length;
        let ramasRender = [];
        for (var e in ramaCat) {
          var Genero = "";
          if (ramaCat[e].rama == "V") {
            Genero = "Varonil";
          } else if (ramaCat[e].rama == "F") {
            Genero = "Femenil";
          } else if (ramaCat[e].rama == "M") {
            Genero = "Mixta";
          }

          var classColumns = "s12 m12 l12";
          if (ramasCount == 2) {
            classColumns = "s12 m6 l6";
          } else if (ramasCount == 3) {
            classColumns = "s12 m4 l4";
          }
          var categorias = ramaCat[e].categorias;
          let categoriasRender = [];
          for (var f in categorias) {
            if (!categorias[f].absolutos) {
              var edadesTexto = `(${categorias[f].edadDesde} - ${categorias[f].edadHasta}) `;
              if (
                categorias[f].edadHasta == 200 &&
                categorias[f].edadDesde == 0
              ) {
                edadesTexto = "(Todas las edades)";
              } else if (categorias[f].edadDesde == 0) {
                edadesTexto = `(edades hasta ${categorias[f].edadHasta} años)`;
              } else if (categorias[f].edadHasta == 200) {
                edadesTexto = `(${categorias[f].edadDesde} y mayores)`;
              }
              categoriasRender.push(
                <p
                  key={`${categorias[f].codigo}-${ramaCat[e].rama}-${ModalidadesEvento[i].codigoModalidad}`}
                >
                  {categorias[f].nombreCategoria} {edadesTexto}
                </p>
              );
            }
          }
          ramasRender.push(
            <div
              key={`${ramaCat[e].rama}-${ModalidadesEvento[i].codigoModalidad}`}
              className={`col ${classColumns}`}
            >
              <h5>Rama {Genero}</h5>
              {categoriasRender}
            </div>
          );
        }
        modalidadesRender.push(
          <div className="row">
            <hr />
            <h5>{ModalidadesEvento[i].descripcionModalidad}</h5>
            {ramasRender}
          </div>
        );
      }

      return modalidadesRender;
    };
    return (
      <div>
        {renderModalidades(this.props.eventSubEventsRegistry).map(
          (cont, index) => (
            <div key={index}>{cont}</div>
          )
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventConfiguration: state.events.eventConfiguration,
    eventConvocatory: state.events.eventConvocatory,
    eventSubEventsRegistry: state.events.eventSubEventsRegistry,
  };
};

const ModalidadesRender = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalidadesRenderView);

export default ModalidadesRender;
