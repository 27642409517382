import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function UploadButtons(props) {
  const {
    callback,
    text,
    type,
    uploadingPhotos,
    uploadingPhotosProgress,
  } = props;
  const classes = useStyles();
  const [idButton, setIdButton] = useState(Math.round(Math.random() * 100));
  const handleChange = (e) => {
    console.log(e);
    console.log(e.target);
    console.log(e.target.files);
    callback(e.target.files[0]);
  };

  let uploadingView;
  if (uploadingPhotos != null) {
    uploadingView = (
      <div style={{ padding: 30 }}>
        Subiendo : {uploadingPhotos}
        <CircularProgress variant="static" value={uploadingPhotosProgress} />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      {uploadingView}

      <input
        accept={type}
        className={classes.input}
        id={idButton}
        multiple
        type="file"
        onChange={(e) => handleChange(e)}
      />
      <label htmlFor={idButton}>
        <Button variant="contained" color="primary" component="span">
          {text}
        </Button>
      </label>
    </div>
  );
}
