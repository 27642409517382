import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Link } from "react-router-dom";

import { Icon, Row } from "react-materialize";
import { auth } from "../../firebase/firebasejs";
import M from "materialize-css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AppContainer from "../../AppContainer";

export default function Login() {
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    auth
      .signInWithEmailAndPassword(data.correo, data.password)
      .then(() => {
        M.toast("Bienvenido", 4000);
      })
      .catch((error) => {
        // Handle Errors here.
        // ...
        M.toast(
          "Error al inicar Sesion intente de nuevo" + error.message,
          4000
        );
      });
  };
  console.log(userID);
  if (atleteAccount != undefined && atleteAccount !== null) {
    return (
      <AppContainer>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper style={{ padding: 10 }}>
              <h5>Bienvenido</h5>
              <Row>
                <h2>{atleteAccount.nombres + " " + atleteAccount.apellidos}</h2>
              </Row>
            </Paper>
          </Grid>
        </Grid>
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper style={{ padding: 10 }}>
              <h4>Iniciar Sesión</h4>
              <Row>
                <label>Correo</label>
                <input
                  name="correo"
                  type="email"
                  ref={register({ required: true })}
                />
                {errors.correo && "Campo requerido"}
              </Row>
              <Row>
                <label>Contraseña</label>
                <input
                  name="password"
                  type="password"
                  ref={register({ required: true, minLength: 6 })}
                />
                {errors.correo && "Mínimo 6 caracteres"}
              </Row>
              <Row>
                <Button type="submit" variant="contained" color="primary">
                  Iniciar Sesión<Icon left>send</Icon>
                </Button>
              </Row>
              <Row>
                <Link to="/password-restore">
                  <Button variant="contained" color="primary">
                    Recuperar contraseña{" "}
                  </Button>
                </Link>
              </Row>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </AppContainer>
  );
}
