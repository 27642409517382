import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { helperMultisort } from "../../../../../../../services/utils";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import ResultModal from "../../../SpecificVirtualEvent/VirtualEventMenu/VirtualEventResults/ResultModal";
import ChatCustom from "../../../Chat/ChatCustom";
import { CSVLink } from "react-csv";
import Button from "@material-ui/core/Button";
import {
  useCalculateRelativePositions,
  useCalculateAbsolutePositions,
} from "../../../VirtualEventsHooks/VirtualEventsHooks";
import PhotoEditorDefault from "../../../PhotoEditorVirtual/PhotoEditorDefault";
import { fechahora } from "../../../../../../../services/utils";
import LogoGarmin from "../../../../GarminApi/GarminAssets/Garmin_Connect_app_1024x1024-02.png";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function ParticipantesTable(props) {
  const { currentEventParticipants, currentEvent, filter } = props;
  const [viewRaceNumber, setViewRacenumber] = useState(false);
  const classes = useStyles();
  const getEvidence = (participant) => {
    let evidence = {
      garmin: false,
      photo: false,
      strava: false,
    };
    if (participant.garminActivitySummary !== undefined) {
      evidence.garmin = true;
    }
    if (participant.photos !== undefined) {
      var photo = [];
      photo = Object.values(participant.photos).filter((obj) => {
        return obj.photoType === "evidence";
      });
      if (photo.length > 0) {
        evidence.photo = true;
      }
    }

    return evidence;
  };

  let results = [];
  if (currentEventParticipants !== undefined) {
    results = Object.values(currentEventParticipants);
  }

  results = useCalculateRelativePositions(results, false, currentEvent);
  results = useCalculateAbsolutePositions(results, false, currentEvent);

  if (filter != null) {
    results = results.filter((result) => {
      return result.athleteGender === filter;
    });
  }

  if (currentEventParticipants !== undefined) {
    results = helperMultisort(results, ["finishTime"], ["ASC"]);
  }

  function csvDataFunc() {
    var csvData = [];
    var newParticipants = { ...currentEventParticipants };
    for (var i in newParticipants) {
      //delete newParticipants[i].photos;
      const form = newParticipants[i].form
        ? { ...newParticipants[i].form }
        : {};
      delete newParticipants[i].athleteTokenPush;
      delete newParticipants[i].form;
      csvData.push({
        ...newParticipants[i],
        ...form,
        finishTimeDate:
          newParticipants[i].finishTimeDate !== undefined
            ? fechahora(newParticipants[i].finishTimeDate.toDate() / 1000)
            : "",
      });
    }
    return csvData;
  }
  return (
    <div>
      <CSVLink data={csvDataFunc()}>
        <Button>Descargar Lista de particpantes</Button>
      </CSVLink>
      <Button onClick={() => setViewRacenumber(!viewRaceNumber)}>
        Ver dorsales
      </Button>
      <TableContainer component={Paper}>
        <ChatCustom
          buttonType="block"
          writeAvailable={true}
          buttonText="Envia mensajes a todos los participantes"
          topics={["all", "public"]}
          title="Mensajes a los atletas"
        />

        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Número</TableCell>
              <TableCell>Nombres</TableCell>
              <TableCell>Apellidos</TableCell>
              <TableCell>Fecha de nacimiento</TableCell>
              <TableCell align="right">Género</TableCell>
              <TableCell align="right">General / Rama / Cat</TableCell>
              <TableCell align="right">Tiempo Meta</TableCell>
              <TableCell align="right">Evidencia</TableCell>
              <TableCell align="right">Tiempo Final</TableCell>
              <TableCell align="right">Hora registro tiempo final</TableCell>
              <TableCell align="right">Chat</TableCell>
              <TableCell align="right">Detalle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((participant, key) => (
              <TableRow key={key}>
                <TableCell component="th" scope="row">
                  {participant.raceNumber}
                </TableCell>
                <TableCell component="th" scope="row">
                  {participant.athleteName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {participant.athleteLastName}
                </TableCell>
                <TableCell align="right">{participant.athleteBirth}</TableCell>
                <TableCell align="right">
                  {participant.athleteGender === "M" ? "Varonil" : "Femenil"}
                </TableCell>
                <TableCell align="right">
                  {participant.positionAbsolute} /{" "}
                  {participant.positionAbsoluteGender}/{" "}
                  {participant.positionAbsoluteCategory}
                </TableCell>
                <TableCell align="right">{participant.goalTime}</TableCell>
                <TableCell align="right">
                  {getEvidence(participant).garmin ? (
                    <img src={LogoGarmin} height={30} alt="logoGarmi" />
                  ) : (
                    ""
                  )}
                  {getEvidence(participant).photo ? <CameraAltIcon /> : ""}
                  {getEvidence(participant).strava ? "Strava" : ""}
                </TableCell>
                <TableCell align="right">{participant.finishTime}</TableCell>
                <TableCell align="right">
                  {participant.finishTimeDate !== undefined &&
                    fechahora(participant.finishTimeDate.toDate() / 1000)}
                </TableCell>
                <TableCell align="right">
                  <ChatCustom
                    buttonType="block"
                    writeAvailable={true}
                    buttonText="Chat"
                    topics={[participant.id + "-organizer", participant.id]}
                    title={
                      participant.athleteName +
                      " " +
                      participant.athleteLastName
                    }
                  />
                </TableCell>
                <TableCell align="right">
                  <ResultModal
                    participant={participant}
                    currentEvent={currentEvent}
                  />
                  {participant && viewRaceNumber && (
                    <PhotoEditorDefault
                      participant={participant}
                      currentEvent={currentEvent}
                      urlPhoto={currentEvent.raceNumberImage}
                      type={"raceNumber"}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
