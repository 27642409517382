import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Image(props) {
  const { image, alt, bgColor } = props.componentConfig;
  return (
    <Grid container direction="row" style={{ backgroundColor: bgColor }}>
      <Grid item xs={12}>
        {image && <img src={image} alt={alt} width="100%" />}
      </Grid>
    </Grid>
  );
}

Image.displayName = "Imagen";

export const componentDescription = {
  componentConfig: {
    alt: {
      label: "Texto interno para la imagen",
      errorMessage: "Campo Requerido",
      fieldName: "alt",
      type: "text",
      defaultValue: "vacio",
      required: true,
      regex: "",
      textSize: "h6",
    },
    image: {
      label: "Imagen",
      errorMessage: "Campo Requerido",
      fieldName: "image",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
