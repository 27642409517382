import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

import PhotoEditorDefault from "../../../PhotoEditorVirtual/PhotoEditorDefault";

import Login from "../../../../../../Login/Login";

import { useForm, Controller } from "react-hook-form";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { participants } from "../../../../../../../firebase/firebasejs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function FinishLinePicturesDefault(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(
    (state) => state.virtualEvents.currentEventParticipants
  );

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();

  const thisVirtualEvent = myVirtualEvents[currentEvent.eventID];
  var photoURL = atleteAccount.photoURL;
  if (thisVirtualEvent.startLinePhoto != undefined) {
    photoURL = thisVirtualEvent.startLinePhoto;
  }

  const participant = currentEventParticipants[userID];

  if (atleteAccount == null) {
    return <Login />;
  }

  if (
    myVirtualEvents == undefined ||
    currentEvent == undefined ||
    participant == undefined
  ) {
    return <CircularProgress />;
  }
  if (currentEvent == {}) {
    return <h1>Usuario no autorizado para este evento</h1>;
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-around">
          <Grid item xs={12}>
            {/* <Typography gutterBottom variant="h5" component="h5">
              Descarga tu foto con el marco del evento
            </Typography> */}
          </Grid>
          <Grid item xs={12} sm={4}>
            <PhotoEditorDefault
              participant={participant}
              currentEvent={currentEvent}
              urlPhoto={participant.finishLinePhoto}
              type={"frameImage"}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <PhotoEditorDefault
              participant={participant}
              currentEvent={currentEvent}
              urlPhoto={participant.finishLinePhoto}
              type={"finishTime"}
            />
          </Grid>
          {currentEvent.medalImage && (
            <Grid item xs={12} sm={4}>
              <PhotoEditorDefault
                participant={participant}
                currentEvent={currentEvent}
                urlPhoto={currentEvent.medalImage}
                type={"medal"}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
