import React from 'react';
import { connect } from 'react-redux'
import {eventParticipants,
        registryConfiguration} from '../../../../../data-store/actions/registry-actions';
import {fechaMesDia} from '../../../../../services/utils';
import DashboardResume from './DashboardResume';
import DashboardPaymentMethod from './DashboardPaymentMethod';
import DashboardRegistryDispersion from './DashboardRegistryDispersion';
import DashboardRegistryByUser from './DashboardRegistryByUser';
import DashboardExtraInputs from './DashboardExtraInputs';
import DashboardExtraProducts from './DashboardExtraProducts';
import DownloadButtomAllData from '../DownloadData/DownloadButtomAllData';
import DashboardModRamCat from './DashboardModRamCat';

class DashboardContainerView extends React.Component {

	constructor(props){
		super(props)
    }


	setCuentaJson = (ModalidadesEventoCuenta) => {
		var json = ModalidadesEventoCuenta;
	
		for(var i in json){
		  var ramaCat = json[i].ramaCat;
		  json[i].cuenta = 0;
		  for(var e in ramaCat){
			json[i].ramaCat[e].cuenta = 0;
				//ramaCat[e].cuenta;
 
			var categorias = json[i].ramaCat[e].categorias;
			for(var f in categorias){
			  json[i].ramaCat[e].categorias[f].cuenta = 0;
			  } 
			}
		  }
		  console.log(json);
		return json;
	  }
	
	dashboardInscritosView = (perfilUsuarioEvento) => {

		var filtrarData = false;
		if(perfilUsuarioEvento.localeCompare('inscriptor') == 0){
			filtrarData = true;
		}

 
			var jsonDashboard = {};
			
				jsonDashboard.totalInscritos = 0;
				jsonDashboard.totalPagosEfectivo = 0;
				jsonDashboard.cantidadPagosEfectivo = 0;
				jsonDashboard.totalCompletoOxxo = 0;
				jsonDashboard.comisionCompletoOxxo = 0;
				jsonDashboard.cantidadCompletoOxxo = 0;
				jsonDashboard.totalTarjeta = 0;
				jsonDashboard.comisionTarjeta = 0;
				jsonDashboard.cantidadTarjeta = 0;
				jsonDashboard.totalIncompletos = 0;
				jsonDashboard.totalBorrados = 0;
				jsonDashboard.totalCobrado = 0;
				jsonDashboard.adicionales = {};
				jsonDashboard.productos = {};
				jsonDashboard.diaInscritos = {}
				jsonDashboard.byUser = {}
				jsonDashboard.modalidadesyCategorias = {};
				jsonDashboard.modalidadesyCategorias = this.setCuentaJson(JSON.parse(JSON.stringify(this.props.eventSubEvents)));
							console.log(jsonDashboard.modalidadesyCategorias);

				for(var i in this.props.registryConfiguration.formulario){
					var value = this.props.registryConfiguration.formulario[i];
					if(value.tipoCampo == "Select" || value.tipoCampo == "checkbox"){
						jsonDashboard.adicionales[value.iDCampo] = {};
						jsonDashboard.adicionales[value.iDCampo].etiqueta = value.etiqueta;
						jsonDashboard.adicionales[value.iDCampo].tipoCampo = value.tipoCampo;
						jsonDashboard.adicionales[value.iDCampo].campo = value.campo;
						jsonDashboard.adicionales[value.iDCampo].iDCampo = value.iDCampo;
					}
				}
				var modalidadesBuscar = this.props.eventSubEvents;
				var arrayCampoModalidad = [];

				for(var i in modalidadesBuscar){
					var modalidadCampos = modalidadesBuscar[i].configModalidad.camposAdicionalesModalidad;
					if(modalidadCampos != undefined){
						for(var e in modalidadCampos){
							var campos = modalidadCampos[e];
							for(var f in campos){
								var value = campos[f];
								if(value.tipoCampo == "Select" || value.tipoCampo == "checkbox"){
									if(!arrayCampoModalidad.includes(campos[f].campo)){
										arrayCampoModalidad.push(campos[f].campo);
										jsonDashboard.adicionales[value.iDCampo] = {};
										jsonDashboard.adicionales[value.iDCampo].etiqueta = value.etiqueta;
										jsonDashboard.adicionales[value.iDCampo].tipoCampo = value.tipoCampo;
										jsonDashboard.adicionales[value.iDCampo].campo = value.campo;
										jsonDashboard.adicionales[value.iDCampo].iDCampo = value.iDCampo;
									}
								}
							}
						}
					}
				}
	
				var arrayCodigoProducto = [];

				for(var i in modalidadesBuscar){
					var modalidadProductos = modalidadesBuscar[i].configModalidad.productosAdicionales;
					if(modalidadProductos != undefined){
						for(var e in modalidadProductos){
							var value = modalidadProductos[e];
							if(!arrayCodigoProducto.includes(modalidadProductos[e].codigoProducto)){
								arrayCodigoProducto.push(modalidadProductos[e].codigoProducto);
								jsonDashboard.productos[value.codigoProducto] = {};
								jsonDashboard.productos[value.codigoProducto].codigoProducto = value.codigoProducto;
								jsonDashboard.productos[value.codigoProducto].descripcionProducto = value.descripcionProducto;
								jsonDashboard.productos[value.codigoProducto].formularioProducto = value.formularioProducto;
								jsonDashboard.productos[value.codigoProducto].maxCantidad = value.maxCantidad;
								jsonDashboard.productos[value.codigoProducto].nombreProducto = value.nombreProducto;
								var formularioProducto = value.formularioProducto;
								if(formularioProducto != undefined){
									for(var f in formularioProducto){
										//productosAdicionalesModalidadTituloHTML.push(<th key={i+e+f+"input"} data-field={formularioProducto[f].etiqueta}>{formularioProducto[f].etiqueta}</th>);
									}
								}
					
								console.log(JSON.stringify(modalidadProductos[e]));
							}
						}
					}
				}
		
			if(this.props.eventParticipants != null){
			for(var i in this.props.eventParticipants){
			if(!filtrarData || this.props.eventParticipants[i].usuarioRegistra.localeCompare(this.props.userID) == 0){
				 if(this.props.eventParticipants[i].borrado){
					jsonDashboard.totalBorrados++; 
				}else{
			
					var precioCobrado = 0;
					var comisionOnline = 0;
					var detalleOrden = "";
					var modalidad = this.props.eventParticipants[i].modalidad;
					var categoria = this.props.eventParticipants[i].categoria;
					var genero = this.props.eventParticipants[i].rama;
					var user = this.props.eventParticipants[i].usuarioRegistra;
		
					var fechaInscrito = fechaMesDia(this.props.eventParticipants[i].fechaRegistro);
					
					//var modCatJson = modalidadCateogoriaNameByCode(modalidad, categoria, genero);
			
					var noCompletado = "";
			
					var noCompletado = "";
					if(this.props.eventParticipants[i].statusPago.formaPago == ""){
						noCompletado = "Registro onLine Incompleto";
					}
		
					var order = this.props.eventParticipants[i].order;
		
					if(this.props.eventParticipants[i].statusPago.status == "ok"){
		
						if(jsonDashboard.diaInscritos[fechaInscrito] == undefined){
							jsonDashboard.diaInscritos[fechaInscrito] = 0;
						}
						jsonDashboard.diaInscritos[fechaInscrito]++;
		
						if(jsonDashboard.byUser[user] == undefined){
							jsonDashboard.byUser[user] = 0;
						}
						jsonDashboard.byUser[user]++;
					}
		
					for(var p in order){
						if(this.props.eventParticipants[i].statusPago.status == "ok"){
							if(order[p].code == "comision"){
								comisionOnline = comisionOnline + parseFloat(order[p].unit_price *order[p].quantity);
							}else{
								precioCobrado = precioCobrado + parseFloat(order[p].unit_price *order[p].quantity);
								jsonDashboard.totalCobrado = parseFloat(jsonDashboard.totalCobrado) + parseFloat(order[p].unit_price *order[p].quantity);
							}
						}
					}
		
		
					var statusPago = "";
					if(this.props.eventParticipants[i].statusPago.formaPago == ""){
						//"Registro onLine Incompleto";
						jsonDashboard.totalIncompletos++;
					}else if(this.props.eventParticipants[i].statusPago.formaPago == "oxxo_pay" && this.props.eventParticipants[i].statusPago.status == "pendiente"){
						//"Pendiente de pago en oxxo";
						jsonDashboard.totalIncompletos++;
					}else if(this.props.eventParticipants[i].statusPago.formaPago == "oxxo_pay" && this.props.eventParticipants[i].statusPago.status == "ok"){
						//"Pagado en oxxo";
						jsonDashboard.totalInscritos++;
						jsonDashboard.totalCompletoOxxo = jsonDashboard.totalCompletoOxxo + precioCobrado;
						jsonDashboard.comisionCompletoOxxo = jsonDashboard.comisionCompletoOxxo + comisionOnline;
						jsonDashboard.cantidadCompletoOxxo++;
					}else if(this.props.eventParticipants[i].statusPago.formaPago == "efectivo"){
						//"Pagado en efectivo";
						jsonDashboard.totalInscritos++;
						jsonDashboard.totalPagosEfectivo = jsonDashboard.totalPagosEfectivo + precioCobrado;
						jsonDashboard.cantidadPagosEfectivo++;
					}else if(this.props.eventParticipants[i].statusPago.formaPago == "card"){
						//"Pagado con tarjeta";
						jsonDashboard.totalInscritos++;
						jsonDashboard.totalTarjeta = jsonDashboard.totalTarjeta + precioCobrado;
						jsonDashboard.comisionTarjeta = jsonDashboard.comisionTarjeta + comisionOnline;
						jsonDashboard.cantidadTarjeta++;
					}
			
		
					if(this.props.eventParticipants[i].statusPago.status == "ok"){
		
							for (var e in jsonDashboard.adicionales){
								var campo = jsonDashboard.adicionales[e].iDCampo;
								if(this.props.eventParticipants[i][campo] != null){
									if(jsonDashboard.adicionales[e].tipoCampo == "Select" || 
										jsonDashboard.adicionales[e].tipoCampo == "checkbox"){
										var valor = this.props.eventParticipants[i][campo];
										if(jsonDashboard.adicionales[e].valores == undefined){
											jsonDashboard.adicionales[e].valores = {};
										}
		
										if(jsonDashboard.adicionales[e].valores[valor] != undefined){
											jsonDashboard.adicionales[e].valores[valor]++;
										}else{
											jsonDashboard.adicionales[e].valores[valor] = 1;
										}
										if(jsonDashboard.adicionales[e].suma != undefined){
											jsonDashboard.adicionales[e].suma++;
										}else{
											jsonDashboard.adicionales[e].suma = 1;
										}
									}
								}
							}
							for (var e in jsonDashboard.productos){
								var campo = jsonDashboard.productos[e].codigoProducto;
								for(var f in this.props.eventParticipants[i].order){
									var item = this.props.eventParticipants[i].order[f];
									if(item.code.localeCompare("producto-"+campo) == 0){
										if(jsonDashboard.productos[e].sumaCompras != undefined){
											jsonDashboard.productos[e].sumaCompras++;
											jsonDashboard.productos[e].sumaCantidades = jsonDashboard.productos[e].sumaCantidades + item.quantity;// aqui va el monto;
											jsonDashboard.productos[e].sumaIngresos = jsonDashboard.productos[e].sumaIngresos + (item.unit_price * item.quantity);
										}else{
											jsonDashboard.productos[e].sumaCompras = 1;
											jsonDashboard.productos[e].sumaCantidades = item.quantity;// aqui va el monto;
											jsonDashboard.productos[e].sumaIngresos = (item.unit_price * item.quantity);
										}
									}
								}
							}

							if(jsonDashboard.modalidadesyCategorias != undefined && jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad] != undefined){
								if(jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].cuenta == undefined){
								  jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].cuenta = 0;
								}
								jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].cuenta++;
								if(jsonDashboard.modalidadesyCategorias != undefined && jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat != undefined){
								  if(jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero] == undefined){
									jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero] = {};
								  }
								  if(jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero].cuenta == undefined){
									jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero].cuenta = 0;
								  }
								  jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero].cuenta++;
								
								  if(jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero].categorias != undefined){ 
									if(jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero].categorias["categoria_"+categoria] == undefined){
									  jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero].categorias["categoria_"+categoria] = {};
									}
									if(jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero].categorias["categoria_"+categoria].cuenta == undefined){
									  jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero].categorias["categoria_"+categoria].cuenta = 0;
									}
									jsonDashboard.modalidadesyCategorias["modalidad_"+modalidad].ramaCat["rama_"+genero].categorias["categoria_"+categoria].cuenta++;
								  }
								}
							  }
					
						}
				}
			}
			}
			}

			return jsonDashboard;
		}


	render() {

		var perfilUsuarioEvento = this.props.registryAutorizedUsers[this.props.userID].perfil;
		var jsonDashboard = this.dashboardInscritosView(perfilUsuarioEvento);
		var reportType = 3;
		if(perfilUsuarioEvento.localeCompare('MasterCliente') == 0 || 
		perfilUsuarioEvento.localeCompare('MasterOrganizador') == 0 || 
		perfilUsuarioEvento.localeCompare('master') == 0){
			reportType = 2
		}


		return (
			<div>
				<h4>DASHBOARD</h4>
				<DownloadButtomAllData eventParticipants={this.props.eventParticipants}
										reportType={reportType}
										eventDetail={this.props.eventDetail}
										registryAutorizedUsers={this.props.registryAutorizedUsers}
										userID={this.props.userID}
										registryConfiguration={this.props.registryConfiguration}
										eventSubEvents={this.props.eventSubEvents}/>
				<DashboardResume jsonDashboard={jsonDashboard}/>
				<DashboardModRamCat modalidadesyCategorias={jsonDashboard.modalidadesyCategorias}/>
				<DashboardPaymentMethod CobradoOxxo={jsonDashboard.totalCompletoOxxo} 
																CobradoEfectivo={jsonDashboard.totalPagosEfectivo} 
																CobradoTarjeta={jsonDashboard.totalTarjeta}/>
				<DashboardRegistryDispersion jsonDiaInscrito={jsonDashboard.diaInscritos}/>
				<DashboardRegistryByUser jsonbyUser={jsonDashboard.byUser}
										registryAutorizedUsers={this.props.registryAutorizedUsers}
				/>
				<DashboardExtraInputs jsonAdicionales={jsonDashboard.adicionales}
				/>
				<DashboardExtraProducts jsonProducts={jsonDashboard.productos}
				/>

			</div>
					);
		}
  }

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

const mapStateToProps = (state) => {
	return {
		eventParticipants : state.registry.eventParticipants,
		eventSubEvents :state.events.eventSubEvents,
		registryConfiguration : state.registry.registryConfiguration,
        registryAutorizedUsers : state.registry.registryAutorizedUsers,
		userID : state.atlete.userID,
		eventDetail: state.events.eventDetail,
		atleteAccount : state.atlete.atleteAccount
	}
  }
  
const DashboardContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardContainerView);
export default DashboardContainer;
