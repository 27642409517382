import React from 'react';
import { connect } from 'react-redux'
import {eventParticipants,
		registryConfiguration} from '../../../../../data-store/actions/registry-actions';
import {completeNull, modalidadCateogoriaNameByCode, calcularEdad} from '../../../../../services/utils';

import {timestampToDate} from '../../../../../services/utils';

import ParticipantDetailContainer from '../ParticipantDetail/ParticipantDetailContainer';
import FormDialog from '../../../Modals/FormDialog';

class IncomeRowView extends React.Component {

	constructor(props){
		super(props)
		this.state = {isOpen: false}

		
	}
	
	filterType = (filter, data) => {
		if(filter == 1 && data.statusPago.status == "ok" && !data.borrado){
			return true;
		}else if(filter == 2 && data.statusPago.status == "pendiente"){
			return true
		}else if(filter ==3){
			return true;
		}else if(filter ==4){
			return true;
		}
		return false;
	}
	

	toggleModal = (e) => {
        e.preventDefault();
        console.log("toogle modal");
            this.setState({
            });
          }

	
		
	render() {


		var perfilUsuarioEvento = this.props.registryAutorizedUsers[this.props.userID].perfil;
		var inscritoData = this.props.inscritoData;

		var filterFinal = this.filterType(this.props.filter, inscritoData);

		if(!filterFinal){
			return null;
	
		}else{
	
			var inscritoKey = this.props.inscritoKey;
			var camposAdicionales = this.props.camposAdicionales;
			var productosAdicionalesData = this.props.productosAdicionales;
				
	
			var precioCobrado = 0;
			var detalleOrden = "";
			var modalidad = inscritoData.modalidad;
			var categoria = inscritoData.categoria;
			var genero = inscritoData.rama;
					
			var modCatJson = modalidadCateogoriaNameByCode(this.props.eventSubEvents, modalidad, categoria, genero);
				
			var apellidosSeparadosSet = this.props.registryConfiguration.configuracion.separarApellidos;
			var apellidosDatos = [];
			if(apellidosSeparadosSet){
				apellidosDatos.push(<td  key={`pat${inscritoKey}`}>
				<FormDialog 
											buttomLabel={completeNull(inscritoData.apellidoPaterno)}
											ShowIcon={false}
											ButtonStyle={null}
											maxWidthProp={'lg'}
											style={{
													minHeight: '80vh',
													maxHeight: '80vh',
											}}
											report={<ParticipantDetailContainer 
													ParticipantDetail={inscritoData}
													ParticipantDetailKey={inscritoKey}/>}
											/>
											</td>);
				apellidosDatos.push(<td  key={`mat${inscritoKey}`}>
								<FormDialog 
											buttomLabel={completeNull(inscritoData.apellidoMaterno) + " (ABRIR DETALLE)"}
											ShowIcon={false}
											ButtonStyle={null}
											maxWidthProp={'lg'}
											style={{
													minHeight: '80vh',
													maxHeight: '80vh',
											}}
											report={<ParticipantDetailContainer 
													ParticipantDetail={inscritoData}
													ParticipantDetailKey={inscritoKey}/>}
											/></td>);
			}else{
				apellidosDatos.push(<td  key={`ap${inscritoKey}`}>
							<FormDialog 
											buttomLabel={completeNull(inscritoData.apellidos) + " (ABRIR DETALLE)"}
											ShowIcon={false}
											ButtonStyle={null}
											maxWidthProp={'lg'}
											style={{
													minHeight: '80vh',
													maxHeight: '80vh',
											}}
											report={<ParticipantDetailContainer 
													ParticipantDetail={inscritoData}
													ParticipantDetailKey={inscritoKey}/>}
											/></td>);
			}
				
					var camposAdicionalesData = [];
					var camposAdicionalesArray = camposAdicionales.camposAdicionalesModalidad;
					for(var i in camposAdicionalesArray){
						if(camposAdicionalesArray[i].tipoCampo != "titulo"){
							if(inscritoData[camposAdicionalesArray[i].iDCampo] != undefined){
								camposAdicionalesData.push(<td key={i} >{completeNull(inscritoData[camposAdicionalesArray[i].iDCampo])}</td>);
							}else{
								camposAdicionalesData.push(<td key={i} >N/A</td>);
							}
						}
					};
		
					var productosAdicionales = [];
					var productosAdicionalesArray = productosAdicionalesData.productosAdicionalesModalidadTitulo;
					for(var f in productosAdicionalesArray){
					  var order = inscritoData.order;
				  
					  var productExist = false;
					  var unitPrice = productosAdicionalesArray[f].precioProducto;
		
					  if(order != undefined){
						for(var i in order){
							var codeProducto = "producto-"+productosAdicionalesArray[f].codigoProducto;
						  if(order[i].code == codeProducto){
							productExist = true;
							productosAdicionales.push(<td key={f+i+"cantidad"} >{order[i].quantity}</td>);
						  productosAdicionales.push(<td key={f+i+"precio"} >{order[i].unit_price}</td>);
							productosAdicionales.push(<td key={f+i+"total"} >{order[i].quantity * order[i].unit_price}</td>);
							var formularioProducto = productosAdicionalesArray[f].formularioProducto;
							if(formularioProducto != undefined){
							  for(var e in formularioProducto){
								if(formularioProducto[e].tipoCampo != "titulo"){
								  if(order[i].extraData != undefined && order[i].extraData[formularioProducto[e].iDCampo] != undefined){
								  productosAdicionales.push(<td key={f+i+e+"input"} >{order[i].extraData[formularioProducto[e].iDCampo]}</td>);
								  }else{
									productosAdicionales.push(<td key={f+i+e+"input"} >N/R</td>);
								  }
								}
							  }
							}
						  }
						}
					  }
					// este if entra si no existe el producto adicional dentro de la orden, es para agregar la colmna vacia
					if(!productExist){
						productosAdicionales.push(<td key={f+"cantidad"} >0</td>);
						productosAdicionales.push(<td key={f+"precio"} >{unitPrice}</td>);
						productosAdicionales.push(<td key={f+"total"} >0</td>);
						var formularioProducto = productosAdicionalesArray[f].formularioProducto;
						if(formularioProducto != undefined){
							for(var i in formularioProducto){
							if(formularioProducto[i].tipoCampo != "titulo"){
								productosAdicionales.push(<td key={f+i+"PA"} >N/A</td>);
							}
							}
						}
					}
				}
				
				var resaltaBorrado = "";
				var borrado = "";
				var noCompletado = "";
				if(inscritoData.borrado){
					resaltaBorrado = "#e57373 red lighten-2";
					borrado = "Borrado";
				}
				
				var noCompletado = "";
				if(inscritoData.statusPago.formaPago == ""){
					noCompletado = "Registro onLine Incompleto";
				}
				
				var statusPago = "";
				if(inscritoData.statusPago.formaPago == "oxxo_pay" && inscritoData.statusPago.status == "pendiente"){
					statusPago = "Pendiente de pago en oxxo";
				}else if(inscritoData.statusPago.formaPago == "oxxo_pay" && inscritoData.statusPago.status == "ok"){
					statusPago = "Pagado en oxxo";
				}else if(inscritoData.statusPago.formaPago == "efectivo"){
					statusPago = "Pagado en efectivo";
				}else if(inscritoData.statusPago.formaPago == "card"){
					statusPago = "Pagado con tarjeta";
				}
				
				var usuario;
				var usuariosAutorizados = this.props.registryAutorizedUsers;
				if(inscritoData.statusPago.formaInscripcion == "punto de Venta"){
				
					if(usuariosAutorizados[inscritoData.usuarioRegistra] != undefined){
					usuario = usuariosAutorizados[inscritoData.usuarioRegistra].nombre;
					}else{
					usuario ="vacio";
					}
				}else{
					usuario = "on line";
				}
				
				var edadActual = this.props.registryConfiguration.configuracion.edadActual;
				var fechaEvento = this.props.eventDetail.fechaEvento;
				
				var edad = calcularEdad(inscritoData.fechaNacimiento, fechaEvento, edadActual);
				var order = inscritoData.order;
					for(var p in order){
						precioCobrado = parseFloat(precioCobrado) + parseFloat(order[p].unit_price);
					}
				
		let row = (<tr className={resaltaBorrado}>
							<td >{completeNull(inscritoData.numero)}</td>
							 <td ><FormDialog 
											buttomLabel={completeNull(inscritoData.nombres) + " (ABRIR DETALLE)"}
											ShowIcon={false}
											ButtonStyle={null}
											maxWidthProp={'lg'}
											style={{
													minHeight: '80vh',
													maxHeight: '80vh',
											}}
											report={<ParticipantDetailContainer 
													ParticipantDetail={inscritoData}
													ParticipantDetailKey={inscritoKey}/>}
											/></td>
							{apellidosDatos}
							 <td >{completeNull(inscritoData.correo)}</td>
							 <td >{completeNull(inscritoData.telefono)}</td>
							 <td >{completeNull(modCatJson.modalidad)}</td>
							 <td >{completeNull(inscritoData.rama)}</td>
							 <td >{completeNull(modCatJson.categoria)}</td>
							 <td >{usuario}</td>
							 <td >{precioCobrado}</td>
							 <td >{completeNull((inscritoData.serverTime != undefined)?timestampToDate(inscritoData.serverTime):inscritoData.fechaRegistro)}</td>
							{camposAdicionalesData}
							{productosAdicionales}
							<td>{borrado} {noCompletado} {statusPago}
							</td>
						</tr>);

			return row;
		}
	}
  }

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

const mapStateToProps = (state) => {
	return {		
		eventDetail: state.events.eventDetail,
		eventParticipants : state.registry.eventParticipants,
		registryConfiguration : state.registry.registryConfiguration,
        registryAutorizedUsers : state.registry.registryAutorizedUsers,
		eventSubEvents :state.events.eventSubEvents,
		eventSubEventsSnap :state.events.eventSubEventsSnap,
        userID : state.atlete.userID
	}
  }
  
const IncomeRow = connect(
	mapStateToProps,
	mapDispatchToProps
)(IncomeRowView);

export default IncomeRow;
