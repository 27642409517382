import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function DownloadFileButtom(props) {
  const {
    title,
    textArray,
    bgColor,
    textColor,
    position,
    file,
    textButton,
    paddingButton,
  } = props.componentConfig;
  function download(file) {
    console.log("response", file);
    Axios({
      url: file, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      console.log("response", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const dataType = {
        "audio/aac": ".aac",
        "application/x-abiword": ".abw",
        "video/x-msvideo": ".avi",
        "application/vnd.amazon.ebook": ".azw",
        "application/octet-stream": ".gpx",
        "text/css": ".css",
        "text/csv": ".csv",
        "application/msword": ".doc",
        "image/gif": ".gif",
        "text/html": ".html",
        "image/x-icon": ".ico",
        "text/calendar": ".ics",
        "image/jpeg": ".jpeg",
        "application/javascript": ".js",
        "application/json": ".json",
        "audio/midi": ".mid",
        "video/mpeg": ".mpeg",
        "application/vnd.apple.installer+xml": ".mpkg",
        "application/pdf": ".pdf",
        "application/vnd.ms-powerpoint": ".ppt",
        "application/x-rar-compressed": ".rar",
        "image/svg+xml": ".svg",
        "application/x-shockwave-flash": ".swf",
        "image/tiff": ".tiff",
        "font/ttf": ".ttf",
        "image/webp": ".webp",
        "font/woff": ".woff",
        "application/xhtml+xml": ".xhtml",
        "application/vnd.ms-excel": ".xls",
        "application/xml": ".xml",
        "application/vnd.mozilla.xul+xml": ".xul",
        "application/zip": ".zip",
      };

      link.setAttribute(
        "download",
        textButton + dataType[response.data.type] || ".txt"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }
  return (
    <Grid container direction="row" style={{ backgroundColor: bgColor }}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          style={{
            color: textColor,
            textAlign: position || "justify",
            marginTop: 30,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            color: textColor,
            textAlign: position || "justify",
            marginTop: 30,
          }}
        >
          <p>{textArray}</p>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ padding: paddingButton || 10 }}
          onClick={() => download(file)}
        >
          {textButton}
        </Button>
      </Grid>
    </Grid>
  );
}

DownloadFileButtom.displayName = "Botón de descarga";

export const componentDescription = {
  componentConfig: {
    file: {
      label: "Documento para descarga",
      errorMessage: "Campo Requerido",
      fieldName: "file",
      type: "file",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    textButton: {
      label: "Texto del botón",
      errorMessage: "Campo Requerido",
      fieldName: "textButton",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    paddingButton: {
      label: "Margen el botón",
      errorMessage: "Campo Requerido",
      fieldName: "paddingButton",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    title: {
      label: "Titulo",
      errorMessage: "Campo Requerido",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    textArray: {
      label: "Texto",
      errorMessage: "Campo Requerido",
      fieldName: "textArray",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    textColor: {
      label: "Color del texto",
      errorMessage: "Campo Requerido",
      fieldName: "textColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    position: {
      label: "Posición Horizontal",
      errorMessage: "Campo Requerido",
      fieldName: "position",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "left",
          label: "Izquierda",
        },
        {
          value: "center",
          label: "Centro",
        },
        {
          value: "right",
          label: "Derecha",
        },
      ],
    },
  },
};
