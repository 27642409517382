import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { closeSnackbar } from "../../../../data-store/actions/organizers-actions";
import { helperMultisort } from "../../../../services/utils";

import BuscadorResultsCustomsViews from "./BuscadorResultsCustomsViews";

function DragDropContextBoxPlain(props) {
  const { view, number } = props;
  const [viewItems, setViewItems] = useState(view.views);
  const [newItems, setNewItems] = useState(view.views);
  const [showNewItems, setShowNewItems] = useState(true);

  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEvents1 = useSelector((state) => state.events.eventSubEvents);
  const eventResults = useSelector((state) => state.events.eventResults);
  const eventConfiguration = useSelector(
    (state) => state.events.eventConfiguration
  );
  const puntosControl = useSelector((state) => state.events.puntosControl);

  useEffect(() => {
    setViewItems(view.views);
    if (number) {
      callback(findRegistry(number));
    } else {
      callbackArray(eventResults);
    }
  }, [view, eventResults]);

  const componentRef = useRef();

  const style = {
    backgroundColor: view.miniTheme.background || "",
    height: view.miniTheme.height + "px",
    width: view.miniTheme.width + "px",
    padding: view.miniTheme.padding,
    fontFamily: view.miniTheme.fontFamily,
    fontSize: view.miniTheme.fontSize,
  };

  function callbackArray(dataArray) {
    setNewItems(findDataArray(dataArray));
  }
  function callback(data) {
    setNewItems(findData(data));
  }

  function findData(data) {
    let newItems = [];
    for (var i in viewItems) {
      console.log(viewItems[i]);
      let text = viewItems[i].text;
      for (var e in data) {
        console.log("[" + e + "]", data[e]);
        if (typeof data[e] !== "object") {
          text = text.replace("[" + e + "]", data[e]);
        }
      }
      newItems.push({ ...viewItems[i], text: text });
    }
    return newItems;
  }
  function findDataArray(dataArray) {
    let dataArrayNew = dataArray.filter((a) => a.to !== "00:00:00");

    let result = helperMultisort(dataArrayNew, ["fhm"], ["ASC"]);

    let newItems = [];
    for (var i in viewItems) {
      console.log(viewItems[i]);
      let text = viewItems[i].text;
      let indexData = text.split("#")[1] / 1;
      let data = null;
      console.log("indexData", text, text.split("#")[1], indexData);

      if (indexData && indexData > 0) {
        data = result[indexData - 1];
      } else if (indexData && indexData < 0) {
        data = result[result.length + indexData];
      }
      console.log(
        "indexData",
        result.length,
        text,
        text.split("#")[1],
        indexData,
        data,
        result
      );
      for (var e in data) {
        console.log("[" + e + "]", data[e]);
        if (typeof data[e] !== "object") {
          text = text.replace("#" + indexData + "#", "");
          text = text.replace("[" + e + "]", data[e]);
        }
      }
      newItems.push({ ...viewItems[i], text: text });
    }
    return newItems;
  }
  const findRegistry = (value) => {
    const atleta = eventResults.filter((a) => a.num === value / 1)[0];
    console.log("value1", value, number, atleta, eventResults);
    return atleta;
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{
          ...style,
          backgroundImage: "url(" + view.miniTheme.backgroundImage + ")",
        }}
        id="contentView"
        ref={componentRef}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="task-container"
          >
            {showNewItems &&
              newItems.map((viewItem, index) => (
                <Grid item xs={viewItem.width || 12} sm={viewItem.width || 12}>
                  <div
                    style={{
                      padding: view.miniTheme.paddingBox,
                      backgroundColor: viewItem.background,
                      backgroundImage: "url(" + viewItem.backgroundImage + ")",
                      fontSize: viewItem.fontSize || view.miniTheme.fontSize,
                      fontFamily: view.miniTheme.fontFamily,
                      color: viewItem.color || view.miniTheme.color,
                      textAlign: viewItem.position || "center",
                    }}
                  >
                    {viewItem.text}
                  </div>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default DragDropContextBoxPlain;
