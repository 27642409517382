import React from 'react';
import {Bar} from 'react-chartjs-2';

const DashboardRegistryByUser = ({jsonbyUser, registryAutorizedUsers}) => {


  var arrayColors = ["rgba(255, 99, 132, 0.2)",
                     "rgba(255, 159, 64, 0.2)",
                     "rgba(255, 205, 86, 0.2)",
                     "rgba(75, 192, 192, 0.2)",
                     "rgba(54, 162, 235, 0.2)",
                     "rgba(153, 102, 255, 0.2)",
                     "rgba(201, 203, 207, 0.2)"];
  var dataDispersion = {
    datasets: [{
        label: '',
        data: [],
        backgroundColor : [],
        borderColor : [],
        borderWidth : 1
    }],
    labels: []
}

  dataDispersion.labels.push("On Line");
  dataDispersion.datasets[0].data.push(0);
  dataDispersion.datasets[0].backgroundColor.push(arrayColors[0]);
  dataDispersion.datasets[0].borderColor.push(arrayColors[0]);

  var x = 1;
  for (var i in jsonbyUser){
    if(registryAutorizedUsers[i] != undefined){
      var userFinal = registryAutorizedUsers[i].nombre;
      dataDispersion.labels.push(userFinal);
      dataDispersion.datasets[0].data.push(jsonbyUser[i]);
      dataDispersion.datasets[0].backgroundColor.push(arrayColors[x]);
      dataDispersion.datasets[0].borderColor.push(arrayColors[x]);
    }else{
      dataDispersion.datasets[0].data[0] = dataDispersion.datasets[0].data[0] + jsonbyUser[i];
    }
    x++;
  }

  return (			
    <div className="row z-depth-2 padding">
      <h5>Inscripciones por usuario</h5>
        <div className="col s12">
              <Bar
                data={dataDispersion}
              />
        </div>
    </div>);
}

export default DashboardRegistryByUser;
