import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
    marginTop: "20px",
    backgroundColor: theme.palette.background.default,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
  inputLabelTitle: {
    color: theme.palette.primary.main,
    alignItems: "center",
  },
}));

export default function PaymentDetailRegistryForm(props) {
  const { order, currency, currencyBase, removeItemOrder } = props;

  const classes = useStyles();

  let rows = [];
  let totalPayment = 0;
  if (order && order.length > 0 && order[0].unit_price != undefined) {
    for (var i in order) {
      var price =
        Math.round(order[i].unit_price * currency.tipoCambio * 100) / 100;
      totalPayment = totalPayment + price * order[i].quantity;
      rows.push(
        <TableRow className={classes.inputLabelTitle} key={i}>
          <TableCell className={classes.inputLabel} component="th" scope="row">
            {order[i].name}
          </TableCell>
          <TableCell className={classes.inputLabel} align="center">
            {order[i].description}
          </TableCell>
          <TableCell className={classes.inputLabel} align="center">
            {order[i].quantity}
          </TableCell>
          <TableCell className={classes.inputLabel} align="center">
            {" "}
            {price} {currency.codigo}
          </TableCell>
          <TableCell className={classes.inputLabel} align="center">
            {" "}
            {price * order[i].quantity} {currency.codigo}
          </TableCell>
        </TableRow>
      );
    }
  }
  return (
    <TableContainer component={Paper} className={classes.root}>
      <Typography
        className={classes.inputLabel}
        style={{ margin: "10px" }}
        variant={"h3"}
        component="h2"
      >
        Estado de Cuenta
      </Typography>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.inputLabel}>Producto</TableCell>
            <TableCell
              className={classes.inputLabel}
              style={{ width: "50%" }}
              align="center"
            >
              Descripción
            </TableCell>
            <TableCell className={classes.inputLabel} align="center">
              Cantidad
            </TableCell>
            <TableCell className={classes.inputLabel} align="center">
              Precio
            </TableCell>
            <TableCell className={classes.inputLabel} align="center">
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows}
          <TableRow>
            <TableCell
              className={classes.inputLabel}
              align="center"
            ></TableCell>
            <TableCell
              className={classes.inputLabel}
              align="center"
            ></TableCell>
            <TableCell
              className={classes.inputLabel}
              align="center"
            ></TableCell>
            <TableCell className={classes.inputLabel} align="center">
              Total a pagar
            </TableCell>
            <TableCell className={classes.inputLabel} align="center">
              {totalPayment.toFixed(2)} {currency.codigo}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {currencyBase.cambioAPeso != undefined &&
        currency.codigo != "MXN" &&
        (currency.mostrarCambioAPesos === undefined ||
          currency.mostrarCambioAPesos) && (
          <Paper style={{ padding: "10px" }}>
            <Typography variant={"subtitle2"} component="h2">
              Pago en pesos Méxicanos a tasa {currency.cambioAPeso} MXN/
              {currency.codigo}- Total{" "}
              <span>{(totalPayment * currency.cambioAPeso).toFixed(2)}</span>{" "}
              MXN{" "}
            </Typography>
          </Paper>
        )}
    </TableContainer>
  );
}
