import React from 'react';
import {Input, Icon} from 'react-materialize';
import Button from 'react-materialize/lib/Button';
import {fechaSeparada} from '../../services/utils';
import DynamicInputTitle from './DinamicInputsTypes/DynamicInputTitle';
import DynamicInputDate from './DinamicInputsTypes/DynamicInputDate';
import DynamicInputSelect from './DinamicInputsTypes/DynamicInputSelect';
import DynamicInputSelectOld from './DinamicInputsTypes/DynamicInputSelectOld';
import RequiredMessage from './RequiredMessage';
/**
 * props 
 * inputObject={this.props.inputObject} objeto estandar para campos dinamicos dashport

 formato de inputObject = {
      "campo" : 1,
      "etiqueta" : "Correo",
      "iDCampo" : "correo",
      "longitud" : 100,
      "observacion" : "",
      "tipoCampo" : "text",
      "validacion" : "Requerido",
      "valor" : "",
      "anchoCampo":"s12 m4 l4"
    }

 actualValue={this.state[this.props.inputObject.iDCampo]} el valor inicial del campo
    callbackSave={this.saveChanges} si no se define esta no muetra el boton de guardar
    callbackchange={e => this.getValue(e)} // siempre se asigna 
 * 
 * 
 */
class DynamicInputs extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      value:this.props.actualValue,
      update:false
    }
  }

    componentWillMount = () =>{
      console.log("componentWillMount " + this.props.inputObject.iDCampo +" "+ this.props.actualValue);

      var e = {target:{
        value:this.props.actualValue,
        target : this.props.actualValue,
        name:this.props.inputObject.iDCampo
      }};
  
      if((this.props.actualValue == null || this.props.actualValue == "") && this.props.inputObject.tipoCampo == "checkbox"){
        e.target.value = "false";
        e.target.checked = false;
      }
        this.getValue(e);
      console.log(e.target.value);
      console.log(this.props.inputObject);
    }

    componentWillUnmount = () => {
      console.log("Will unmount");
      this.props.callbackchange(this.state.value, this.props.inputObject, null);
    }

  getValue = (e) => {
    var valor = e.target.value;
    if(this.props.inputObject.tipoCampo == "checkbox"){
      valor = e.target.checked;
    }else if(this.props.inputObject.tipoCampo == "radio"){
      console.log("radio");
      valor = e.target.checked;
    }
    var valid = 1; // valid 1 no validar por ahora
      valid  = this.validate(valor); 
      if(valid == 3){
        this.setState({
          value : valor,
          message : (this.props.inputObject.regexMessage == undefined)?"Campo requerido":this.props.inputObject.message
          });
      }else{
        this.setState({
          value : valor,
          message : ""
          });
      }
      console.log(valor);
      this.props.callbackchange(valor, this.props.inputObject, valid);
    }

    validate = (value) =>{

      if(this.props.inputObject.validacion == "Requerido"){
        if(value != undefined 
          && value != null
          && value != ""){
            return 2; // valid 2 es valido
          }
          return 3;// valid 3 es invalido el valor
        }
      return 2; // valid 2 es valido
    }
    validateonBlur = (e) =>{
      console.log("validate on Blur");
    }

    validateFromProp(){
      var valid  = this.validate();  
      this.props.callbackchange(this.state.value, this.props.inputObject, valid);
    }

    render(){
    var anchoCampo = (this.props.inputObject.anchoCampo == undefined)?"s12 m6 l6":this.props.inputObject.anchoCampo;
    let buttonSave;
    let s = 12;
    if(this.props.callbackSave != undefined){
      s = 9;
      buttonSave = (<Button floating large
                      onClick={() => this.props.callbackSave(this.props.inputObject.iDCampo, this.state.value)} 
                      className='red' waves='light' icon="save" />);
    }
    let requerido = (this.props.inputObject.validacion == "Requerido")?"*":"";

    let inputByType;
    if(this.props.inputObject.tipoCampo.localeCompare("date")==0){

      return (<div><DynamicInputDate 
        inputObject={this.props.inputObject} 
        getValue={e => this.getValue(e)}
        onBlur={(e)=>this.validateonBlur(e)}
        buttonSave={buttonSave}
        value={this.state.value}
        />
        <RequiredMessage message={this.state.message}/>
        </div>);
      
        
    }else if(this.props.inputObject.tipoCampo.localeCompare("Select")==0){
      return (<DynamicInputSelectOld 
                inputObject={this.props.inputObject} 
                getValue={e => this.getValue(e)}
                onBlur={(e)=>this.validateonBlur(e)}
                buttonSave={buttonSave}
                value={this.state.value}
                message={this.state.message}/>);
         
    }else if(this.props.inputObject.tipoCampo.localeCompare("checkbox")==0){
      
      var checked = false;
      if(this.state.value){
        console.log("check");
        checked = true;
      }

      inputByType = (
        <div className={`col ${anchoCampo}`}>
          <Input s={s} 
            onChange={(e) => this.getValue(e)}
            type='checkbox' 
            value={this.props.inputObject.etiqueta}
            label={this.props.inputObject.etiqueta +" "+requerido}
            defaultValue={checked}
            />
        {buttonSave}
        <RequiredMessage message={this.state.message}/>

        </div>
      );
  
    }else if(this.props.inputObject.tipoCampo.localeCompare("radio")==0){
      
      var campo = this.props.inputObject.valor.map((v)=>{
          return (<div><Input s={s} 
            onBlur={(e)=>this.validateonBlur(e)}
            onChange={(e) => this.getValue(e)}
            type='radio' 
            value={v.value}
            label={v.texto}
            defaultValue={checked}
            name={this.props.inputObject.iDCampo}
            checked={this.state.value === v.value}
            />
            <RequiredMessage message={this.state.message}/>
            </div>
          );
      });
      inputByType = (
      <div className={`col ${anchoCampo}`}>
        {campo}
        {buttonSave}
      </div>);
  
    }else if(this.props.inputObject.tipoCampo.localeCompare("titulo")==0){
      inputByType = (<DynamicInputTitle inputObject={this.props.inputObject}/>);

    }else{
      var value = (this.state.value == null)?"":this.state.value;
      inputByType = (<div className={`col ${anchoCampo}`}>
      <Input s={s} 
        onBlur={(e)=>this.validateonBlur(e)}
        onChange={e => this.getValue(e)} 
        value={value} label={this.props.inputObject.etiqueta +" "+requerido} validate>
        </Input>
        {buttonSave}
        <RequiredMessage message={this.state.message}/>
        </div>);
  
    }  

      return inputByType;
  
  }
}

export default DynamicInputs;
