import React from 'react';
import { connect } from 'react-redux'
import {registryParticipantDataFunc, resetRegistryParticipantData} from '../../../../../data-store/actions/registry-actions';
import M from 'materialize-css';
import {fechahora, recorrerObjetoJsonLoop} from '../../../../../services/utils';
import { dbRef } from '../../../../../firebase/firebasejs';
import RegistryConfirmation from '../RegistryConfirmation/RegistryConfirmation';
import cloneDeep from 'lodash/cloneDeep';

import ModalDashport from '../../../Modals/ModalDashport';


class SendRegistryDataView extends React.Component {
  constructor(props){
    super(props);
    this.state = {isOpen: false}

  }
  toggleModal = (e) => {
    e.preventDefault();
    console.log("toogle modal");
        this.setState({
          isOpen: !this.state.isOpen
        });
      }

  sendParticipandRegistry = (e) =>{

    var atleteRegistryObject = {

    }
    var validateVar = this.validateFunc(this.props.stateFormContainer.validate);
    if(validateVar.statusValidate){
      let dataToSend = cloneDeep(this.props.stateFormContainer);
      dataToSend.fechaRegistro = fechahora();
      console.log();

      if(this.props.userID == undefined){
        dataToSend.usuarioRegistra = "No registrado";
      }else{
        dataToSend.usuarioRegistra = this.props.userID;
      }
      // 1 Inscripcion en linea
      // 2 Inscricppcion en punto de venta
      // 3 inscripcion por codigo

      if(this.props.registryType == 1){
        dataToSend.tipoRegistro = this.props.registryType;
        dataToSend.statusPago = {
          status:"pendiente",
          formaInscripcion:"en linea",
          formaPago:"",
          fechaPago:"", 
          idOperacion:0
        };
      }else if(this.props.registryType == 2){
        dataToSend.tipoRegistro = this.props.registryType;
        dataToSend.statusPago = {
          status:"ok",
          formaInscripcion:"punto de Venta",
          formaPago:"efectivo",
          fechaPago:fechahora(),
          idOperacion:0
        };
      }else if(this.props.registryType == 3){
        dataToSend.tipoRegistro = this.props.registryType;
        dataToSend.statusPago = {
          status:"ok",
          formaInscripcion:"Codigo de inscripción",
          formaPago:"efectivo",
          fechaPago:fechahora(),
          idOperacion:0
        };
      }else if(this.props.registryType == 4){
        dataToSend.tipoRegistro = 2;// seteo a 2 para ver enviar correo como ya pagado
        dataToSend.statusPago = {
          status:"ok",
          formaInscripcion:"en linea",
          formaPago:"efectivo",
          fechaPago:fechahora(), 
          idOperacion:0
        };
      }

      if(dataToSend.apellidos == undefined){
        console.log("apellidos undefined");
        dataToSend.apellidos = dataToSend.apellidoPaterno +" "+dataToSend.apellidoMaterno;
      }
      dataToSend.serverTime = dbRef.ServerValue.TIMESTAMP;
      //TODO: cambiar la fecha seteada por el usuairo a la hora del servidor
      // TODO: para registros de inscripciones y para resutlados en tiempo real de los eventos.


      if(this.props.continue){
        this.props.backForward(true);
      }
      const ignoreState = [
        "validate",
        "SubEventsInputsKey",
        "aditionalInputsModCatKey",
        "aditionalProductsKey",
        "newaditionalProductsKey",
        "registryType",
        "confirmacionTab",
        "datosAtletaTab",
        "opcionesEventoTab",
        "pagoTab",
        "value",
        "confirmationType",
        "paymentMethod",
        "oxxoReference"
      ];
      for(var i in ignoreState){
        delete dataToSend[ignoreState[i]];
      }
      this.props.sendParticipandRegistryEvent("evento_"+this.props.eventDetail.iDEvento, dataToSend);

    }else{
      for(var i in validateVar.validateIsues){
        M.toast(`El campo ${validateVar.validateIsues[i].etiqueta} es un campo requerido`, 7000);
      }
    }
  }

  validateFunc = (validateParam) => {
    //TODO: mejorar la validacion por tipo de campo, especificamente telefono.
    //TODO: agregar mas informacion de validacion el el objeto JSON . mensaje de error t expresiones regulares custom
    // TODO: mostrar vista de confirmacion de registro.
    var statusValidate = true;
    var validateIsues = [];
    for(var i in validateParam){
      if(validateParam[i].validacion == "Requerido"){
        if(this.props.stateFormContainer[validateParam[i].iDCampo] != undefined 
          && this.props.stateFormContainer[validateParam[i].iDCampo] != null
          && this.props.stateFormContainer[validateParam[i].iDCampo] != ""){
            
        }else{
          statusValidate = false;
          validateIsues.push(validateParam[i]);
        }
      }
    }

    return {statusValidate:statusValidate , validateIsues:validateIsues};

  }

    
  render(){
    var modalDetail;
    if(!this.props.hideModal){
      if(this.props.registryParticipantDataConfirm != null 
        && this.props.registryParticipantDataConfirm != undefined){
          if(this.props.registryParticipantDataConfirm.numero != undefined
            && this.props.registryParticipantDataConfirm.numero != 0){
              modalDetail = <ModalDashport
              show={true} 
              onClose={this.props.resetParticipandRegistryEvent}
              report={<div style={{padding:50}}><RegistryConfirmation 
                registryParticipantDataConfirm={this.props.registryParticipantDataConfirm}
                registryParticipantKeyConfirm={this.props.registryParticipantKeyConfirm}
                registryConfiguration={this.props.registryConfiguration}
                eventSubEvents={this.props.eventSubEvents}
                eventDetail={this.props.eventDetail}
                tipo={1}
                /></div> }
              />
  
            }else{
              M.toast(`Asignado folio`, 4000);
            }
      }
  
    }

    let buttom = (<a className="waves-effect waves-light btn" onClick={(e) => this.sendParticipandRegistry()}><i className="material-icons left">send</i>enviar</a>)
    if(this.props.continue){
      buttom = (<a className="waves-effect waves-light btn" onClick={(e) => this.sendParticipandRegistry()}><i className="material-icons left">arrow_forward</i>Continuar</a>)
    }

    return (
      <div>
        {modalDetail}
        {buttom}
      </div>);
  }

}

const mapDispatchToProps = (dispatch) => {
	return {
      sendParticipandRegistryEvent: (eventoIndex, atleteData) => {
        dispatch(registryParticipantDataFunc(eventoIndex, atleteData))
      },
      resetParticipandRegistryEvent: () => {
        dispatch(resetRegistryParticipantData())
      }
		}
	}

const mapStateToProps = (state) => {
	return {
    registryParticipantDataConfirm : state.registry.registryParticipantData,
    registryParticipantKeyConfirm : state.registry.registryParticipantKey,
    registryError:state.registry.registryError
	}
  }
  
const SendRegistryData = connect(
	mapStateToProps,
	mapDispatchToProps
)(SendRegistryDataView);
export default SendRegistryData;
