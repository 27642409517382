import * as types from "./action-types";
import * as fb from "../../firebase/firebasejs";

//###########################################################################3

//###########################################################################3
export function getVirtualEventsMessages(userID, eventID, organizer) {
  console.log("getVirtualEventsMessages");

  if (window.$virtualEventsMessages != undefined) {
    console.log("$virtualEventsMessages");
    window.$virtualEventsMessages();
  }
  if (window.$virtualEventsMessages1 != undefined) {
    console.log("$virtualEventsMessages1");
    window.$virtualEventsMessages1();
  }
  if (window.$virtualEventsMessages2 != undefined) {
    console.log("$virtualEventsMessages2");
    window.$virtualEventsMessages2();
  }

  return (dispatch) => {
    if (userID === undefined) {
      return false;
    }
    dispatch({
      type: types.MESSAGES_REQUESTED,
      messagesRequested: eventID,
    });
    // obtner datos de se relacionan con el usuario;
    window.$virtualEventsMessages = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.chatVirtualEvent)
      .where("topics", "array-contains", userID)
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            //console.log("message ADD: ", change.doc.data());
            dispatch({
              type: types.CHAT_VIRTUAL_EVENT,
              message: change.doc.data(),
              messageId: change.doc.id,
            });
          }
          if (change.type === "modified") {
            //console.log("message CHANGE: ", change.doc.data());
            dispatch({
              type: types.CHAT_VIRTUAL_EVENT,
              message: change.doc.data(),
              messageId: change.doc.id,
            });
          }
          if (change.type === "removed") {
            console.log("Removed city: ", change.doc.data());
          }
        });
      });

    //obtener datos publicos por el organizador
    window.$virtualEventsMessages1 = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.chatVirtualEvent)
      .where("topics", "array-contains", "public")
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            console.log("message ADD: ", change.doc.data());
            dispatch({
              type: types.CHAT_VIRTUAL_EVENT,
              message: change.doc.data(),
              messageId: change.doc.id,
            });
          }
          if (change.type === "modified") {
            console.log("message CHANGE: ", change.doc.data());
            dispatch({
              type: types.CHAT_VIRTUAL_EVENT,
              message: change.doc.data(),
              messageId: change.doc.id,
            });
          }
          if (change.type === "removed") {
            console.log("Removed city: ", change.doc.data());
          }
        });
      });

    if (organizer) {
      //obtener datos publicos por el organizador
      window.$virtualEventsMessages2 = fb.firestore
        .collection(fb.virtualEvents)
        .doc(eventID)
        .collection(fb.chatVirtualEvent)
        .where("topics", "array-contains", "organizer")
        .onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            if (change.type === "added") {
              //console.log("message ADD: ", change.doc.data());
              dispatch({
                type: types.CHAT_VIRTUAL_EVENT,
                message: change.doc.data(),
                messageId: change.doc.id,
              });
            }
            if (change.type === "modified") {
              //console.log("message CHANGE: ", change.doc.data());
              dispatch({
                type: types.CHAT_VIRTUAL_EVENT,
                message: change.doc.data(),
                messageId: change.doc.id,
              });
            }
            if (change.type === "removed") {
              console.log("Removed city: ", change.doc.data());
            }
          });
        });
    }
  };
}
//###########################################################################3
export function sendVirtualEventMessage(userID, eventID, message) {
  console.log("getVirtualEventsMessages");

  return (dispatch) => {
    let docRef = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.chatVirtualEvent)
      .doc(message.id);
    docRef.set(message);
  };
}
//###########################################################################3
//###########################################################################
export function resetChat() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CURRENT_EVENT_CHAT,
      chatVirtualEvent: {},
    });
  };
}
