import React from "react";
import Container from "@material-ui/core/Container";
import Footer from "./components/footer/";
import ParallaxDashport from "./components/navigation-ui/ParallaxDashport";

import AppBarDashport from "./components/navigation-ui/AppBarDashport";

export default function AppContainer({ children }) {
  return (
    <AppBarDashport>
      <ParallaxDashport />
      <Container
        maxWidth="md"
        style={{ marginTop: "50px", marginBottom: "50px" }}
      >
        {children}
      </Container>
      <Footer />
    </AppBarDashport>
  );
}
