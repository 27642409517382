import React from "react";

import HeaderAtleteModal from "./HeaderAtleteModal";
import AtleteData from "./AtleteData";
import AtleteReportsContainer from "./AtleteReportsContainer";

import Background from "../../../../../../../assets/backgroundPhotos/fondogris_Dashport.jpg";

//TODO: Agregar opciones de edicion de datos como en la app de crono.

function AtleteDetailContainer(props) {
  const { currentEvent, participant } = props;

  var avatarWoman =
    "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/Avatares%2FavatarWoman.jpeg?alt=media&token=e79bd135-1392-4847-a7dc-12a389214af6";
  var menAvatar =
    "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/Avatares%2FmenAvatar.jpg?alt=media&token=08ef7521-1996-4b91-903a-f970d211993e";

  // var participantNew = {
  //   ...participant,
  //   goalTime : (participant.goalTime == undefined)?"00:00:00":participant.goalTime,
  //   finishTime : (participant.finishTime == undefined)?"00:00:00":participant.finishTime,
  //   positionAbsoluteGender : (participant.positionAbsoluteGender == undefined)?0:participant.positionAbsoluteGender,
  //   positionRelativeGender : (participant.positionRelativeGender == undefined)?0:participant.positionRelativeGender,
  //   positionAbsolute : (participant.positionAbsolute == undefined)?0:participant.positionAbsolute,
  //   positionRelative : (participant.positionRelative == undefined)?0:participant.positionRelative,
  //   startLinePhoto : (participant.startLinePhoto == undefined || participant.startLinePhoto == null)?((participant.gender == "F")?avatarWoman:menAvatar):participant.startLinePhoto,
  //   finishLinePhoto : (participant.finishLinePhoto == undefined || participant.finishLinePhoto == null)?((participant.gender == "F")?avatarWoman:menAvatar):participant.finishLinePhoto
  // };

  return (
    <div
      className="padding overflowTrue"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        minHeight: 400,
      }}
    >
      <AtleteData participant={participant} currentEvent={currentEvent} />
      <AtleteReportsContainer
        participant={participant}
        currentEvent={currentEvent}
      />
    </div>
  );
}

export default AtleteDetailContainer;
