import React from 'react';
import './OxxoPaymentCode.css';

const OxxoPaymentCode = ({jsonMail, jsonTextos, idioma, reference, tipo}) => {

  var monto = 0;
  var orden = jsonMail.datos.order;
  var monedaBase = jsonMail.datos.monedaBase;
  for(var i in orden){
    monto = monto + (orden[i].unit_price * orden[i].quantity * monedaBase.cambioAPeso);
  }
  return  (      <div style={{cursor: 'auto', color: '#000000', fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif', fontSize: '13px', lineHeight: '22px', textAlign: 'center'}}>
  <span style={{fontFamily: 'Arial, sans-serif'}}>
    {jsonTextos[idioma].postEvento[tipo]}
  </span>
  <div className="opps">
    <div className="opps-header">
      <div className="opps-reminder">Ficha digital. No es necesario imprimir.</div>
      <div className="opps-info">
        <div className="opps-brand"><img src="https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/oxxo_brand%2Foxxopay_brand.png?alt=media&token=a855a8cf-817f-4036-a34a-ddf926e54fad" alt="OXXOPay" /></div>
        <div className="opps-ammount">
          <h3 className="oopsh3">Monto a pagar</h3>
          <h2> ${monto} <sup>MXN</sup></h2>
          <p>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
        </div>
      </div>
      <div className="opps-reference">
        <h3 className="oopsh3">Referencia</h3>
        <h1 className="opps">{reference}</h1>
      </div>
    </div>
    <div className="opps-instructions">
      <h3 className="oopsh3">Instrucciones</h3>
      <ol>
        <li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank">Encuéntrala aquí</a>.</li>
        <li>Indica en caja que quieres ralizar un pago de <strong>OXXOPay</strong>.</li>
        <li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
        <li>Realiza el pago correspondiente con dinero en efectivo.</li>
        <li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
      </ol>
      <div className="opps-footnote">Al completar estos pasos recibirás un correo de <strong>Dashport</strong> confirmando tu pago.</div>
    </div>
  </div>	
</div>);
}


export default OxxoPaymentCode;