import React from "react";
import { connect } from "react-redux";
import { atletePhotosFunc } from "../../../../../../../data-store/actions/atlete-actions";
import { eventPhotoConfiguration } from "../../../../../../../data-store/actions/events-actions";

import AtleteControls from "./AtleteControls";
import AtleteCharts from "./AtleteCharts";
import AtletePhotos from "./AtletePhotos";
import AtleteGarminDetail from "./AtleteGarminDetail";
import PhotoEditorDefault from "../../../PhotoEditorVirtual/PhotoEditorDefault";

function AtleteReportsContainer(props) {
  const { participant, currentEvent, publicView } = props;
  //({eventDetail, resultado, atletePhotos, eventConfiguration})
  console.log(participant);
  console.log(currentEvent);
  return (
    <div className="row">
      <div className="col s12">
        {/* <AtleteControls participant={participant} currentEvent={currentEvent}/> */}
        <AtleteGarminDetail
          participant={participant}
          currentEvent={currentEvent}
        />
        <hr />
        <AtletePhotos participant={participant} currentEvent={currentEvent} />
        {/* 
                        <hr/>
                        <AtleteCharts participant={participant}  currentEvent={currentEvent}/>
                        <hr/> */}
        <hr />
      </div>
    </div>
  );
}

export default AtleteReportsContainer;
