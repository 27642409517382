import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useForm, Controller } from "react-hook-form";
import GarminAthorizedHealthAPI from "../GarminApi/GarminRequestApi/GarminAthorizedHealthAPI";
import GarminAthorizedTrainingAPI from "../GarminApi/GarminRequestApi/GarminAthorizedTrainingAPI";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    margin: 10,
  },
  media: {
    height: 70,
  },
  content: {
    flex: 1,
  },
  cardHeader: {
    backgroundColor: "red",
    color: "white",
  },
  time: {
    flex: 0.3,
  },
  paper: {
    textAlign: "center",
  },
  large: {
    width: 50,
    height: 50,
  },
}));

export default function AthleteProfileCard(props) {
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const classes = useStyles();

  const { control, register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <Grid container direction="row" justify="space-around" alignItems="center">
      <Grid Item xs={12} className={classes.paper}>
        <GarminAthorizedHealthAPI />
      </Grid>
      {/* <Grid Item xs={12}  className={classes.paper}>
              <GarminAthorizedTrainingAPI/>
            </Grid> */}
      {/* <Grid Item xs={12}  className={classes.paper}>
              Próximamente STRAVA...
            </Grid> */}
    </Grid>
  );
}
