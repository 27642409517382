import React from 'react';


const OrderDetail = ({ParticipantDetail}) => {
var order = ParticipantDetail.order;
var addInscripcion = [];
var precioCobrado = 0;

var monedaAUsar = ParticipantDetail.monedaAUsar;
var monedaBase = ParticipantDetail.monedaBase;
for(var p in order){

    addInscripcion.push(
        <tr key={order[p].code} className="itemOrderResume">
        <td className="itemOrderName" data-order-name={order[p].name}>{order[p].name}</td>
        <td className="itemOrderDescription" data-order-description={order[p].description}>{order[p].description}</td>
        <td className="itemOrderQuantity" data-order-quantity={order[p].quantity}>{order[p].quantity}</td>
        <td className="itemOrderPrice" data-order-price={order[p].unit_price} data-order-codigo={order[p].code} data-order-preciokey={order[p].precioKey}> {order[p].unit_price} {monedaBase.codigo}</td>
        <td></td>
        </tr>);
        precioCobrado = parseFloat(precioCobrado) + parseFloat(order[p].unit_price);
    }

    var totalaPagar = 
    (<tfoot>
        <tr>
        <td></td>
        <td></td>
        <td>Total a pagado</td>
        <td> <span id="precioFinal">{precioCobrado}</span> {monedaBase.codigo}</td>
        <td></td>
        </tr>
    </tfoot>);

    var tabla = 
    (<div className="row">
    <hr/>
        <div className="card-panel col s12 m12 l12">
        <table className="bordered centered">
            <thead>
            <tr>
                <th>Producto</th>
                <th>Descripción</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th></th>
            </tr>
            </thead>
                {totalaPagar}
            <tbody id="listadoProductos">
                {addInscripcion}
            </tbody>
        </table>
        </div>
    </div>);

  return tabla;
}




export default OrderDetail;
