import React from 'react';
//import {Input, Icon} from 'react-materialize';
import Button from 'react-materialize/lib/Button';

import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import RequiredMessage from '../RequiredMessage';

class DynamicInputSelect extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      value:this.props.value,
      update:false
    }
  }



  setValue = (value) => {
    console.log("Set value" + this.props.inputObject.iDCampo);
    console.log(value);
    var e = {target:{
      value:value,
      name:this.props.inputObject.iDCampo
    }};
    if(this.state.update){
      console.log("setValue true");
      this.props.getValue(e);
      this.setState({update : false});
    }
  }

    validate = () =>{

      // if(this.props.inputObject.validacion == "Requerido"){
      //   if(this.state.value != undefined 
      //     && this.state.value != null
      //     && this.state.value != ""){
      //       alert("ok");  

      //     }else{
      //       alert("campo requerido");  
      //     }
      // }
    }

  render(){
    var anchoCampo = (this.props.inputObject.anchoCampo == undefined)?"s12 m6 l6":this.props.inputObject.anchoCampo;
    let requerido = (this.props.inputObject.validacion == "Requerido")?"*":"";
    let s = 12;

    let inputByType;
    var selectOptions = [];
    if(this.props.inputObject.selectLabel != undefined){
      selectOptions.push(
        <MenuItem key={0} value={0}>
          {this.props.inputObject.selectLabel}
        </MenuItem>);
    }else{
      selectOptions.push(
        <MenuItem key={0} value={0}>
          Selecciona una Opción
        </MenuItem>);
    }

    this.props.inputObject.valor.map((v,i) => {
          selectOptions.push(
            <MenuItem key={i+1} value={v.valor}>{v.texto}</MenuItem>);
        });
      var valueLabel = (this.props.inputObject.valor[this.props.value] != undefined)?this.props.inputObject.valor[this.props.value].texto:"vacio";
      var value = (this.props.inputObject.valor[this.props.value] != undefined)?this.props.inputObject.valor[this.props.value].valor:0;
      if(selectOptions.length <= 2){
        // TODO,esta parte no logro que funciones bien, 
        inputByType = (
          <div className={`col ${anchoCampo}`}>
            <div>{this.props.inputObject.etiqueta}</div>
            <h5>{valueLabel}</h5>
          </div>
        );
        this.setValue(value);

      }else{
        inputByType = (
          <div className={`col ${anchoCampo}`}>
          <FormControl style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}>
            <InputLabel htmlFor="age-helper">{this.props.inputObject.etiqueta}</InputLabel>
                <Select
                value={this.props.value}
                onChange={e => this.props.getValue(e)}
                input={<Input name="age" id="age-auto-width" />}
                autoWidth
              >
                  {selectOptions}

              </Select>
          </FormControl>
          {this.props.buttonSave}
          <RequiredMessage message={this.props.message}/>
  
          </div>
        );
  
      }


      return inputByType;
  
  }
}

export default DynamicInputSelect;
