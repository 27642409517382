import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardPayment from "./CardPayment";
import OxxoPayment from "./OxxoPayment";
import CodePayment from "./CodePayment";
import { showSnackbar } from "../../../../../data-store/actions/organizers-actions";
import firebase from "firebase";
import { fechahora } from "../../../../../services/utils";
import * as fb from "../../../../../firebase/firebasejs";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function PaymentsView(props) {
  const {
    callback,
    callbackCode,
    order,
    test,
    currency,
    currencyBase,
    currentOrder,
    eventDetail,
  } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const registryParticipantKey = useSelector(
    (state) => state.registry.registryParticipantKey
  );

  const classes = useStyles();
  const [view, setView] = useState(false);
  function endRegistry() {
    const paymentObject = {
      status: "ok",
      formaInscripcion: "en linea",
      formaPago: "efectivo",
      fechaPago: fechahora(),
      serverTime: firebase.database.ServerValue.TIMESTAMP,
      idOperacion: 0,
      amount: 0,
    };

    var updates = {};
    const eventoIndex = "evento_" + eventDetail.iDEvento;

    updates[
      `${fb.EventosCompletos}/${eventoIndex}/${fb.inscritos}${fb.listadoInscritos}${registryParticipantKey}/statusPago/`
    ] = paymentObject;
    console.log("paymentObject", updates);
    console.log("paymentObject", paymentObject);
    if (eventoIndex && registryParticipantKey) {
      return fb.db
        .ref()
        .update(updates)
        .then(function () {
          console.log("Datos Guardados");
          callback(true, false);
          dispatch(showSnackbar(true, "Pago exitoso ", 4000));
        })
        .catch(function (error) {
          console.log("Error" + error);
          dispatch(
            showSnackbar(true, "Error en el registro intente de nuevo ", 4000)
          );
        });
    } else {
      dispatch(
        showSnackbar(true, "Error en el registro intente de nuevo", 4000)
      );
    }
  }
  const totalOrder = order
    ? order.reduce(function (acc, obj) {
        return acc + parseFloat(obj.unit_price) * obj.quantity;
      }, 0)
    : 0;
  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      >
        <Typography color="primary" component="h1" variant="h6">
          Total a pagar $
        </Typography>
        <Typography color="primary" component="h1" variant="h3">
          {order &&
            order.reduce(function (acc, obj) {
              return (
                acc +
                Math.round(
                  parseFloat(obj.unit_price) *
                    obj.quantity *
                    currency.tipoCambio *
                    100
                ) /
                  100
              );
            }, 0)}{" "}
          {currency.codigo}
        </Typography>
      </Grid>
      {!view && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ padding: "0px", textAlign: "center" }}
        >
          {/* {registryConfiguration.configuracion.formasDePago.codigoDescuento && (
            <CodePayment
              callbackCode={callbackCode}
              currency={currency}
              currentOrder={currentOrder}
              eventDetail={eventDetail}
            />
          )} */}
          {registryParticipantKey &&
            registryConfiguration.configuracion.formasDePago.conektaCard
              .disponible &&
            totalOrder > 0 && (
              <Button
                onClick={() => setView("CARD")}
                variant="contained"
                color="primary"
                style={{
                  height: "150px",
                  width: "150px",
                  fontSize: "18px",
                  margin: "10px",
                }}
              >
                Tarjeta de Crédito o Débito
              </Button>
            )}
          {registryParticipantKey &&
            registryConfiguration.configuracion.formasDePago.conektaOxxoPay
              .disponible &&
            totalOrder > 0 && (
              <Button
                onClick={() => setView("OXXO")}
                variant="contained"
                color="primary"
                style={{
                  height: "150px",
                  width: "150px",
                  fontSize: "18px",
                  margin: "10px",
                }}
              >
                Pago en Oxxo
              </Button>
            )}
          {registryParticipantKey && totalOrder === 0 && (
            <Button
              onClick={() => endRegistry()}
              variant="contained"
              color="primary"
              style={{
                height: "150px",
                fontSize: "18px",
                margin: "10px",
              }}
            >
              Finalizar Registro
            </Button>
          )}
        </Grid>
      )}
      {view === "CARD" && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ padding: "0px", textAlign: "center" }}
        >
          <Button
            onClick={() => setView(false)}
            variant="contained"
            color="primary"
          >
            Cambiar método de pago
          </Button>

          <CardPayment
            currency={currency}
            currencyBase={currencyBase}
            callback={callback}
            order={order}
            currentOrder={currentOrder}
            eventDetail={eventDetail}
          />
        </Grid>
      )}
      {view === "OXXO" && (
        <Grid
          item
          xs={12}
          sm={12}
          style={{ padding: "0px", textAlign: "center" }}
        >
          <Button
            onClick={() => setView(false)}
            variant="contained"
            color="primary"
          >
            Cambiar método de pago
          </Button>

          <OxxoPayment
            currency={currency}
            currencyBase={currencyBase}
            callback={callback}
            order={order}
            currentOrder={currentOrder}
            eventDetail={eventDetail}
          />
        </Grid>
      )}
    </Grid>
  );
}
