import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AppContainer from "../../AppContainer";

export default function PrivacyPolicies() {
  return (
    <AppContainer>
      <div className="container-home">
        <div className="header center grey-text text-darken-3">
          <h2>AVISO DE PRIVACIDAD</h2>
          <b>DASHPORT, S.A.P.I. de C.V. </b> a través de su representante
          Eduardo Madrid Alvarez de Lugo, con domicilio en el predio marcado con
          el numero 481 de la Calle 45 con cruzamientos entre las calles 54 y 56
          de la Colonia Centro en la ciudad de Mérida Yucatán, el cual es el
          responsable del uso y protección de sus datos personales, a si como
          para atender sus solicitudes respecto a los mismos, y al respecto le
          informa lo siguiente:
          <p>
            Los datos personales que recabamos de usted, los utilizaremos para
            las siguientes finalidades que son necesarias para el servicio que
            solicita a fin de poder brindarle este de manera eficiente:
          </p>
          <p>
            Solicitar su autorización de enviarle información vía correo
            electrónico sobre el o los eventos deportivos en los cuales se
            encuentre inscrito. De manera adicional, utilizaremos su información
            personal para las siguientes finalidades que no son necesarias para
            el servicio solicitado, pero que nos permiten y facilitan brindarle
            una mejor atención: . Mantenerlo informado sobre los cambios del o
            los eventos en los que se encuentre inscrito. Identificar a cada
            usuario a fin de que el servicio pueda ser personalizado atendiendo
            a sus intereses con respecto al servicio que se le proporciona
            Proveerle información acerca de eventos, talleres, o programas que
            puedan ser de su interés de acuerdo al giro de nuestro negocio. En
            caso de que no desee que sus datos sean tratados con las finalidades
            de manera adicional, puede manifestar su negativa al margen de este
            mismo escrito, enviando un correo electrónico a info@dashport.run,
            con la leyenda “NO DESEO QUE MIS DATOS PERSONALES SEAN TRATADOS DE
            MANERA ADICIONAL AL SERVICIO QUE ME SERA PROPORCIONADO”, acompañado
            de su firma autógrafa.
          </p>
          <p>
            Para llevar a cabo las finalidades descritas en el presente aviso de
            privacidad, utilizaremos los siguientes datos personales de
            identificación y contacto:
          </p>
          <p>Fecha de Nacimiento Domicilio Correo Electrónico Teléfono</p>
          <p>
            Le informamos que sus datos personales no serán divulgados por
            ningún medio de comunicación, que permita que otros usuarios tengan
            acceso a sus datos personales.
          </p>
          <p>
            Siendo indispensable contar con su información actualizada, para el
            o los eventos en los que se encuentre inscrito, puede tener acceso
            para la rectificación, cancelación u oposición de sus datos
            personales previamente proporcionados, para fines específicos, en
            caso de rectificación deberá indicar las modificaciones a realizarse
            aportando la documentación necesaria que sustente su petición. Estos
            derechos se conocen como derechos ARCO; enviando su solicitud por
            medio del siguiente correo electrónico con el título “RECTIFICACION
            DE DATOS PERSONALES:
          </p>
          <p>info@dashport.run</p>
          <p>
            Acompañando la siguiente documentación que facilite su localización:
          </p>
          <p>
            Nombre del titular y domicilio para comunicar la respuesta a su
            solicitud. Los Documentos que acrediten la identidad o, en su caso,
            la representación legal el titular. La descripción clara y precisa
            de los datos personales respecto de los que se busca ejercer los
            derechos mencionados. Cualquier otro elemento o documento que
            facilite la localización de los datos personales. Le comunicamos que
            sus solicitudes serán atendidas a la brevedad posible, en un plazo
            máximo de veinte días hábiles, contados desde la fecha en que se
            recibió la solicitud de acceso, rectificación, cancelación u
            oposición, haciéndole llegar la respuesta correspondiente por medio
            de la dirección de correo electrónico que previamente nos
            proporcione, procediendo a realizar la misma en caso de ser
            procedente en un plazo de quince días naturales siguientes a la
            comunicación de su respuesta.
          </p>
          <p>
            Asimismo usted tiene derecho a revocar su consentimiento para el
            tratamiento de sus datos personales, siguiendo el procedimiento
            antes descrito, sin embargo es importante, para sus debido
            conocimiento que no en todos los casos podremos atender su solicitud
            de revocación, por situaciones de índole legal o administrativo que
            nos requiera seguir tratando sus datos personales, la revocación
            implicara que no le podamos seguir brindando el servicio solicitado
            inicialmente.
          </p>
          <p>
            Le informamos que sus datos personales para brindarle mayor
            seguridad por ningún motivo serán trasferidos a terceros.
          </p>
          <p>
            En caso de que el presente aviso de privacidad, implique algún
            cambio por disposiciones legales, administrativas o con fines de
            mejora continua, nos comprometemos a mantenerlo informado, por medio
            de envío de correo electrónico y/o notificaciones por escrito, en la
            dirección que previamente nos proporcione, con la finalidad de
            poderles ofrecer un servicio eficaz.
          </p>
          <p>
            Los datos personales que nos proporciona, permanecerá en resguardo
            del responsable por un periodo de tres años a partir del día
            siguiente en el que nos otorgue su autorización ello, con fundamento
            en el articulo 10 de la Ley Federal de Protección de Datos
            Personales en Posesión de los Particulares.
          </p>
          <p>
            Otorgo mi consentimiento para que mis datos personales sean tratados
            conforme a lo señalado en el presente aviso de privacidad.
          </p>
        </div>
        <div className="row center"></div>
      </div>
    </AppContainer>
  );
}
