const ProductionKey = "key_axRJqi7LVKACYU7nBx2wxrQ"; // Dashport produccion
const TestKey = "key_MSqFZrW2myaTurXVRgL1Twg";

const ConektaHelper = {
  initConekta: (produccion) => {
    if (produccion) {
      window.Conekta.setPublicKey(ProductionKey); // Dashport produccion
    } else {
      console.log("evento de prueba");
      window.Conekta.setPublicKey(TestKey); // EM pruebas
    }
  },
  getCardBrand: (cardNumber) => {
    return window.Conekta.card.getBrand(cardNumber);
  },
  validateCardNumber: (cardNumber) => {
    return window.Conekta.card.validateNumber(cardNumber);
  },
  validateCvc: (cvc) => {
    return window.Conekta.card.validateCVC(cvc);
  },
  validateExpirationDate: (expiryMonth, expiryYear) => {
    return window.Conekta.card.validateExpirationDate(expiryMonth, expiryYear);
  },
  tokenize: (
    cardNumber,
    cardHolder,
    expiryMonth,
    expiryYear,
    cvc,
    successCallback,
    errorCallback
  ) => {
    const tokenParams = {
      card: {
        number: cardNumber,
        name: cardHolder,
        exp_year: expiryYear,
        exp_month: expiryMonth,
        cvc,
      },
    };
    window.Conekta.Token.create(tokenParams, successCallback, errorCallback);
  },
};

export default ConektaHelper;
