import React, { useSelector } from 'react';
import ConektaCard from './ConektaCard';
import OxxoPay from './OxxoPay';

function PaymentContainer() {

  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const myVirtualEvents = useSelector(state => state.virtualEvents.myVirtualEvents);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);

  
  const order = [
    {
      name:currentEvent.eventName,
      description:"Inscripción al evento "+ currentEvent.eventName+" a nombre de "+atleteAccount.nombres+" "+atleteAccount.apellidos,
      quantity:1,
      unit_price:Math.round(currentEvent.price * 100) / 100,
      code:"insc-"+currentEvent.eventID,
      precioKey:""
    }
  ]
  return (<div className="row">
   
    <h5>Opciones de pago</h5>
    <ConektaCard 
      athleteData={atleteAccount}
      userID={userID}
      test={true}
      currentEvent={currentEvent}
      order={order}
      />
    <OxxoPay 
      athleteData={atleteAccount} 
      userID={userID}
      test={true}
      currentEvent={currentEvent}
      order={order}
      />
              
  </div>);
}

export default PaymentContainer;
