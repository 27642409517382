import React from 'react';

const BackForwardButton = ({action, forward}) => {
  let continueButtonView = "";
  let textButton = (forward)?"Continuar":"Anterior";
  let ubication = (forward)?"col s8 offset-s4 m4 offset-m8 l4 offset-l8":"col s8 m4 l4";
  let icon = (forward)?"arrow_forward":"arrow_back";

    continueButtonView = 
    <div className="row">
      <div className={ubication}>
        <a onClick={action} className="waves-effect waves-light btn-large">
          <i className="material-icons right">{icon}</i>
          {textButton}
        </a>
      </div>
    </div>

    return continueButtonView;
}

export default BackForwardButton;
