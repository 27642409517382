import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import PhotoEditor from "./PhotoEditor";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "black",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  logo: {
    height: "40px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PhotoEditorContainer(props) {
  const {
    buttomLabel,
    title,
    BarButtons,
    ButtonStyle,
    urlPhoto,
    participant,
    currentEvent,
  } = props;
  // BarButtons =  Array de Componentes botones que muestran un Dialog adicional o hacen algo.
  // buttomLabel =  Texto del boton del rDialog full.
  console.log("participant", participant);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button
        style={
          ButtonStyle == undefined || ButtonStyle == null ? null : ButtonStyle
        }
        color="primary"
        onClick={handleClickOpen}
        variant="contained"
      >
        {buttomLabel}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <PhotoEditor
          urlPhoto={urlPhoto}
          participant={participant}
          currentEvent={currentEvent}
          title={title}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      </Dialog>
    </div>
  );
}
