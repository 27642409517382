import React, { Component } from 'react';
import DynamicInputs from '../../../../../DynamicInput/DynamicInputs';

class CurrencySelect extends Component {

  componentDidMount(){
    var monedaData = this.props.registryConfiguration.configuracion.moneda;
    if(monedaData !== undefined){
      for(var i in monedaData){
        if(monedaData[i].tipo =="base"){
          this.index = i;
          this.props.setCurrencyUsed(monedaData[i], monedaData[i], i);
        }
      }
    }
  }
  getValue = (value) => {
    var monedaAUsar = this.props.registryConfiguration.configuracion.moneda[value];
    this.index = value;
    this.props.setCurrencyUsed(monedaAUsar, this.props.monedaBase, value);
  }
  
  render(){
    var monedaData = this.props.registryConfiguration.configuracion.moneda;
    var listaMonedasSelect = [];
    var listaMonedas = [];
  
    let view = "";
  
    if(monedaData !== undefined){
      let lengthCurrency = 0;
          for(var i in monedaData){
            if(monedaData[i].codigo == this.props.monedaAUsar.codigo){
              console.log(monedaData[i].codigo);
              console.log(monedaData);
              console.log(i);
              console.log(i);
              this.index = i;
            }
            lengthCurrency++;
            if(monedaData[i].tipo =="base"){
              listaMonedas.push({valor:i, texto:monedaData[i].texto});
            }else{
              listaMonedasSelect.push({valor:i, texto:monedaData[i].texto});
            }
          }
    
          var currencyList = listaMonedas.concat(listaMonedasSelect);
          console.log(currencyList);
    
          let select = {
            campo : 1,
            etiqueta : "Selecciona la moneda a usar",
            iDCampo : "setCurrency",
            longitud : 100,
            observacion : "",
            tipoCampo : "Select",
            validacion : "Requerido",
            valor : currencyList,
            anchoCampo:"s12 m12 l12"
          }
          console.log(this.index);
          if(lengthCurrency > 1){
            view = (
              <div className="row">
              <div className="col z-depth-2 s12 m8 offset-m2 l8 offset-l2 red-text #ffebee red lighten-5">
                <h5 id="textoPesos">Precios denominados en {monedaData[this.index].texto}</h5>
                <DynamicInputs key={i} inputObject={select} 
                    actualValue={this.index} 
                    callbackchange={this.getValue}/>
              </div>
              </div>)
          }
        }
        console.log(this.index);

    return view;
  }
} 



export default CurrencySelect;
