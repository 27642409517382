import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {fechahora} from '../../../../../services/utils';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import {sendVirtualEventMessage, getVirtualEventsMessages} from '../../../../../data-store/actions/chat-actions'


const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
    padding:10,
    height:500,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  menu: {
    flex:1,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:50,
    width:100,
  },
}));

export default function Messages(props){
  const {topics, between, writeAvailable, chatVirtualEventArray} = props

  const dispatch = useDispatch();
  const userID = useSelector(state => state.atlete.userID);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(state => state.virtualEvents.currentEventParticipants);

  const classes = useStyles();
  const { register, handleSubmit, errors, reset } = useForm();

  var topicsNew = [...topics];
  topicsNew.push(userID)
  const onSubmit = (data,e) => {
    console.log(data);
    var date = Date.now();
    var message = {
      id:date+"-"+Math.round(Math.random()*1000),
      type:"text",
      text:data.text,
      topics:topicsNew,
      userIDCreate : userID,
      date : Math.round(date/1000)
    }
    e.target.reset();

    dispatch(sendVirtualEventMessage(userID, currentEvent.eventID, message))
  }


  return (
			<div className={classes.root}>
      <Grid container spacing={3}>
      {writeAvailable &&
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper style={{padding:10, backgroundColor:"#ffcdd2"}}>
              <Typography gutterBottom variant="h5">
                Escribe un mensaje
              </Typography>
              <div style={{flexDirection:"row"}}>
                  <input name="text" ref={register} />
                  <Button type="submit" >enviar </Button>
              </div>
            </Paper>
          </form>
        
        </Grid>
        }
        <Grid item xs={12}>
          {chatVirtualEventArray.map((message, i) =>
          <Paper key={i} style={{padding:5, margin:5}}>
            <Grid container spacing={3}>
              <Grid item xs={3} >
              <div style={{textAlign: 'center'}}>
                {(currentEventParticipants[message.userIDCreate] !== undefined &&
                currentEventParticipants[message.userIDCreate].startLinePhoto !== undefined)?
                <Avatar  alt="" src={currentEventParticipants[message.userIDCreate].startLinePhoto} />:
                <Avatar alt="" src={currentEvent.eventPhoto} />
              
                }
              </div>

              </Grid>
              <Grid item xs={9}>
                <h5 >{message.text}</h5>
                <span>{fechahora(message.date)}</span>
              </Grid>
            </Grid>


          </Paper>)}
        </Grid>
      </Grid>
    </div>
	  );
	}
	