import React from 'react';
import { connect } from 'react-redux'
import {auth} from '../../firebase/firebasejs';
import { Link } from 'react-router-dom';
import M from 'materialize-css'
import logosideNav from '../../assets/dashport-logos/DASHPORT_isotipo_W.png';
import Grid from '@material-ui/core/Grid';

class SesionViewView extends React.Component {

	constructor(props){
		super(props)
		
	}

	LogOut = (e) =>{
		auth.signOut().then(() => {
			// Sign-out successful.
			
			M.toast('Sesion Finalizada', 4000)
	  
			console.log("sesion cerrada");
		  }, function(error) {
			// An error happened.
			console.log("Error");
		  });
	  
	}

	render() {
		const styles = {
			color:"#FFFFFF",
			fontSize:'15px',
		}
		let element = "";
		console.log(this.props);
		if(this.props.atleteAccount == null || this.props.atleteAccount == undefined){
			element = (
				<Grid container spacing={2}>
				  <Grid item xs={12} style={styles}  onClick={this.props.handleDrawerClose}>
					  <img className="circle" height="60px" alt="logoDashportBlanco" src={logosideNav}/>
				  </Grid>
				  <Grid item xs={12} style={styles}>
					  <Link style={styles} to={"/login"} onClick={this.props.handleDrawerClose}>Iniciar Sesion</Link>
				  </Grid>
				  <Grid item xs={12} style={styles}>
					  <Link style={styles} to={"/registro"} onClick={this.props.handleDrawerClose}>Registrate</Link>
				  </Grid>
				</Grid>);
		}else{
			element = (
				<Grid container spacing={2}>
				  <Grid item xs={12} style={styles}>
					  <img className="circle" height="60px" alt="logoDashportBlanco" src={logosideNav}/>
				  </Grid>
				  <Grid item xs={12} style={styles} >
					  {this.props.atleteAccount.nombres} {this.props.atleteAccount.apellidos}
				  </Grid>
				  <Grid item xs={12} style={styles}>
					  {this.props.atleteAccount.correo}
				  </Grid>
				  <Grid item xs={12} style={styles}>
					  <a style={styles} onClick={e => this.LogOut(e)} >Cerrar Sesión</a>
				  </Grid>
				</Grid>);
		}

	return (
		<div style={{flexGrow: 1, padding:'30px', background:"#ef4123", color:'#FFFFFF'}}>
			{element}
		</div>);
	}
  }

const mapDispatchToProps = (dispatch) => {
	return {
		}
	}

const mapStateToProps = (state) => {
	console.log(state.atlete.atleteAccount);
	return {
		atleteAccount : state.atlete.atleteAccount
	}
  }
  
const SesionView = connect(
	mapStateToProps,
	mapDispatchToProps
)(SesionViewView);



export default SesionView;
