import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import FormRender from "../../../Fields/FormRender";
import {
  registryConfiguration,
  registryFormDataFunc,
} from "../../../../data-store/actions/registry-actions";
import { translateForm } from "../../../Fields/FieldUtils";

export default function Form(props) {
  const dispatch = useDispatch();
  const {
    bgColor,
    textColor,
    fontSize,
    title,
    eventoIndex,
    formId,
    paddingForm,
    buttonText,
    buttonTextReSend,
  } = props.componentConfig;

  const registryConfigurationData = useSelector(
    (state) => state.registry.registryConfiguration
  );
  const [send, setSend] = useState(false);

  useEffect(() => {
    if (eventoIndex) {
      console.log("getRegistryDATA", eventoIndex);
      dispatch(registryConfiguration(eventoIndex));
      //createEventAttribute("evento_" + eventDetail.iDEvento, route, newInput)
    }
  }, [eventoIndex]);

  // has no dependency - this will be called on-component-mount
  //trackEvent({ event: { event: "PageView", page: eventId } });

  function onSubmit(data) {
    console.log(data);

    dispatch(registryFormDataFunc(eventoIndex, formId, data));
    setSend(true);
  }
  function getProfileToShow(input, profile) {
    if (
      (input.hideInPublic === undefined || input.hideInPublic === false) &&
      profile === "Public"
    ) {
      return true;
    } else if (
      (input.hideInscriptor === undefined || input.hideInscriptor === false) &&
      profile === "inscriptor"
    ) {
      return true;
    } else if (
      (input.hideMasterCliente === undefined ||
        input.hideMasterCliente === false) &&
      profile === "MasterCliente"
    ) {
      return true;
    } else if (
      (input.hideMasterOrganizador === undefined ||
        input.hideMasterOrganizador === false) &&
      profile === "MasterOrganizador"
    ) {
      return true;
    }
  }
  function filterForm(form) {
    return form.filter((input) => {
      return getProfileToShow(input, "Public");
    });
  }

  return (
    <Grid container direction="column" style={{ backgroundColor: bgColor }}>
      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{
                color: textColor,
                textAlign: "center",
                fontSize: fontSize,
                padding: "20px",
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {registryConfigurationData &&
              registryConfigurationData.gruposFormularios &&
              registryConfigurationData.gruposFormularios[formId] &&
              !send && (
                <div
                  style={{
                    paddingLeft: paddingForm,
                    paddingRight: paddingForm,
                    paddingBottom: 20,
                  }}
                >
                  <FormRender
                    callback={onSubmit}
                    formObject={translateForm(
                      filterForm(
                        registryConfigurationData.gruposFormularios[formId]
                      )
                    )}
                    submitButtonText={buttonText || "Enviar"}
                  />
                </div>
              )}
            {registryConfigurationData &&
              registryConfigurationData.gruposFormularios &&
              registryConfigurationData.gruposFormularios[formId] &&
              send && (
                <div
                  style={{
                    paddingLeft: paddingForm,
                    paddingRight: paddingForm,
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      color: textColor,
                      textAlign: "center",
                      fontSize: fontSize,
                      padding: "20px",
                    }}
                  >
                    Formulario enviado correctamente
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setSend(false)}
                  >
                    {buttonTextReSend || "Regresar al formulario"}
                  </Button>
                </div>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

Form.displayName = "Formulario";

export const componentDescription = {
  componentConfig: {
    title: {
      label: "Titulo del formulario",
      errorMessage: "",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    buttonText: {
      label: "Texto del botón de enviar formulario",
      errorMessage: "",
      fieldName: "buttonText",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    buttonTextReSend: {
      label: "Texto del botón para regresar al formulario",
      errorMessage: "",
      fieldName: "buttonTextReSend",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    fontSize: {
      label: "Tamaño de la fuente",
      errorMessage: "",
      fieldName: "fotSize",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    eventoIndex: {
      label: "Id del evento en Dashport",
      errorMessage: "",
      fieldName: "eventoIndex",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    formId: {
      label: "Id grupo de campos en Dashport",
      errorMessage: "",
      fieldName: "formId",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    paddingForm: {
      label: "Margen del formulario",
      errorMessage: "",
      fieldName: "paddingForm",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    position: {
      label: "Posición Horizontal",
      errorMessage: "Campo Requerido",
      fieldName: "position",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "left",
          label: "Izquierda",
        },
        {
          value: "center",
          label: "Centro",
        },
        {
          value: "right",
          label: "Derecha",
        },
      ],
    },
    textColor: {
      label: "Color del texto",
      errorMessage: "Campo Requerido",
      fieldName: "textColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color del Fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
