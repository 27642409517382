import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import LoginContainerVirtualEvents from "../../../Login/LoginContainerVirtualEvents";
import ViewVirtualEvent from "./ViewVirtualEvent";

import { useForm, Controller } from "react-hook-form";
import MyVirtualEvents from "./MyVirtualEvents";
import {
  getUserOrganizedVirtualEvents,
  getUserVirtualEvents,
  resetLastEvent,
} from "../../../../data-store/actions/virtual-events-actions";
import AppContainer from "../../../../AppContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function VirtualEventsCotainer(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    if (myVirtualEvents == undefined && userID != undefined) {
      dispatch(getUserVirtualEvents(userID));
    }
  }, [myVirtualEvents, userID]);

  const classes = useStyles();

  const { control, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  if (atleteAccount == null) {
    return <LoginContainerVirtualEvents />;
  }

  return (
    <AppContainer>
      <div className={classes.root}>
        <Grid container justify="center" direction="row" spacing={3}>
          <Grid item xs={12}>
            <h3>Eventos virtuales</h3>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ViewVirtualEvent />
              <Link to={"/retos-organizador/"}>
                <Button>Ver mis retos promovidos / Crear un reto</Button>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <MyVirtualEvents />
          </Grid>
        </Grid>
      </div>
    </AppContainer>
  );
}
