import React from 'react';
import { connect } from 'react-redux'
import { Modal , Button, Input, Icon, Row } from 'react-materialize';
import AtleteDetailsModal from '../../Modals/atleteDetailsModal';
import ParticipantDetailContainer from './ParticipantDetail/ParticipantDetailContainer';
import {getCleanedString} from '../../../../services/utils';
import FormDialog from '../../Modals/FormDialog';

class BuscadorRegistryView extends React.Component {
    // icon true el modla se activa con el icono de la lupa
    // icon false con el texto buscar por numero o nombre
    constructor(props){
        super(props)
        this.state = {
            nombreNumero : 0,
        }
    }

    findRegistry = (e) =>{
    	var value = e.target.value;
        var ValorBuscado = value;
        let listaAtletas =[];
        if(this.props.eventParticipants != undefined){
        if(!isNaN(ValorBuscado)){
            console.log("numero");
            for(var i in this.props.eventParticipants){
                const value = this.props.eventParticipants[i];
                const index = i;
                if(value.numero == ValorBuscado){
                     listaAtletas.push(<li key={i}
                        className="collection-item">
                      <FormDialog 
                            buttomLabel={((value.numero == undefined)?"Folio Pendiente":value.numero)+" - "+value.nombres+" - "+value.apellidos}
                            ShowIcon={false}
                            ButtonStyle={null}
                            maxWidthProp={'lg'}
                            style={{
                                minHeight: '80vh',
                                maxHeight: '80vh',
                              }}
                            report={<ParticipantDetailContainer 
                                ParticipantDetail={value}
                                ParticipantDetailKey={index}/>}
                            />
                      </li>);
                }
            }
            return this.setState({listaAtletas:listaAtletas});
          }else{
            console.log("texto");
            if(ValorBuscado.length>2){
              ValorBuscado= getCleanedString(ValorBuscado);
              var nombreBuscado = ValorBuscado.toUpperCase();
                for(var i in this.props.eventParticipants){
                    const value = this.props.eventParticipants[i];
                    const index = i;
                    console.log(value.nombres);
                    if(value.nombres != undefined){
                        const nombreComparar = value.nombres.toUpperCase() +" "+ value.apellidos.toUpperCase();
                        if(nombreComparar.match(nombreBuscado)!=null){
                            listaAtletas.push(<li key={i}
                                                    className="collection-item">
                                                <FormDialog 
                                                        buttomLabel={((value.numero == undefined)?"Folio Pendiente":value.numero)+" - "+nombreComparar}
                                                        ShowIcon={false}
                                                        ButtonStyle={null}
                                                        maxWidthProp={'lg'}
                                                        style={{
                                                            minHeight: '80vh',
                                                            maxHeight: '80vh',
                                                        }}
                                                        report={<ParticipantDetailContainer 
                                                            ParticipantDetail={value}
                                                            ParticipantDetailKey={index}/>}
                                                        />
                                                </li>);
                        }
                    }
                }
            }
            return this.setState({listaAtletas:listaAtletas});
          }
        }
    }


    render(){
    let buttom;
    if(this.props.icon){
        buttom = (<li><i className="material-icons md-40 marginIcons">search</i></li>);
    }else{
        buttom = (<li><a href="#" className="red-text">Buscar por Nombre o Número</a></li>);
    }
        return (
                <div>
                    <h5>Buscador por Nombre o Número</h5>
                    <Row>
                        <Input name="nombreNumero" onChange={e => this.findRegistry(e)} type="text" label="Nombre o Número" validate s={12} />
                    </Row>
                    <ul id="ListaNumeroNombre" className="collection">
                        {this.state.listaAtletas}
                    </ul>
                </div>)

    }
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

const mapStateToProps = (state) => {
	return {		
		eventDetail: state.events.eventDetail,
		eventParticipants : state.registry.eventParticipants,
	}
  }
  
const BuscadorRegistry = connect(
	mapStateToProps,
	mapDispatchToProps
)(BuscadorRegistryView);

export default BuscadorRegistry;