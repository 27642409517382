import React from 'react';

const DashboardExtraProducts = ({jsonProducts}) => {

  var Adicionales = [];
  var data = {    
    labels: [],
    series: []
};
  for (var e in jsonProducts){
    console.log("resumen ad");
    
    var nombreProducto = jsonProducts[e].nombreProducto;
    var objeto = jsonProducts[e].formularioProducto;
    var suma = jsonProducts[e].suma;
    console.log("resumen suma" + suma);
    console.log("resumen ad" + nombreProducto);
    console.log("resumen ad" + JSON.stringify(objeto));

      Adicionales.push(<div key={e} className="row">
                        <h5>
                          {nombreProducto}
                        </h5>
                        <div className="col s4">
                          <span>Total ventas</span>
                          <br/>
                          {jsonProducts[e].sumaCompras}
                        </div>
                        <div className="col s4">
                          <span>Total cantidad vendidas</span>
                          <br/>
                          {jsonProducts[e].sumaCantidades}
                        </div>
                        <div className="col s4">
                          <span>Total ingreso</span>
                          <br/>
                          {jsonProducts[e].sumaIngresos}
                        </div>
                      </div>);

  }

    return (			
        <div className="row z-depth-2 padding">
          <div className="col s12">
            {Adicionales}
          </div>
        </div>);
}

export default DashboardExtraProducts;
