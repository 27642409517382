import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Paper } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventIcon from "@material-ui/icons/Event";
//import UserPaymentMethodsAdd from "../UserProfile/UserPaymentMethodsAdd";
import CardPaymentList from "./CardPaymentList";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { showSnackbar } from "../../../../../data-store/actions/organizers-actions";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "black",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function EmailConfirm(props) {
  const { callbackConfirm, currentOrder } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [sending, setSending] = useState(false);
  // const [cardNumber, setCardNumber] = useState(null);
  // const [cardOwner, setCardOwner] = useState(null);

  function onSubmit(data) {
    // setCardNumber(data.cardNumber);
    // setCardOwner(data.cardOwner);
    setSending(true);
    console.log("data", data);
    // console.log("data", cardNumber);
    // console.log("data", sending);
  }

  return (
    <Grid
      container
      direction="row"
      style={{
        height: "auto",
        width: "100%",
      }}
    >
      <Grid
        item
        xs={12}
        sm={12}
        style={{ padding: "10px", textAlign: "center" }}
      ></Grid>
      <Grid
        item
        xs={12}
        sm={12}
        alignItems={"center"}
        style={{ margin: "20px" }}
      >
        <Typography
          color="secondary"
          variant="h5"
          style={{ padding: 10, textAlign: "center" }}
        >
          Revisa y confirma el correo antes de continuar
        </Typography>
        <Typography
          color="primary"
          variant="h4"
          style={{ padding: 10, textAlign: "center" }}
        >
          {currentOrder.correo || ""}
        </Typography>

        <Button
          onClick={() => callbackConfirm(true)}
          type="submit"
          style={{ width: "100%", height: 60, padding: 10 }}
          color="primary"
          variant="contained"
        >
          Confirmar mi correo
        </Button>
        {/* <Button
          onClick={() => callbackConfirm(true)}
          type="submit"
          style={{ width: "100%", height: 60, padding: 10 }}
          color="primary"
        >
          Corregir correo
        </Button> */}
      </Grid>
    </Grid>
  );
}
