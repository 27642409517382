import React from 'react';
import CardProduct from '../ShopingCar/CardProduct'; 

class ListAditionalProduct extends React.Component {
  constructor(props){
    super(props);
  }


  getValue = (valor, inputObject) => {
    //this.props.update(valor, inputObject);
    this.setState({[inputObject.iDCampo]: valor});
    }

  render(){
    let aditionalProducts = [];
    if(this.props.modalidad != undefined && this.props.categoria != undefined &&
      this.props.modalidad > 0 && this.props.categoria > 0){

      var aditionaProducsArray = this.props.eventSubEvents["modalidad_"+this.props.modalidad]
        .configModalidad.productosAdicionales;
 
      for(var i in aditionaProducsArray){
        aditionalProducts.push(
          <CardProduct key={i} 
            producto={aditionaProducsArray[i]} 
            updateOrder={this.props.updateOrder} 
            registrytype={this.props.registrytype}
            monedaAUsar={this.props.monedaAUsar}
            callbackchange={this.getValue}
            />
        );
  
      }
    }

    return (<div className="row">
              {aditionalProducts}
            </div>
    );
  }
}

export default ListAditionalProduct;
