import React from "react";
import { RegistryConfirmationData } from "./RegistryConfirmationData";
import QRCode from "qrcode.react";

import { calcularEdad } from "../../../../../services/utils";
import OxxoPaymentCode from "./OxxoPaymentCode";
import CardPaymentPromissoryNote from "./CardPaymentPromissoryNote";
import DetailOrderConfirmation from "./DetailOrderConfirmation";
import DisclaimerResponsabilitiesHTML from "../DisclaimerResponsabilities/DisclaimerResponsabilitiesHTML";
import jsPDF from "jspdf";
import PhotoEditorDefault from "../../../Athletes/VirtualEvents/PhotoEditorVirtual/PhotoEditorDefault";
import { Typography } from "@material-ui/core";

class RegistryConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tipo: this.props.tipo,
      update: true,
    };
  }

  // { registryParticipantDataConfirm,
  //   registryParticipantKeyConfirm,
  //   eventDetail,
  //   eventSubEvents,
  //   registryConfiguration,
  //   tipo,
  //   reference,
  //   codigoSuscripcion }) => {

  detallePagoTarjetaFuncImpresoHTML = (jsonMail) => {
    return `
      <style>
      .fuente{
        font-size:11px;
        font-family:arial;
        margin-top:1px;
        padding-top:1px;
        }
        .title{
          font-size:13;
          font-weight: bold;
        }
      </style>
      <div> </div>     
        <div class="fuente">De igual manera declaro que realice el pago de la inscripción via web con la tarjeta
        <b>${jsonMail.datos.statusPago.brand}</b> registrada con el nombre de usuario 
        <b>${jsonMail.datos.statusPago.cardName}</b> cuyos últimos 4 digitos 
        son <b>${jsonMail.datos.statusPago.last4}</b> por un monto de <b> 
        ${jsonMail.datos.statusPago.amount} MXN</b> a nombre de Dashport</div>
  
        <div class="fuente">Nota: La siguiente seccion debe ser llenada por el dueño de la tarjeta bancaria 
        utilizada para realiar el pago.</div>
  
      <div class="title">Pagaré de tarjeta de Crédito / Débito negociable únicamente con instituciones de 
      crédto.</div>
      <div class="fuente">Por este pagaré prometo y me obligo incondicionalemente a pagar a la orden de la 
      institucion emisora de la tarjeta relacionada abajo, en sus oficinas, la cantidad de <b> 
      ${jsonMail.datos.statusPago.amount} MXN</b> el cual suscribo al amparo del contrato  que 
      tengo celebrado con dicha institucion para el uso de esta tarjeta.</div>
      
      <div class="fuente">Reconozco y acepto que el presente es comprobante de la operacion señalada abajo, el 
      cual tiene pleno valor probatorio y fuerza legal en virtud de que firme y/o digite mi 
      firma electrónica, la cual es de mi exclusiva responsabilidad por lo que manifiesto 
      plena conformidad respecto al cargo efectuado a la cuenta de la que se deriva esta 
      tarjeta. El presente pagaré es negociable unicamente con instituciones bancarias a 
      excepción que éste sea suscrito por tarjetahabientes de American Express.</div>
      
      <div class="fuente">Fecha y Hora de la transacción: <b>${jsonMail.datos.statusPago.fechaPago}</b></div>
      
      <div class="fuente">Importe :  <b>${jsonMail.datos.statusPago.amount} MXN</b></div>
      <div class="fuente"> Firma: __________________________________ </div>
      
      <div class="fuente">Adicional a la identificación del atleta debe adjuntar:</div>
      <ul class="fuente">
        <li>1) Copia de la identificación oficial del dueño de la tarjeta por ambas caras.</li>
        <li>2) Copia de la tarjeta por su cara delantera tapando todos los digits salvo los ultimos 4.</li>
        <li>3) Copia de la tarjeta por su cara trasera tapando los digitos del código de seguridad.</li>
      </ul>
  
      `;
  };
  deslindeResponsabilidadesMas18ImpresoHTML = (
    nombreEvento,
    linkAvisoPrivacidad,
    nombresYApellidos,
    numero
  ) => {
    return `
    <style>
    .fuente{
      font-size:11px;
      font-family:"arial";
      margin-top:1px;
      padding-top:1px;
    }
    </style>     

    <div class="fuente">Es indispensable presentar este comprobante impreso y una copia de 
    tu Credencial de Elector para poder recoger tu Kit de participante. El aviso de 
    privacidad se puede consultar en <b>${linkAvisoPrivacidad}</b></div>

    <div class="fuente">Al realizar mi inscripción en este evento declaro que me encuentro 
    en plena capacidad física para participar en el mismo y deslindo de cualquier 
    responsabilidad a las empresas organizadoras y patrocinadoras así como a sus 
    representantes, de cualquier lesión o accidente como consecuencia de mi participación 
    en el evento.</div>

    <div class="fuente">EXONERACIÓN: Yo, participante inscrito al evento denominado 
    <b>${nombreEvento}</b>, por el sólo hecho de firmar este documento, acepto cualquier 
    y todos los riesgos y peligros que sobre mi persona recaigan en cuanto a mi participación 
    en el evento antes referido en adelante el "Evento". Por lo tanto, yo soy el único 
    responsable de mi condición física, preparación, capacidad, mi salud, cualquier 
    consecuencia, accidentes, perjuicios, deficiencias que puedan causar, de cualquier 
    manera posible alteraciones a mi salud, integridad física, o inclusive la muerte, 
    así como soy y seré responsable de los gastos totales que se generen por causa de algún 
    accidente en competencia a mi persona. Por esa razón libero de cualquier responsabilidad 
    al respecto a las instalaciones del lugar, los dueños del lugar donde se realiza la 
    competencia, a las personas, clubes, grupos, a las Asociaciones que la integran y/o a 
    la Empresa / Comité Organizador, sus directores, patrocinadores, accionistas, 
    representantes, directores financieros y consejeros de las compañías, y por medio 
    de este conducto renuncio, sin limitación alguna a cualquier derecho, demanda o 
    indemnización al respecto. También reconozco y acepto que todas las personas y 
    entidades referidas en el último párrafo no son ni serán consideradas responsables 
    por la protección y custodia de mis pertenencias personales; Además, no serán 
    responsables por cualquier desperfecto, pérdida o robo relacionados con mis 
    pertenencias personales. Autorizó al Comité organizador y/o a quien ésta designe 
    el uso de mi imagen y voz, ya sea parcial o totalmente, en cuanto a todo lo 
    relacionado en el Evento, de cualquier manera y en cualquier momento. Por este 
    conducto reconozco que sé y entiendo todas las regulaciones del Evento, incluyendo y 
    sin limitarse en lo general, así como en lo particular al reglamento interior de 
    competencia. Igualmente, manifiesto bajo protesta de decir verdad que mi equipo de 
    competencia reúne y cumple con todos los requisitos reglamentarios aplicables, sin 
    perjuicio de la facultad que se tenga para revisar dicho equipo y los demás 
    establecidos en la mencionada normatividad.</div>
  
    <div class="fuente">Tipo de ID ______________________  Número:<b>${numero}</b></div>
    <div class="fuente">_______________________________________________________ </div>
    <div class="fuente">  Firma <b>${nombresYApellidos}</b></div>`;
  };
  deslindeResponsabilidadesMenos18ImpresoHTML = (
    nombreEvento,
    linkAvisoPrivacidad,
    nombresYApellidos,
    numero
  ) => {
    return `    <style>
    .fuente{
      font-size:11px;
      font-family:"arial";
      margin-top:1px;
      padding-top:1px;
    }
    .center{
      text-align:center;
    }
    </style>     
    <div class="fuente">Es indispensable presentar este comprobante impreso y una copia de 
    tu Credencial de Elector para poder recoger tu Kit de participante. El aviso de 
    privacidad se puede consultar en ${linkAvisoPrivacidad}</div>

    <div class="fuente">Al realizar mi inscripción en este evento declaro que me encuentro en plena 
    capacidad física para participar en el mismo y deslindo de cualquier responsabilidad 
    a las empresas organizadoras y patrocinadoras así como a sus representantes, de 
    cualquier lesión o accidente como consecuencia de mi participación en el evento.</div>
    
    <div class="fuente">CARTA RESPONSIVA PARA MENORES DE 18 AÑOS: Yo, el abajo firmante, 
    como padre, madre o tutor del menor que estamos inscribiendo al evento 
    <b>${nombreEvento}</b>, declaro por esta misiva, por el sólo hecho de firmar 
    este documento, acepto cualquier y todos los riesgos y peligros que sobre el/la 
    niño/a recaigan en cuanto a su participación en el evento antes referido en adelante 
    el "Evento". Por lo tanto, yo soy el único responsable de conocer su estado de salud, 
    su condición física, preparación, capacidad, cualquier consecuencia, accidentes, 
    perjuicios, deficiencias que puedan causar, de cualquier manera posible alteraciones 
    a su salud, integridad física, o inclusive la muerte, así como soy y seré responsable 
    los gastos totales que se generen por causa de algún accidente en competencia. Yo, el 
    abajo firmante, seré siempre responsable de los accidentes que llegara a sufrir el niño 
    o niña competidor inscrito, ya que declaro que estoy en pleno conocimiento de los 
    riesgos y peligros inherentes en las áreas del evento o participando en este, por lo 
    que por esa razón libero de cualquier responsabilidad al respecto a las instalaciones 
    del lugar, los dueños del lugar donde se realiza la competencia, a las personas, clubes, 
    grupos, a las Asociaciones que la integran y/o a la Empresa / Comité Organizador, sus 
    directores, patrocinadores, accionistas, representantes, directores financieros y 
    consejeros de las compañías, y por medio de este conducto renuncio, sin limitación 
    alguna a cualquier derecho, demanda o indemnización al respecto. Además, no serán 
    responsables por cualquier desperfecto, pérdida o robo relacionados con las pertenencias 
    personales del niño o niña. Así mismo, autorizó al Comité organizador y/o a quien 
    ésta designe el uso de la imagen y voz del niño/a, ya sea parcial o totalmente, en 
    cuanto a todo lo relacionado en el Evento, de cualquier manera y en cualquier momento. 
    Por este conducto reconozco que sé y entiendo todas las regulaciones del Evento, 
    incluyendo y sin limitarse en lo general, así como en lo particular al reglamento 
    interior de competencia. Igualmente, manifiesto bajo protesta de decir verdad que 
    el equipo de competencia reúne y cumple con todos los requisitos reglamentarios 
    aplicables, sin perjuicio de la facultad que se tenga para revisar dicho equipo y 
    los demás establecidos en la mencionada normatividad.</div>
    
    <div class="fuente">Tipo de ID ______________________  Número: <b>${numero}</b></div>
    <div class="fuente">Atleta representado: <b>${nombresYApellidos}</b></div>
  
    <div class="fuente">_________________________________________________________</div>
    <div class="fuente">Nombre Completo del Padre o Tutor (escrito a mano)</div>

    <div class="fuente">_________________________________________________________</div>
    <div class="fuente">Firma</>
    <div class="fuente">EN TESTIMONIO DE FE, COMO RESPONSABLES DEL COMPETIDOR INSCRITO, 
    NOSOTROS COMO PADRES O TUTORES, DECLARAMOS HABER ENTENDIDO LO ANTERIORMENTE
     DESCRITO Y AÑADIMOS LA FIRMA EN FORMA VOLUNTARIA.</div>`;
  };
  render() {
    var jsonMail = {};
    jsonMail.inscritoKey = this.props.registryParticipantKeyConfirm;
    jsonMail.eventoIndex = "evento_" + this.props.eventDetail.iDEvento;

    jsonMail.detalleEvento = this.props.eventDetail;
    jsonMail.datos = this.props.registryParticipantDataConfirm;
    jsonMail.modalidades = this.props.eventSubEvents;
    console.log(this.props.eventSubEvents);
    jsonMail.inscripcionesConfiguracion = this.props.registryConfiguration;

    var color = "#000000";
    if (jsonMail.detalleEvento.organizador.colorOrganizador != undefined) {
      color = jsonMail.detalleEvento.organizador.colorOrganizador;
    }
    var numero = 0;
    if (jsonMail.datos.numero != undefined) {
      numero = jsonMail.datos.numero;
    }
    var distancia = 0;
    var modIndex = "modalidad_" + jsonMail.datos.modalidad;
    if (jsonMail.datos.modalidad != undefined) {
      distancia = jsonMail.modalidades[modIndex].descripcionModalidad;
    }
    var rama = 0;
    var ramaIndex;
    if (jsonMail.datos.rama != undefined) {
      ramaIndex = "rama_" + jsonMail.datos.rama;
      rama = jsonMail.datos.rama;
    }
    var categoriaJson;
    var categoria;
    var categoriaIndex;
    if (jsonMail.datos.categoria != undefined) {
      categoriaIndex = "categoria_" + jsonMail.datos.categoria;
      categoriaJson =
        jsonMail.modalidades[modIndex].ramaCat[ramaIndex].categorias[
          categoriaIndex
        ];
      categoria = categoriaJson.nombreCategoria;
    }

    // tipo 0 = es que no se muestra la confirmacion todavia
    // tipo 1 = solo confirmacion aplica para pago en efectivo y tarjeta de credito aprobada
    // tipo 2 = registro y ficha de pago
    // tipo 3 = confirmacion de inscripcion y pago recibido en oxxo

    var idioma = "es";

    var jsonTextos = RegistryConfirmationData;

    var edad = calcularEdad(
      jsonMail.datos.fechaNacimiento,
      jsonMail.detalleEvento.fechaEvento,
      true
    );
    var deslindeFinal;
    if (edad < 18) {
      deslindeFinal = this.deslindeResponsabilidadesMenos18ImpresoHTML(
        jsonMail.detalleEvento.nombreEvento,
        jsonMail.detalleEvento.organizador.linkAvisoPrivacidad,
        jsonMail.datos.nombres + " " + jsonMail.datos.apellidos,
        numero
      );
    } else {
      deslindeFinal = this.deslindeResponsabilidadesMas18ImpresoHTML(
        jsonMail.detalleEvento.nombreEvento,
        jsonMail.detalleEvento.organizador.linkAvisoPrivacidad,
        jsonMail.datos.nombres + " " + jsonMail.datos.apellidos,
        numero
      );
    }

    var detallePagoTarjeta;

    if (jsonMail.datos.statusPago.last4 != undefined) {
      detallePagoTarjeta = <CardPaymentPromissoryNote jsonMail={jsonMail} />;
    }

    var fichaDePago;
    if (this.state.tipo == 2) {
      fichaDePago = (
        <OxxoPaymentCode
          jsonMail={jsonMail}
          jsonTextos={jsonTextos}
          idioma={idioma}
          reference={this.props.oxxoReference}
          tipo={this.state.tipo}
        />
      );
    }
    var detalleOrden = (
      <DetailOrderConfirmation
        jsonMail={jsonMail}
        jsonTextos={jsonTextos}
        idioma={idioma}
        reference={this.props.oxxoReference}
        tipo={this.state.tipo}
      />
    );

    var nombresYApellidos = "";
    if (this.state.tipo == 4) {
      nombresYApellidos = this.props.codigoSuscripcion;
    } else {
      nombresYApellidos =
        jsonMail.datos.nombres + " " + jsonMail.datos.apellidos;
    }

    if (this.state.tipo == 0) {
      return <h5>Confirmación pendiente</h5>;
    }

    if (this.props.download) {
      setTimeout(() => {
        try {
          var doc = new jsPDF("p", "mm", "letter");
          var margin = 20;

          // new QRCode(
          //   document.getElementById("qrcode"),
          //   jsonMail.inscripcionesConfiguracion.configuracion
          //     .rutaDetalleInscrito +
          //     jsonMail.eventoIndex +
          //     "/" +
          //     jsonMail.inscritoKey
          // );
          // var c = document.getElementById("canvasID");
          // var qrfinal = c.toDataURL();
          // doc.addImage(qrfinal, "JPEG", 140, 20, 40, 40);
          function pdfTexto(left, top, titulo, texto) {
            doc.setFont("times");
            doc.setFontType("normal");
            doc.setFontSize(8);
            doc.text(left, top, titulo.toString());
            doc.setFont("times");
            doc.setFontType("normal");
            doc.setFontSize(10);
            var text = doc
              .setFont("times")
              .setFontSize(12)
              .setFontType("normal")
              .splitTextToSize(texto.toString(), 80);
            doc.text(left, top + 5, text);
          }
          var extraMargin = 1.2;

          const qrCodeCanvas = document.querySelector("canvas");
          const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);

          // Then you can do whatever you want with this img
          doc.addImage(qrCodeDataUri, "jpg", 140, 20, 45, 45);

          pdfTexto(
            margin * extraMargin,
            25,
            "Nombres y Apellidos",
            jsonMail.datos.nombres + " " + jsonMail.datos.apellidos
          );
          pdfTexto(
            margin * 2 * extraMargin,
            35,
            "",
            jsonTextos[idioma].tituloDatos
          );
          pdfTexto(margin * extraMargin, 45, jsonTextos[idioma].Numero, numero);
          pdfTexto(
            margin * 2.5 * extraMargin,
            45,
            jsonTextos[idioma].Distancia,
            distancia
          );
          pdfTexto(margin * extraMargin, 60, jsonTextos[idioma].Rama, rama);
          pdfTexto(
            margin * 2.5 * extraMargin,
            60,
            jsonTextos[idioma].Categoria,
            categoria
          );
          doc.fromHTML(deslindeFinal, 20, 70, { width: 180 });
          doc.fromHTML(detallePagoTarjeta, 20, 170, { width: 180 });
          var download = document.getElementById("download");
          doc.save(
            `Deslinde de responsabildiades - ${jsonMail.detalleEvento.nombreEvento} -  ${jsonMail.datos.nombres} ${jsonMail.datos.apellidos}.pdf`
          );
        } catch (err) {
          console.log(err);
        }
      }, 1000);
    }

    return (
      <div>
        <div className="row">
          <div className="col s12 center-align">
            {jsonTextos[idioma].preNombre[this.state.tipo]}
          </div>
          <div className="col s12">
            <h4 className="center-align">{nombresYApellidos}</h4>
          </div>
          <div className="col s12 center-align">
            {jsonTextos[idioma].postNombre[this.state.tipo]}
          </div>
        </div>
        <div className="row">
          <div className="col s12">
            <h3 className="center-align">
              {jsonMail.detalleEvento.nombreEvento}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col s6 offset-s3">
            <img
              alt="logo Evento"
              title="logo Evento"
              src={jsonMail.detalleEvento.logoEvento}
              style={{
                border: "none",
                borderRadius: "0px",
                display: "block",
                fontSize: "13px",
                outline: "none",
                textDecoration: "none",
                width: "100%",
              }}
              width={212}
            />
          </div>
        </div>
        <hr />
        {jsonMail.inscripcionesConfiguracion.configuracion.dorsal &&
          numero > 0 && (
            <div className="row">
              <div className="col s12">
                <h2 className="center-align">Dorsal digital</h2>
              </div>
              <div className="center-align col s12 m12">
                <PhotoEditorDefault
                  size="80%"
                  participant={{
                    raceNumber: numero,
                    athleteName: jsonMail.datos.nombres,
                    athleteLastName: jsonMail.datos.apellidos,
                  }}
                  currentEvent={{
                    distance: 10,
                    raceNumberFontColor:
                      jsonMail.inscripcionesConfiguracion.configuracion.dorsal
                        .raceNumberFontColor,
                    raceNumberWhiteBack:
                      jsonMail.inscripcionesConfiguracion.configuracion.dorsal
                        .raceNumberWhiteBack,
                  }}
                  urlPhoto={
                    jsonMail.inscripcionesConfiguracion.configuracion.dorsal
                      .raceNumberImage
                  }
                  type={"raceNumber"}
                />
              </div>
            </div>
          )}

        <div className="row">
          <div className="col s12">
            <h5 className="center-align">
              {jsonTextos[idioma].LinkYQRSeguimiento}
            </h5>
          </div>
          <div className="col s12 m12 center-align">
            <QRCode
              value={
                jsonMail.inscripcionesConfiguracion.configuracion
                  .rutaDetalleInscrito +
                "?event=" +
                jsonMail.eventoIndex +
                "&registry=" +
                jsonMail.inscritoKey +
                "&download=true"
              }
            />
          </div>
          <div className="col s12 m12 center-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"h5"}
              component="h2"
            >
              <a
                target="_blank"
                href={`${jsonMail.inscripcionesConfiguracion.configuracion.rutaDetalleInscrito}?event=${jsonMail.eventoIndex}&registry=${jsonMail.inscritoKey}&download=true`}
              >
                {jsonMail.inscripcionesConfiguracion.configuracion
                  .rutaDetalleInscrito +
                  "?event=" +
                  jsonMail.eventoIndex +
                  "&registry=" +
                  jsonMail.inscritoKey +
                  "&download=true"}
              </a>
            </Typography>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col s6 offset-s3"></div>
        </div>
        <div className="row">
          <div className="col s12">
            <h5 className="center-align">{jsonTextos[idioma].tituloDatos}</h5>
          </div>
          <div className="col s3 center-align">
            <b className="center-align">{jsonTextos[idioma].Numero}</b>
            <br />
            {numero}
          </div>
          <div className="col s3  center-align">
            <b className="center-align">{jsonTextos[idioma].Distancia}</b>
            <br />
            {distancia}
          </div>
          <div className="col s3  center-align">
            <b className="center-align">{jsonTextos[idioma].Rama}</b>
            <br />
            {rama}
          </div>
          <div className="col s3 center-align">
            <b>{jsonTextos[idioma].Categoria}</b>
            <br />
            {categoria}
          </div>
        </div>
        {detalleOrden}
        {fichaDePago}
        <footer />
      </div>
    );
  }
}

export default RegistryConfirmation;
