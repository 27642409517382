import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import GarminHealtAPIRecentActivities from '../../../../GarminApi/GarminImportActivities/GarminHealtAPIRecentActivities';
import Login from '../../../../../../Login/Login'
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import LogoGarmin from '../../../../GarminApi/GarminAssets/Garmin_Connect_app_1024x1024-02.png';


import Typography from '@material-ui/core/Typography';

import { useForm, Controller } from 'react-hook-form';
import { getUserVirtualEvents } from '../../../../../../../data-store/actions/virtual-events-actions'

import MyEvidencePictures from './MyEvidencePictures'
import MyEvidenceTime from './MyEvidenceTime';
import MyTime from './MyTime';

const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  button: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height : 100
  },
}));

export default function VirtualEventMyEvidence(props){
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const myVirtualEvents = useSelector(state => state.virtualEvents.myVirtualEvents);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  const classes = useStyles();
  const [evidenceView, setEvidenceView] = useState("Photo");
  
 
  const { control, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    console.log(data);
  }

	if(atleteAccount == null){
		return <Login/>
  }
  
  if(myVirtualEvents == undefined || currentEvent == undefined){
    return <CircularProgress/>
  }
  if(currentEvent == {}){
    return <h1>Usuario no autorizado para este evento</h1>
  }

  let evidenceViewFinal;
  if(evidenceView == "Garmin"){
    evidenceViewFinal = <GarminHealtAPIRecentActivities/>
  }else if(evidenceView == "Photo"){
    evidenceViewFinal = <MyEvidencePictures/>
  }else if(evidenceView == "Strava"){
    evidenceViewFinal = <div></div>
  }

  if(!currentEvent.admitResults){
    return (<div className={classes.root}>
    <Grid container spacing={3} 
      alignItems="center"
      alignContent="center"
      direction="column">
      <Grid item xs={12}>
        <Paper className={classes.paper}>
            {currentEvent.eventEnded && 
            <div>
              <Typography gutterBottom variant="h2" component="h2">
                Evento finalizado
              </Typography>
              <Typography gutterBottom variant="h5" component="h2">
                Si realizaste el evento y no registraste el tiempo puedes contactar al organizador para recibir acceso
              </Typography>
            </div>
          }
            {!currentEvent.eventEnded && 
              <Typography gutterBottom variant="h2" component="h2">
                Evento deshabilitado para registrar tiempos
              </Typography>
            }
        </Paper>
      </Grid>
    </Grid>
  </div>)

  }
	  return (
			<div className={classes.root}>
      <Grid container spacing={3} 
        alignItems="center"
        alignContent="center"
        direction="column">
        <Grid item xs={12}>
          <MyTime/>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="h2">
              Selecciona un método para evidenciar tu reto.
          </Typography>
      </Grid>
      <Grid item xs={12}>
        <ButtonGroup size="large" style={{height:70}} color="primary" aria-label="large outlined primary button group">
          <Button onClick={()=> setEvidenceView("Photo")} style={{height:70}}>
            <CameraAltIcon style={{fontSize:40}}/>
            Fotografía
          </Button>
          <Button onClick={()=> setEvidenceView("Garmin")} style={{height:70}}>
            <img src={LogoGarmin} style={{padding:10, height:60, width:60}}/>
            Garmin
          </Button>
        </ButtonGroup>
      </Grid>

        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
          {evidenceViewFinal}
          </Paper>
        </Grid>
      </Grid>
    </div>
	  );
	}
	