import React, { useState }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { requestActivitiesGarmin } from '../../../../../data-store/actions/atlete-actions'
import Avatar from '@material-ui/core/Avatar';
import LogoGarmin from '../GarminAssets/Garmin_Connect_app_1024x1024-02.png'
import LogoDashport from '../../../../../assets/dashport-logos/DASHPORT_03.png'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import GarminRequestTokenButton from '../GarminRequestApi/GarminRequestTokenButton';
import GarminActivitiesCard from './GarminActivitiesCard';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function GarminHealtAPIRecentActivities(props){
  const classes = useStyles();
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const activitiesGarminLoading = useSelector(state => state.atlete.activitiesGarminLoading);

  const userID = useSelector(state => state.atlete.userID);



  if(atleteAccount.garmin == undefined || atleteAccount.garmin["health"] === undefined ||atleteAccount.garmin && atleteAccount.garmin["health"] === null){
    return (
      <Grid container justify="center" style={{textAlign:"center"}} spacing={3}>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          <h5>Conecta tu reloj Garmin antes de iniciar tu reto</h5>
        </Grid>
        <Grid item xs={12} >
          <GarminRequestTokenButton api="health"/>;
        </Grid>
      </Grid>
    )
  }


  return (
      <Grid container justify="center" style={{textAlign:"center"}} spacing={3}>
        <Grid item xs={4} >
          <Avatar variant="square" src={LogoGarmin} style={{height:80, width:80}}/>
        </Grid>
        <Grid item xs={4} >
          <ArrowForwardIcon color="primary" />
        </Grid>
        <Grid item xs={4} >
          <Avatar variant="square" src={LogoDashport} style={{height:80, width:80}}/>
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          <Typography gutterBottom variant="h5" >
            Selecciona la actividad que vas a aplicar para el reto
          </Typography>
        </Grid>
        <Grid item xs={12}  style={{textAlign:"center"}}>
        {activitiesGarminLoading && <CircularProgress/>}
          <GarminActivitiesCard/>
        </Grid>
      </Grid>
	  );
	}
	