import * as types from "./action-types";
import * as fb from "../../firebase/firebasejs";
import axios from "axios";
import { getPhotoFileToUpload } from "../../services/utils";
import { getUserVirtualEvents } from "./virtual-events-actions";

import { sumarSegAFecha } from "../../services/utils";
// #########################################################################################
export function atletePhotosFunc(eventoIndex, fechaHoraMeta, puntoFotografico) {
  let ref = fb.db.ref(
    `${fb.EventosCompletos}${eventoIndex}/${fb.fotosEventoRuta}${fb.fotosRuta}${fb.puntoFotograficoPrevRuta}${puntoFotografico}`
  );

  let segundoAntesdesDespues = 200;

  return (dispatch) => {
    ref
      .orderByChild("horaAjustada")
      .startAt(sumarSegAFecha(fechaHoraMeta, -segundoAntesdesDespues))
      .endAt(sumarSegAFecha(fechaHoraMeta, segundoAntesdesDespues))
      .once("value", (snapshot) => {
        console.log(snapshot.val());
        dispatch({
          type: types.ATLETE_PHOTOS,
          atletePhotos: snapshot.val(),
        });
      });
  };
}
//###########################################################################
export function uploadUserPhoto(userID, file, photoType) {
  return (dispatch) => {
    console.log("uploadPhoto");
    return getPhotoFileToUpload(file, 800, photoType).then((image) => {
      if (image) {
        let fotoSinJPG = file.name.substring(0, file.name.length - 4);
        let nombreRandom =
          fotoSinJPG + "_" + Math.floor(Math.random() * 100000 + 1) + ".JPG";

        let storageRef = fb.storage.ref();
        let uploadTask = storageRef
          .child("users/" + userID + "/profilePhotos/" + nombreRandom)
          .put(image);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: file.name,
              uploadingPhotosProgress: progress,
            });

            console.log("Upload is " + progress + "% done");
          },
          function (error) {
            // Handle unsuccessful uploads
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: null,
              uploadingPhotosProgress: null,
            });
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                dispatch({
                  type: types.UPLOADING_PHOTOS,
                  uploadingPhotos: null,
                  uploadingPhotosProgress: null,
                });
                dispatch(saveUploadedUserPhoto(userID, downloadURL, photoType));
                console.log("File available at", downloadURL);
              });
          }
        );
      }
    });
  };
}
//###########################################################################
export function saveUploadedUserPhoto(userID, url, photoType) {
  return (dispatch) => {
    let photoObject = {
      photoURL: url,
    };
    return fb.firestore
      .collection(fb.users)
      .doc(userID)
      .set(photoObject, { merge: true })
      .then(() => {
        console.log("Registro actualizado en la vista de participantes!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###########################################################################
export function updateUserAtribute(userID, objectChage) {
  return (dispatch) => {
    return fb.firestore
      .collection(fb.users)
      .doc(userID)
      .set(objectChage, { merge: true })
      .then(() => {
        console.log("Registro actualizado en la vista de participantes!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}

// #########################################################################################

// #########################################################################################

export function atleteAccount() {
  console.log("Monitorear cuenta");
  return (dispatch) => {
    if (window.$userGlobalData != undefined) {
      console.log("$userGlobalData");
      window.$userGlobalData();
    }

    //let atleteData = null;
    fb.auth.onAuthStateChanged((user) => {
      if (user) {
        let user = fb.auth.currentUser;
        let uid;
        if (user != null) {
          //let name = user.displayName;
          //let email = user.email;
          //let photoUrl = user.photoURL;
          uid = user.uid;
          //let IDCompetidor = uid; // The user's ID, unique to the Firebase project. Do NOT use
          // this value to authenticate with your backend server, if
          // you have one. Use User.getToken() instead.
        }
        dispatch(resetUserAccount());
        dispatch(getUserVirtualEvents(uid));
        window.$userGlobalData = fb.firestore.collection("users").doc(uid);

        return window.$userGlobalData.onSnapshot((user) => {
          if (user.exists) {
            let userData = user.data();
            //getTokenPush(uid, userData.tokenPush)
            console.log(userData);
            if (userData.photoURL === undefined) {
              if (userData.genero === "F") {
                userData.photoURL =
                  "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/Avatares%2FavatarWoman.jpeg?alt=media&token=e79bd135-1392-4847-a7dc-12a389214af6";
              } else {
                userData.photoURL =
                  "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/Avatares%2FmenAvatar.jpg?alt=media&token=08ef7521-1996-4b91-903a-f970d211993e";
              }
            }
            dispatch({
              type: types.ATLETE_ACCOUNT,
              atleteAccount: userData,
              userID: uid,
            });
          }
        });
      } else {
        dispatch({
          type: types.ATLETE_ACCOUNT,
          atleteAccount: null,
          userID: null,
        });
      }
    });
  };
}
//###########################################################################
export function resetUserAccount() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_USER_ACCOUNT,
      myVirtualEvents: undefined,
      myOrganizedVirtualEvents: undefined,
      currentEvent: undefined,
      currentEventReq: undefined,
      currentEventParticipants: {},
      currentEventParticipantsRequested: false,
      uploadingPhotos: null,
      uploadingPhotosProgress: null,
    });
  };
}

// #########################################################################################
export function requestTokenGarmin(userID, api) {
  return (dispatch) => {
    console.log(api);
    let headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    let params = { userID: userID, api: api };

    axios
      .post(
        "https://us-central1-kmetasfirebase.cloudfunctions.net/requestTokenGarminApi",
        params,
        headers
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: types.REQUESTTOKENGARMIN,
          requestTokenURLGarmin: res.data.urlRequest,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
//##################################################################
export function requestActivitiesGarminUser(userID, timestamp) {
  console.log("requestActivitiesGarminUser");
  let offset = new Date().getTimezoneOffset() * 60;
  let timestampFinal = timestamp.seconds - offset;
  return (dispatch) => {
    dispatch({
      type: types.ACTIVITIESGARMINLOADING,
      activitiesGarminLoading: true,
    });
    console.log(userID);
    console.log(timestampFinal);
    console.log(timestamp);

    fb.firestore
      .collection(fb.users)
      .doc(userID)
      .collection(fb.activities)
      .where("startTimeInSeconds", ">", timestampFinal)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          dispatch({
            type: types.ACTIVITIESGARMIN,
            activityGarmin: doc.data(),
            activityGarminId: doc.id,
          });
          console.log(doc.id, " => ", doc.data());
        });
        console.log("actividades");
        dispatch({
          type: types.ACTIVITIESGARMINLOADING,
          activitiesGarminLoading: false,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.ACTIVITIESGARMINLOADING,
          activitiesGarminLoading: false,
        });
        console.log("Error getting documents: ", error);
      });
  };
}
// #########################################################################################
export function requestActivitiesAppTraking(userID) {
  console.log("requestActivitiesGarminUser");
  return (dispatch) => {
    fb.firestore
      .collection(fb.users)
      .doc(userID)
      .collection(fb.traking)
      .doc("1595820900")
      .collection("steeps")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          dispatch({
            type: types.TRAKING_ACTIVITY_APP,
            trakingActivityApp: doc.data(),
            trakingActivityAppId: doc.id,
          });
          console.log(doc.id, " => ", doc.data());
        });
        console.log("actividades");
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
}
//##################################################################
export function requestActivityGarminDetail(userID, activityID) {
  return (dispatch) => {
    dispatch({
      type: types.ACTIVITIESGARMINLOADING,
      activitiesGarminLoading: true,
    });
    dispatch({
      type: types.ACTIVITIESGARMINDETAIL,
      activityGarminDetail: undefined,
      activityGarminDetailId: undefined,
    });

    fb.firestore
      .collection(fb.users)
      .doc(userID)
      .collection(fb.activityDetails)
      .doc(activityID)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch({
            type: types.ACTIVITIESGARMINDETAIL,
            activityGarminDetail: doc.data(),
            activityGarminDetailId: doc.id,
          });
          console.log(doc.id, " => ", doc.data());
        } else {
          console.log(doc.id, " => ", doc.data());
        }
        dispatch({
          type: types.ACTIVITIESGARMINLOADING,
          activitiesGarminLoading: false,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.ACTIVITIESGARMINLOADING,
          activitiesGarminLoading: false,
        });
        console.log("Error getting documents: ", error);
      });
  };
}
// #########################################################################################
export function requestActivitiesGarmin(userID, api, timestamp) {
  return (dispatch) => {
    let headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    // endpoints
    // pulseOx, respiration, activities, activityDetails, moveiq, manuallyUpdatedActivities
    //epochs, dailies, thirdPartyDailies, bodyComps, stressDetails, userMetrics

    let startDay = Math.floor(timestamp / 1000);
    let lapsTime = startDay + 60 * 59 * 24;
    let params = {
      userID: userID,
      uploadStartTimeInSeconds: startDay,
      uploadEndTimeInSeconds: lapsTime,
      endpoints: "activityDetails",
      api: api,
    };

    dispatch({
      type: types.ACTIVITIESGARMINLOADING,
      activitiesGarminLoading: true,
    });

    axios
      .post(
        "https://us-central1-kmetasfirebase.cloudfunctions.net/getActivityGarminApi",
        params,
        headers
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: types.ACTIVITIESGARMIN,
          activitiesGarmin: res.data.data,
          activitiesGarminLoading: false,
        });
        dispatch({
          type: types.ACTIVITIESGARMINLOADING,
          activitiesGarminLoading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.ACTIVITIESGARMINLOADING,
          activitiesGarminLoading: false,
        });
        console.log(error);
      });
  };
}
// #########################################################################################
// #########################################################################################
export function requestDeregistrationGarmin(userID, api) {
  return (dispatch) => {
    let headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    // endpoints
    // pulseOx, respiration, activities, activityDetails, moveiq, manuallyUpdatedActivities
    //epochs, dailies, thirdPartyDailies, bodyComps, stressDetails, userMetrics

    let params = {
      userID: userID,
      api: api,
    };

    dispatch({
      type: types.ACTIVITIESGARMINLOADING,
      activitiesGarminLoading: true,
    });

    axios
      .post(
        "https://us-central1-kmetasfirebase.cloudfunctions.net/deleteRegistrationGarmin",
        params,
        headers
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: types.ACTIVITIESGARMINLOADING,
          activitiesGarminLoading: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.ACTIVITIESGARMINLOADING,
          activitiesGarminLoading: false,
        });
        console.log(error);
      });
  };
}
// #########################################################################################
export function postWorkoutGarmin(userID, api, workout) {
  return (dispatch) => {
    let headers = {
      headers: { "Access-Control-Allow-Origin": true },
    };

    let now = Math.floor(Date.now() / 1000);
    let lapsTime = now - 60 * 60 * 23;
    let params = {
      userID: userID,
      workout: workout,
      api: api,
    };

    axios
      .post(
        "https://us-central1-kmetasfirebase.cloudfunctions.net/postWorkout",
        params,
        headers
      )
      .then((res) => {
        console.log(res);
        dispatch({
          type: types.POSTWORKOUTGARMIN,
          postWorkoutGarmin: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

// #########################################################################################

function getTokenPush(userID, tokenPush) {
  // 	let requestTokenPush = false;
  // 	if(tokenPush == undefined){
  // 		requestTokenPush = true;
  // 	}else if(localStorage.getItem(userID+"-tokenPush") == undefined){
  // 		requestTokenPush = true;
  // 	}
  // if(requestTokenPush){
  // 	fb.messaging
  // 	.requestPermission()
  // 	.then(async function() {
  // 	  const token = await fb.messaging.getToken();
  // //	  setToken(token);
  // 	  console.log(window.location.hostname);
  // 	  let data = {tokenPush:{token:token, host:window.location.hostname, date:Date.now()}}
  // 	  let newTokenRef = fb.firestore.collection("users").doc(userID);
  // 	  return newTokenRef.set(data,{merge : true}).then(() =>{
  // 		return "ok";
  // 	  });
  // 	})
  // 	.catch(function(err) {
  // 	  console.log("Unable to get permission to notify.", err);
  // 	});
  // }
}

// #########################################################################
// function migrateUser(){
// 	if(false){

// 	console.log("migrate users");

// 			for(let i in competidoresData){

// 				const index = i;
// 				if(competidoresData[index].genero == "V"){
// 					competidoresData[index].genero = "M";

// 					fb.firestore
// 					.collection("users")
// 					.doc(index)
// 					.set(competidoresData[index], { merge: true })
// 					.then(function(docRef) {
// 						console.log("Usuario registrado en firestore", competidoresData[index]);
// 					})
// 					.catch(function(error) {
// 						console.error("Error registrando usuario: ", error);
// 					});
// 				}

// 			}
// 		}

// 	}

// function migrateUser(){
// 	if(false){

// 	console.log("migrate users");

// 			for(let i in competidoresData){
// 				const index = i;
// 					fb.firestore
// 					.collection("users")
// 					.doc(index)
// 					.set(competidoresData[index], { merge: true })
// 					.then(function(docRef) {
// 						console.log("Usuario registrado en firestore", competidoresData[index]);
// 					})
// 					.catch(function(error) {
// 						console.error("Error registrando usuario: ", error);
// 					});

// 			}
// 		}

// 	}

// function testQuery () {
// 	let UAT = 'e645033a-bbc9-4213-82db-b96be16b1e65';
// 	let docRef = fb.firestore.collection("users").where('garmin.health.oauth_token',"==",UAT);
//     docRef.get()
//     .then(function(querySnapshot) {
//         querySnapshot.forEach(function(doc) {
//             // doc.data() is never undefined for query doc snapshots
//             console.log(doc.id, " => ", doc.data());
//         });
//     }).catch((error) => {
//         console.log(error);
//         console.log(error);
//       });
// }

// function uploadData(){
// 	if(false){
// 		console.log("upload");

// 		fb.firestore
// 			.collection("users")
// 			.doc("RhPFsu5yImbp5VJfcPnChJyGfmB3")
// 			.set(training, { merge: true })
// 			.then(function(docRef) {
// 			  console.log("Document written");
// 			})
// 			.catch(function(error) {
// 			  console.error("Error adding document: ", error);
// 			});

// 			// .collection("locations")
// 			// .doc("location_1")

// 	}

// }

// let training = {
//     "training": {
//         "zones": {
//             "heartRateZones": {
//                 "SWIMMING": {
//                     "1": 120,
//                     "2": 140,
//                     "3": 150,
//                     "4": 160,
//                     "5": 170,
//                     "6": 195
//                 },
//                 "CYCLING": {
//                     "1": 120,
//                     "2": 140,
//                     "3": 150,
//                     "4": 160,
//                     "5": 170,
//                     "6": 195
//                 },
//                 "RUNNING": {
//                     "1": 120,
//                     "2": 140,
//                     "3": 150,
//                     "4": 160,
//                     "5": 170,
//                     "6": 195
//                 }
//             },
//             "powerZones": {
//                 "SWIMMING": {
//                     "1": 193,
//                     "2": 202,
//                     "3": 243,
//                     "4": 257,
//                     "5": 316,
//                     "6": 333,
//                     "7": 2000
//                 },
//                 "CYCLING": {
//                     "1": 193,
//                     "2": 202,
//                     "3": 243,
//                     "4": 257,
//                     "5": 316,
//                     "6": 333,
//                     "7": 2000
//                 },
//                 "RUNNING": {
//                     "1": 193,
//                     "2": 202,
//                     "3": 243,
//                     "4": 257,
//                     "5": 316,
//                     "6": 333,
//                     "7": 2000
//                 }
//             }
//         }
//     }
// }
