import React from 'react';
import './Configuracion/css/materialize.min.css';
import './Configuracion/css/style.2.css';

export default function AboutDashport() {
  return (

<div>
  <div className="section white backGroundDashport" id="inicio">
    <div className="row container">
        <div className="col s4">
          <img className="responsive-img" src="Configuracion/Imagenes/logosDashport/DASHPORT_03.png" />
        </div>
        <div className="col s8">
          <h5 className="valign-wrapper">
            Servicios tecnológicos para eventos deportivos de resistencia.
          </h5>
          <p>
              Ofrecemos servicios tecnológicos y soporte para los mismos, que se 
              adecuan a las necesidades de los distintos grupos involucrados en la 
              industria de eventos de resistencia, organizadores, jueces, 
              patrocinadores y atletas. Tomamos en cuenta las necesidades 
              económicas y técnicas de cada grupo a fin de realmente poder 
              ofrecer soluciones útiles y redituables.
          </p>
        </div>
        <div className="col s12 center">
          <h5>Continua para ver nuestra propuesta</h5>
          <a className="btn-floating red pulse"><i className="material-icons text-white">keyboard_arrow_down</i></a>              
        </div>
      </div>
    </div>

<div className="parallax-container">
  <div className="parallax">
    <img src="Configuracion/Imagenes/FotosFondo/mujer_corriendo_Dashport.jpg" />
  </div>
</div>


<div className="section white backGroundDashport">
    <div className="row container">
      <div className="col s12 m4 l4">
          <i className="material-icons md-primary">view_list</i>
      </div>
      <div className="col s12 m8 l8">
          <h2>Inscripciones</h2>
          <p>Gestiona con total tranquilidad y de forma simple el registro de 
            todos los participantes al evento, el pago, la asignación de 
            números de competencia, categorías y distancias. Cuenta con 
            opciones de pago en efectivo a través de puntos  de venta y 
            opciones en linea con pago en oxxo, tarjetas de débito y crédito.</p>
            <p>La mejor plataforma para vender inscripciones (como una boletera tracicional)
            con los requiremientos que demandan los eventos de resistencia</p>
            <p>Contáctanos por mas información</p>
      </div>
    </div>



      <div className="row container center">
        <h2>Ventajas y características</h2>
        <div className="col s12 m4 l3">
          <div className="z-depth-1 descriptionSquare">
              Configurar cualquier tipo de evento deportivo.
          </div>
        </div>
          <div className="col s12 m4 l3">
            <div className="z-depth-1 descriptionSquare">
              Podrás requerir a los participantes toda la información necesaria para el 
              evento usando <b>campos personalizados</b> (ejm talla, equipo de corredores y cualquier otro).
            </div>
        </div>
        <div className="col s12 m4 l3">
          <div className="z-depth-1 descriptionSquare">
              <b>Vende productos adicionales</b> a las inscripción por si misma,
              estos productos pueden ser segmentados por la modalidad o sub 
              evento en el que el atleta desea participar.
          </div>
        </div>
        <div className="col s12 m4 l3">
          <div className="z-depth-1 descriptionSquare">
              Habilita <b>múltiples puntos de venta</b> para pago en efectivo sin 
              costo adicional (tiendas deportivas locales, de patrocinantes,
                clubes de corredores entre otros)
          </div>
        </div>
        <div className="col s12 m4 l3">
          <div className="z-depth-1 descriptionSquare">
              Disponibilidad las 24 horas de forma online para inscripciones y 
              consulta de estadísticas del evento en <b>Tiempo Real</b>.
            </div>
        </div>
      </div>
</div>

      
<div className="section #ffebee red lighten-5">
    <div className="row container">
        <div className="col s12 m12 l12">
          <h1>Imagenes de muestra</h1>
          <p>Imagenes tomadas de nuestra interfaz de usuario con un evento demo</p>
          <div className="slider" >
            <ul className="slides white" id="inscripcionesSlides">
            </ul>
          </div>
      </div>
    </div>
</div>


<div className="section white">
<div className="row container center">
  <h3>Eventos que ya usaron nuestra plataforma de inscripciones</h3>
  <div id="listaEventosInscritos"></div>
</div>
</div>

<div className="parallax-container">
  <div className="parallax">
    <img src="Configuracion/Imagenes/FotosFondo/fotoPrincipal_Dashport_bajaResolucion.jpg"/>
  </div>
</div>
    
<div className="section white backGroundDashport">
    <div className="row container">
        <div className="col s12 m8 l8">
            <h2>Cronometraje</h2>
            <p>Cronometraje de eventos de reto personal eficiente, fácil de utilizar y accesible para que ningún evento sea realizado sin este servicio. Todos los atletas tendrán sus tiempos generales, por rama y categoría, asi como la capacidad de comparar su desempeño que los demás competidores.</p>
            <p>Contáctanos por mas información</p>
        </div>
        <div className="col s12 m4 l4">
            <i className="large material-icons md-primary">access_alarm</i>
        </div>
    </div>


<div className="row container">
  <div className="col s12 m12 l12">
      <h2>Opciones disponibles</h2>
  </div>
  <div className="col s12 m6 l6">
    <div className=" z-depth-1 #ffebee red lighten-5" style={{height:"200px", margin: "5px", padding:" 10px"}}>
          <h5>Opción 1: Cronometraje vía Aplicación móvil:</h5>
      <p>
        Consiste en una aplicación desarrollada por Dashport para ser 
        utilizada por el staff del evento, bien sea personal “Cronometrista” 
        o jueces oficiales del evento. ( en uso desde Agosto 2016)
      </p>
    </div>
  </div>
  <div className="col s12 m6 l6">
    <div className=" z-depth-1 #ffebee red lighten-5" style={{height:"200px", margin: "5px", padding:" 10px"}}>
        <h5>
        Opción 2: Cronometraje via Aplicación móvil y chip 
        para los corredores (chip en desarrollo):
      </h5>
      <p>
          Consiste en la integración de la aplicación móvil con un chip para 
          evitar el trabajo manual que requiere la opcion 1.
      </p>
    </div>
  </div>
</div>


<div className="row container">
  <div className="col s12 m12 l12">
      <h2>Opcion 1: Cronometraje usando solo aplicación Movil</h2>
      <p>
        Los usuarios de la aplicación deben hacer básicamente dos 
        tareas para cronometrar el evento. 
      </p>
      <p>
          1- Ubicarse en el punto de control asignado y seleccionar el mismo en 
          la aplicación.
      </p>
      <p>
          2- Registrar de forma manual los números de competidor que pasen por el 
          punto de control dentro de la aplicación.
      </p>
      <p>
        Con esas dos actividades y una correcta configuración del evento 
        (configuración siempre a cargo de dashport) se pueden obtener todos los resultados de todos los atletas participantes según su rama, categoría y posición general.
      </p>
      <p>
        Con esa metodología se puede sustituir el sistema tradicional de chips para 
        eventos de baja y media participación.
      </p>
  </div>
</div>


<div className="row container center">
<h2>Ventajas y características</h2>
<div className="col s12 m4 l3">
<div className="z-depth-1 descriptionSquare">
    <b>Bajo costo</b> para el organizador dado que los atletas no requieren usar 
    un <b>chip y no se requiere de equipos costosos</b>.
</div>
</div>
<div className="col s12 m4 l3">
  <div className="z-depth-1 descriptionSquare">
      Alcance similar al de los chips en eventos de mediana o baja 
      participación (aprox 1000 atletas para eventos de 10km pueden 
      ser mas en eventos mas largos).
  </div>
</div>
<div className="col s12 m4 l3">
<div className="z-depth-1 descriptionSquare">
    <b>Múltiples puntos de control</b> sin costo adicional mas allá del 
    costo del operador.
</div>
</div>
<div className="col s12 m4 l3">
<div className="z-depth-1 descriptionSquare">
    Acceso en <b>zonas remotas</b> donde seria difícil instalar equipos 
    de cronometraje tradicional.
</div>
</div>
<div className="col s12 m4 l3">
<div className="z-depth-1 descriptionSquare">
    Seguimiento en <b>tiempo real</b> de los atletas que cruzan los puntos 
    de control y la meta.
  </div>
</div>
</div>
</div>



<div className="section #ffebee red lighten-5">
  <div className="row container">
  <div className="col s12 m12 l12">
    <h1>Imagenes de muestra</h1>
    <p>Imagenes tomadas de nuestra interfaz de usuario con un evento demo</p>
    <div className="slider" >
      <ul className="slides white" id="cronometrajeSlides">
      </ul>
    </div>
    </div>
  </div>
</div>


<div className="parallax-container">
  <div className="parallax">
    <img src="Configuracion/Imagenes/FotosFondo/corredor_con_logo_Dashport.jpg"/>
  </div>
</div>



<div className="section white backGroundDashport">
    <div className="row container">
        <div className="col s12 m4 l4">
            <i className="large material-icons md-primary">camera_enhance</i>
        </div>
        <div className="col s12 m8 l8">
            <h2>Resultados y Fotografías</h2>
            <p>
                Plataforma web usada para que los atletas puedan consultar los 
                resultados de cada evento en el que participan y descargar las fotografías 
                en los eventos que contraten este servicio.
            </p>
            <p>Contáctanos por mas información</p>
        </div>
    </div>

<div className="row container center">
<div className="col s12 m4 l3">
<div className="z-depth-1 descriptionSquare">
  Cada atleta tendrá información de su tiempo oficial del evento, 
  la posición que ocupo en cuanto a todos los atletas de su
   misma categoría, rama y la posición general del evento.
</div>
</div>
<div className="col s12 m4 l3">
  <div className="z-depth-1 descriptionSquare">
    Los resultados son los generados por nuestra plataforma de cronometraje 
    u otra que el cliente desee utilizar.
  </div>
</div>
<div className="col s12 m4 l3">
<div className="z-depth-1 descriptionSquare">
    Los atletas podrán descargar su certificado oficial de participación del 
    evento en formato JPG y PDF con su tiempo oficial y posiciones ocupadas.
</div>
</div>
<div className="col s12 m4 l3">
<div className="z-depth-1 descriptionSquare">
    La plataforma de fotografias permite a todos los atletas 
    editar (cortar, agregar marcos) y descargar muy fácilmente de forma gratuita
    todas sus fotos.
</div>
</div>
<div className="col s12 m4 l3">
  <div className="z-depth-1 descriptionSquare">
      Las fotografias pueden incluir el logo del evento y/o logo de patrocinadores. 
      Con esto aumentamos la exposición de marcas y del evento en las redes 
      sociales de los atletas
  </div>
</div>
</div>
</div>


<div className="section #ffebee red lighten-5">
<div className="row container">
<div className="col s12 m12 l12">
<h1>Imagenes de muestra</h1>
<p>Imagenes tomadas de nuestra interfaz de usuario con un evento demo</p>
<div className="slider" >
  <ul className="slides white" id="resultadosYFotografiasSlides">
  </ul>
</div>
</div>
</div>
</div>



<div className="parallax-container">
  <div className="parallax">
    <img src="Configuracion/Imagenes/FotosFondo/mujer_corriendo_Dashport.jpg" />
  </div>
</div>


</div>

  )  
}



