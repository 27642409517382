import React, {useState, useEffect} from 'react';
import PhotoEditorContainer from '../../../PhotoEditorVirtual/PhotoEditorContainer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import axios from 'axios';

import { segundosATiempo } from '../../../../../../../services/utils'


import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import TodayIcon from '@material-ui/icons/Today';
import TimerIcon from '@material-ui/icons/Timer';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SpeedIcon from '@material-ui/icons/Speed';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import PoolIcon from '@material-ui/icons/Pool';
import AtleteGarminDetailItem from './AtleteGarminDetailItem'

export default function AtleteGarminDetail(props){
    const { participant, currentEvent} = props;
    const [laps, setLaps] = useState([])

  useEffect(() => {
    var headers = {
        headers: {'Access-Control-Allow-Origin': true}
    };
    axios.get(summary.activityDetailFile, {}, headers)
    .then(res=>{
      console.log(res);
      setLaps(res.data.laps);
      if(res.data.laps != undefined){
        configLaps(res.data.laps, participant.garminActivitySummary);
      }

    }).catch(error=>{
        console.log(error);
      });

  },[]);


  const configLaps = (laps, summary) => {
      var lapsFinal = [];
      var durationTime = summary.durationInSeconds;
      var startTimeInSeconds = summary.startTimeInSeconds;
      var endTime = startTimeInSeconds + durationTime;

      for (var i in laps){
          const lapStart = laps[i].startTimeInSeconds;
          if(i > 0){
            lapsFinal.push({
                timeTotal: lapStart - startTimeInSeconds,
                time : lapStart - laps[i - 1].startTimeInSeconds,
                lap : parseInt(i)
            })
            console.log(lapStart - startTimeInSeconds);
            console.log(lapStart);
            console.log(startTimeInSeconds);
          }
          
      }
      lapsFinal.push({
        timeTotal: endTime - startTimeInSeconds,
        time : endTime - laps[laps.length - 1].startTimeInSeconds,
        lap : laps.length
    })

      setLaps(lapsFinal);

  }

    if(participant.garminActivitySummary == undefined){
        return <div></div>
      }
      var summary = participant.garminActivitySummary;

      const iconsArray = {
        RUNNING:<DirectionsRunIcon />,
        CYCLING:<DirectionsBikeIcon />,
        SWIMMING:<PoolIcon />
      }

    
     return (
                <Paper style={{padding:10}}>
                    <Typography gutterBottom variant="h2" component="h1">
                        Parciales Garmin
                    </Typography>

                <List aria-label="garmin-activities">
                    {laps.map((lap, key) => 
                        <AtleteGarminDetailItem key={key} text={"Tiempo Parcial " + lap.lap} value={"Parcial:" + segundosATiempo(lap.time) +"\n Acumulado: "+segundosATiempo(lap.timeTotal)} icon={<TimerIcon/>}/>
                    )}
              
                    </List>
                 </Paper>
    )
}
