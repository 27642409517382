import React from 'react';
import {Input, Icon} from 'react-materialize';
import Button from 'react-materialize/lib/Button';
import {fechaSeparada} from '../../../services/utils';

class DynamicInputDate extends React.Component {


  validate = () =>{

    // if(this.props.inputObject.validacion == "Requerido"){
    //   if(this.state.value != undefined 
    //     && this.state.value != null
    //     && this.state.value != ""){
    //       alert("ok");  

    //     }else{
    //       alert("campo requerido");  
    //     }
    // }
  }

  getValueDate = (e) => {
    var valor = e.target.value;
    var name = e.target.name;

    if(this.props.value == null){
      
    }      
 
    var valorAnterior = this.props.value;
    var fechaSeparada = valorAnterior.split("/");
    var fechaSeteada = `${fechaSeparada[2]}/${fechaSeparada[1]}/${fechaSeparada[0]}`;

    if(name == "Dia"){
      console.log("dia");
      fechaSeteada = `${fechaSeparada[0]}/${fechaSeparada[1]}/${valor}`;
    }else if(name == "Mes"){
      console.log("mes");
      fechaSeteada = `${fechaSeparada[0]}/${valor}/${fechaSeparada[2]}`;
    }else if(name == "Year"){
      console.log("year");
      fechaSeteada = `${valor}/${fechaSeparada[1]}/${fechaSeparada[2]}`;
    }

    var f = {target:{
      value:fechaSeteada,
      name:this.props.inputObject.iDCampo
    }}
    this.props.getValue(f);

  }



  render(){
    var anchoCampo = (this.props.inputObject.anchoCampo == undefined)?"s12 m6 l6":this.props.inputObject.anchoCampo;
          var dia = [];
          var mes = [];
          var year = [];
          
          for (var i = 1; i <= 31; i++) { 
            if(i<10){
              dia.push(<option key={i+"day"} value={`0${i}`}>{i}</option>);
            }else{
              dia.push(<option key={i+"day"} value={i}>{i}</option>);
            }
          }
          var meses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
    
          for (var i = 1; i <= 12; i++) { 
            if(i<10){
              mes.push(<option key={i+"month"} value={`0${i}`}>{meses[i-1]}</option>);
            }else{
              mes.push(<option key={i+"month"} value={i}>{meses[i-1]}</option>);
            }
          }
          
          var f=new Date();
          var yearI = f.getFullYear();
          var yearInsertar;
          for (var i = 0; i <= 100; i++) { 
            yearInsertar = yearI-i;
    
          year.push(<option key={i+"year"} value={yearInsertar}>{yearInsertar}</option>);
          }
          var valorAnterior = this.props.value;      
          var fechaSep = fechaSeparada(valorAnterior);
          var yearAnterior = fechaSep.ano;
          var mesAnterior = fechaSep.mes;
          var diaAnterior = fechaSep.dia;
      
      
          var fechaForm = (
            <div className={`col ${anchoCampo}`}>
              <div className="col s12">{this.props.inputObject.etiqueta}</div>
              <Input name="Year" s={3}
                  onBlur={(e)=>this.validate(e)}
                  onChange={e => this.getValueDate(e)} 
                  type='select' 
                  label="Año"
                  defaultValue={yearAnterior}>
                {year}
              </Input>
              <Input name="Mes" s={3}  
                  onChange={e => this.getValueDate(e)} 
                  type='select' 
                  label="Mes" 
                  defaultValue={mesAnterior}>
                {mes}
              </Input>
              <Input name="Dia" s={3}  
                  onChange={e => this.getValueDate(e)} 
                  type='select' 
                  label="Dia" 
                  defaultValue={diaAnterior}>
                {dia}
              </Input>
              {this.props.buttonSave}
            </div>);
      
        return (fechaForm);
  }
}

export default DynamicInputDate;
