import React from 'react';

import HeaderAtleteModal from './HeaderAtleteModal';
import AtleteData from './AtleteData';
import AtleteReportsContainer from './AtleteReportsContainer';

import Background from '../../../../assets/backgroundPhotos/fondogris_Dashport.jpg';

//TODO: Agregar opciones de edicion de datos como en la app de crono.

const AtleteDetailContainer = ({eventDetail, resultado}) => {

  return (
    <div className="padding overflowTrue"
      style={{backgroundImage: `url(${Background})`, backgroundRepeat  : 'no-repeat'}}> 
        <AtleteData resultado={resultado} eventDetail={eventDetail}/>
        <AtleteReportsContainer resultado={resultado} eventDetail={eventDetail}/>
    </div>
  )
}

export default AtleteDetailContainer;
