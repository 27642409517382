import React from 'react';

const CardPaymentPromissoryNote = ({ jsonMail }) => {

  return (<div style={{ cursor: 'auto', color: '#000000', fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif', fontSize: '13px', lineHeight: '22px', textAlign: 'center' }}>
    <p>
      De igual manera declaro que realice el pago de la inscripción via web con la tarjeta
       <b>{jsonMail.datos.statusPago.brand}</b> registrada con el nombre de usuario 
       <b>{jsonMail.datos.statusPago.cardName}</b> cuyos últimos 4 digitos son 
       <b>{jsonMail.datos.statusPago.last4}</b>
      por un monto de <b>$ {jsonMail.datos.statusPago.amount} MXN</b> a nombre de 
      <b>Dashport</b>
    </p>
  </div>);

}

export default CardPaymentPromissoryNote;