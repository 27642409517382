import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import PhotoEditorContainer from "../../../PhotoEditorVirtual/PhotoEditorContainer";

import Login from "../../../../../../Login/Login";

import { useForm, Controller } from "react-hook-form";
import {
  uploadPhoto,
  getUserVirtualEvents,
  saveUploadedPhoto,
} from "../../../../../../../data-store/actions/virtual-events-actions";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { helperMultisort } from "../../../../../../../services/utils";

import InputFileButton from "../../../InputFileButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function FinishLinePictures(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(
    (state) => state.virtualEvents.currentEventParticipants
  );

  const participant = currentEventParticipants[userID];

  const classes = useStyles();

  const thisVirtualEvent = myVirtualEvents[currentEvent.eventID];
  var photoURL = atleteAccount.photoURL;
  if (thisVirtualEvent.finishLinePhoto != undefined) {
    photoURL = thisVirtualEvent.finishLinePhoto;
  }

  const callback = (photo) => {
    dispatch(
      uploadPhoto(
        userID,
        currentEvent.eventID,
        photo,
        "finishLine",
        currentEvent
      )
    );
  };

  if (atleteAccount == null) {
    return <Login />;
  }
  if (
    myVirtualEvents == undefined ||
    currentEvent == undefined ||
    participant == undefined
  ) {
    return <CircularProgress />;
  }
  if (currentEvent == {}) {
    return <h1>Usuario no autorizado para este evento</h1>;
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      alignContent="center"
      direction="column"
    >
      <Grid item xs={12} sm={6}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container justify="space-around">
              <Grid item xs={5}>
                <Typography gutterBottom variant="h5" component="h5">
                  Sube tu fotografía apenas termines tu reto
                </Typography>
                <InputFileButton
                  type="image/*"
                  callback={callback}
                  text="Subir Foto"
                />
                <PhotoEditorContainer
                  buttomLabel={"Editor de fotos"}
                  title={"Editor"}
                  participant={participant}
                  currentEvent={currentEvent}
                  urlPhoto={participant.finishLinePhoto}
                />
              </Grid>
              <Grid item xs={5}>
                <CardMedia
                  component="img"
                  alt={currentEvent.eventName}
                  image={photoURL}
                  title={currentEvent.eventName}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
