import React, { useState }from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { postWorkoutGarmin } from '../../../../../data-store/actions/atlete-actions'
import workout from '../postWorkout.json';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Avatar from '@material-ui/core/Avatar';
import LogoGarmin from '../GarminAssets/Garmin_Connect_app_1024x1024-02.png'
import LogoDashport from '../../../../../assets/dashport-logos/DASHPORT_03.png'
import GarminRequestTokenButton from '../GarminRequestApi/GarminRequestTokenButton';


const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function GarminAthorizedTrainingAPIView(props){
  const classes = useStyles();
  const [requestToken, setRequestToken] = useState(true);

	const { atleteAccount, activitiesGarmin, sendRequestActivities, userID, postWorkout } = props;
 
  const handleClick = () => {
    postWorkout(userID, "training");

  }

  if(atleteAccount.garmin && atleteAccount.garmin["training"] === undefined){
    return <GarminRequestTokenButton api="training"/>;
  }

	  return (
      <Grid container justify="center" style={{textAlign:"center"}} spacing={3}>
        <Grid item xs={12}  style={{textAlign:"center"}}>
          <p>Tu usuario ya está conectado con Garmin Training</p>
        </Grid>
        <Grid item xs={4} >
          <Avatar variant="square" src={LogoDashport}/>
        </Grid>
        <Grid item xs={4} >
          <ArrowForwardIcon color="primary" />
        </Grid>
        <Grid item xs={4} >
          <Avatar variant="square" src={LogoGarmin}/>
        </Grid>
        {/* <Grid item xs={12}  style={{textAlign:"center"}}>
          <Button  onClick={() => handleClick()} variant="contained" color="primary" size="large">
            Enviar entrenamiento
          </Button>
        </Grid> */}
        <Grid item xs={12}  style={{textAlign:"center"}}>
        </Grid>
      </Grid>
	  );
	}
	

	const mapDispatchToProps = (dispatch) => {
		return {
      postWorkout : (userID, api) =>{

        dispatch(postWorkoutGarmin(userID, api, workout))
      }

		}
	}
	
	const mapStateToProps = (state) => {
		return {		
			eventDetail: state.events.eventDetail,
			eventParticipants : state.registry.eventParticipants,
      atleteAccount : state.atlete.atleteAccount,
      userID : state.atlete.userID,
      activitiesGarmin : state.atlete.activitiesGarmin
		}
		}
		
	const GarminAthorizedTrainingAPI = connect(
		mapStateToProps,
		mapDispatchToProps
	)(GarminAthorizedTrainingAPIView);
	
	export default GarminAthorizedTrainingAPI;
