import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import M from "materialize-css";

import AthleteProfileAccessActivities from "./AthleteProfileAccessActivities";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import { useForm, Controller } from "react-hook-form";
import AthletePictures from "./AthletePictures";
import TrakingAppContainer from "../TrakingApp/TrakingAppContainer";
import EditableField from "../../../EditableField/EditableField";
import EditableFieldSelect from "../../../EditableField/EditableFieldSelect";

import { updateUserAtribute } from "../../../../data-store/actions/atlete-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1000,
    margin: 10,
  },
  media: {
    height: 70,
  },
  content: {
    flex: 1,
  },
  cardHeader: {
    backgroundColor: "red",
    color: "white",
  },
  time: {
    flex: 0.3,
  },
  paper: {
    textAlign: "center",
  },
  large: {
    width: 50,
    height: 50,
  },
}));

export default function AthleteProfileCard(props) {
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const classes = useStyles();

  const { control, register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    dispatch(updateUserAtribute(userID, data));
    M.toast("Datos ajustados éxitosamente", 4000);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar className={classes.large}>
              {atleteAccount.nombres.charAt(0) +
                atleteAccount.apellidos.charAt(0)}
            </Avatar>
          }
          // action={
          //   <IconButton aria-label="settings">
          //     <SettingsIcon />
          //   </IconButton>
          // }
          title={"Perfil del atleta"}
          //          subheader="Datos personales del atleta presiona el icono de la derecha para modificaciones"
        />
        <Divider />
        <CardContent className={classes.content}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid Item xs={12} sm={3} className={classes.paper}>
              <AthletePictures />
            </Grid>
            <Grid Item xs={12} sm={7} className={classes.paper}>
              <EditableField
                fieldObject={{
                  label: "Nombres",
                  errorMessage: "Campo Requerido",
                  fieldName: "nombres",
                  type: "text",
                  defaultValue: atleteAccount.nombres,
                  required: true,
                  regex: "",
                  textSize: "h3",
                }}
                onSubmit={onSubmit}
              />
              <EditableField
                fieldObject={{
                  label: "Apellidos",
                  errorMessage: "Campo Requerido",
                  fieldName: "apellidos",
                  type: "text",
                  defaultValue: atleteAccount.apellidos,
                  required: true,
                  regex: "",
                  textSize: "h3",
                }}
                onSubmit={onSubmit}
              />
              <EditableField
                fieldObject={{
                  label: "Correo",
                  errorMessage: "Campo Requerido",
                  fieldName: "correo",
                  type: "text",
                  defaultValue: atleteAccount.correo,
                  required: true,
                  regex: "",
                  textSize: "h5",
                }}
                onSubmit={onSubmit}
              />
              <EditableField
                fieldObject={{
                  label: "Telefono",
                  errorMessage: "Campo Requerido",
                  fieldName: "telefono",
                  type: "text",
                  defaultValue: atleteAccount.telefono,
                  required: true,
                  regex: "",
                  textSize: "h5",
                }}
                onSubmit={onSubmit}
              />

              <EditableField
                fieldObject={{
                  label: "Género",
                  errorMessage: "Campo Requerido",
                  fieldName: "genero",
                  type: "select",
                  defaultValue: atleteAccount.genero,
                  required: true,
                  regex: "",
                  textSize: "h5",
                  optionsArray: [
                    {
                      value: "F",
                      label: "Femenil",
                    },
                    {
                      value: "M",
                      label: "Varonil",
                    },
                  ],
                }}
                onSubmit={onSubmit}
              />
            </Grid>
            <Grid Item xs={12} sm={2} className={classes.paper}>
              <AthleteProfileAccessActivities />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* <TrakingAppContainer/> */}
    </form>
  );
}
