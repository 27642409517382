import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import TimerIcon from "@material-ui/icons/Timer";

import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import { blue } from "@material-ui/core/colors";

import IconButton from "@material-ui/core/IconButton";
import FilterIcon from "@material-ui/icons/Filter";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  font: {
    fontFamily: "MavenPro-VariableFont_wght",
  },
  font1: {
    fontFamily: "digital-7Final",
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, participant, callback } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value, value2) => {
    onClose(value);
    console.log(value, value2);
    callback(value, value2);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Selecciona texto</DialogTitle>
      <List>
        {participant.goalTime != undefined && (
          <ListItem
            button
            onClick={() =>
              handleListItemClick(participant.goalTime, "Tiempo Objetivo")
            }
          >
            <ListItemAvatar>
              <Avatar>
                <TimerIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Tiempo objetivo"
              secondary={participant.goalTime}
            />
          </ListItem>
        )}
        {participant.finishTime != undefined && (
          <ListItem
            button
            onClick={() =>
              handleListItemClick(participant.finishTime, "Tiempo Final")
            }
          >
            <ListItemAvatar>
              <Avatar>
                <TimerIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Tiempo Final"
              secondary={participant.finishTime}
            />
          </ListItem>
        )}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function FiltersDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { frames, participant, callback } = props;
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <IconButton aria-label="framesFilters" edge="end" color="inherit">
        <FilterIcon onClick={handleClickOpen} />
      </IconButton>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        frames={frames}
        participant={participant}
        callback={callback}
      />
    </div>
  );
}
