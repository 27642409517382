import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

export default function InputFileButton(props) {
  const { callback, text, type } = props;
  const uploadingPhotos = useSelector(state => state.virtualEvents.uploadingPhotos);
  const uploadingPhotosProgress = useSelector(state => state.virtualEvents.uploadingPhotosProgress);


  

  const handleChange = (e) =>{
      console.log(e);
      console.log(e.target);
      console.log(e.target.files);
      callback(e.target.files[0]);
  }



  let uploadingView;
  if(uploadingPhotos != null){
    uploadingView = (<div style={{padding:30}}>
      Subiendo : {uploadingPhotos}
      <CircularProgress variant="static" value={uploadingPhotosProgress} />
      </div>)
  }

  return (
      <Grid container>
        {uploadingView}
        <label style={{backgroundColor:"red", borderRadius:1, padding:15, color:"white", margin:15, cursor: "pointer", fontSize:15}} >
            {text}
            <input accept={type} onChange={(e) => handleChange(e)} style={{display: "none"}} id="upload-photo" name="photos" type="file" />
        </label>
      </Grid>
  );
}