import * as types from "../actions/action-types";

const initialState = [];
export default function registryReducer(state = initialState, action) {
  switch (action.type) {
    case types.EVENT_PARTICIPANTS:
      return Object.assign({}, state, {
        eventParticipants: action.eventParticipants,
      });
    case types.EVENT_PARTICIPANTS_CHANGED:
      let key = action.eventParticipantsChangedKey;
      return Object.assign({}, state, {
        eventParticipants: Object.assign({}, state.eventParticipants, {
          [key]: action.eventParticipantsChanged,
        }),
      });
    case types.REGISTRY_PARTICIPANT_DATA:
      return Object.assign({}, state, {
        registryParticipantData: action.registryParticipantData,
        registryParticipantKey: action.registryParticipantKey,
        registryError: action.registryError,
      });
    case types.RESET_REGISTRY_PARTICIPANT_DATA:
      return Object.assign({}, state, {
        registryParticipantData: action.registryParticipantData,
        registryParticipantKey: action.registryParticipantKey,
        registryError: action.registryError,
      });
    case types.REGISTRY_FORM_DATA:
      return Object.assign({}, state, {
        registryFormtData: action.registryFormtData,
        registryFormtKey: action.registryFormtKey,
        registryError: action.registryError,
      });

    case types.EVENT_PARTICIPANTS_REMOVED:
      let key1 = action.eventParticipantsChangedKey;
      let stateNew = {};
      for (let i in state.eventParticipants) {
        if (i !== key1) {
          stateNew[i] = state.eventParticipants[i];
        }
      }
      return Object.assign({}, state, {
        eventParticipants: stateNew,
      });
    case types.REGISTRY_CONFIGURATION:
      return Object.assign({}, state, {
        registryConfiguration: action.registryConfiguration,
      });
    case types.REGISTRY_AUTORIZED_USERS:
      return Object.assign({}, state, {
        registryAutorizedUsers: action.registryAutorizedUsers,
      });
    default:
      return state;
  }
}
