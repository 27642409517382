import * as types from "./action-types";
import * as fb from "../../firebase/firebasejs";

// #########################################################################################

export function initOrganizerEvents(businessId, eventStatus) {
  var ref = fb.db.ref(fb.EventosLista);
  return (dispatch) => {
    ref
      .orderByChild("businessId")
      .equalTo(businessId)
      .on("value", (snapshot) => {
        dispatch({
          type: types.ORGANIZER_EVENTS,
          organizerEvents: snapshot.val(),
        });
      });
  };
}
// #########################################################################################
export function getOrganizerWeb(url) {
  let ref = fb.db.ref(`organizersWeb/${url}/web/`);
  console.log("getOrganizerWeb");

  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.ORGANIZER_WEB,
        organizerWeb: snapshot.val(),
      });
    });
  };
}
// #########################################################################################
export function getEventWeb(eventoIndex) {
  const ref = fb.db.ref(`${fb.EventosCompletos}/${eventoIndex}/${fb.eventWeb}`);
  console.log("eventWeb");

  return (dispatch) => {
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      dispatch({
        type: types.EVENT_WEB_PUBLIC,
        eventWebPublic: snapshot.val(),
      });
    });
  };
}
//###########################################################################
export function showSnackbar(show, text, duration) {
  return (dispatch) => {
    console.log("showSnackbar");
    dispatch({
      type: types.SHOW_SNACKBAR,
      snackbar: { show: show, text: text, duration: duration },
    });
    setTimeout(() => {
      dispatch({
        type: types.SHOW_SNACKBAR,
        snackbar: { show: false, text: "", duration: 0 },
      });
    }, duration);
  };
}
//###########################################################################
export function closeSnackbar() {
  return (dispatch) => {
    console.log("showSnackbar");
    dispatch({
      type: types.SHOW_SNACKBAR,
      snackbar: { show: false, text: "", duration: 0 },
    });
  };
}
