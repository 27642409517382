import * as types from "../actions/action-types";

const initialState = [];
export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case types.CHAT_VIRTUAL_EVENT:
      let messageId = action.messageId;
      let message = action.message;
      message.id = messageId;
      return Object.assign({}, state, {
        chatVirtualEvent: Object.assign({}, state.chatVirtualEvent, {
          [messageId]: message,
        }),
      });
    case types.MESSAGES_REQUESTED:
      return Object.assign({}, state, {
        messagesRequested: action.messagesRequested,
      });
    case types.RESET_CURRENT_EVENT_CHAT:
      return Object.assign({}, state, {
        chatVirtualEvent: action.chatVirtualEvent,
      });
    default:
      return state;
  }
}
