import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Avatar, Paper } from "@material-ui/core";
import CardProduct from "../ShopingCar/CardProduct";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    margin: "20px",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
    width: "100%",
    backgroundColor: "#e0e0e0",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function AditionalProducts(props) {
  const { currentOrder, callbackProducts } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const eventDetail = useSelector((state) => state.events.eventDetail);
  const eventSubEventsRegistry = useSelector(
    (state) => state.events.eventSubEventsRegistry
  );
  const registryConfiguration = useSelector(
    (state) => state.registry.registryConfiguration
  );

  const distanceProducts = !eventSubEventsRegistry[
    "modalidad_" + currentOrder.modalidad
  ].hideAditionalProducts
    ? eventSubEventsRegistry["modalidad_" + currentOrder.modalidad]
        .configModalidad.productosAdicionales
    : {};

  const classes = useStyles();

  function onProductClick(producto, item, add) {
    console.log("producto", producto);
    console.log("producto", item);
    callbackProducts(producto, item, add);
  }
  useEffect(() => {
    var elmnt = document.getElementById("topProducts");
    elmnt.scrollIntoView();
  }, []);

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ padding: "10px" }}
    >
      <div id="topProducts" style={{ height: "20px", width: "20px" }}></div>

      {distanceProducts &&
        Object.values(distanceProducts)
          .filter(
            (a) => a.hideInPublic === undefined || a.hideInPublic === false
          )
          .map((producto, i) => (
            <Grid item xs={12} sm={12}>
              <CardProduct
                key={i}
                producto={producto}
                updateOrder={onProductClick}
                registrytype={null}
                monedaAUsar={currentOrder.monedaAUsar}
                callbackchange={() => false}
                currentQuantity={() =>
                  currentOrder.order.filter(
                    (a) => a.code === "producto-" + producto.codigoProducto
                  )[0] !== undefined
                    ? currentOrder.order.filter(
                        (a) => a.code === "producto-" + producto.codigoProducto
                      )[0].quantity
                    : 0
                }
              />
            </Grid>
          ))}
    </Grid>
  );
}
