import React from 'react';
import { connect } from 'react-redux'
import { Modal , Button, Input, Icon, Row } from 'react-materialize';
import {getCleanedString} from '../../../services/utils';
import FormDialog from '../Modals/FormDialog';
import AtleteDetailsContainer from '../reports/atleteDetail/AtleteDetailContainer';

class BuscadorResultsView extends React.Component {
    // icon true el modla se activa con el icono de la lupa
    // icon false con el texto buscar por numero o nombre
    constructor(props){
        super(props)
        this.state = {
            nombreNumero : 0,
        }
    }

    findRegistry = (e) =>{
    	var value = e.target.value;
        var ValorBuscado = value;
        let listaAtletas =[];
        if(this.props.eventResults != undefined){
            if(!isNaN(ValorBuscado)){
            console.log("numero");
                this.props.eventResults.forEach((value, i) => {
                //      console.log(ListaAtletas[value]);
                        if(value.num == ValorBuscado){
                        if(this.props.certificates){
                                listaAtletas.push(<li key={i}
                                    className="collection-item">
                                <FormDialog 
                                        buttomLabel={((value.num == undefined)?"Folio Pendiente":value.num)+" - "+value.nombre +" - IMPRIMIR CERTIFICADO"}
                                        ShowIcon={false}
                                        ButtonStyle={null}
                                        maxWidthProp={'lg'}
                                        style={{
                                            minHeight: '80vh',
                                            maxHeight: '80vh',
                                        }}
                                        report={<div>Aqui va la impresion de certificado</div>}
                                        />
                                </li>);
                            }else{
                                listaAtletas.push(<li key={i}
                                    className="collection-item">
                                <FormDialog 
                                        buttomLabel={((value.num == undefined)?"Folio Pendiente":value.num)+" - "+value.nombre}
                                        ShowIcon={false}
                                        ButtonStyle={null}
                                        maxWidthProp={'lg'}
                                        style={{
                                            minHeight: '80vh',
                                            maxHeight: '80vh',
                                        }}
                                        report={<AtleteDetailsContainer 
                                            eventDetail={this.props.eventDetail}
                                            resultado={value} />}
                                        />
                                </li>);
                            } 
                        }
                });

                return this.setState({listaAtletas:listaAtletas});
            }else{
                console.log("texto");
                if(ValorBuscado.length>2){
                    this.props.eventResults.forEach((value, i) => {
                        ValorBuscado= getCleanedString(ValorBuscado);
                        var nombreBuscado = ValorBuscado.toUpperCase();
                        const nombreComparar = value.nombre.toUpperCase();
                        if(nombreComparar.match(nombreBuscado)!=null){
                            if(this.props.certificates){
                                listaAtletas.push(<li key={i}
                                    className="collection-item">
                                        <FormDialog 
                                                buttomLabel={((value.num == undefined)?"Folio Pendiente":value.num)+" - "+value.nombre +" - IMPRIMIR CERTIFICADO"}
                                                ShowIcon={false}
                                                ButtonStyle={null}
                                                maxWidthProp={'lg'}
                                                style={{
                                                    minHeight: '80vh',
                                                    maxHeight: '80vh',
                                                }}
                                                report={<div>Aqui va la impresion de certificado</div>}
                                                />
                                            </li>);
                            }else{
                                listaAtletas.push(<li key={i}
                                    className="collection-item">
                                        <FormDialog 
                                                buttomLabel={((value.num == undefined)?"Folio Pendiente":value.num)+" - "+value.nombre}
                                                ShowIcon={false}
                                                ButtonStyle={null}
                                                maxWidthProp={'lg'}
                                                style={{
                                                    minHeight: '80vh',
                                                    maxHeight: '80vh',
                                                }}
                                                report={<AtleteDetailsContainer 
                                                    eventDetail={this.props.eventDetail}
                                                    resultado={value} />}
                                                />
                                        </li>);
                            } 
                        }
                    });
                    return this.setState({listaAtletas:listaAtletas});
                }
            }
        }
    }



    render(){
    let buttom;
    if(this.props.icon){
        buttom = (<li><i className="material-icons md-40 marginIcons">search</i></li>);
    }else{
        buttom = (<li><a href="#" className="red-text">Buscar por Nombre o Número</a></li>);
    }
        return (
                <div>
                    <h5>Buscador por Nombre o Número</h5>
                    <Row>
                        <Input name="nombreNumero" onChange={e => this.findRegistry(e)} type="text" label="Nombre o Número" validate s={12} />
                    </Row>
                    <ul id="ListaNumeroNombre" className="collection">
                        {this.state.listaAtletas}
                    </ul>
                </div>)

    }
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

const mapStateToProps = (state) => {
	return {		
		eventDetail: state.events.eventDetail,
		eventResults : state.events.eventResults,
	}
  }
  
const BuscadorResults = connect(
	mapStateToProps,
	mapDispatchToProps
)(BuscadorResultsView);

export default BuscadorResults;