import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useForm, Controller } from "react-hook-form";
import EventPictures from "./EventPictures";
import EventCertificatePicture from "./EventCertificatePicture";
import EventAccess from "./EventAccess";
import EventAudios from "./EventAudios";
import EventGeneralData from "./EventGeneralData";
import EventRaceNumberImage from "./EventRaceNumberImage";
import EventPicturesConfig from "./EventPicturesConfig";
import EventCategoryConfig from "./EventCategoryConfig";
import EventPlaces from "./EventPlaces";
import EventMedalImage from "./EventMedalImage";
import EventForm from "./EventForm";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  menu: {
    flex: 1,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 50,
    width: 100,
  },
}));

export default function EventConfig(props) {
  const { eventID } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  let myOrganizedVirtualEvents = useSelector(
    (state) => state.virtualEvents.myOrganizedVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(
    (state) => state.virtualEvents.currentEventParticipants
  );

  const classes = useStyles();

  const { control, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h5>Configuración del evento</h5>
        </Grid>
        <Grid item xs={12} sm={6}>
          <EventGeneralData />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EventPictures />
          <EventCertificatePicture />
          <EventRaceNumberImage />
          <EventMedalImage />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EventAccess />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EventAudios />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EventPicturesConfig />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EventCategoryConfig />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EventPlaces />
        </Grid>
        <Grid item xs={12} sm={6}>
          <EventForm />
        </Grid>
      </Grid>
    </div>
  );
}
