import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";

import Login from "../../../../../../Login/Login";

import { useForm, Controller } from "react-hook-form";
import { saveFinishTime } from "../../../../../../../data-store/actions/virtual-events-actions";

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    margin: 10,
  },
  media: {
    height: 70,
  },
  content: {
    height: 100,
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  cardHeader: {
    backgroundColor: "red",
    color: "white",
  },
  time: {
    flex: 0.3,
  },
  paper: {
    textAlign: "center",
  },
});

export default function MyGoalTime(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const classes = useStyles();

  const { control, register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    var hours = data.hours / 1 < 10 ? "0" + data.hours / 1 : data.hours / 1;
    var minutes =
      data.minutes / 1 < 10 ? "0" + data.minutes / 1 : data.minutes / 1;
    var seconds =
      data.seconds / 1 < 10 ? "0" + data.seconds / 1 : data.seconds / 1;
    var time = hours + ":" + minutes + ":" + seconds;
    dispatch(saveFinishTime(userID, currentEvent.eventID, time, false));
  };
  const resetGoal = () => {
    dispatch(saveFinishTime(userID, currentEvent.eventID, null, false));
  };

  if (atleteAccount == null) {
    return <Login />;
  }

  if (myVirtualEvents == undefined || currentEvent == undefined) {
    return <CircularProgress />;
  }
  if (currentEvent == {}) {
    return <h1>Usuario no autorizado para este evento</h1>;
  }
  const thisVirtualEvent = myVirtualEvents[currentEvent.eventID];
  console.log(thisVirtualEvent);

  if (
    thisVirtualEvent.goalTime != undefined &&
    thisVirtualEvent.goalTime != null
  ) {
    return (
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.paper}>
          <Typography gutterBottom variant="h5" component="h2">
            Tiempo Objetivo
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.paper}>
          <div style={{ fontSize: 70 }}>{thisVirtualEvent.goalTime}</div>
        </Grid>
        <Grid item xs={12} className={classes.paper}>
          <Button onClick={() => resetGoal()}>Cambiar Meta</Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.paper}>
          <Typography gutterBottom variant="h5" component="h2">
            Registra tu tiempo objetivo
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.paper}>
          <div>Horas</div>
          <input
            name="hours"
            defaultValue={0}
            type="number"
            style={{ fontSize: 50 }}
            ref={register({ required: true, min: 0, max: 100 })}
          />
          {errors.hours && <span>Campo requerido</span>}
        </Grid>
        <Grid item xs={1} className={classes.paper}>
          <div style={{ fontSize: 50 }}>:</div>
        </Grid>
        <Grid item xs={2} className={classes.paper}>
          <div>Minutos</div>
          <input
            name="minutes"
            defaultValue={0}
            type="number"
            style={{ fontSize: 50 }}
            ref={register({ required: true, min: 0, max: 59 })}
          />
          {errors.minutes && <span>Campo requerido</span>}
        </Grid>
        <Grid item xs={1} className={classes.paper}>
          <div style={{ fontSize: 50 }}>:</div>
        </Grid>
        <Grid item xs={2} className={classes.paper}>
          <div>Segundos</div>
          <input
            name="seconds"
            defaultValue={0}
            type="number"
            style={{ fontSize: 50 }}
            ref={register({ min: 0, max: 59 })}
          />
          {errors.seconds && <span>Campo requerido</span>}
        </Grid>
        <Grid item xs={12} className={classes.paper}>
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
            Guardar Meta
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
