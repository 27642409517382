import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import PhotoEditor from './PhotoEditor';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background:'black'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },ButtonStyleDefault:{
    color : 'red'
  },logo:{
    height : '40px'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PhotoEditorContainer(props) {
  const {buttomLabel, title, BarButtons, ButtonStyle, urlPhoto, resultado} = props;
  // BarButtons =  Array de Componentes botones que muestran un Dialog adicional o hacen algo. 
  // buttomLabel =  Texto del boton del rDialog full. 

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
  
   return (
    <div>
      <Button className={classes.ButtonStyleDefault} style={(ButtonStyle == undefined || ButtonStyle == null)?null:ButtonStyle} color="primary" onClick={handleClickOpen}>
        {buttomLabel}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <PhotoEditor urlPhoto={urlPhoto} 
          resultado={resultado} 
          title={title} 
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}/>

      </Dialog>
    </div>
  );
}


