import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import YouTube from "react-youtube";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function YoutubeVideo(props) {
  const { videoId, autoplay, bgColor } = props.componentConfig;

  const propsVideo = {
    width: "100%",
    playerVars: {
      autoplay: autoplay ? 1 : 0,
      start: 0,
    },
  };

  return (
    <Grid container direction="row" style={{ backgroundColor: bgColor }}>
      <Grid item xs={12}>
        <YouTube videoId={videoId} opts={propsVideo} />
      </Grid>
    </Grid>
  );
}

YoutubeVideo.displayName = "Youtube Video";

export const componentDescription = {
  componentConfig: {
    videoId: {
      label: "Video Id de yutube",
      errorMessage: "Campo Requerido",
      fieldName: "videoId",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    autoplay: {
      label: "Iniciar el video al cargar la web?",
      errorMessage: "Campo Requerido",
      fieldName: "autoplay",
      type: "switch",
      defaultValue: false,
      required: true,
      regex: "",
      textSize: "h6",
    },

    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
