import React from "react";
import { TiempoAPasoMinxK } from "../../../../../../../services/utils";
import { DescargarCertificadoReg } from "./certificadoLogic";
import Typography from "@material-ui/core/Typography";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import LogoGarmin from "../../../../GarminApi/GarminAssets/Garmin_Connect_app_1024x1024-02.png";
import M from "materialize-css";

const descargarCertificadoReg = (e, currentEvent, participant, tipo) => {
  e.preventDefault();
  console.log("descargar certificado");
  if (currentEvent.eventEnded) {
    DescargarCertificadoReg(currentEvent, participant, tipo);
  } else {
    M.toast(
      "Evento en desarrollo, al finalizar el plazo definido puedes ingresar a descargar tu certificado oficial",
      5000
    );
  }
};

const getEvidence = (participant) => {
  let evidence = {
    garmin: false,
    photo: false,
    strava: false,
    noEvidence: true,
  };
  console.log("getEvidence", participant);
  if (participant.garminActivitySummary != undefined) {
    evidence.garmin = true;
    evidence.noEvidence = false;
  }
  if (participant.photos !== undefined) {
    var photo = [];
    photo = Object.values(participant.photos).filter((obj) => {
      return obj.photoType === "evidence";
    });
    if (photo.length > 0) {
      evidence.photo = true;
      evidence.noEvidence = false;
    }
  }

  return evidence;
};

const AtleteData = ({ currentEvent, participant }) => {
  var paso = TiempoAPasoMinxK(participant.finishTime, currentEvent.distance);

  let preliminar = "";
  if (!currentEvent.eventEnded) {
    preliminar = "(Resultados preliminares)";
  }
  return (
    <div>
      <div className="divider"></div>
      <div className="row">
        <div className="col s8 left-align">
          <Typography gutterBottom variant="h3">
            {participant.athleteName + " " + participant.athleteLastName}
          </Typography>
          <Typography gutterBottom variant="h5">
            {preliminar}
          </Typography>
        </div>
        <div className="col s4 left-align">
          <a
            style={{ margin: 2 }}
            onClick={(e) =>
              descargarCertificadoReg(e, currentEvent, participant, 2)
            }
            className="waves-effect waves-teal btn-flat red white-text"
            target="_blank"
          >
            JPG
          </a>
          <a
            style={{ margin: 2 }}
            onClick={(e) =>
              descargarCertificadoReg(e, currentEvent, participant, 1)
            }
            className="waves-effect waves-teal btn-flat red white-text"
            target="_blank"
          >
            PDF
          </a>
        </div>
      </div>
      <div className="divider"></div>
      <div className="row">
        <span className="col s4 m4 center-align">
          <b>Número </b>
          <br />
          <Typography variant="h4" gutterBottom>
            {participant.raceNumber}
          </Typography>
        </span>
        <span className="col s4 m4 center-align">
          <b>Género </b>
          <br />
          <Typography variant="h4" gutterBottom>
            {participant.athleteGender == "M" ? "Varonil" : "Femenil"}
          </Typography>
        </span>
        <span className="col s4 m4 center-align">
          <b>Distancia </b>
          <br />
          <Typography variant="h3" gutterBottom>
            {currentEvent && currentEvent.eventType === "byTime"
              ? "N/A"
              : currentEvent.distance}
          </Typography>
        </span>
      </div>
      <div className="row z-depth-2">
        <div className="#f44336 red white-text col s6 m6 l3 center-align">
          <b>Tiempo Previsto</b>
          <br />
          <Typography variant="h4" gutterBottom>
            {participant.goalTime}
          </Typography>
        </div>
        <div className="#f44336 red white-text col s6 m6 l3 center-align">
          <b>Tiempo Final</b>
          <br />
          <Typography variant="h4" gutterBottom>
            {participant.finishTime}
          </Typography>
        </div>
        <div className="#f44336 red white-text col s6 m6 l3 center-align">
          <b>Paso min/K</b>
          <br />
          <Typography variant="h4" gutterBottom>
            {currentEvent && currentEvent.eventType === "byTime" ? "N/A" : paso}
          </Typography>
        </div>
        <div className="#f44336 red white-text col s6 m6 l3 center-align">
          <b>Evidencia</b>
          <br />
          <Typography variant="h4" gutterBottom>
            {getEvidence(participant).garmin ? (
              <img src={LogoGarmin} height={30} />
            ) : (
              ""
            )}
            {getEvidence(participant).photo ? <CameraAltIcon /> : ""}
            {getEvidence(participant).strava ? "Strava" : ""}
            {getEvidence(participant).noEvidence ? "Sin Evidencia" : ""}
          </Typography>
        </div>
        <div
          className="#f44336 red white-text col s4 m2 l2 center-align"
          style={{ height: 80 }}
        >
          <b>Posición General</b>
          <br />
          <Typography variant="h5" gutterBottom>
            {participant.positionAbsolute}
          </Typography>
        </div>
        <div
          className="#f44336 red white-text col s4 m2 l2 center-align"
          style={{ height: 80 }}
        >
          <b>Posición Rama</b>
          <br />
          <Typography variant="h5" gutterBottom>
            {participant.positionAbsoluteGender}
          </Typography>
        </div>
        <div
          className="#f44336 red white-text col s4 m2 l2 center-align"
          style={{ height: 80 }}
        >
          <b>Posición Categoría</b>
          <br />
          <Typography variant="h5" gutterBottom>
            {participant.positionAbsoluteCategory}
          </Typography>
        </div>
        <div
          className="#f44336 red white-text col s4 m2 l2 center-align"
          style={{ height: 80 }}
        >
          <b>Posición Relativa General</b>
          <br />
          <Typography variant="h5" gutterBottom>
            {participant.positionRelative}
          </Typography>
        </div>
        <div
          className="#f44336 red white-text col s4 m2 l2 center-align"
          style={{ height: 80 }}
        >
          <b>Posición Relativa Rama</b>
          <br />
          <Typography variant="h5" gutterBottom>
            {participant.positionRelativeGender}
          </Typography>
        </div>
        <div
          className="#f44336 red white-text col s4 m2 l2 center-align"
          style={{ height: 80 }}
        >
          <b>Posición Relativa Cateogría</b>
          <br />
          <Typography variant="h5" gutterBottom>
            {participant.positionRelativeCategory}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AtleteData;
