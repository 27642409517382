import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";

export let store;
export default function configureStore() {
  let initialState = {
    virtualEvents: {
      myVirtualEvents: undefined,
      myOrganizedVirtualEvents: undefined,
      currentEvent: undefined,
      currentEventReq: undefined,
      currentEventParticipants: {},
      currentEventParticipantsRequested: false,
      eventPass: {},
      eventPassRequest: false,
      uploadingPhotos: null,
      uploadingPhotosProgress: null,
      authorizedEventsList: [],
    },
    atlete: {
      activitiesGarminLoading: false,
      userID: undefined,
      atleteAccount: undefined,
    },
    chat: {
      chatVirtualEvent: {},
      messagesRequested: false,
    },
    events: {
      eventDetail: false,
      eventSubEvents: false,
    },
    registry: {
      registryConfiguration: false,
      registryParticipantData: false,
      registryParticipantKey: false,
    },
    organizer: { snackbar: { show: false, text: "", duration: 0 } },
  };

  const DEBUG = true;
  const middleware = [thunk, DEBUG && logger].filter(Boolean);
  store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
  );

  return store;
}
