import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import {
  uploadEventPhoto,
  updateEventAtribute,
} from "../../../../../../../data-store/actions/virtual-events-actions";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import InputFileButton from "../../../../VirtualEvents/InputFileButton";
import EditableField from "../../../../../../EditableField/EditableField";

import { DescargarCertificadoReg } from "../../../SpecificVirtualEvent/VirtualEventMenu/atleteDetail/certificadoLogic";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function EventPictures(props) {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  let myOrganizedVirtualEvents = useSelector(
    (state) => state.virtualEvents.myOrganizedVirtualEvents
  );

  const classes = useStyles();

  const participantDemo = {
    athleteName: "Nombre de ",
    athleteLastName: "Prueba",
    raceNumber: 1,
    distance: "10 km",
    category: "Única",
    athleteGender: "M",
    finishTime: "00:45:30",
    positionAbsolute: 1,
    positionAbsoluteGender: 1,
    positionAbsoluteCategory: 1,
  };

  const deletePicture = () => {
    dispatch(
      updateEventAtribute(userID, currentEvent.eventID, {
        certificateImage: null,
      })
    );
  };

  const callback = (photo) => {
    dispatch(
      uploadEventPhoto(
        userID,
        currentEvent.eventID,
        photo,
        "certificateImage",
        myOrganizedVirtualEvents
      )
    );
  };
  const onSubmit = (data) => {
    console.log(data);
    dispatch(updateEventAtribute(userID, currentEvent.eventID, data));
    //M.toast("Datos ajustados éxitosamente", 4000);
  };

  var certificateImage = false;
  if (
    currentEvent.certificateImage != undefined ||
    currentEvent.certificateImage != null
  ) {
    certificateImage = true;
  }

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {certificateImage && (
          <CardMedia
            component="img"
            alt={currentEvent.eventName}
            height="auto"
            image={currentEvent.certificateImage}
            title={currentEvent.eventName}
          />
        )}

        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Imagen para el certificado
          </Typography>
        </CardContent>
      </CardActionArea>
      <div>
        <InputFileButton
          type="image/*"
          callback={callback}
          text="Subir imagen de certificado"
        />
      </div>
      <div>
        <Button
          onClick={() =>
            DescargarCertificadoReg(currentEvent, participantDemo, "JPG")
          }
        >
          Certificado de prueba
        </Button>
      </div>
      <div>
        <Button onClick={() => deletePicture()}>Borrar foto</Button>
      </div>
      <div>
        <EditableField
          fieldObject={{
            label: "Ocultar fondo blanco",
            errorMessage: "Campo Requerido",
            fieldName: "certificateWhiteBack",
            type: "switch",
            defaultValue: currentEvent.certificateWhiteBack || false,
            required: false,
            regex: "",
            textSize: "h5",
          }}
          onSubmit={onSubmit}
        />
      </div>
      <div>
        <EditableField
          fieldObject={{
            label: "Color del texto (formato RGB)",
            errorMessage: "Campo Requerido",
            fieldName: "certificateFontColor",
            type: "color",
            defaultValue: currentEvent.certificateFontColor || "#000000",
            required: true,
            regex: "",
            textSize: "h5",
          }}
          onSubmit={onSubmit}
        />
      </div>
      <div>
        <EditableField
          fieldObject={{
            label:
              "Posición del texto, (0 izquierda, 1 centro, 2 derecha, admite decimales)",
            errorMessage: "Campo Requerido",
            fieldName: "certificatePosition",
            type: "text",
            defaultValue: currentEvent.certificatePosition || 1,
            required: true,
            regex: "",
            textSize: "h5",
            min: 0,
            max: 2,
          }}
          onSubmit={onSubmit}
        />
      </div>
    </Card>
  );
}
