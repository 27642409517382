import React from 'react';
import { connect } from 'react-redux'
import {eventParticipants,
		registryConfiguration} from '../../../../../data-store/actions/registry-actions';

import IncomeRow from './IncomeRow';


class IncomeTableContView extends React.Component {

	constructor(props){
		super(props)

	}
	render() {

		var cantidadRegistros = 0;
		for(var i in this.props.eventParticipants){
		cantidadRegistros++;
		}

		var conteoActual = 0;

		var dataTable = [];
		var perfilUsuarioEvento = this.props.registryAutorizedUsers[this.props.userID].perfil;
		for(var i in this.props.eventParticipants){
			conteoActual++;
			if(perfilUsuarioEvento == "master"
				|| perfilUsuarioEvento == "MasterCliente"
				|| perfilUsuarioEvento == "MasterOrganizador"
				|| this.props.userID == this.props.eventParticipants[i].usuarioRegistra){
					if((cantidadRegistros-this.props.quantity) < conteoActual){
						dataTable.push(<IncomeRow key={i} 
							inscritoData={this.props.eventParticipants[i]} 
							inscritoKey={i} 
							camposAdicionales={this.props.camposAdicionales} 
							productosAdicionales={this.props.productosAdicionales}
							filter={this.props.filter}/>
							);
					}
				}
		}
			return (
                <tbody>
                    {dataTable}
                </tbody>
        );
	}
  }

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

const mapStateToProps = (state) => {
	return {		
		eventParticipants : state.registry.eventParticipants,
    registryAutorizedUsers : state.registry.registryAutorizedUsers,
        userID : state.atlete.userID
	}
  }
  
const IncomeTableCont = connect(
	mapStateToProps,
	mapDispatchToProps
)(IncomeTableContView);

export default IncomeTableCont;
