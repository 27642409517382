/**
 * Created by avaldez on 12/20/16.
 */
import React from 'react';


const ContactUs = () => (
	<div>
	<h5 className="white-text">Contacto</h5>
	<ul>
		<li>
			<a className="white-text left-align" href="mailto:info@dashport.run">
				<i className="fa fa-envelope"/>
				&nbsp;
				info@dashport.run
			</a>
		</li>
		<li>
			<a className="white-text left-align"
			   href="https://www.facebook.com/dashportrun/"
			   target="_blank"
			>
				<i className="fa fa-facebook-official"/>
				&nbsp;
				Facebook Dashportrun
			</a>
		</li>
	</ul>
</div>

)
export default ContactUs;
