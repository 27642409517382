import React from 'react';
import DynamicInputs from '../../../../DynamicInput/DynamicInputs';
import BackForwardButton from './BackForwardButton';

class EspecificAtleteDataForm extends React.Component {
  constructor(props){
    super(props);

  }


  render(){
    console.log(this.props.actualValues);
 
    let aditionalInputs = [];
    let inputValue = "";
    if(this.props.registryConfiguration != undefined){
      for(var i in this.props.registryConfiguration.formulario){
        let campo = this.props.registryConfiguration.formulario[i];
          if(this.props.atleteAccount[campo.iDCampo] != undefined){
              inputValue = this.props.atleteAccount[campo.iDCampo];
          }else if(this.props.actualValues[campo.iDCampo] != undefined){
              inputValue = this.props.actualValues[campo.iDCampo];
          }else{
              inputValue = null
          }
  
        if(this.props.registrytype != 2 && campo.hideInPublic){
          // condicional para ocultar o mostrar los campos adicioanles cuando sea 
          //registro en linea o cuando sea usuarios autorizados.
  
        }else{
          aditionalInputs.push(
            <DynamicInputs key={i} inputObject={campo} 
              actualValue={inputValue} 
              validate={this.props.validate}
              callbackchange={this.props.update}
              />
          );
        }
  
      }
  
    }

    return (<div className="row">
              {aditionalInputs}
            </div>
    );
  }
}

export default EspecificAtleteDataForm;
