import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Login from "../../../../Login/Login";
import CreateVirtualEvent from "./CreateVirtualEvent";
import MyOrganizedVirtualEvents from "./MyOrganizedVirtualEvents";

import { useForm, Controller } from "react-hook-form";
import {
  getUserOrganizedVirtualEvents,
  getUserOrganizedVirtualEventsOLD,
  getUserVirtualEvents,
} from "../../../../../data-store/actions/virtual-events-actions";
import AppContainer from "../../../../../AppContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function ChallengesOrganizerContainer(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const eventID = match.params.eventID;
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myOrganizedVirtualEvents = useSelector(
    (state) => state.virtualEvents.myOrganizedVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);

  useEffect(() => {
    if (myOrganizedVirtualEvents == undefined && userID != undefined) {
      //dispatch(getUserOrganizedVirtualEvents(userID));
      dispatch(getUserOrganizedVirtualEventsOLD(userID));
    }
  }, [myOrganizedVirtualEvents, userID]);

  const classes = useStyles();

  const { control, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  if (atleteAccount == null) {
    return <Login />;
  }

  return (
    <AppContainer>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h5>Dashboard de configuración de eventos</h5>
          </Grid>
          <Grid item xs={12}>
            <CreateVirtualEvent />
          </Grid>
          <Grid item xs={12}>
            <MyOrganizedVirtualEvents />
          </Grid>
        </Grid>
      </div>
    </AppContainer>
  );
}
