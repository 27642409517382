import * as types from "./action-types";
import * as fb from "../../firebase/firebasejs";
import { getPhotoFileToUpload, makeid } from "../../services/utils";
import { resetChat } from "./chat-actions";
import { store } from "../configureStore";
import M from "materialize-css";
import { getVirtualEventsMessages } from "./chat-actions";
import { firestore } from "firebase";

//###########################################################################3
export function resetCreateEvent() {
  return (dispatch) => {
    dispatch({
      type: types.CREATE_VIRTUAL_EVENT,
      createVirtualEvent: undefined,
    });
  };
}

//###########################################################################3
export function createEvent(eventData) {
  return (dispatch) => {
    const eventID =
      Math.round(Math.random() * 10000) +
      "-" +
      Math.round(Math.random() * 10000) +
      "-" +
      Math.round(Math.random() * 10000);
    eventData.eventID = eventID;
    return fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .set(eventData)
      .then(() => {
        console.log("Evento agregado en lista de eventos virtuales");
        dispatch({
          type: types.CREATE_VIRTUAL_EVENT,
          createVirtualEvent: eventData,
        });

        return fb.firestore
          .collection(fb.users)
          .doc(eventData.userCreate.userIDCreate)
          .collection(fb.myOrganizedVirtualEvents)
          .doc(eventID)
          .set(eventData);
      })
      .then(() => {
        console.log("Evento agregado en perfil de usuario como evento creado");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}

//###########################################################################3
export function validateRegistry(eventData, eventPassId, userData) {
  return (dispatch) => {
    console.log(eventData.eventID);
    console.log(eventPassId);
    console.log(fb.virtualEvents);
    console.log(fb.eventPass);

    const docRef = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventData.eventID)
      .collection(fb.eventPass)
      .doc(eventPassId);

    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          if (doc.data().active) {
            console.log(doc.data());
            userData.eventPass = eventPassId;
            if (doc.data().raceNumber) {
              userData.raceNumber = doc.data().raceNumber;
            }
            dispatch(participateEvent(eventData, userData));
            dispatch({
              type: types.CURRENT_EVENT_AUTHORIZED,
              currentEventAuthorized: true,
            });
          } else {
            M.toast("Código ya utilizado y agotado", 4000);
            dispatch({
              type: types.CURRENT_EVENT_AUTHORIZED,
              currentEventAuthorized: false,
            });
          }
        } else {
          M.toast("Código inválido", 4000);
          dispatch({
            type: types.CURRENT_EVENT_AUTHORIZED,
            currentEventAuthorized: false,
          });
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };
}
//###########################################################################3
export function participateEvent(eventData, participantData) {
  return (dispatch) => {
    console.log(participantData.userID);

    fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventData.eventID)
      .collection(fb.participants)
      .doc(participantData.userID)
      .set(participantData)
      .then(() => {
        console.log("Participante registrado en el evento!");
        dispatch({
          type: types.REGISTRY_VIRTUAL_EVENT,
          registryVirtualEvent: participantData,
        });

        return fb.firestore
          .collection(fb.users)
          .doc(participantData.userID)
          .collection(fb.myVirtualEvents)
          .doc(eventData.eventID)
          .set({ active: true, eventData: eventData });
      })
      .then(() => {
        console.log("Registro actualizado en la vista de participantes!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###########################################################################3
export function getUserVirtualEvents(userID) {
  console.log("myVirtualEvents");

  if (window.$userVirtualEvents != undefined) {
    console.log("$userVirtualEvents");
    window.$userVirtualEvents();
  }

  return (dispatch) => {
    if (userID === undefined) {
      return false;
    }
    window.$userVirtualEvents = fb.firestore
      .collection(fb.users)
      .doc(userID)
      .collection(fb.myVirtualEvents)
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            console.log("my virtualevent ADD: ", change.doc.data());
            dispatch({
              type: types.USER_MY_VIRTUAL_EVENTS,
              addEvent: change.doc.data(),
              addEventId: change.doc.id,
            });
          }
          if (change.type === "modified") {
            console.log("my virtualevent CHANGE: ", change.doc.data());
            dispatch({
              type: types.USER_MY_VIRTUAL_EVENTS,
              addEvent: change.doc.data(),
              addEventId: change.doc.id,
            });
            if (
              change.doc.id ===
              store.getState().virtualEvents.currentEvent.eventID
            ) {
              dispatch(
                getEventData(
                  change.doc.id,
                  store.getState().virtualEvents.myVirtualEvents,
                  store.getState().atlete.userID
                )
              );
            }
          }
          if (change.type === "removed") {
            console.log("Removed city: ", change.doc.data());
          }
        });
      });
  };
}
//###########################################################################3
export function getUserOrganizedVirtualEvents(userID) {
  console.log("getUserOrganizedVirtualEvents");

  if (window.$userOrganizedVirtualEvents != undefined) {
    console.log("$userOrganizedVirtualEvents");
    window.$userOrganizedVirtualEventsAuthorized();
  }
  return (dispatch) => {
    window.$userOrganizedVirtualEventsAuthorized = fb.firestore
      .collection(fb.virtualEvents)
      .where("authorized", "array-contains", userID)
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            console.log("my virtualeventorg ADD: ", change.doc.data());
            dispatch({
              type: types.USER_MY_ORGANIZED_VIRTUAL_EVENTS,
              addOrganizedEvent: change.doc.data(),
              addOrganizedEventId: change.doc.id,
            });
          }
          if (change.type === "modified") {
            console.log("my virtualeventorg CHANGE: ", change.doc.data());
            dispatch({
              type: types.USER_MY_ORGANIZED_VIRTUAL_EVENTS,
              addOrganizedEvent: change.doc.data(),
              addOrganizedEventId: change.doc.id,
            });
            if (
              change.doc.id ===
              store.getState().virtualEvents.currentEvent.eventID
            ) {
              dispatch(
                getEventData(
                  change.doc.id,
                  store.getState().virtualEvents.myOrganizedVirtualEvents,
                  store.getState().atlete.userID
                )
              );
            }
          }
          if (change.type === "removed") {
            console.log("Removed city: ", change.doc.data());
          }
        });
      });
  };
}
//###########################################################################3
export function getUserOrganizedVirtualEventsOLD(userID) {
  console.log("getUserOrganizedVirtualEvents");

  if (window.$userOrganizedVirtualEvents != undefined) {
    console.log("$userOrganizedVirtualEvents");
    window.$userOrganizedVirtualEvents();
  }
  return (dispatch) => {
    window.$userOrganizedVirtualEvents = fb.firestore
      .collection(fb.users)
      .doc(userID)
      .collection(fb.myOrganizedVirtualEvents)
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            console.log("my virtualeventorg ADD: ", change.doc.data());
            dispatch({
              type: types.USER_MY_ORGANIZED_VIRTUAL_EVENTS,
              addOrganizedEvent: change.doc.data(),
              addOrganizedEventId: change.doc.id,
            });
          }
          if (change.type === "modified") {
            console.log("my virtualeventorg CHANGE: ", change.doc.data());
            dispatch({
              type: types.USER_MY_ORGANIZED_VIRTUAL_EVENTS,
              addOrganizedEvent: change.doc.data(),
              addOrganizedEventId: change.doc.id,
            });
            if (
              change.doc.id ===
              store.getState().virtualEvents.currentEvent.eventID
            ) {
              dispatch(
                getEventData(
                  change.doc.id,
                  store.getState().virtualEvents.myOrganizedVirtualEvents,
                  store.getState().atlete.userID
                )
              );
            }
          }
          if (change.type === "removed") {
            console.log("Removed city: ", change.doc.data());
          }
        });
      });
  };
}
//###########################################################################3
export function getVirtualEventsList(startDate, endDate) {
  return (dispatch) => {
    const docRef = fb.firestore
      .collection(fb.virtualEvents)
      .orderBy("eventStart", "desc")
      .limit(150);

    docRef
      .get()
      .then(function (querySnapshot) {
        let eventList = [];

        querySnapshot.forEach(function (doc) {
          let eventData = doc.data();
          eventData.id = doc.id;
          eventList.push(eventData);
          dispatch({
            type: types.AUTHORIZED_EVENTS_LIST,
            authorizedEventsList: eventList,
          });
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  };
}

//###########################################################################

//###########################################################################3
export function validateUserInEvent(eventID, userID) {
  return (dispatch) => {
    console.log(eventID);
    const docRef = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.participants)
      .doc(userID);

    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch(getEventParticipants(eventID));
          dispatch({
            type: types.CURRENT_EVENT_AUTHORIZED,
            currentEventAuthorized: true,
          });
          console.log("Document data:", doc.data());
        } else {
          dispatch({
            type: types.CURRENT_EVENT_AUTHORIZED,
            currentEventAuthorized: false,
          });
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };
}

//###########################################################################
export function getMyOrganizedEventAllData(
  eventID,
  myOrganizedVirtualEvents,
  userID
) {
  return (dispatch) => {
    if (userID != undefined) {
      if (myOrganizedVirtualEvents === undefined && userID != undefined) {
        console.log("getUserOrganizedVirtualEvents");
        //dispatch(getUserOrganizedVirtualEvents(userID));
        dispatch(getUserOrganizedVirtualEventsOLD(userID));
      }
      dispatch(getEventData(eventID, myOrganizedVirtualEvents, userID));
      dispatch(getVirtualEventsMessages(userID, eventID, true));
      dispatch(getEventParticipants(eventID, userID));
      dispatch(getEventPass(eventID));
    }
  };
}
//###########################################################################
export function getMyParticipantEventAllData(eventID, myVirtualEvents, userID) {
  return (dispatch) => {};
}

//###########################################################################
export function getEventData(eventID, myVirtualEvents, userID) {
  if (window.$getEventData != undefined) {
    console.log("$getEventData");
    window.$getEventData();
  }

  return (dispatch) => {
    console.log("getEventData");
    console.log(eventID);
    console.log(myVirtualEvents);
    if (userID === undefined) {
      return false;
    }
    dispatch({
      type: types.CURRENT_EVENT_REQUESTED,
      currentEventReq: eventID,
    });
    window.$getEventData = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .onSnapshot(function (doc) {
        console.log("Current data: ", doc.data());
        const currentEvent = store.getState().virtualEvents.currentEvent;
        if (doc.exists) {
          if (
            currentEvent !== undefined &&
            currentEvent.eventID != doc.data().eventID
          ) {
            console.log("Cambio un evento diferente al actual", doc.data());
          } else {
            dispatch({
              type: types.CURRENT_EVENT,
              currentEvent: doc.data(),
              currentEventReq: eventID,
            });
          }
          console.log("Document data:", doc.data());
        } else {
          dispatch({
            type: types.CURRENT_EVENT,
            currentEvent: {},
            currentEventReq: eventID,
          });
          console.log("No such document!");
        }
      });
  };
}

//###########################################################################
export function resetLastEvent() {
  return (dispatch) => {
    dispatch({
      type: types.RESET_CURRENT_EVENT,
      currentEvent: undefined,
      currentEventReq: false,
      currentEventParticipants: {},
      currentEventParticipantsRequested: false,
      eventPass: {},
    });
    dispatch(resetChat());
  };
}
//###########################################################################
export function getEventParticipants(eventID, userID) {
  console.log("getPartiti");

  if (window.$unsubscribeEventParticipants != undefined) {
    console.log("unsubscribeParticipants");
    window.$unsubscribeEventParticipants();
  }

  return (dispatch) => {
    if (userID === undefined) {
      return false;
    }
    dispatch({
      type: types.CURRENT_EVENT_PARTICIPANTS_REQUEST,
      currentEventParticipantsRequested: eventID,
    });

    window.$unsubscribeEventParticipants = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.participants)
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            //console.log("participants ADD: ", change.doc.data());
            dispatch({
              type: types.CURRENT_EVENT_PARTICIPANTS,
              participant: change.doc.data(),
              participantId: change.doc.id,
            });
          }
          if (change.type === "modified") {
            //console.log("participants CHANGE: ", change.doc.data());
            dispatch({
              type: types.CURRENT_EVENT_PARTICIPANTS,
              participant: change.doc.data(),
              participantId: change.doc.id,
            });
          }
          if (change.type === "removed") {
            console.log("Removed city: ", change.doc.data());
          }
        });
      });
  };
}
//###########################################################################
export function getEventPass(eventID) {
  if (window.$getEventPass != undefined) {
    console.log("$getEventPass");
    window.$getEventPass();
  }

  console.log("evenPassget ");
  return (dispatch) => {
    dispatch({
      type: types.EVENT_PASS_REQUEST,
      eventPassRequest: eventID,
    });

    window.$getEventPass = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.eventPass)
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            //console.log("evenPass org ADD: ", change.doc.data());
            dispatch({
              type: types.EVENT_PASS,
              eventPass: change.doc.data(),
              eventPassId: change.doc.id,
            });
          }
          if (change.type === "modified") {
            //console.log("evenPass org CHANGE: ", change.doc.data());
            dispatch({
              type: types.EVENT_PASS,
              eventPass: change.doc.data(),
              eventPassId: change.doc.id,
            });
          }
          if (change.type === "removed") {
            console.log("Removed city: ", change.doc.data());
          }
        });
      });
  };
}
//###########################################################################
export function createEventPass(eventID, objectData, eventPass, userID) {
  return (dispatch) => {
    const docRef = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.eventPass);

    for (let i = 0; i < objectData.codeQuantity; i++) {
      let code = makeid(6);
      const date = Date.now() / 1000;
      console.log(code);
      if (eventPass === undefined || eventPass[code] === undefined) {
        const object = {
          userID: userID,
          code: code,
          uses: objectData.codeQuantityUses / 1,
          active: true,
          date: date,
        };
        console.log(object);
        docRef.doc(code).set(object);
      } else {
        i--;
      }
    }
  };
}
//###########################################################################
//###########################################################################
export function createEventPass1(eventID, code) {
  return (dispatch) => {
    const docRef = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.eventPass);

    const date = Date.now() / 1000;
    console.log(code);
    const object = {
      userID: "evento_330",
      code: code,
      uses: 1,
      active: true,
      date: date,
    };
    console.log(object);
    docRef.doc(code).set(object);
  };
}
//###########################################################################
export function updateEventPass(eventID, code, objectData) {
  return (dispatch) => {
    const docRef = fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.eventPass);
    return docRef.doc(code).set(objectData, { merge: true });
  };
}
//###########################################################################
export function uploadPhoto(userID, eventID, file, photoType, currentEvent) {
  return (dispatch) => {
    console.log("uploadPhoto");
    return getPhotoFileToUpload(file, 500, photoType).then((image) => {
      if (image) {
        let fotoSinJPG = file.name.substring(0, file.name.length - 4);
        let nombreRandom =
          fotoSinJPG + "_" + Math.floor(Math.random() * 100000 + 1) + ".JPG";

        let storageRef = fb.storage.ref();
        let uploadTask = storageRef
          .child("users/" + userID + "/eventPhotos/" + nombreRandom)
          .put(image);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: file.name,
              uploadingPhotosProgress: progress,
            });

            console.log("Upload is " + progress + "% done");
          },
          function (error) {
            // Handle unsuccessful uploads
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: null,
              uploadingPhotosProgress: null,
            });
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                dispatch({
                  type: types.UPLOADING_PHOTOS,
                  uploadingPhotos: null,
                  uploadingPhotosProgress: null,
                });
                dispatch(
                  saveUploadedPhoto(userID, eventID, downloadURL, photoType)
                );
                console.log("File available at", downloadURL);
              });
          }
        );
      }
    });
  };
}
//###########################################################################
export function saveUploadedPhoto(userID, eventID, url, photoType) {
  return (dispatch) => {
    let photoObject = {
      photos: {
        [Date.now()]: {
          url: url,
          photoType: photoType,
        },
      },
    };
    if (photoType === "startLine") {
      photoObject.startLinePhoto = url;
    } else if (photoType === "finishLine") {
      photoObject.finishLinePhoto = url;
    }
    fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.participants)
      .doc(userID)
      .set(photoObject, { merge: true })
      .then(() => {
        console.log("Participante registrado en el evento!");

        return fb.firestore
          .collection(fb.users)
          .doc(userID)
          .collection(fb.myVirtualEvents)
          .doc(eventID)
          .set(photoObject, { merge: true });
      })
      .then(() => {
        console.log("Registro actualizado en la vista de participantes!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###########################################################################
export function uploadEventPhoto(userID, eventID, file, photoType) {
  return (dispatch) => {
    console.log("uploadPhoto");
    return getPhotoFileToUpload(file, 500, photoType).then((image) => {
      if (image) {
        let fotoSinJPG = file.name.substring(0, file.name.length - 4);
        let nombreRandom =
          fotoSinJPG + "_" + Math.floor(Math.random() * 100000 + 1) + ".JPG";

        let storageRef = fb.storage.ref();
        let uploadTask = storageRef
          .child("users/" + userID + "/eventPhotos/" + nombreRandom)
          .put(image);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: file.name,
              uploadingPhotosProgress: progress,
            });

            console.log("Upload is " + progress + "% done");
          },
          function (error) {
            // Handle unsuccessful uploads
            dispatch({
              type: types.UPLOADING_PHOTOS,
              uploadingPhotos: null,
              uploadingPhotosProgress: null,
            });
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                dispatch({
                  type: types.UPLOADING_PHOTOS,
                  uploadingPhotos: null,
                  uploadingPhotosProgress: null,
                });
                dispatch(
                  updateEventAtribute(userID, eventID, {
                    [photoType]: downloadURL,
                  })
                );
                console.log("File available at", downloadURL);
              });
          }
        );
      }
    });
  };
}
//###########################################################################
export function updateEventAtribute(userID, eventID, object) {
  return (dispatch) => {
    console.log("update atribute event", object);

    return fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .set(object, { merge: true })
      .then(() => {
        console.log("Participante registrado en el evento!");

        return fb.firestore
          .collection(fb.users)
          .doc(userID)
          .collection(fb.myOrganizedVirtualEvents)
          .doc(eventID)
          .set(object, { merge: true });
      })
      .then(() => {
        console.log("Registro actualizado en la vista de participantes!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}

//###########################################################################
export function uploadAudio(userID, eventID, file, photoType) {
  return (dispatch) => {
    let nombreRandom =
      Math.floor(Math.random() * 100000 + 1) +
      " - " +
      photoType +
      " - " +
      file.name;

    let storageRef = fb.storage.ref();
    let uploadTask = storageRef
      .child("users/" + userID + "/eventAudio/" + nombreRandom)
      .put(file);
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        dispatch({
          type: types.UPLOADING_PHOTOS,
          uploadingPhotos: file.name,
          uploadingPhotosProgress: progress,
        });

        console.log("Upload is " + progress + "% done");
      },
      function (error) {
        // Handle unsuccessful uploads
        dispatch({
          type: types.UPLOADING_PHOTOS,
          uploadingPhotos: null,
          uploadingPhotosProgress: null,
        });
      },
      function () {
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          dispatch({
            type: types.UPLOADING_PHOTOS,
            uploadingPhotos: null,
            uploadingPhotosProgress: null,
          });
          dispatch(
            updateEventAtribute(userID, eventID, { [photoType]: downloadURL })
          );
          console.log("File available at", downloadURL);
        });
      }
    );
  };
}

//###########################################################################
export function saveFinishTime(userID, eventID, Time, finish) {
  return (dispatch) => {
    // si finish está seteado tru guardo el valor en tiempo final,
    // si es false guardo el valor como tiempo meta.
    let timetoSet = {
      goalTime: Time,
    };
    if (finish) {
      timetoSet = {
        finishTime: Time,
        finishTimeDate: firestore.FieldValue.serverTimestamp(),
      };
    }

    fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.participants)
      .doc(userID)
      .set(timetoSet, { merge: true })
      .then(() => {
        console.log("Tiempo registrado en el evento!");

        return fb.firestore
          .collection(fb.users)
          .doc(userID)
          .collection(fb.myVirtualEvents)
          .doc(eventID)
          .set(timetoSet, { merge: true });
      })
      .then(() => {
        console.log("Registro actualizado en la vista de participantes!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###########################################################################
export function saveParticipantData(userID, eventID, object) {
  return (dispatch) => {
    // si finish está seteado tru guardo el valor en tiempo final,
    // si es false guardo el valor como tiempo meta.

    fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.participants)
      .doc(userID)
      .set(object, { merge: true })
      .then(() => {
        console.log("dat registrado en el evento!");

        return fb.firestore
          .collection(fb.users)
          .doc(userID)
          .collection(fb.myVirtualEvents)
          .doc(eventID)
          .set(object, { merge: true });
      })
      .then(() => {
        console.log("Registro actualizado en la vista de participantes!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###########################################################################
//###########################################################################
export function saveAtribute(userID, eventID, object) {
  return (dispatch) => {
    // si finish está seteado tru guardo el valor en tiempo final,
    // si es false guardo el valor como tiempo meta.

    fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.participants)
      .doc(userID)
      .set(object, { merge: true })
      .then(() => {
        console.log("Tiempo registrado en el evento!");

        return fb.firestore
          .collection(fb.users)
          .doc(userID)
          .collection(fb.myVirtualEvents)
          .doc(eventID)
          .set(object, { merge: true });
      })
      .then(() => {
        console.log("Registro actualizado en la vista de participantes!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
//###########################################################################
export function saveGarminActivity(userID, eventID, Time, garminActivity) {
  return (dispatch) => {
    let finishTimeEvent = {
      finishTime: Time,
      finishTimeDate: firestore.FieldValue.serverTimestamp(),
      garminActivitySummary: garminActivity,
    };
    console.log(finishTimeEvent);

    fb.firestore
      .collection(fb.virtualEvents)
      .doc(eventID)
      .collection(fb.participants)
      .doc(userID)
      .set(finishTimeEvent, { merge: true })
      .then(() => {
        console.log("Tiempo registrado en el evento! s");

        return fb.firestore
          .collection(fb.users)
          .doc(userID)
          .collection(fb.myVirtualEvents)
          .doc(eventID)
          .set(finishTimeEvent, { merge: true });
      })
      .then(() => {
        console.log("Tiempo registrado en el perfil del atleta!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };
}
