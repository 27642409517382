import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles((theme) => ({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
  },
  inputLabel: {
    color: theme.palette.primary.main,
  },
}));

export default function FieldColor(props) {
  const { fieldObject, register, errors } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
  } = fieldObject;
  const classes = useStyles();
  const [editMode, setEditMode] = useState(true);

  return (
    <TextField
      margin="none"
      fullWidth
      label={label}
      disabled={!editMode}
      helperText={errors[fieldName] ? errorMessage : null}
      error={Boolean(errors[fieldName])}
      defaultValue={defaultValue}
      inputRef={register({ required: required })}
      name={fieldName}
      type={"color"}
      InputLabelProps={{
        className: classes.inputLabel,
      }}
      InputProps={{
        className: classes.input,
        inputProps: {
          max: max,
          min: min,
        },
      }}
    />
  );
}
