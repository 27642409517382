import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';
import {segundosATiempo} from '../../../../services/utils'

function HeartRateZonesChart(props){
  const {activity, atleteAccount} = props;

  if(activity == undefined || activity == null){    
    return <h5>Vacio</h5>
  }


  var dataDispersion = {
    datasets: [
      {label: 'Heart Rate', data: [], borderColor:"red" },
    ],
    labels: []
}

var activityData = activity.samples;

  if(atleteAccount.training != undefined && 
    atleteAccount.training.zones != undefined && 
    atleteAccount.training.zones.heartRateZones != undefined &&
    atleteAccount.training.zones.heartRateZones[activity.summary.activityType] != undefined){

      var activityUserZones = atleteAccount.training.zones.heartRateZones[activity.summary.activityType];
      
      var timeBefore = 0;
      var timeByzones = {};
      for (var i in activityData){
        var zone = findZone(activityUserZones, activityData[i].heartRate); 
        var timeInZone = activityData[i].clockDurationInSeconds - timeBefore;
          
          // veo si ya hay un dato en esa zona si no hay creo el atributo
          if(timeByzones[zone] == undefined){
              timeByzones[zone] = {}
          }
          // sumo el tiempo al valor de tiempo anterior en esa zona
            timeByzones[zone] = timeByzones[zone] + timeInZone;
                // seteo el tiempo anterior para usarlo en el próximo
            timeBefore = activityData[i].clockDurationInSeconds;
        }

        for(var e in timeByzones){
          dataDispersion.labels.push("Zone "+e);
          dataDispersion.datasets[0].data.push(timeByzones[e]);
        }

    }

    const options = {
      scales: {
        xAxes: [{
            ticks: {
              callback: function(value) { 
                  return segundosATiempo(value); 
              }
          }
        }]
      },  
      tooltips: {
        // Disable the on-canvas tooltip
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            console.log(tooltipItem);
              var label = segundosATiempo(tooltipItem.xLabel);
              return label;
          }
      }
      },
      maintainAspectRatio:true
    }
    function findZone(activityUserZones, value){
      
      var initValue = 0;
      var zone = 0;
      for(var i in activityUserZones){
        if(value > initValue && value <= activityUserZones[i]){
          zone = i;
        }
        initValue = activityUserZones[i]
      }
      return zone;
    }


  return (			
    <div className="row z-depth-2 padding">
      <h5>Heart Rate Zones</h5>
        <div className="col s12">
              <HorizontalBar
                data={dataDispersion}
                options={options}
              />
        </div>
    </div>);
}

export default HeartRateZonesChart;
