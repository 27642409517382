import React from 'react';
import {Line} from 'react-chartjs-2';

const DashboardRegistryDispersion = ({jsonDiaInscrito}) => {

  var dataDispersion = {
    datasets: [{
        label: 'Inscritos por día',
        data: []
    }],
    labels: []
}
  for (var i in jsonDiaInscrito){
    dataDispersion.labels.push(i);
    dataDispersion.datasets[0].data.push(jsonDiaInscrito[i]);
  }

  return (			
    <div className="row z-depth-2 padding">
      <h5>Dispersión de inscripciones</h5>
        <div className="col s12">
              <Line
                data={dataDispersion}
              />
        </div>
    </div>);
}

export default DashboardRegistryDispersion;
