import React, { useState, useEffect } from "react";
import { translateForm } from "../../../../Fields/FieldUtils";
import FormRender from "../../../../Fields/FormRender";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.primary.main,
    borderBlock: 1,
  },
}));

export default function CardProduct(props) {
  const { updateOrder, producto, monedaAUsar, currentQuantity } = props;
  const [extraData, setExtraData] = useState({});
  const [quantity, setQuantity] = useState(currentQuantity || 0);
  const classes = useStyles();
  const theme = useTheme();
  const [add, setAdd] = useState(false);

  useEffect(() => {
    if (!producto.formularioProducto) {
      setAdd(true);
    }
  }, [producto]);

  function updateOrderFunc(producto, add) {
    // Aqui validar cantidad maxima a comprar
    // aqui ver si el item es igual aumentar uno la cantidad si no agregar nuevo item con distintas caracteristicas
    // aqui actualizar el producto que diga ya agregado y la opcion de quitar.
    //

    if (add) {
      setQuantity(quantity + 1);
    } else {
      setQuantity(quantity - 1);
    }
    var extraDataString = "";
    for (var i in extraData) {
      extraDataString = extraDataString + " - " + i + " : " + extraData[i];
    }

    var item = {
      name: producto.nombreProducto,
      description: producto.descripcionProducto + " " + extraDataString,
      quantity: 1,
      unit_price: Math.round(producto.precioProducto * 100) / 100,
      code: "producto-" + producto.codigoProducto,
      precioKey: "",
    };
    if (extraData != null) {
      item.extraData = extraData;
    }

    console.log(item);
    updateOrder(producto, item, add);
  }
  function onSubmit(data) {
    console.log(data);
    setAdd(true);
    setExtraData({ ...data });
  }

  //const anchoCampoArray = producto.anchoCampo.split(" ");
  console.log("producto", producto);
  if (!producto) {
    return <div></div>;
  }
  return (
    <Grid container direction="row" style={{ marginTop: 30 }}>
      <Grid
        item
        xs={
          12
          // producto.anchoCampo !== undefined
          //   ? producto.anchoCampo.substring(
          //       producto.anchoCampo.indexOf("s") + 1,
          //       2
          //     ) / 1
          //   : 12
        }
        sm={
          12
          // producto.anchoCampo !== undefined
          //   ? producto.anchoCampo.substring(
          //       producto.anchoCampo.indexOf("m") + 1,
          //       2
          //     ) / 1
          //   : 12
        }
      >
        <Card
          className={classes.root}
          style={{
            backgroundColor:
              producto.color !== undefined
                ? producto.color.split(" ")[0]
                : theme.palette.background.default,
          }}
        >
          <CardActionArea>
            {producto.imagenProducto && (
              <img
                className="activator anchoFotoProducto"
                src={producto.imagenProducto}
                alt="imagen producto"
              />

              // <CardMedia
              //   className={classes.media}
              //   image={producto.imagenProducto}
              //   title="Imagen producto"
              // />
            )}
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {producto.nombreProducto}
              </Typography>
              <Typography variant="body2" color="secondary" component="p">
                {producto.descripcionProducto}
              </Typography>
              <Typography variant="body2" color="secondary" component="p">
                Precio:{" "}
                {(producto.precioProducto * monedaAUsar.tipoCambio).toFixed(2)}{" "}
                {monedaAUsar.codigo}
              </Typography>
              <Typography variant="body2" color="secondary" component="p">
                Cantidad: {quantity}
              </Typography>
              <Typography variant="body2" color="secondary" component="p">
                Total:{" "}
                {(
                  producto.precioProducto *
                  monedaAUsar.tipoCambio *
                  quantity
                ).toFixed(2)}{" "}
                {monedaAUsar.codigo}
              </Typography>

              {producto.formularioProducto && (
                <FormRender
                  callback={onSubmit}
                  formObject={translateForm(producto.formularioProducto)}
                  submitButtonText={"Guardar datos"}
                />
              )}
            </CardContent>
          </CardActionArea>
          <CardActions>
            {producto.maxCantidad > quantity && add && (
              <Button
                size="small"
                color="primary"
                onClick={() => updateOrderFunc(producto, true)}
              >
                Agregar
              </Button>
            )}
            {producto.maxCantidad == quantity && add && (
              <Button size="small" color="primary" disabled>
                Agregar
              </Button>
            )}
            {quantity > 0 && (
              <Button
                size="small"
                color="primary"
                onClick={() => updateOrderFunc(producto, false)}
              >
                Eliminar
              </Button>
            )}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
