import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Footer(props) {
  const {
    title,
    text,
    padding,
    bgColor,
    buttonColor,
    buttonTextColor,
  } = props.componentConfig;
  return (
    <Grid
      container
      direction="row"
      style={{
        backgroundColor: bgColor,
        width: "100%",
        padding: padding,
      }}
    >
      <Grid item xs={12} sm={6} style={{ paddingLeft: "20px" }}>
        <Typography variant="h3" style={{ color: "white", textAlign: "left" }}>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ color: "white", textAlign: "left" }}
        >
          {text}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} style={{ verticalAlign: "center" }}></Grid>
    </Grid>
  );
}

Footer.displayName = "Footer";

export const componentDescription = {
  componentConfig: {
    title: {
      label: "Titulo",
      errorMessage: "Campo Requerido",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    text: {
      label: "Texto",
      errorMessage: "Campo Requerido",
      fieldName: "text",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
