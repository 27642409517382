import React from "react";
import { useSelector } from "react-redux";

import { Switch, Route, Router, Redirect } from "react-router-dom";

import Home from "./components/pages/Home";
import PrivacyPolicies from "./components/pages/PrivacyPolicies";
import CreateAccount from "./components/Login/CreateAccountNew";
import Login from "./components/Login/LoginSocialMedia";
import PassworRestore from "./components/Login/PassworRestore";
import ChallengesOrganizerConfig from "./components/pages/Athletes/VirtualEvents/ChallengesOrganizer/ChallengesOrganizerConfig/ChallengesOrganizerConfig";

import AthletesCotainer from "./components/pages/Athletes/AthletesCotainer";
import PastEventsList from "./components/pages/pastevents/PastEventsList";
import PastEventShow from "./components/pages/pastevents/PastEventShow";
import LiveEventsList from "./components/pages/liveevents/LiveEventsList";
import LiveEventShow from "./components/pages/liveevents/LiveEventShow";
import NextEventsList from "./components/pages/nextEvents/NextEventsList";
import NextEventShow from "./components/pages/nextEvents/NextEventShow";

import DashportServicesList from "./components/pages/dashportServices/DashportServicesList";
import AboutDashport from "./components/pages/dashportServices/AboutDashport";

import VirtualEventsCotainer from "./components/pages/Athletes/VirtualEvents/VirtualEventsCotainer";
import SpecificVirtualEventCotainer from "./components/pages/Athletes/VirtualEvents/SpecificVirtualEvent/SpecificVirtualEventCotainer";
import ChallengesOrganizerContainer from "./components/pages/Athletes/VirtualEvents/ChallengesOrganizer/ChallengesOrganizerContainer";
import AuthorizedUsersContainer from "./components/pages/AuthorizedUsers/AuthorizedUsersContainer";

import LayoutContainer from "./components/OrganizerWeb/LayoutContainers/LayoutContainer";
import Loading from "./components/OrganizerWeb/LayoutContainers/Loading";
import RegistryConfirmationPublic from "./components/pages/nextEvents/Registry/RegistryConfirmation/RegistryConfirmationPublic";
import CustomViewUrl from "./components/pages/reports/CustomViews/CustomViewUrl";
function Root() {
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const organizerWeb = useSelector((state) => state.organizer.organizerWeb);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        atleteAccount != null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  const dashportRoot = (
    <Switch>
      <Route exact path="/registro" component={CreateAccount} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/password-restore" component={PassworRestore} />
      <Route exact path="/servicios" component={DashportServicesList} />
      <Route exact path="/nosotros" component={AboutDashport} />
      <Route exact path="/atletas" component={AthletesCotainer} />
      <Route exact path="/proximos-eventos" component={NextEventsList} />
      <Route
        exact
        path="/proximos-eventos/:EventoIndex"
        component={NextEventShow}
      />
      <Route
        exact
        path="/detalle-inscrito"
        component={RegistryConfirmationPublic}
      />
      <Route exact path="/live/:EventoIndex" component={LiveEventShow} />
      <Route exact path="/live" component={LiveEventsList} />{" "}
      <Route
        exact
        path="/eventos-anteriores/:EventoIndex"
        component={PastEventShow}
      />
      <Route exact path="/eventos-anteriores" component={PastEventsList} />
      <Route exact path="/resultados/:EventoIndex" component={PastEventShow} />
      <Route exact path="/resultados" component={PastEventsList} />
      <Route exact path="/retos/" component={VirtualEventsCotainer} />
      <Route
        exact
        path="/retos/:eventID"
        component={SpecificVirtualEventCotainer}
      />
      <Route
        exact
        path="/retos/:eventID/code/:codeReceived"
        component={SpecificVirtualEventCotainer}
      />
      <Route
        exact
        path="/retos-organizador/"
        component={ChallengesOrganizerContainer}
      />
      <Route
        exact
        path="/retos-organizador/:eventID"
        component={ChallengesOrganizerConfig}
      />
      <Route exact path="/" component={Home} />
      <Route exact path="/politicas-privacidad/" component={PrivacyPolicies} />
      <Route
        exact
        path="/usuario-autorizado/"
        component={AuthorizedUsersContainer}
      />
      <Route
        exact
        path="/usuario-autorizado/:eventID"
        component={AuthorizedUsersContainer}
      />
      <Route exact path="/:route" component={LayoutContainer} />
      <Route exact path="/:route/:EventoIndex" component={LayoutContainer} />
      <Route
        exact
        path="/views/:EventoIndex/:viewIndex/:number"
        component={CustomViewUrl}
      />
      <Route
        exact
        path="/views/:EventoIndex/:viewIndex/"
        component={CustomViewUrl}
      />
      <Route
        exact
        path="/:route/:EventoIndex/:route2"
        component={LayoutContainer}
      />
    </Switch>
  );
  const organizerRoot = (
    <Switch>
      <Route exact path="/" component={LayoutContainer} />
      <Route exact path="/:route" component={LayoutContainer} />
      <Route exact path="/:route/:idregistro" component={LayoutContainer} />
      <Route
        exact
        path="/:route/:idregistro/:descarga"
        component={LayoutContainer}
      />
    </Switch>
  );

  //const url = "serialmtbnl.com";
  const url = window.location.hostname;
  if (
    url === "dashport.run" ||
    url === "localhost" ||
    url === "retos.dashport.run"
  ) {
    return dashportRoot;
  } else {
    if (!organizerWeb) {
      return <Loading />;
    }
    return organizerRoot;
  }
}

export default Root;
