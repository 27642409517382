import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import SesionView from "../Login/SesionView";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Forward from "@material-ui/icons/Forward";
import HomeIcon from "@material-ui/icons/Home";
import Event from "@material-ui/icons/Event";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import SvgIcon from "@material-ui/core/SvgIcon";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";

import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(1),
    color: "#EF4123",
  },
}));

const SideMenuView = ({ atleteAccount, handleDrawerClose }) => {
  const classes = useStyles();

  let authorizedUsers;
  if (atleteAccount && atleteAccount.perfil === 1) {
    authorizedUsers = (
      <List>
        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.link}>
            <SvgIcon>
              <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
            </SvgIcon>
          </ListItemIcon>
          <Link to={`/usuario-autorizado/`}>
            <ListItemText
              className={classes.link}
              primary={"Usuarios Autorizados"}
            />
          </Link>
        </ListItem>
      </List>
    );
  }

  return (
    <div>
      <SesionView handleDrawerClose={handleDrawerClose} />
      <List>
        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.link}>
            <Forward />
          </ListItemIcon>
          <Link to={`/proximos-eventos/`}>
            <ListItemText
              className={classes.link}
              primary={"Próximos Eventos"}
            />
          </Link>
        </ListItem>
        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.link}>
            <SvgIcon>
              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
            </SvgIcon>
          </ListItemIcon>
          <Link to={`/resultados/`}>
            <ListItemText className={classes.link} primary={"Resultados"} />
          </Link>
        </ListItem>
        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.link}>
            <SvgIcon>
              <path d="M13.49 5.48c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-3.6 13.9l1-4.4 2.1 2v6h2v-7.5l-2.1-2 .6-3c1.3 1.5 3.3 2.5 5.5 2.5v-2c-1.9 0-3.5-1-4.3-2.4l-1-1.6c-.4-.6-1-1-1.7-1-.3 0-.5.1-.8.1l-5.2 2.2v4.7h2v-3.4l1.8-.7-1.6 8.1-4.9-1-.4 2 7 1.4z" />
            </SvgIcon>
          </ListItemIcon>
          <Link to={`/live/`}>
            <ListItemText className={classes.link} primary={"Live"} />
          </Link>
        </ListItem>

        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.link}>
            <EmojiEventsIcon />
          </ListItemIcon>
          <Link to={`/retos/`}>
            <ListItemText
              className={classes.link}
              primary={"Retos Virtuales"}
            />
          </Link>
        </ListItem>
        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.link}>
            <Event />
          </ListItemIcon>
          <Link to={`/retos-organizador/`}>
            <ListItemText
              className={classes.link}
              primary={"Mis retos Organizados"}
            />
          </Link>
        </ListItem>
        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.link}>
            <HomeIcon />
          </ListItemIcon>
          <Link to={`/`}>
            <ListItemText className={classes.link} primary={"Inicio"} />
          </Link>
        </ListItem>
        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.link}>
            <DirectionsRunIcon />
          </ListItemIcon>
          <Link to={`/atletas/`}>
            <ListItemText className={classes.link} primary={"Mi perfil"} />
          </Link>
        </ListItem>

        {/* <ListItem button  onClick={handleDrawerClose}>
		  <ListItemIcon className={classes.link}><Event/></ListItemIcon>
			<Link to={`/mis-eventos/`}>
				<ListItemText className={classes.link} primary={"Mis Eventos"}/> 
			</Link>
		</ListItem> */}

        <Divider />

        <ListItem button onClick={handleDrawerClose}>
          <ListItemIcon className={classes.link}>
            <InboxIcon />
          </ListItemIcon>
          <Link to={`/servicios/`}>
            <ListItemText className={classes.link} primary={"Sobre Dashport"} />
          </Link>
        </ListItem>
      </List>
      <Divider />
      {authorizedUsers}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    atleteAccount: state.atlete.atleteAccount,
  };
};

const SideMenu = connect(mapStateToProps)(SideMenuView);

export default SideMenu;
