import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function Title(props) {
  const { title, bgColor, position, textColor } = props.componentConfig;
  return (
    <Grid container direction="row" style={{ backgroundColor: bgColor }}>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          style={{ color: textColor, textAlign: position, marginTop: 30 }}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

Title.displayName = "Titulo";

export const componentDescription = {
  componentConfig: {
    title: {
      label: "Titulo",
      errorMessage: "Campo Requerido",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    position: {
      label: "Posición Horizontal",
      errorMessage: "Campo Requerido",
      fieldName: "position",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "left",
          label: "Izquierda",
        },
        {
          value: "center",
          label: "Centro",
        },
        {
          value: "right",
          label: "Derecha",
        },
      ],
    },
    textColor: {
      label: "Color del texto",
      errorMessage: "Campo Requerido",
      fieldName: "textColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
