import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

import Login from "../../Login/Login";
import VirtualEventsOrganizerCard from "../Athletes/VirtualEvents/ChallengesOrganizer/VirtualEventsOrganizerCard";

import {
  resetLastEvent,
  getVirtualEventsList,
} from "../../../data-store/actions/virtual-events-actions";

import Typography from "@material-ui/core/Typography";
import AppContainer from "../../../AppContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function AuthorizedUsersContainer(props) {
  const dispatch = useDispatch();
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  let myOrganizedVirtualEvents = useSelector(
    (state) => state.virtualEvents.myOrganizedVirtualEvents
  );
  const userID = useSelector((state) => state.atlete.userID);
  const authorizedEventsList = useSelector(
    (state) => state.virtualEvents.authorizedEventsList
  );
  const classes = useStyles();

  useEffect(() => {
    dispatch(resetLastEvent());
    if (authorizedEventsList.length === 0) {
      dispatch(getVirtualEventsList());
    }
  }, []);

  if (athleteAccount == null || athleteAccount.perfil != 1) {
    return <Login />;
  }

  console.log("authorizedEventsList", authorizedEventsList);

  return (
    <AppContainer>
      <div className={classes.root}>
        <Typography gutterBottom variant="h3" component="h2">
          Eventos virtuales organizados por los usuarios
        </Typography>
        <Grid container spacing={3}>
          {authorizedEventsList.map((event, i) => (
            <Grid item xs={12} sm={6} key={i}>
              <Link to={"/retos-organizador/" + event.id}>
                <VirtualEventsOrganizerCard
                  showButtons={false}
                  currentEvent={event}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </AppContainer>
  );
}
