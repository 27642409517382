import React from "react";
import { connect } from "react-redux";
import { initEvents } from "../../data-store/actions/events-actions";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AppContainer from "../../AppContainer";

const HomeView = ({ events, onTodoClick, eventos, context }) => {
  const SetTextoInicio = () => {
    let textoInicio = [
      <div>
        <h1>Si correr es tu pasión</h1>
        <h4>Dashport te interesa</h4>
      </div>,
      <div>
        <h1>Si eres activo fisicamente</h1>
        <h4>Dashport te interesa</h4>
      </div>,
      <div>
        <h1>Si crees que todos necesitamos ejercitarnos</h1>
        <h4>Dashport te interesa</h4>
      </div>,
      <div>
        <h1>Si entrenar te hace feliz</h1>
        <h4>Dashport te interesa</h4>
      </div>,
      <div>
        <h1>Si adoras las carreras</h1>
        <h4>Dashport te interesa</h4>
      </div>,
      <div>
        <h1>Si crees que correr crea mejores personas</h1>
        <h4>Dashport te interesa</h4>
      </div>,
      <div>
        <h1>Si tienes metas deportivas</h1>
        <h4>Dashport te interesa</h4>
      </div>,
      <div>
        <h1>Si participas en eventos deportivos</h1>
        <h4>Dashport te interesa</h4>
      </div>,
      <div>
        <h1>Si crees que el ejercicio crea un mundo mejor</h1>
        <h4>Dashport te interesa</h4>
      </div>,
      <div>
        <h1>Si tu próxima meta se mide en distancia y tiempo</h1>
        <h4>Dashport te interesa</h4>
      </div>,
    ];
    return textoInicio[Math.floor(Math.random() * textoInicio.length)];
  };

  return (
    <AppContainer>
      <div className="container-home">
        <div className="header center grey-text text-darken-3">
          {SetTextoInicio()}
        </div>
        <div className="row center">
          <Button
            variant="contained"
            component={Link}
            to={"/proximos-eventos/"}
            color="primary"
            size="large"
          >
            Próximos eventos
          </Button>
        </div>
      </div>
    </AppContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTodoClick: () => {
      dispatch(initEvents());
    },
  };
};

const Home = connect(mapStateToProps, mapDispatchToProps)(HomeView);

export default Home;
