import React from 'react';
//import { Parallax} from 'react-parallax';
import { Parallax} from 'react-materialize';
import foto from '../../assets/backgroundPhotos/fotoPrincipal_Dashport_bajaResolucion.jpg';

const ParalaxDashport = () => (
  <Parallax className="altoParalax" imageSrc={foto} />
)

export default ParalaxDashport;
