import React from "react";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

class EventClockView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
      actualTime: "00:00:00",
      clocks: [],
      indexMenu: 0,
    };
  }

  componentDidMount = () => {
    this.setState({ isMounted: true });
    this.initComponent();
  };
  componentWillUnmount = () => {
    this.setState({ isMounted: false });
    clearInterval(this.timer);
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (prevProps != this.props) {
      this.initComponent();
    }
  };

  initComponent = () => {
    clearInterval(this.timer);
    if (
      this.props.eventSubEvents != null &&
      this.props.eventSubEvents != undefined
    ) {
      let clocks = [];
      for (var i in this.props.eventSubEvents) {
        var StartTimesEvent = this.props.eventSubEvents[i].hSalidas;
        for (var e in StartTimesEvent) {
          clocks.push({
            texto:
              this.props.eventSubEvents[i].descripcionModalidad +
              " " +
              StartTimesEvent[e].descripcionSerie,
            valor: StartTimesEvent[e].hS,
          });
        }
      }
      this.setState({
        clocks: clocks,
        startTime: clocks[this.state.indexMenu].valor,
      });
      this.timer = setInterval(this.startclock, 1000);
    }
  };

  startclock = () => {
    if (this.state.isMounted) {
      if (
        this.state.startTime != null &&
        this.state.startTime != "0000-00-00 00:00:00"
      ) {
        var hora = new Date(this.state.startTime);
        var Ahora = new Date();

        var AhoraSev = Ahora.getTime() / 1000;
        var horaSev = hora.getTime() / 1000;

        var SegundosFinal = AhoraSev - horaSev;

        var hours = Math.floor(SegundosFinal / 3600);
        var minutes = Math.floor((SegundosFinal % 3600) / 60);
        var seconds = Math.floor(SegundosFinal % 60);

        //Anteponiendo un 0 a lass horas si son menos de 10
        hours = hours < 10 ? "0" + hours : hours;

        //Anteponiendo un 0 a los minutos si son menos de 10
        minutes = minutes < 10 ? "0" + minutes : minutes;

        //Anteponiendo un 0 a los segundos si son menos de 10
        seconds = seconds < 10 ? "0" + seconds : seconds;

        var Mostrar = hours + ":" + minutes + ":" + seconds; // 2:41:30
        this.setState({ actualTime: Mostrar });
      } else {
        this.setState({ actualTime: "00:00:00" });
      }
    }
  };

  handleClick = (event) => {
    this.setState({ openMenu: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ openMenu: null });
  };
  handleMenuItemClick = (event, index) => {
    let startTime = this.state.startTime;
    if (this.state.clocks != []) {
      startTime = this.state.clocks[index].valor;
    }
    this.setState({ openMenu: null, indexMenu: index, startTime: startTime });
  };

  render() {
    return (
      <Grid
        container
        item
        container
        spacing={1}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid
          item
          xs={12}
          style={{
            fontSize: this.props.fontSize / 4 + "px",
          }}
        >
          {this.state.clocks[this.state.indexMenu] != undefined
            ? this.state.clocks[this.state.indexMenu].texto
            : ""}
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={(event) => this.handleClick(event)}
            style={{
              fontSize: this.props.fontSize + "px",
              fontFamily: "digital-7Final",
            }}
          >
            {this.state.actualTime}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={this.state.openMenu}
            keepMounted
            open={Boolean(this.state.openMenu)}
            onClose={this.handleClose}
          >
            {this.state.clocks.map((value, index) => {
              return (
                <MenuItem
                  key={index}
                  disabled={index === this.state.indexMenu}
                  selected={index === this.state.indexMenu}
                  onClick={(event) => this.handleMenuItemClick(event, index)}
                >
                  {value.texto}
                </MenuItem>
              );
            })}
          </Menu>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventSubEvents: state.events.eventSubEvents,
    eventDetail: state.events.eventDetail,
  };
};

const EventClock = connect(mapStateToProps, mapDispatchToProps)(EventClockView);

export default EventClock;
