import React from 'react';
import {deleteParticipantNumber} from '../../../../../../firebase/firebasejs';


class DeletedParticipantNumberROW extends React.Component {
//= ({eventoIndex, ParticipantDetail, incritoKey, userID, atleteAccount}) => {

    handleClick = () => {
        deleteParticipantNumber(this.props.eventoIndex,
                        this.props.ParticipanDetailKey,
                        this.props.ParticipanDetail,
                        this.props.userID,
                        this.props.atleteAccount);
      }

render(){

      var botonLiberar = (<a onClick={this.handleClick} className="waves-effect waves-light btn">liberar</a>);
      var numeroPrev = (this.props.ParticipanDetail.numero == null )?"No Asignado":this.props.ParticipanDetail.numero;
        var userBorra = "";
        var fechaBorra = "";
        var userLibera = "";
        var fechaLibera = "";
    
        for(var e in this.props.ParticipanDetail.changesRegistry){
          if(this.props.ParticipanDetail.changesRegistry[e].action == 1){
            userBorra = this.props.ParticipanDetail.changesRegistry[e].nombresUser +" "+this.props.ParticipanDetail.changesRegistry[e].apellidosUser;
  
            var d = new Date(this.props.ParticipanDetail.changesRegistry[e].date);
            var fecha = d.getHours()+":"+d.getMinutes()+":"+d.getSeconds()+" "+d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
            fechaBorra = fecha;
          }else if(this.props.ParticipanDetail.changesRegistry[e].action == 20){
            userLibera = this.props.ParticipanDetail.changesRegistry[e].nombresUser +" "+this.props.ParticipanDetail.changesRegistry[e].apellidosUser;
            var d = new Date(this.props.ParticipanDetail.changesRegistry[e].date);
            var fecha = d.getHours()+":"+d.getMinutes()+":"+d.getSeconds()+" "+d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
            fechaLibera = fecha;
            botonLiberar = "";
          }        
        }

        return (<tr>
            <td>{numeroPrev}</td>
            <td>{this.props.ParticipanDetail.nombres}</td>
            <td>{this.props.ParticipanDetail.apellidos}</td>
            <td>{userBorra}</td>
            <td>{fechaBorra}</td>
            <td>{userLibera}</td>
            <td>{fechaLibera}</td>
            <td>{botonLiberar}</td>
            </tr>
        );
    }
}
export default DeletedParticipantNumberROW;