import React from "react";
import { connect } from "react-redux";
import { helperMultisort } from "../../../services/utils";
import {
  ResultadosGanadores,
  ResultadosGenerales,
  ResultadosRama,
  ResultadosCategoria,
  controlesArrayFunc,
  ImprimirDatos,
} from "./reportsLogic";
import {
  detalleEvento,
  resultadosEvento,
  getPuntosControl,
} from "../../../data-store/actions/events-actions";

import "materialize-css/dist/css/materialize.min.css";
import Row from "./RowResultReport";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

import Background from "../../../assets/backgroundPhotos/fondogris_Dashport.jpg";

class ResultsReportNewView extends React.Component {
  constructor(props) {
    super(props);
    var filasTotal = this.getReport(true);
    this.state = {
      filas: filasTotal.slice(0, 20),
      filasTotal: filasTotal,
      keepRendering: false,
    };
  }

  componentDidMount = () => {
    console.log("did mount");
    window.addEventListener("scroll", this.handleScroll, true);
  };

  componentWillUpdate = () => {
    //this.getReport(false);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    this.setState({ keepRendering: false });
  };

  handleScroll = () => {
    //var el = document.getElementById("dialogReport");
    //window.scrollBy(100, 100);
    //console.log(window.pageYOffset);

    console.log("handelScroll");
    this.setState({
      keepRendering: true,
    });
    this.recursive();
  };

  recursive = () => {
    setTimeout(() => {
      let hasMore = this.state.filas.length + 1 < this.state.filasTotal.length;
      console.log(hasMore);
      this.setState((prev, props) => ({
        filas: this.state.filasTotal.slice(0, prev.filas.length + 5),
      }));
      // bloqueo la recursividad y se activa solo si continua haciendo scroll
      //if (hasMore && this.state.keepRendering) this.recursive();
    }, 0);
  };

  getReport = (init) => {
    var iDEvento = this.props.eventDetail.iDEvento;

    let checkControls = false;
    if (this.props.controles && this.props.puntosControl != undefined) {
      checkControls = true;
    }
    if (!this.props.controles) {
      checkControls = true;
    }
    if (this.props.eventResults != undefined && checkControls) {
      var resultadosOrdendos;

      if (this.props.reportResultType === 4) {
        resultadosOrdendos = ResultadosGanadores(this.props.eventResults);
      } else if (this.props.reportResultType === 1) {
        resultadosOrdendos = ResultadosGenerales(this.props.eventResults);
      } else if (this.props.reportResultType === 2) {
        resultadosOrdendos = ResultadosRama(this.props.eventResults);
      } else if (this.props.reportResultType === 3) {
        resultadosOrdendos = ResultadosCategoria(this.props.eventResults);
      } else {
        resultadosOrdendos = this.props.eventResults;
      }
    }
    var filas = ImprimirDatos(
      resultadosOrdendos,
      this.props.reportResultType,
      false,
      this.props.controles,
      iDEvento,
      this.props.eventConfiguration.timingConfig
    );

    if (init) {
      return filas;
    } else {
      this.setState((prev, props) => ({
        filas: filas.slice(0, prev.filas.length + 20),
        filasTotal: filas,
      }));
    }
  };

  render() {
    var element = "";

    if (
      this.props.eventDetail != undefined &&
      (this.props.puntosControl != undefined || !this.props.controles)
    ) {
      var controlesArray;
      if (this.props.controles) {
        controlesArray = controlesArrayFunc(this.props.puntosControl);
      }

      element = (
        <Table
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
          }}
          id="Tabla-1"
          className="bordered centered highlight"
        >
          <TableHead></TableHead>
          <TableBody id="Resultados">
            {this.state.filas.map((data, index) => (
              <Row
                key={index}
                data={data}
                controlesArray={controlesArray}
                controles={this.props.controles}
              />
            ))}
          </TableBody>
        </Table>
      );
    } else {
      element = <h5>Cargando</h5>;
    }
    return element;
  }
}

const mapDispatchToProps = (dispatch, { match }) => {
  return {};
};

const mapStateToProps = (state) => {
  return {
    eventDetail: state.events.eventDetail,
    eventResults: state.events.eventResults,
    puntosControl: state.events.puntosControl,
    eventConfiguration: state.events.eventConfiguration,
  };
};

const ResultsReportNew = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsReportNewView);

export default ResultsReportNew;
