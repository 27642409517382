export const INIT_EVENTS = "INIT_EVENTS";
export const EVENT_DETAIL = "EVENT_DETAIL";
export const EVENT_CONFIGURATION = "EVENT_CONFIGURATION";
export const EVENT_RESULTS = "EVENT_RESULTS";
export const EVENT_PHOTOS_CONFIGURATION = "EVENT_PHOTOS_CONFIGURATION";
export const EVENT_POINTS_CONTROLS = "EVENT_POINTS_CONTROLS";
export const EVENT_USERS = "EVENT_USERS";
export const ATLETE_PHOTOS = "ATLETE_PHOTOS";
export const ATLETE_ACCOUNT = "ATLETE_ACCOUNT";
export const REQUESTTOKENGARMIN = "REQUESTTOKENGARMIN";
export const ACTIVITIESGARMIN = "ACTIVITIESGARMIN";
export const ACTIVITIESGARMINDETAIL = "ACTIVITIESGARMINDETAIL";
export const ACTIVITIESGARMINLOADING = "ACTIVITIESGARMINLOADING";

export const POSTWORKOUTGARMIN = "POSTWORKOUTGARMIN";

export const CUSTOM_STRINGS = "CUSTOM_STRINGS";

export const EVENT_CONVOCATORY = "EVENT_CONVOCATORY";
export const EVENT_SUBEVENTS = "EVENT_SUBEVENTS";
export const EVENT_PARTICIPANTS = "EVENT_PARTICIPANTS";
export const EVENT_PARTICIPANTS_CHANGED = "EVENT_PARTICIPANTS_CHANGED";
export const EVENT_PARTICIPANTS_REMOVED = "EVENT_PARTICIPANTS_REMOVED";
export const EVENT_PASS = "EVENT_PASS";
export const EVENT_PASS_REQUEST = "EVENT_PASS_REQUEST";
export const MESSAGES_REQUESTED = "MESSAGES_REQUESTED";
export const REGISTRY_CONFIGURATION = "REGISTRY_CONFIGURATION";
export const REGISTRY_AUTORIZED_USERS = "REGISTRY_AUTORIZED_USERS";
export const REGISTRY_PARTICIPANT_DATA = "REGISTRY_PARTICIPANT_DATA";
export const RESET_REGISTRY_PARTICIPANT_DATA =
  "RESET_REGISTRY_PARTICIPANT_DATA";

export const AUTHORIZED_EVENTS_LIST = "AUTHORIZED_EVENTS_LIST";

export const CREATE_VIRTUAL_EVENT = "CREATE_VIRTUAL_EVENT";
export const REGISTRY_VIRTUAL_EVENT = "REGISTRY_VIRTUAL_EVENT";
export const USER_MY_VIRTUAL_EVENTS = "USER_MY_VIRTUAL_EVENTS";
export const USER_MY_ORGANIZED_VIRTUAL_EVENTS =
  "USER_MY_ORGANIZED_VIRTUAL_EVENTS";
export const CURRENT_EVENT = "CURRENT_EVENT";
export const CURRENT_EVENT_REQUESTED = "CURRENT_EVENT_REQUESTED";
export const RESET_CURRENT_EVENT = "RESET_CURRENT_EVENT";
export const RESET_USER_ACCOUNT = "RESET_USER_ACCOUNT";

export const CURRENT_EVENT_AUTHORIZED = "CURRENT_EVENT_AUTHORIZED";
export const CURRENT_EVENT_PARTICIPANTS = "CURRENT_EVENT_PARTICIPANTS";
export const CURRENT_EVENT_PARTICIPANTS_REQUEST =
  "CURRENT_EVENT_PARTICIPANTS_REQUEST";

export const TRAKING_ACTIVITY_APP = "TRAKING_ACTIVITY_APP";

export const UPLOADING_PHOTOS = "UPLOADING_PHOTOS";

// chat
export const CHAT_VIRTUAL_EVENT = "CHAT_VIRTUAL_EVENT";
export const RESET_CURRENT_EVENT_CHAT = "RESET_CURRENT_EVENT_CHAT";

// organizer
export const ORGANIZER_CONFIG = "ORGANIZER_CONFIG";
export const ORGANIZER_WEB = "ORGANIZER_WEB";
export const EVENT_WEB_PUBLIC = "EVENT_WEB_PUBLIC";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const ORGANIZER_EVENTS = "ORGANIZER_EVENTS";

// form
export const REGISTRY_FORM_DATA = "REGISTRY_FORM_DATA";

export const EVENT_SUBEVENTS_REGISTRY = "EVENT_SUBEVENTS_REGISTRY";

export const CUSTOM_VIEWS = "CUSTOM_VIEWS";
