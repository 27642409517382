import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { useForm, Controller } from 'react-hook-form';

import Messages from './Messages';

const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  menu: {
    flex:1,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height:50,
    width:100,
  },
}));

export default function EventComunication(props){
  const { eventID } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const myVirtualEvents = useSelector(state => state.virtualEvents.myVirtualEvents);
  let myOrganizedVirtualEvents = useSelector(state => state.virtualEvents.myOrganizedVirtualEvents);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(state => state.virtualEvents.currentEventParticipants);

  const classes = useStyles();

  const { control, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    console.log(data);
  }

  if(currentEvent == undefined){
    return <CircularProgress/>
  }else if(JSON.stringify(currentEvent) === JSON.stringify({})){
    return <h4>El id del evento ingresado no existe</h4>
  }

	  return (
			<div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h5" component="h2">
            Envia mensajes a los participantes del evento
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* <Messages/> */}
        </Grid>
      </Grid>
    </div>
	  );
	}
	