import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import configureStore from "./data-store/configureStore";

import $ from "jquery";
import "./index.css";
import App from "./App";

import { registerServiceWorker } from "./register-sw";
//registerServiceWorker();

$(document).ready(function () {
  //	var initialState = {events:{events:{"evento_1":{"nombreEvento":"prueba","idEvento":1},"evento_2":{"nombreEvento":"prueba2","idEvento":2}}}};
  const store = configureStore();
  ReactDOM.render(<App store={store} />, document.getElementById("root"));
});
