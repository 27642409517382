import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import LayoutResolver from "../../LayoutContainers/LayoutResolver";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function GetView(props) {
  const { view } = props.componentConfig;
  const organizerWeb = useSelector((state) => state.organizer.organizerWeb);
  console.log("organizerWeb", organizerWeb);
  return (
    <div>
      {organizerWeb &&
        organizerWeb.containers &&
        organizerWeb.containers[view] && (
          <LayoutResolver page={organizerWeb.containers[view]} />
        )}
    </div>
  );
}

GetView.displayName = "Insertar otra vista";

export const componentDescription = {
  componentConfig: {
    title: {
      label: "Nombre de la vista (La vista no puede ser la misma vista actual)",
      errorMessage: "Campo Requerido",
      fieldName: "view",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
