import React from 'react';

const ChangesDetail = ({ParticipantDetail}) => {

    var tipoChangesJson = {
        1:"Borrado",
        2:"Reversa Borrado",
        3:"Cambio Correo",
        4:"Cambio Nombre",
        5:"Cambio Apellido",
        6:"Cambio Telefono",
        7:"Cambio fecha de nacimiento",
        8:"Cambio modalidad",
        9:"Cambio rama",
        10:"Cambio Categoria",
        11:"Cambio campos adicionales",
        20:"Folio liberado"
      };
    
      var changes = ParticipantDetail.changesRegistry;
    
      var addChange =[];
    for(var p in changes){
      var d = new Date(changes[p].date);
    var fecha = d.getHours()+":"+d.getMinutes()+":"+d.getSeconds()+" "+d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
    var tipoCambio = tipoChangesJson[changes[p].action];
    addChange.push(
        <tr key={p} className="itemOrderResume">
          <td className="mailDate" data-change-date={fecha}>{fecha}</td>
          <td className="userChange" data-change-user={`${changes[p].nombresUser} {changes[p].apellidosUser}`}>{changes[p].nombresUser} {changes[p].apellidosUser}</td>
          <td className="cambio" data-change-cambio={tipoCambio}>{tipoCambio}</td>
          <td className="cambio" data-change-cambio={changes[p].description}>{changes[p].description}</td>
    </tr>);
    }
      var tabla = (
      <div className="row">
      <hr/>
        <div className="card-panel col s12 m12 l12">
        <table className="bordered centered">
            <thead>
              <tr>
                  <th>Fecha</th>
                  <th>Usuario</th>
                  <th>Cambio</th>
                  <th>Descripcion</th>
              </tr>
            </thead>
            <tbody id="listadoProductos">
            {addChange}
    
            </tbody>
          </table>
        </div>
      </div>);
    
      return tabla;
    

}

export default ChangesDetail;
