import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

export default function TimeRemaining(props) {
  const {
    dateTime,
    bgColor,
    position,
    textColor,
    fontSize,
  } = props.componentConfig;

  const [actualTime, setActualTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    console.log(`initializing interval`);
    const interval = setInterval(startclock, 1000);

    return () => {
      console.log(`clearing interval`);
      clearInterval(interval);
    };
  }, []);

  // has no dependency - this will be called on-component-mount
  //trackEvent({ event: { event: "PageView", page: eventId } });
  function startclock() {
    var eventDate = new Date(dateTime);
    var Ahora = new Date();

    var AhoraSev = Ahora.getTime();
    var eventDateSev = eventDate.getTime();

    var distance = eventDateSev - AhoraSev;

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setActualTime({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    });
  }

  return (
    <Grid container direction="row" style={{ backgroundColor: bgColor }}>
      <Grid item xs={12}>
        <Grid container direction="row">
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h3"
              style={{
                color: textColor,
                textAlign: position,
                fontSize: fontSize,
                padding: "20px",
              }}
            >
              {actualTime.days}
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: textColor,
                textAlign: position,
              }}
            >
              Días
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h3"
              style={{
                color: textColor,
                textAlign: position,
                fontSize: fontSize,
                padding: "20px",
              }}
            >
              {actualTime.hours}
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: textColor,
                textAlign: position,
              }}
            >
              Horas
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h3"
              style={{
                color: textColor,
                textAlign: position,
                fontSize: fontSize,
                padding: "20px",
              }}
            >
              {actualTime.minutes}
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: textColor,
                textAlign: position,
              }}
            >
              Minutos
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h3"
              style={{
                color: textColor,
                textAlign: position,
                fontSize: fontSize,
                padding: "20px",
              }}
            >
              {actualTime.seconds}
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: textColor,
                textAlign: position,
              }}
            >
              Segundos
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

TimeRemaining.displayName = "Contador para el evento";

export const componentDescription = {
  componentConfig: {
    dateTime: {
      label: "Fecha Hora del evento",
      errorMessage: "",
      fieldName: "dateTime",
      type: "dateTime",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    fontSize: {
      label: "Tamaño de la fuente",
      errorMessage: "",
      fieldName: "fotSize",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    position: {
      label: "Posición Horizontal",
      errorMessage: "Campo Requerido",
      fieldName: "position",
      type: "select",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      optionsArray: [
        {
          value: "left",
          label: "Izquierda",
        },
        {
          value: "center",
          label: "Centro",
        },
        {
          value: "right",
          label: "Derecha",
        },
      ],
    },
    textColor: {
      label: "Color del texto",
      errorMessage: "Campo Requerido",
      fieldName: "textColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color del Fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
