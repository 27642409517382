import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Category from "./Category";

const useStyles = makeStyles((theme) => ({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.primary.main,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
    color: theme.palette.primary.main,
  },
  text: {
    position: "relative",
    paddingRight: 40,
    color: theme.palette.primary.main,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
    color: theme.palette.primary.main,
  },
}));

export default function Distance(props) {
  const {
    distance,
    gender,
    birth,
    currency,
    eventDate,
    callback,
    currentAge,
    categoryObject,
  } = props;
  const classes = useStyles();

  // function filterOptions(optionsArray, gender, birth) {
  //   for (var i in optionsArray) {
  //   }

  //   return;
  // }

  function calcularEdad(fechaNacimiento, fechaEvento, edadActual) {
    if (fechaNacimiento == undefined) {
      return 0;
    }

    // Si la fecha es correcta, calculamos la edad
    var values = fechaNacimiento.split("-");

    if (values[2] == undefined) {
      values = fechaNacimiento.split("/");
    }
    var dia = values[2];
    var mes = values[1];
    var ano = values[0];

    var fecha_hoy = fechaEvento.split("-");

    if (edadActual) {
      // si edad actual el true se toma la fecha del evento.
      var ahora_dia = fecha_hoy[2];
      var ahora_mes = fecha_hoy[1];
      var ahora_ano = fecha_hoy[0];
    } else {
      // tomamos el 31 de diciembre del a;o del evento
      var ahora_dia = 31;
      var ahora_mes = 12;
      var ahora_ano = fecha_hoy[0];
    }

    // realizamos el calculo
    var edad = ahora_ano - ano;
    if (ahora_mes < mes) {
      edad--;
    }
    if (mes == ahora_mes && ahora_dia < dia) {
      edad--;
    }
    if (edad > 1900) {
      edad -= 1900;
    }
    console.log("edad", edad);
    return edad;
  }

  const age = calcularEdad(birth, eventDate, currentAge || true);
  console.log("edad", gender);

  if (distance.ramaCat["rama_" + gender] === undefined) {
    console.log(gender);
    console.log(distance.ramaCat["rama_" + gender]);
    return (
      <Typography
        className={classes.text}
        gutterBottom
        color="primary"
        variant={"h6"}
        component="h2"
      >
        Esta distancia no tiene categorías para el genero seleccionado y edad
        seleccionada
      </Typography>
    );
  }

  return (
    <div style={{ margin: "10px", textAlign: "left" }}>
      <Typography gutterBottom color="secondary" variant={"h5"} component="h2">
        {distance.descripcionModalidad} - (
        {Math.round(
          distance.configModalidad.precio.preventa_1.precio_1 *
            currency.tipoCambio *
            100
        ) / 100}{" "}
        {currency.codigo})
      </Typography>

      {Object.values(distance.ramaCat["rama_" + gender].categorias).map(
        (category) => {
          if (category.edadDesde <= age && age <= category.edadHasta) {
            return (
              <Category
                callback={callback}
                categoryObject={categoryObject}
                distance={distance.codigoModalidad}
                category={category}
                gender={gender}
                birth={birth}
                eventDate={eventDate}
              />
            );
          }
        }
      )}
    </div>
  );
}
