import React from 'react';
import DynamicInputs from '../../../../DynamicInput/DynamicInputs';



class CardProduct extends React.Component {

  getValue = (valor, inputObject) => {
    this.setState({[inputObject.iDCampo]: valor});
    }

    updateOrder = (e, producto) => {

      // Aqui validar cantidad maxima a comprar
      // aqui ver si el item es igual aumentar uno la cantidad si no agregar nuevo item con distintas caracteristicas
      // aqui actualizar el producto que diga ya agregado y la opcion de quitar.
      //
      var extraData = this.state;
      var extraDataString = "";
      for(var i in extraData){
        extraDataString = extraDataString + " - " + i +" : " + extraData[i];
      }

      var item = {
        name:producto.nombreProducto,
        description:producto.descripcionProducto+" " + extraDataString,
        quantity:1,
        unit_price:Math.round(producto.precioProducto * 100) / 100,
        code:"producto-"+producto.codigoProducto,
        precioKey:""
      };
      if(extraData != null){
        item.extraData =extraData;
      }


      console.log(extraData);
      this.props.updateOrder(e, producto, item, true);
     
    }
  


    render(){
      var card;
      var producto = this.props.producto;

          let camposFormProducto = [];
          for(var i in producto.formularioProducto){
            let inputProducto = producto.formularioProducto[i]
            camposFormProducto.push(
              <DynamicInputs key={i} inputObject={producto.formularioProducto[i]} 
                actualValue={null} 
                callbackchange={this.getValue}
                />
            );
          }
      
      
        if(producto.imagenProducto != undefined){
          card = (<div className="col s12 m6 l6">
                    <div className="marginProducto z-depth-1">
                      <div className="waves-effect waves-block waves-light">
                        <img className="activator anchoFotoProducto" src={producto.imagenProducto}/>
                      </div>
                      <div>
                        <h5>{producto.nombreProducto}</h5>
                      </div>
                      <div>
                        <p>{producto.descripcionProducto}</p>
                        {camposFormProducto}
                      </div>
                      <hr/>
                      <div>
                        <a className="waves-effect waves-teal btn-flat red-text" 
                          onClick={(e) => this.updateOrder(e, producto)}>
                          precio {(producto.precioProducto * this.props.monedaAUsar.tipoCambio).toFixed(2)} {this.props.monedaAUsar.codigo} Agregar</a>
                      </div>
                    </div>
                  </div>);
      
        }else{
          card = (<div className="col s12 m6 l6">
                    <div className="marginProducto z-depth-1">
                      <div>
                        <h5>{producto.nombreProducto}</h5>
                      </div>
                      <div>
                        <p>{producto.descripcionProducto}</p>
                        {camposFormProducto}
                      </div>
                      <hr/>
                      <div>
                          <a className="waves-effect waves-teal btn-flat red-text" 
                          onClick={(e) => this.updateOrder(e, producto)}>
                          precio {(producto.precioProducto * this.props.monedaAUsar.tipoCambio).toFixed(2)} {this.props.monedaAUsar.codigo} Agregar</a>
                      </div>
                    </div>
                  </div>);
        }
      return card;
    }
}

export default CardProduct;
