export const StandarInputsData = { correo:{
  "campo" : 1,
  "etiqueta" : "Correo",
  "iDCampo" : "correo",
  "longitud" : 100,
  "observacion" : "",
  "tipoCampo" : "text",
  "validacion" : "Requerido",
  "valor" : "",
  "anchoCampo":"s12 m4 l4"
}, nombres:{
  "campo" : 2,
  "etiqueta" : "Nombres",
  "iDCampo" : "nombres",
  "longitud" : 100,
  "observacion" : "",
  "tipoCampo" : "text",
  "validacion" : "Requerido",
  "valor" : "",
  "anchoCampo":"s12 m6 l6"
},apellidos:{
  "campo" : 3,
  "etiqueta" : "Apellidos",
  "iDCampo" : "apellidos",
  "longitud" : 100,
  "observacion" : "",
  "tipoCampo" : "text",
  "validacion" : "Requerido",
  "valor" : "",
  "anchoCampo":"s12 m6 l6"
},apellidoMaterno:{
  "campo" : 3,
  "etiqueta" : "Apellido Materno",
  "iDCampo" : "apellidoMaterno",
  "longitud" : 100,
  "observacion" : "",
  "tipoCampo" : "text",
  "validacion" : "Requerido",
  "valor" : "",
  "anchoCampo":"s12 m3 l3"
},apellidoPaterno:{
  "campo" : 3,
  "etiqueta" : "Apellido Paterno",
  "iDCampo" : "apellidoPaterno",
  "longitud" : 100,
  "observacion" : "",
  "tipoCampo" : "text",
  "validacion" : "Requerido",
  "valor" : "",
  "anchoCampo":"s12 m3 l3"
},telefono:{
  "campo" : 4,
  "etiqueta" : "Telefono",
  "iDCampo" : "telefono",
  "longitud" : 100,
  "observacion" : "",
  "tipoCampo" : "text",
  "validacion" : "Requerido",
  "valor" : "",
  "anchoCampo":"s12 m4 l4"
},fechaNacimiento:{
  "campo" : 5,
  "etiqueta" : "Fecha de Nacimiento",
  "iDCampo" : "fechaNacimiento",
  "longitud" : 100,
  "observacion" : "",
  "tipoCampo" : "date",
  "validacion" : "Requerido",
  "valor" : "",
  "anchoCampo":"s12 m4 l4"
},genero:{
  "campo" : 6,
  "etiqueta" : "Genero",
  "iDCampo" : "rama",
  "longitud" : 100,
  "observacion" : "",
  "tipoCampo" : "Select",
  "validacion" : "Requerido",
  "selectLabel":"Genero",
  "valor" : [ {
    "texto" : "Femenino",
    "valor" : "F"
  },{
    "texto" : "Masculino",
    "valor" : "V"
  } ],
  "anchoCampo":"s12 m4 l4"
}};