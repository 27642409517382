import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function HeaderLanding(props) {
  const {
    logo,
    title,
    text,
    image,
    bgColor,
    imageHeight,
  } = props.componentConfig;
  return (
    <Grid
      container
      direction="row"
      style={{
        backgroundColor: bgColor,
        width: "100%",
        padding: "40px",
        paddingTop: 100,
      }}
    >
      <Grid item xs={12} sm={7} style={{ paddingTop: 50, paddingLeft: "20px" }}>
        <div
          style={{ width: "100%", alignContent: "left", alignItems: "left" }}
        >
          <img
            src={logo}
            style={{ display: "block", margin: "0" }}
            alt={title}
            height="100px"
          />
        </div>
        <Typography
          variant="h3"
          style={{ color: "white", textAlign: "left", marginTop: 30 }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ color: "white", textAlign: "justify", marginTop: 30 }}
        >
          {text}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={5}>
        <img src={image} alt={title} height={imageHeight} />
      </Grid>
    </Grid>
  );
}

HeaderLanding.displayName = "Header (logo y text inzquierda, imagen derecha)";

export const componentDescription = {
  componentConfig: {
    logo: {
      label: "Logo Para el Header",
      errorMessage: "Campo Requerido",
      fieldName: "logo",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    title: {
      label: "Titulo",
      errorMessage: "Campo Requerido",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    text: {
      label: "Texto",
      errorMessage: "Campo Requerido",
      fieldName: "text",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    image: {
      label: "Imagen a la derecha del header",
      errorMessage: "Campo Requerido",
      fieldName: "image",
      type: "photo",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    imageHeight: {
      label: "Alto de la imagen",
      errorMessage: "Campo Requerido",
      fieldName: "imageHeight",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
  },
};
