import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import {
  uploadEventPhoto,
  updateEventAtribute,
} from "../../../../../../../data-store/actions/virtual-events-actions";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import InputFileButton from "../../../InputFileButton";
import PhotoEditorDefault from "../../../PhotoEditorVirtual/PhotoEditorDefault";

import { DescargarCertificadoReg } from "../../../SpecificVirtualEvent/VirtualEventMenu/atleteDetail/certificadoLogic";
import EditableField from "../../../../../../EditableField/EditableField";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function EventRaceNumberImage(props) {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  let myOrganizedVirtualEvents = useSelector(
    (state) => state.virtualEvents.myOrganizedVirtualEvents
  );

  const classes = useStyles();

  const participantDemo = {
    athleteName: "Nombre",
    athleteLastName: "prueba",
    raceNumber: 1,
    distance: "10",
    cat: "Libre",
    athleteGender: "M",
    finishTime: "01:03:52",
    goalTime: "01:03:52",
    positionAbsolute: 3,
    positionAbsoluteGender: 2,
    positionAbsoluteCategory: 1,
  };

  const deletePicture = () => {
    dispatch(
      updateEventAtribute(userID, currentEvent.eventID, {
        raceNumberImage: null,
      })
    );
  };
  const onSubmit = (data) => {
    console.log(data);
    dispatch(updateEventAtribute(userID, currentEvent.eventID, data));
    //M.toast("Datos ajustados éxitosamente", 4000);
  };

  const callback = (photo) => {
    dispatch(
      uploadEventPhoto(
        userID,
        currentEvent.eventID,
        photo,
        "raceNumberImage",
        myOrganizedVirtualEvents
      )
    );
  };

  return (
    <Card className={classes.root}>
      <PhotoEditorDefault
        participant={participantDemo}
        currentEvent={currentEvent}
        urlPhoto={currentEvent.raceNumberImage}
        type={"raceNumber"}
      />

      <div>
        <InputFileButton
          type="image/*"
          callback={callback}
          text="Subir imagen para número de competencia"
        />
      </div>
      <div>
        <Button onClick={() => deletePicture()}>Borrar foto</Button>
      </div>
      <div>
        <EditableField
          fieldObject={{
            label: "Ocultar fondo blanco",
            errorMessage: "Campo Requerido",
            fieldName: "raceNumberWhiteBack",
            type: "switch",
            defaultValue: currentEvent.raceNumberWhiteBack || false,
            required: false,
            regex: "",
            textSize: "h5",
          }}
          onSubmit={onSubmit}
        />
      </div>
      <div>
        <EditableField
          fieldObject={{
            label: "Color del texto (formato RGB)",
            errorMessage: "Campo Requerido",
            fieldName: "raceNumberFontColor",
            type: "color",
            defaultValue: currentEvent.raceNumberFontColor || "#000000",
            required: true,
            regex: "",
            textSize: "h5",
          }}
          onSubmit={onSubmit}
        />
      </div>
    </Card>
  );
}
