import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
} from "@material-ui/core";
import Field from "./Field";
import FieldSelect from "./FieldSelect";
import FieldDate from "./FieldDate";

export default function FormRender(props) {
  const { formObject, callback, submitButtonText } = props;

  const { register, handleSubmit, errors, watch, control } = useForm();
  console.log("errors", errors);

  const onSubmit = (data) => {
    console.log(data);
    const dataNew = {};
    for (var i in data) {
      const index = i;
      const getInputType = Object.values(formObject).filter(
        (a) => a.fieldName === index
      );
      if (
        getInputType[0] &&
        (getInputType[0].max !== undefined || getInputType[0].min !== undefined)
      ) {
        dataNew[i] = parseFloat(data[i]) / 1;
      } else {
        dataNew[i] = data[i];
      }
    }
    callback(dataNew);
  };
  console.log("watchFieldEVent", watch);
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      {Object.values(formObject)
        .sort((a, b) =>
          a.order !== undefined && b.order !== undefined
            ? a.order - b.order
            : null
        )
        .map((field, index) => (
          <Field
            key={index}
            fieldObject={field}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            watch={watch}
            control={control}
          />
        ))}
      <Button
        type="submit"
        style={{ width: "100%" }}
        variant="contained"
        color="primary"
      >
        {submitButtonText}
      </Button>
    </form>
  );
}
