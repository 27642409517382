import React from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { Link } from "react-router-dom";

import { Icon, Row } from "react-materialize";
import M from "materialize-css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTracking } from "react-tracking";
//import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { auth } from "../../firebase/firebasejs";
import Typography from "@material-ui/core/Typography";
import AppContainer from "../../AppContainer";

export default function Login(props) {
  const { hideCreateAccountButton } = props;
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const { trackEvent } = useTracking();
  trackEvent({
    eventName: "Login",
    typeEvent: "PageView",
    params: { label: "Login" },
  });

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    auth
      .signInWithEmailAndPassword(data.correo, data.password)
      .then(() => {
        M.toast("Bienvenido", 4000);
      })
      .catch((error) => {
        // Handle Errors here.
        // ...
        M.toast("Error al inicar Sesion intente de nuevo" + error, 4000);
      });
  };

  if (userID != undefined) {
    return (
      <AppContainer>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper style={{ padding: 10, textAlign: "center" }}>
              <Typography gutterBottom variant="h5" component="h2">
                Bienvenido
              </Typography>

              <Row>
                <Typography gutterBottom variant="h2" component="h2">
                  <h2>
                    {atleteAccount.nombres + " " + atleteAccount.apellidos}
                  </h2>
                </Typography>
              </Row>
              <Button
                variant="contained"
                component={Link}
                to={"/retos/"}
                color="primary"
                size="large"
              >
                Mis retos
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper style={{ padding: 15 }}>
              <Typography gutterBottom variant="h4" component="h2">
                Iniciar Sesión
              </Typography>
              {/* <Button
              onClick={() => logInFacebook()}
              variant="contained"
              color="primary"
              style={{ width: "100%", margin: 10 }}
            >
              Login Facebook
            </Button>
            <Button
              onClick={() => logInGoogle()}
              variant="contained"
              color="primary"
              style={{ width: "100%", margin: 10 }}
            >
              Sesión con Google
            </Button> */}

              <Row>
                <label>Correo</label>
                <input
                  name="correo"
                  type="email"
                  ref={register({ required: true })}
                />
                {errors.correo && "Campo requerido"}
              </Row>
              <Row>
                <label>Contraseña</label>
                <input
                  name="password"
                  type="password"
                  ref={register({ required: true, minLength: 6 })}
                />
                {errors.password && "Mínimo 6 caracteres"}
              </Row>
              <Row>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: "100%", margin: 10 }}
                >
                  Iniciar Sesión
                </Button>
              </Row>
              <Row>
                <Link to="/password-restore">
                  <Button color="primary">Recuperar contraseña</Button>
                </Link>
                {!hideCreateAccountButton && (
                  <Link to="/registro">
                    <Button color="primary">Crear Cuenta</Button>
                  </Link>
                )}
              </Row>
              {/* <Row>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
            </Row> */}
            </Paper>
          </Grid>
        </Grid>
      </form>
    </AppContainer>
  );
}
