import React from "react";
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import track from "react-tracking";
import { InitTracking, TrakingData } from "./useTracking";
import LoadFonts from "./fonts/LoadFonts";
//import PushNotificationComponent from './PushNotificationComponent';

import "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import "./App.css";
import Root from "./Root";
import SimpleSnackbar from "./components/Dialog/SimpleSnackbar";
import { showSnackbar } from "./data-store/actions/organizers-actions";
//import Router from './components/navigation/Router'
//import MomentUtils from '@date-io/moment';
import { BrowserRouter } from "react-router-dom";

import { Helmet } from "react-helmet";

import Footer from "./components/footer/";
import ParallaxDashport from "./components/navigation-ui/ParallaxDashport";

import AppBarDashport from "./components/navigation-ui/AppBarDashport";

import { initEvents } from "./data-store/actions/events-actions";
import { atleteAccount } from "./data-store/actions/atlete-actions";
import { getOrganizerWeb } from "./data-store/actions/organizers-actions";
import { createEventPass1 } from "./data-store/actions/virtual-events-actions";
import ico from "./dashport_ico.ico";
const theme = createMuiTheme({
  typography: {
    fontFamily: "Maven, OCEAN,Helvetica, Arial, sans-serif",
  },

  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#ff4400",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#000000",
      main: "#2222221",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffffff",
    },
    // error: will use the default color
  },
  spacing: 4,
});

InitTracking();
//const url = "serialmtbnl.com";
const url = window.location.hostname;

const App = ({ store }) => {
  console.log("URL", url);
  console.log("URL", url.indexOf("dashport.run"));
  console.log("URL", url.indexOf("localhost"));

  const dashportWeb = (
    <Provider store={store}>
      {/* <PushNotificationComponent/>     */}
      <Helmet>
        <title>Dashport</title>
        <link rel="shortcut icon" href={ico} />
      </Helmet>

      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Root />
          <LoadFonts />
          <SimpleSnackbar />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
  const organizerWeb = (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Root />
          <SimpleSnackbar />

          <LoadFonts />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );

  // Condicional que define si se muestra la web de Dashport o la web del organizador o evento.
  if (
    url === "dashport.run" ||
    url === "localhost" ||
    url === "retos.dashport.run"
  ) {
    store.dispatch(initEvents());
    store.dispatch(atleteAccount());
    //store.dispatch(showSnackbar(true, "Funciona ", 20000));

    return dashportWeb;
  } else {
    console.log("organizerWEB");
    //const urlTest = "maratonsaltillo.com";
    const urlTest = url;
    store.dispatch(
      getOrganizerWeb(urlTest.split(".")[0] + "-" + urlTest.split(".")[1])
    );
    return organizerWeb;
  }
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};

const TrackedApp = track(
  // app-level tracking data
  { app: "dashport" },

  // top-level options
  {
    // custom dispatch to console.log in addition to pushing to dataLayer[]
    dispatch: (data) => {
      TrakingData(data);
    },
  }
)(App);

export default TrackedApp;
