import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Checkbox,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles({
  iconSave: {
    right: 0,
    top: 0,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
  },
});

export default function EditableFieldCheckbox(props) {
  const { fieldObject, onSubmit } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    optionsArray,
  } = fieldObject;
  const classes = useStyles();
  const { register, handleSubmit, errors, control } = useForm();
  const [editMode, setEditMode] = useState(false);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  function onSave() {
    setEditMode(false);
    onSubmit({ [fieldName]: currentValue });
  }

  function handleChange(event) {
    setCurrentValue(event.target.checked);
  }
  return editMode ? (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
    >
      <Grid container direction="row">
        <Grid item xs={10}>
          <input
            style={{ display: "none" }}
            type="checkbox"
            name={fieldName}
            ref={register}
            checked={currentValue}
            onChange={handleChange}
          />
          <FormControlLabel
            style={{ color: "white" }}
            value={currentValue}
            control={
              <Checkbox onChange={handleChange} checked={currentValue} />
            }
            label={label}
            labelPlacement="left"
          />

          {errors[fieldName] ? errorMessage : null}
        </Grid>
        <Grid item xs={2}>
          <IconButton
            className={classes.iconSave}
            onClick={handleSubmit(onSave)}
          >
            <SaveIcon />
          </IconButton>
        </Grid>
      </Grid>
      <div style={{ color: "white" }}></div>
    </form>
  ) : (
    <div>
      <Typography
        className={classes.text}
        gutterBottom
        variant={"subtitle2"}
        component="h2"
      >
        {label}:
      </Typography>
      <Typography
        className={classes.text}
        gutterBottom
        variant={textSize || "h5"}
        component="h2"
        style={{ minHeight: "30px" }}
      >
        {defaultValue && "Seleccionado"}
        {!defaultValue && "No Seleccionado"}
        <IconButton
          size={
            !textSize ||
            textSize === "h1" ||
            textSize === "h2" ||
            textSize === "h3" ||
            textSize === "h4" ||
            textSize === "h5"
              ? "medium"
              : "small"
          }
          className={classes.iconEdit}
          onClick={() => setEditMode(true)}
        >
          <EditIcon />
        </IconButton>
      </Typography>
    </div>
  );
}
