import React from 'react';
import ChangesROW from './ChangesROW';

const ChangesList= ({eventParticipants, userID, atleteAccount, eventDetail}) => {

    var dataTable =[];
    var eventoIndex = "evento_"+eventDetail.iDEvento;
   
    for(var i in eventParticipants){
        if(eventParticipants[i].changesRegistry != undefined){
        dataTable.push(<ChangesROW key={i} 
            eventoIndex={eventoIndex} 
            ParticipanDetail={eventParticipants[i]}
            ParticipanDetailKey={i}
            userID={userID}
            atleteAccount={atleteAccount}/>);
        }
      }
    return (
        <div>
            <h4>Lista de cambios efectuados</h4>
            <table className="striped">
                <thead>
                <tr>
                    <th>Folio</th>
                    <th>Nombres</th>
                    <th>Apellidos</th>
                    <th>Cantidad de cambios</th>
                    <th>Cambios</th>
                </tr>
                </thead>
                <tbody>
                    {dataTable}
                </tbody>
            </table>
        </div>
        );
	}
export default ChangesList;