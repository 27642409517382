import React from 'react';

const DetailOrderConfirmation = ({jsonMail, jsonTextos, idioma, reference, tipo}) => {


    var detalleOrden = [];
    var orden = jsonMail.datos.order;
    var sumaMonto = 0;
    var monedaUsadaFecha = jsonMail.datos.monedaBase;
    var monedaBase = jsonMail.datos.monedaBase;
  
    for(var i in orden){
      sumaMonto = sumaMonto + orden[i].unit_price * orden[i].quantity;
  
      detalleOrden.push(
      <div key={i} className="row">
        <div className="col s3 center-align">
          {orden[i].name}
        </div>
        <div className="col s3 center-align">
          {orden[i].description}
        </div>
        <div className="col s3 center-align">
          {orden[i].quantity}
        </div>
        <div className="col s3 center-align">
          {orden[i].unit_price * orden[i].quantity} {monedaBase.codigo}
        </div>
      </div>);
    }
  
  if(monedaUsadaFecha != undefined){
    if(monedaUsadaFecha.codigo != "MXN"){
      detalleOrden.push( 
      <div key={1000} className="row">
        <div className="col s9 right-align">
          Total en {monedaUsadaFecha.texto} 
        </div>
        <div className="col s3 center-align">
          {sumaMonto} {monedaUsadaFecha.codigo}
        </div>
      </div>);
      detalleOrden.push(
      <div key={1001} className="row">
        <div className="col s9 right-align">
          Pesos Méxicanos calculado a tasa de {monedaUsadaFecha.cambioAPeso} MXN/{monedaUsadaFecha.codigo}
        </div>
        <div className="col s3 center-align">
          {Math.floor((sumaMonto * monedaUsadaFecha.cambioAPeso) * 100 ) / 100} MXN
        </div>
      </div>);
    }else{
      detalleOrden.push(
      <div key={1000} className="row">
        <div className="col s9 right-align">
          Total en {monedaUsadaFecha.texto} {sumaMonto} {monedaUsadaFecha.codigo}
        </div>
        <div className="col s3 center-align">
          {sumaMonto} {monedaUsadaFecha.codigo}
        </div>
      </div>);
  
    }
  }
   
    return (

  <div>
    <div className="row">
      <h5 className="center-align">
        {jsonTextos[idioma].detalleOrden}
      </h5>
    </div>
    <div className="row">
      <div className="col s3 center-align">
        <b>{jsonTextos[idioma].Producto}</b>
      </div>
      <div className="col s3 center-align">
        <b>{jsonTextos[idioma].Descripcion}</b>
      </div>
      <div className="col s3 center-align">
        <b>{jsonTextos[idioma].Cantidad}</b>
      </div>
      <div className="col s3 center-align">
        <b>{jsonTextos[idioma].Precio}</b>
      </div>
    </div>
  { detalleOrden }
  </div>);

  }

export default DetailOrderConfirmation;