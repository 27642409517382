import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect, dispatch, useSelector } from "react-redux";
//import { useTracking } from "react-tracking";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  icons: {
    width: 50,
    height: 50,
    color: theme.palette.primary.main,
  },
}));

export default function SocialMedia(props) {
  const {
    title,
    text,
    padding,
    bgColor,
    textColor,
    socialMedia,
  } = props.componentConfig;
  const classes = useStyles();

  const IconsBySellection = (network, color) => {
    if (network === "Facebook") {
      return (
        <FacebookIcon className={classes.icons} style={{ color: color }} />
      );
    } else if (network === "Youtube") {
      return <YouTubeIcon className={classes.icons} style={{ color: color }} />;
    } else if (network === "Instagram") {
      return (
        <InstagramIcon className={classes.icons} style={{ color: color }} />
      );
    } else if (network === "Twitter") {
      return <TwitterIcon className={classes.icons} style={{ color: color }} />;
    } else if (network === "LinkedIn") {
      return (
        <LinkedInIcon className={classes.icons} style={{ color: color }} />
      );
    } else if (network === "Email") {
      return <EmailIcon className={classes.icons} style={{ color: color }} />;
    } else if (network === "Phone") {
      return <PhoneIcon className={classes.icons} style={{ color: color }} />;
    } else {
      return null;
    }
  };

  return (
    <Grid
      container
      direction="row"
      style={{
        backgroundColor: bgColor,
        width: "100%",
        padding: padding,
        alignItems: "center",
      }}
    >
      <Grid item xs={12} sm={12} style={{ paddingLeft: "20px" }}>
        <Typography variant="h3" style={{ color: "white", textAlign: "left" }}>
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ color: "white", textAlign: "left" }}
        >
          {text}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} style={{ verticalAlign: "center" }}>
        {socialMedia &&
          socialMedia
            .filter((item) => !item.hidden)
            .map((box, i) => (
              <Grid container direction="row">
                <Grid key={i} item xs={2} sm={2} style={{ padding: 5 }}>
                  <div
                    style={{
                      width: "100%",
                      alignContent: "left",
                      alignItems: "left",
                    }}
                  >
                    {box.image &&
                      box.image !== "none" &&
                      IconsBySellection(box.image, box.iconColor)}

                    {box.imageUpload && (
                      <img
                        src={box.imageUpload}
                        alt={box.alt}
                        height={"50px"}
                      />
                    )}
                  </div>
                </Grid>
                <Grid
                  key={i}
                  item
                  xs={10}
                  sm={10}
                  style={{
                    padding: 15,
                    alignItems: "left",
                    verticalAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: "left",
                      color: { textColor },
                    }}
                  >
                    <Button
                      target="_blank"
                      href={box.url}
                      style={{
                        color: textColor,
                      }}
                    >
                      {box.text}
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            ))}
      </Grid>
    </Grid>
  );
}

SocialMedia.displayName = "SocialMedia";

export const componentDescription = {
  componentConfig: {
    title: {
      label: "Titulo",
      errorMessage: "Campo Requerido",
      fieldName: "title",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    text: {
      label: "Texto",
      errorMessage: "Campo Requerido",
      fieldName: "text",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    bgColor: {
      label: "Color de fondo",
      errorMessage: "Campo Requerido",
      fieldName: "bgColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    textColor: {
      label: "Color del texto",
      errorMessage: "Campo Requerido",
      fieldName: "textColor",
      type: "color",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
    },
    socialMedia: {
      label: "Lista de Redes sociales",
      errorMessage: "Campo Requerido",
      fieldName: "socialMedia",
      type: "text",
      defaultValue: "",
      required: true,
      regex: "",
      textSize: "h6",
      inputsArray: {
        image: {
          label: "Logo red social",
          errorMessage: "Campo Requerido",
          fieldName: "image",
          type: "select",
          defaultValue: "",
          required: false,
          regex: "",
          textSize: "h6",
          optionsArray: [
            {
              value: "Facebook",
              label: "Facebook",
            },
            {
              value: "Youtube",
              label: "Youtube",
            },
            {
              value: "Instagram",
              label: "Instagram",
            },
            {
              value: "Twitter",
              label: "Twitter",
            },
            {
              value: "LinkedIn",
              label: "LinkedIn",
            },
            {
              value: "Email",
              label: "Email",
            },
            {
              value: "Phone",
              label: "Teléfono",
            },
            {
              value: "none",
              label: "Ninguna (Subiré una imagen)",
            },
          ],
        },
        iconColor: {
          label: "Color del icono",
          errorMessage: "Campo Requerido",
          fieldName: "iconColor",
          type: "color",
          defaultValue: "",
          required: true,
          regex: "",
          textSize: "h6",
        },
        imageUpload: {
          label: "Logo red social Para subir (Opcional)",
          errorMessage: "Campo Requerido",
          fieldName: "imageUpload",
          type: "photo",
          defaultValue: "",
          required: false,
          regex: "",
          textSize: "h6",
        },
        text: {
          label: "Nombre red social o cuenta",
          errorMessage: "Campo Requerido",
          fieldName: "text",
          type: "text",
          defaultValue: "",
          required: true,
          regex: "",
          textSize: "h6",
        },
        url: {
          label: "URL",
          errorMessage: "Campo Requerido",
          fieldName: "url",
          type: "text",
          defaultValue: "",
          required: true,
          regex: "",
          textSize: "h6",
        },
      },
    },
  },
};
