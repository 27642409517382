import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import M from "materialize-css";

import ReactAudioPlayer from "react-audio-player";

import { uploadAudio } from "../../../../../../../data-store/actions/virtual-events-actions";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import InputFileButton from "../../../InputFileButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function EventAudios(props) {
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  let myOrganizedVirtualEvents = useSelector(
    (state) => state.virtualEvents.myOrganizedVirtualEvents
  );

  const classes = useStyles();

  const callbackStart = (audio) => {
    var kbites = 3000;
    if (audio.size < kbites * 1028) {
      dispatch(uploadAudio(userID, currentEvent.eventID, audio, "startAudio"));
    } else {
      M.toast("El audio debe pesar menos de " + kbites + " kb", 5000);
    }
  };
  const callbackFinish = (audio) => {
    var kbites = 3000;
    if (audio.size < kbites * 1028) {
      dispatch(uploadAudio(userID, currentEvent.eventID, audio, "finishAudio"));
    } else {
      M.toast("El audio debe pesar menos de " + kbites + " kb", 5000);
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Audio de salida
        </Typography>
        <InputFileButton
          callback={callbackStart}
          type="audio/*"
          text="Subir audio Para la salida"
        />
        <ReactAudioPlayer src={currentEvent.startAudio} controls />
        <Typography gutterBottom variant="h5" component="h2">
          Audio de la meta
        </Typography>
        <InputFileButton
          callback={callbackFinish}
          type="audio/*"
          text="Subir audio Para la meta"
        />
        <ReactAudioPlayer src={currentEvent.finishAudio} controls />
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}
