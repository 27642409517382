import React from 'react';
import '../Modals/ModalCss.css';

class ModalDashport extends React.Component {
	// props.size = small
	// props.size = big 
	
	constructor(props){
		super(props)

		this.state = {animation:"", show:true, claseModal : "ModalAtleteBig"}
		console.log(this.props.size);
	}
	componentDidMount(){
		if(this.props.size == "small"){
			this.setState({animation:"OpenAtleteSmall", claseModal:"ModalAtleteSmall"})
		}else{
			this.setState({animation:"OpenAtleteBig", claseModal:"ModalAtleteBig"})
		}
	}
	render(){

		if(!this.props.show){
			return null;
		}
		return (
		<div>
			<div onClick={this.props.onClose} className="ModalAtleteBack">
			</div>
			<div className={`${this.state.claseModal} ${this.state.animation}`} id="ModalAtlete" style={{overflowY: 'scroll'}}>
						<span onClick={this.props.onClose} className="btn-floating cerrarModal">
							<i className="material-icons">
								close
							</i>
						</span>
				{this.props.report}
			</div>
		</div>
		)
	}
}
export default ModalDashport;
