import React from "react";
import { ordenarPuntosDeControlDatos } from "./reportsLogic";
import DynamicInputs from "../../DynamicInput/DynamicInputs";
import EventClock from "./EventClock/EventClock";
import Background from "../../../assets/backgroundPhotos/fondogris_Dashport.jpg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditableField from "../../EditableField/EditableField";
import { Button, Paper, Typography } from "@material-ui/core";

class RealTimeResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controlSelect: 0,
      resultsArray: null,
    };
  }
  setControl = (valor) => {
    this.setState({
      controlSelect: valor,
    });
    this.updateList(this.props.eventResults);
  };

  componentDidMount = () => {
    this.initComponent();
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      this.props.eventDetail != undefined &&
      this.props.eventResults != undefined &&
      this.props.puntosControl != undefined
    ) {
      if (prevProps != this.props) {
        if (prevProps.puntosControl != this.props.puntosControl) {
          this.getControlsList();
        }
        if (prevProps.eventResults != this.props.eventResults) {
          this.updateList(this.props.eventResults);
        }
      }
    }
  };
  initComponent = () => {
    if (
      this.props.eventDetail != undefined &&
      this.props.eventResults != undefined &&
      this.props.puntosControl != undefined
    ) {
      this.updateList(this.props.eventResults);
      this.getControlsList();
    }
  };
  updateList = (results) => {
    var filas = ordenarPuntosDeControlDatos(
      false,
      results,
      this.state.controlSelect,
      false
    );
    console.log(filas);
    this.setState({ resultsArray: filas });
  };
  getControlsList = () => {
    if (
      this.props.eventDetail != undefined &&
      this.props.eventResults != undefined &&
      this.props.puntosControl != undefined
    ) {
      var puntosControl = this.props.puntosControl.val();

      var valor = [];
      var arrayControls = [];
      // agrego valor null para tener todos los controles
      valor.push({
        value: null,
        label: "Todos los controles",
      });
      // agrego valor 0 (CERO) para tener solo la meta
      valor.push({
        value: 0,
        label: "Meta del evento",
      });

      for (var i in puntosControl) {
        console.log(puntosControl[i].descripcionFinalPunto);
        var descripcionFinalPunto = puntosControl[i].descripcionFinalPunto;
        var modalidadesControl = puntosControl[i].modalidadesPuntoDeControl;
        for (var e in modalidadesControl) {
          var descripcion = modalidadesControl[e].descripcionModalidad;
          var laps = modalidadesControl[e].laps;
          for (var f in laps) {
            var descripcionLap = laps[f].descripcionPunto;
            var controlPoint = puntosControl[i].kNroPunto * 1000 + laps[f].lap;
            console.log(controlPoint);

            if (
              arrayControls.indexOf(controlPoint) == -1 &&
              controlPoint != 0 &&
              laps[f].tipoControl != 3
            ) {
              arrayControls.push(controlPoint);
              console.log(arrayControls);
              valor.push({
                value: controlPoint,
                label: descripcionFinalPunto + " Control " + controlPoint,
              });
            }
          }
        }
      }
    }

    // agrego valor 100000 para pedir datos de los competidores que no han llegado
    valor.push({
      value: 100000,
      label:
        "Competidores por llegar (Solo aplica para eventos que registraron la salida)",
    });

    this.setState({ controlsList: valor });
  };
  handleChange = (value) => {
    console.log(value);
    this.setControl(value.puntosControlid);
  };

  render() {
    var puntosControl = this.props.puntosControl.val();
    console.log(puntosControl);
    var element = "";

    if (this.state.resultsArray != null) {
      var selectPuntosControl = {
        label: "Puntos de Control",
        errorMessage: "Campo Requerido",
        fieldName: "puntosControlid",
        type: "select",
        defaultValue: null,
        required: false,
        regex: "",
        textSize: "h6",
        optionsArray: this.state.controlsList,
      };

      var PCNombre;
      if (this.state.controlSelect == null) {
        PCNombre = "Todos los controles";
      } else if (this.state.controlSelect === 0) {
        PCNombre = "TIEMPO EN META";
      } else if (this.state.controlSelect === 100000) {
        PCNombre = "Competidores por llegar a la meta";
      } else {
        PCNombre = "Punto de control: " + this.state.controlSelect;
      }

      let report = "";
      if (this.state.controlSelect == 100000) {
        report = this.state.resultsArray.JsonKPCPorTerminar.map(
          (data, index) => (
            <TableRow key={index}>
              <TableCell>{data.num}</TableCell>
              <TableCell>{data.nombre}</TableCell>
              <TableCell>{data.kpc}</TableCell>
              <TableCell>{data.tiempo}</TableCell>
            </TableRow>
          )
        );
      } else {
        report = this.state.resultsArray.JsonKPC.map((data, index) => {
          if (index < this.props.numberOfRows) {
            return (
              <TableRow key={index}>
                <TableCell>{data.num}</TableCell>
                <TableCell>{data.nombre}</TableCell>
                <TableCell>{data.kpc}</TableCell>
                <TableCell>{data.tiempo}</TableCell>
              </TableRow>
            );
          }
          return <div></div>;
        });
      }

      element = (
        <div
          className="row"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          {!this.props.hideClock && <EventClock fontSize={80} />}
          {/* {this.state.controlsList.map((a) => {
            const control = a;
            return (
              <Button onClick={() => this.setControl(control.value)}>
                {control.label}
              </Button>
            );
          })} */}
          <div className="valign-wrapper">
            <div className="col s12">
              <Typography
                color="primary"
                gutterBottom
                variant={"h3"}
                align="center"
              >
                {PCNombre}
              </Typography>

              <Typography
                color="primary"
                gutterBottom
                variant={"subtitle1"}
                align="center"
              >
                {this.props.numberOfRows > 0
                  ? "Mostrando los últimos " +
                    this.props.numberOfRows +
                    " registros"
                  : "Mostrando todos los registros"}
              </Typography>
            </div>

            {/* <div className="col s4">
               <EditableField
                fieldObject={{
                  ...selectPuntosControl,
                  defaultValue: this.state.controlSelect,
                }}
                onSubmit={this.handleChange}
              />
 
               <DynamicInputs inputObject={selectPuntosControl} 
                    actualValue={this.state.controlSelect} 
                    callbackchange={e => this.setControl(e)}/> 
            </div>
            */}
          </div>{" "}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell data-field="id">Número</TableCell>
                <TableCell data-field="name">Nombre</TableCell>
                <TableCell data-field="km">KM</TableCell>
                <TableCell data-field="tiempo">Tiempo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{report}</TableBody>
          </Table>
        </div>
      );
    } else {
      element = "";
    }

    return element;
  }
}

export default RealTimeResults;
