//import ReactGA from 'react-ga';
import ReactPixel from "react-facebook-pixel";
import { analytics } from "./firebase/firebasejs";
import ReactGA from "react-ga4";
import * as fb from "./firebase/firebasejs";
import firebase from "firebase";

const dashportPixelId = "6278342562277979"; // pixel antiguo "318462662546430";
const dashportGoogleAnalytics = "G-98YGV5HXZC"; // código de analytics;
let eventPixelId = false;
let gaId = false;

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

export function InitTracking() {
  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  //ReactPixel.init(dashportPixelId, advancedMatching, options);
  // analitics de firebase iniciado en firebase file;
}

export function TrakingData(data) {
  console.log(data);

  (window.dataLayer = window.dataLayer || []).push(data);

  ReactPixel.init(dashportPixelId, advancedMatching, options);
  //ReactGA.initialize(dashportGoogleAnalytics);

  // inicializar el pixel de facebook si existe
  if (data.params.facebookPixel) {
    eventPixelId = data.params.facebookPixel;
    ReactPixel.init(eventPixelId, advancedMatching, options);
  }
  if (data.params.gaId) {
    gaId = data.params.gaId;
    console.log("data.params.gaId", data.params.gaId);

    ReactGA.initialize([
      {
        trackingId: data.params.gaId,
      },
      {
        trackingId: dashportGoogleAnalytics,
      },
    ]);
  } else {
    ReactGA.initialize([
      {
        trackingId: dashportGoogleAnalytics,
      },
    ]);
  }
  userTrack(data.eventName, data.typeEvent, data.params);
}

//#############################################################
function userTrack(eventoIndex, typeEvent, objectParam) {
  console.log(
    "Tracking evento espedifico para todos los pixeles y ids de analytics del evento"
  );
  console.log("Tracking", eventoIndex, typeEvent, objectParam);

  // objectParam debe contener la el atributo Label obligatorios, va a servir para saber que pagina vio.
  // puede tener la orden de compra del usuario para calcualr el total y contabilizar la campaña.
  // y el atributo currency para enviar la moneda

  objectParam =
    objectParam === undefined || objectParam.label === undefined
      ? { label: "vacio" }
      : objectParam;

  let typeEventArray = {
    pixel: {
      PageView: "PageView",
      InitiateCheckout: "InitiateCheckout",
      Purchase: "Purchase",
      PurchaseFail: "PurchaseFail",
      ViewContent: "ViewContent",
      Lead: "Lead",
      Login: "login",
      sign_up: "sign_up",
      customTrack: {
        PurchaseFail: true,
      },
    },
    ga: {
      PageView: "pageview",
      InitiateCheckout: "begin_checkout",
      Purchase: "purchase",
      PurchaseFail: "PurchaseFail",
      ViewContent: "screen_view",
      Lead: "generate_lead",
      Login: "login",
      sign_up: "sign_up",
    },
    descripcionTypeEvent: {
      PageView: "Para indicar que el usuario entró a ver esa página",
      InitiateCheckout:
        "Cuando el usuario completo sus ddatos y los del evento y pasa a la vista de pago",
      Purchase: "Cuando llega a la vista de confirmación",
      ViewContent: "Cuando ve la pagina, lo voy a usar para la convocatoria",
      Lead: "Cuando presiona el link de inscripciones y llena sus datos",
    },
  };

  console.log(
    "traking - " +
      typeEvent +
      " - " +
      typeEventArray.descripcionTypeEvent[typeEvent]
  );
  // facebook pixel track
  pixelTrack(
    eventoIndex,
    typeEventArray.pixel[typeEvent],
    objectParam,
    typeEventArray.pixel.customTrack[typeEvent]
  );

  trackingLink(eventoIndex, typeEventArray.pixel[typeEvent], objectParam);

  // analytics pixel track
  googleAnalyticsTrack(eventoIndex, typeEventArray.ga[typeEvent], objectParam);
}
//####################################################################################
function googleAnalyticsTrack(eventoIndex, typeEvent, objectParam) {
  console.log("GATrack");
  // https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics?hl=es_419#log-event

  // ReactGA.send({
  //   hitType: "pageview",
  //   page: "/my-path",
  //   title: "Custom Title",
  // });

  analytics.logEvent(typeEvent, objectParam);

  if (typeEvent === "pageview") {
    ReactGA.send({
      hitType: "pageview",
      page: "/" + eventoIndex,
      title: objectParam.eventName || "",
    });
  } else {
    ReactGA.event({
      category: eventoIndex,
      action: typeEvent,
      label: eventoIndex + (objectParam.eventName || ""), // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
    });
  }

  // tracking para el evento
  //   if(gaIdEvento != undefined  && gaIdEvento != ""){
  //       ga(`${eventoIndex}.send`, {
  //           hitType: 'event',
  //           eventCategory: eventoIndex,
  //           eventAction: typeEvent,
  //           eventLabel: objectParam.label
  //         });
  //   }
}
//####################################################################################
function pixelTrack(eventoIndex, typeEvent, objectParam, customTrack) {
  console.log("pixelTrack");
  if (typeEvent === "Purchase" || typeEvent === "InitiateCheckout") {
    // recibo la orden de la compra
    let value = 0;
    let order = objectParam.order;
    let contents = [];
    for (let i in order) {
      value = value + order[i].quantity * order[i].unit_price;
      contents.push({
        id: order[i].code,
        quantity: order[i].quantity,
      });
    }

    objectParam.value = value;
    objectParam.contents = contents;
    objectParam.content_ids = eventoIndex;
  } else {
    objectParam.content_ids = eventoIndex;
  }

  //var trackType = (customTrack)?'trackSingleCustom':'trackSingle';
  // facebook pixel track
  if (objectParam == null) {
    ReactPixel.trackSingle(dashportPixelId, typeEvent, null);
  } else {
    ReactPixel.trackSingle(dashportPixelId, typeEvent, objectParam);
  }

  // pixel del evento
  if (eventPixelId) {
    if (objectParam == null) {
      ReactPixel.trackSingle(eventPixelId, typeEvent, null);
    } else {
      ReactPixel.trackSingle(eventPixelId, typeEvent, objectParam);
    }
  }
}
//####################################################################################
function trackingLink(eventoIndex, typeEvent, objectParam) {
  if (eventoIndex && objectParam.tkl) {
    const updates = {};
    updates[
      `${fb.EventosCompletos}/${eventoIndex}/trackingLinks/${objectParam.tkl}/${typeEvent}/`
    ] = firebase.database.ServerValue.increment(1);
    firebase.database().ref().update(updates);
  }
}
