import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import M from 'materialize-css'
import {helperMultisort} from '../../../../../services/utils';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ChatIcon from '@material-ui/icons/Chat';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Messages from './Messages'
import Badge from '@material-ui/core/Badge';

export default function ChatCustom(props) {
  const {buttonType, buttonText, topics, topicsTo, writeAvailable, title} = props
  const [open, setOpen] = React.useState(false);
  const [messagesCount, setMessagesCount] = React.useState(0);
  const chatVirtualEvent = useSelector(state => state.chat.chatVirtualEvent);
  const [chatVirtualEventArray, setchatVirtualEventArray] = React.useState(false);

  // chat options
  // topics: especificar el id de a quien se dirige, si no va a alguien en especifico debe incluir la palabra "public"
  // between: si Topics incluye la palabra "private" muestro el mensaje si between incluye el mensaje de ids que estan en topics

  useEffect(() =>{
    function checktopics(message) {
      // if(message.topics.indexOf("private")){
      //   delete message.topics[]
      //   if(message.type == "text"){
      //     return message.topics.some(r=> topics.indexOf(r) >= 0)
      //   }
      // }
      console.log("filter ",topics);
      if(message.type == "text"){
        return message.topics.some(r=> topics.indexOf(r) >= 0)
      }
    }
    var chatVirtualEventArray = Object.values(chatVirtualEvent).filter((message) => checktopics(message));
  
    setchatVirtualEventArray(helperMultisort(chatVirtualEventArray, ["date"], ["DESC"]));
    setMessagesCount(chatVirtualEventArray.length);
  

  },[chatVirtualEvent])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let buttonChat;
  if(buttonType == "block"){
    buttonChat = (
    <Button color="primary" variant="contained" onClick={handleClickOpen}>
        {buttonText}
        <Badge badgeContent={messagesCount} color="secondary">
          <ChatIcon />
        </Badge>
    </Button>)
  }else if(buttonType == "fab"){
    buttonChat = (
    <Fab onClick={handleClickOpen} color="primary">
        <Badge badgeContent={messagesCount} color="secondary">
          <ChatIcon />
        </Badge>
    </Fab>)

  }



  return (
<div>
 
  {buttonChat}
  <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
            <Messages chatVirtualEventArray={chatVirtualEventArray} topics={topics} topicsTo={topicsTo} writeAvailable={writeAvailable}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            cerrar
          </Button>
        </DialogActions>
      </Dialog>
</div>
  );
}