import React from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

export default function PaginationComponent(props) {
  const { callBackPage, totalItems, itemsPerPage } = props;

  const [page, setPage] = React.useState(1);

  const handleChange = (value) => {
    setPage(value);
    callBackPage(value);
  };

  let pages = [];
  const pagesQuantity = totalItems / itemsPerPage + 1;

  for (let i = 1; i <= pagesQuantity; i++) {
    pages.push(
      <Grid item xs={1}>
        <Button
          style={{
            backgroundColor: page === i ? "red" : "",
            color: page === i ? "white" : "",
          }}
          onClick={() => handleChange(i)}
          color="primary"
          aria-label="page"
        >
          {i}
        </Button>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      wrap="wrap"
      alignContent="center"
      alignItems="center"
      justify="center"
    >
      {pages}
    </Grid>
  );
}
