import React from 'react';
import {Input, Icon} from 'react-materialize';
import {inputChanges} from '../../firebase/firebasejs';
import DynamicInputs from './DynamicInputs';


/**
 * props ParticipantDetail={} 
              eventDetail={} 
              inputObject={}
 * 
 *  formato de inputObject = {
      "campo" : 1,
      "etiqueta" : "Correo",
      "iDCampo" : "correo",
      "longitud" : 100,
      "observacion" : "",
      "tipoCampo" : "text",
      "validacion" : "Requerido",
      "valor" : "",
      "anchoCampo":"s12 m4 l4"
    }

 * 
 */

class EditableInput extends React.Component {
  constructor(props){
    super(props)
    var textoCampo = (this.props.ParticipantDetail[this.props.inputObject.iDCampo] ==  undefined)?"":this.props.ParticipantDetail[this.props.inputObject.iDCampo];
    this.state = {
      editable : true,
      editing : false,
      [this.props.inputObject.iDCampo]:textoCampo
    }
  }

  getValue = (e) => {
		var valor = e.target.value;
    var name = e.target.name;
    if(this.props.inputObject.tipoCampo == "checkbox"){
      console.log("checkbox");
      valor = e.target.checked;
    }

    console.log(e);
    console.log(valor);
    console.log(name);

    this.setState({[this.props.inputObject.iDCampo]: valor});
    console.log(this.state);

    }

  toogleEditing=()=>{
    this.setState({
      editing:!this.state.editing
    })
  }
  saveChanges = (name, value) => {
    console.log("guardar icon" + value + name);
    this.setState({
      editing:!this.state.editing,
      [name]:value
    });
    var eventoIndex = "evento_"+this.props.eventDetail.iDEvento;
    inputChanges(eventoIndex, 
                  this.props.ParticipantDetailKey, 
                  this.props.ParticipantDetail,
                  this.props.userID,
                  this.props.atleteAccount,
                  this.props.inputObject,
                  value);

  }

  render(){

    var textoCampo = (this.state[this.props.inputObject.iDCampo] ==  undefined)?<span>N/A</span>:this.state[this.props.inputObject.iDCampo];
    if(this.props.inputObject.tipoCampo == "checkbox"){
      textoCampo = (this.state[this.props.inputObject.iDCampo])?<span>si</span>:<span>no</span>;
    }
    var anchoCampo = (this.props.inputObject.anchoCampo == undefined)?"s12 m6 l6":this.props.inputObject.anchoCampo;

    let input;
    if(this.state.editing && this.state.editable){
      input = <DynamicInputs inputObject={this.props.inputObject} 
                              actualValue={this.state[this.props.inputObject.iDCampo]} 
                              callbackSave={this.saveChanges}
                              callbackchange={e => this.getValue(e)}
                              />;
    }else{
      input = (
      <div className={`col ${anchoCampo}`}>
        <div onClick={() => this.toogleEditing()}
           className={`col s12 center-align`}>
          <b>{this.props.inputObject.etiqueta} </b>
          <Icon className="grey-text">edit</Icon>
        </div>
        <div className={`col s12 center-align`}>
            {textoCampo}
        </div>
      </div>
      );
    }
        return input;
  
  }
}

export default EditableInput;
