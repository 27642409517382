import React, { useState } from 'react';
import M from 'materialize-css';
import axios from 'axios';

function OxxoPay (props) {
  const { atleteAccount, userID, test, currentEvent, order } = props;
  const [sending , setSending ] = useState(false);



const sendOxxoPay = () => {
  setSending(true);

  var amount = 0;
  var order = [];

  for(var i in order){

    var price = order[i].unit_price;
    if(currentEvent.currency != "MXN"){
      price = parseFloat(price * currentEvent.rateToMXN);
    }

    var item = {
      name:order[i].name,
      quantity:order[i].quantity,
      unit_price:Math.round(price * 100)
    };

    amount = Math.round((parseFloat(amount) + (parseFloat(price)*parseFloat(order[i].quantity)) )*100)/100;
    console.log("amount " + amount);
    order.push(item);   
  }
  console.log(order);

  var eventoIndex = "evento_"+this.props.eventDetail.iDEvento;

  var apellidos = (atleteAccount.apellidos != undefined)?atleteAccount.apellidos:atleteAccount.apellidoPaterno +" "+atleteAccount.apellidoMaterno;

  var nombres = atleteAccount.nombres +" "+apellidos;
  console.log(nombres);

  var jsonDatos = {
          nombres: nombres,
          correo:atleteAccount.correo,
          telefono:atleteAccount.telefono,
          token:"",
          tipoPago:"oxxo_pay",
          jsonItems:order,
          inscritoKey:userID,
          eventoIndex:currentEvent.eventID,
          last4:"",
          brand:"",
          amount:amount,
          test:true
        };



        var headers = {
          headers: {'Access-Control-Allow-Origin': true}
        };

        axios.post('https://us-central1-kmetasfirebase.cloudfunctions.net/conektaCargoV2', jsonDatos, headers)
        .then(res=>{
          console.log("respuesta oxxo");
          console.log(res);
          console.log(res.data.charges.data[0].payment_method.reference);
          const paymentMethod = 2;
          this.props.setPaymentMethod(paymentMethod, res.data.charges.data[0].payment_method.reference);

          setSending(false);
        })
        .catch(error=>{
            console.log(error)
            setSending(false);

            var errorTexto = "";
            if(error.responseJSON != undefined){
              for(var i in error.responseJSON.details){
                errorTexto = errorTexto + error.responseJSON.details[i].message +" - "
              }
            }
            M.toast('Error procesando el pago: ' + errorTexto, 8000);
          });
      }

    const loadingSend = (
      <div className="preloader-wrapper active">
        <div className="spinner-layer spinner-red-only">
          <div className="circle-clipper left">
            <div className="circle"></div>
          </div>
          <div className="gap-patch">
            <div className="circle"></div>
          </div>
          <div className="circle-clipper right">
            <div className="circle"></div>
          </div>
        </div>
    </div>);
  
    const buttomSend = (
      <a href="#" onClick={(e) => this.sendOxxoPay(e)} id="sendOxxoPayButon" className="waves-effect waves-light btn-large">
            <i className="material-icons right">call_to_action</i><span id="sendOxxoPayText">ficha de pago</span></a>); 
  
  let buttomSendFinal = buttomSend;
  if(this.state.sending){
    buttomSendFinal = loadingSend;
  }else{
    buttomSendFinal = buttomSend;
  }
  
 
    return(<div className="col s12 m6 l6 hoverable">
        <br/>
        <br/>
        <div className="center">
            <i className="large material-icons md-primary">code</i>
            <h5>Efectua el pago en cualquier oxxo cercano</h5>
            {buttomSendFinal}
        </div>
      </div>);
}

export default OxxoPay;
