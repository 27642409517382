import React from "react";

import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export default class PhotoEditorDefault extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      anchoDiv: 0,
      altoDiv: 0,
      canvasKey: 0,
      cropping: false,
      image: null,
      anchoFoto: 0,
      altoFoto: 0,
      imgOrigen: "",
      photoOrientation: 1,
    };

    this.eventPhotoConfiguration = eventPhotoConfigurationDefault;
    if (this.props.currentEvent.photosConfig != undefined) {
      this.eventPhotoConfiguration = this.props.currentEvent.photosConfig;
    }
    this.anchoFoto = 0;
    this.altoFoto = 0;
    this.imgOrigen = 0;
    this.ajutar = 1; // para ajustar la imagen a un tama;o menor y evitar errores al descargar
    this.canvas = 0;
    this.ctx = 0;
    this.JCROPAPI = 0;
  }

  //##################################################################
  componentDidMount() {
    if (this.props.type === "raceNumber" && this.props.urlPhoto == undefined) {
      const raceNumber =
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FfotoPrincipal_Dashport_bajaResolucion%20(1).jpeg?alt=media&token=e04c3966-1881-4428-9385-288e4d120b09";
      this.canvasFunction(raceNumber);
    } else if (
      this.props.type === "medal" &&
      this.props.urlPhoto == undefined
    ) {
      const medal =
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FfotoPrincipal_Dashport_bajaResolucion%20(1).jpeg?alt=media&token=e04c3966-1881-4428-9385-288e4d120b09";
      this.canvasFunction(medal);
    } else {
      this.canvasFunction(this.props.urlPhoto);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.participant != prevProps.participant) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      if (
        this.props.type === "raceNumber" &&
        this.props.urlPhoto == undefined
      ) {
        const raceNumber =
          "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FfotoPrincipal_Dashport_bajaResolucion%20(1).jpeg?alt=media&token=e04c3966-1881-4428-9385-288e4d120b09";
        this.canvasFunction(raceNumber);
      } else if (
        this.props.type === "medal" &&
        this.props.urlPhoto == undefined
      ) {
        const medal =
          "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FfotoPrincipal_Dashport_bajaResolucion%20(1).jpeg?alt=media&token=e04c3966-1881-4428-9385-288e4d120b09";
        this.canvasFunction(medal);
      } else {
        this.canvasFunction(this.props.urlPhoto);
      }
    }
  }

  //##################################################################
  canvasFunction = (url) => {
    console.log("canvasFunction");
    this.canvas = document.getElementById(
      this.props.type + this.props.participant.raceNumber
    );
    this.ctx = this.canvas.getContext("2d");

    /* Enable Cross Origin Image Editing */
    this.imgOrigen = new Image();
    this.imgOrigen.crossOrigin = "";
    this.imgOrigen.src = url;
    this.imgOrigen.onload = () => {
      this.setState({ loading: false });

      if (this.ajutar == 1) {
        var AltoAnchoMax = 1500;

        if (this.imgOrigen.width > this.imgOrigen.height) {
          // foto landscape
          if (this.imgOrigen.width > AltoAnchoMax) {
            this.anchoFoto = AltoAnchoMax;
            this.altoFoto =
              (AltoAnchoMax * this.imgOrigen.height) / this.imgOrigen.width;
          } else {
            this.anchoFoto = this.imgOrigen.width;
            this.altoFoto = this.imgOrigen.height;
          }
        } else if (this.imgOrigen.height > this.imgOrigen.width) {
          // fofo portrait
          if (this.imgOrigen.height > AltoAnchoMax) {
            this.anchoFoto =
              (AltoAnchoMax * this.imgOrigen.width) / this.imgOrigen.height;
            this.altoFoto = AltoAnchoMax;
          } else {
            this.anchoFoto = this.imgOrigen.width;
            this.altoFoto = this.imgOrigen.height;
          }
        } else {
          // foto cuadrada
          if (this.imgOrigen.width > AltoAnchoMax) {
            this.anchoFoto = AltoAnchoMax;
            this.altoFoto =
              (AltoAnchoMax * this.imgOrigen.height) / this.imgOrigen.width;
          } else {
            this.anchoFoto = this.imgOrigen.width;
            this.altoFoto = this.imgOrigen.height;
          }
        }

        //resizeImagen();
        //insertaLogos();
      } else {
        this.anchoFoto = this.imgOrigen.width;
        this.altoFoto = this.imgOrigen.height;
      }
      console.log(this.altoFoto);
      console.log(this.anchoFoto);

      if (this.props.type === "raceNumber") {
        this.anchoFoto = 820 * (10 / 8);
        this.altoFoto = 820;

        this.canvas.width = this.anchoFoto;
        this.canvas.height = this.altoFoto;
      } else {
        this.canvas.width = this.anchoFoto;
        this.canvas.height = this.altoFoto;
      }

      if (this.imgOrigen.width < this.imgOrigen.height) {
        // foto portrait
        this.setState({
          photoOrientation: 1,
        });
      } else if (this.imgOrigen.width > this.imgOrigen.height) {
        // foto landscape
        this.setState({
          photoOrientation: 2,
        });
      } else {
        // foto cuadrada
        this.setState({
          photoOrientation: 3,
        });
      }

      this.ctx.drawImage(this.imgOrigen, 0, 0, this.anchoFoto, this.altoFoto);
      if (this.anchoFoto < this.altoFoto) {
        this.setState({ anchoDiv: "auto", altoDiv: "500px" });
      } else {
        this.setState({ anchoDiv: "500px", altoDiv: "auto" });
      }

      console.log("ancho" + this.anchoFoto + " altoFoto " + this.altoFoto);
      console.log(
        "anchodiv" + this.state.anchoDiv + " altodiv " + this.state.altoDiv
      );

      if (this.props.type === "raceNumber") {
        this.racenumberFunc();
      } else if (this.props.type === "frameImage") {
        this.frameImageFunc();
      } else if (this.props.type === "goalTime") {
        this.goalTimeFunc();
      } else if (this.props.type === "finishTime") {
        this.finishTimeFunc();
      } else if (this.props.type === "medal") {
        this.medalFunc();
      } else {
        this.insertaLogos();
      }
    };
  };

  //##################################################################
  goalTimeFunc = () => {
    let goalTime = this.props.participant.goalTime;
    if (
      this.props.participant.goalTime == undefined ||
      this.props.participant.goalTime == null
    ) {
      goalTime = "00:00:00";
    }

    this.insertaTime(
      this.props.participant.raceNumber,
      "Tiempo Objetivo",
      goalTime,
      this.props.currentEvent.distance
    );
  };
  //##################################################################
  finishTimeFunc = () => {
    let finishTime = this.props.participant.finishTime;
    if (
      this.props.participant.finishTime == undefined ||
      this.props.participant.finishTime == null
    ) {
      finishTime = "00:00:00";
    }

    this.insertaTime(
      this.props.participant.raceNumber,
      "Tiempo Oficial",
      finishTime,
      this.props.currentEvent.distance
    );
  };
  //##################################################################
  racenumberFunc = () => {
    let raceNumber = this.props.participant.raceNumber;
    if (this.props.participant !== undefined) {
      if (
        this.props.participant.raceNumber == undefined ||
        this.props.participant.raceNumber == null
      ) {
        raceNumber = "N/A";
      }

      this.insertaRaceNumber(
        raceNumber,
        this.props.participant.athleteName,
        this.props.participant.goalTime,
        this.props.currentEvent.distance,
        this.props.currentEvent
      );
      this.passCanvasToImg();
    }
  };
  //##################################################################
  medalFunc = () => {
    let raceNumber = this.props.participant.raceNumber;
    if (this.props.participant !== undefined) {
      if (
        this.props.participant.raceNumber == undefined ||
        this.props.participant.raceNumber == null
      ) {
        raceNumber = "N/A";
      }
      let finishTime = this.props.participant.finishTime;
      if (
        this.props.participant.finishTime == undefined ||
        this.props.participant.finishTime == null
      ) {
        finishTime = "00:00:00";
      }

      this.insertaMedal(
        raceNumber,
        this.props.participant.athleteName,
        this.props.participant.athleteLastName,
        finishTime,
        this.props.currentEvent.distance,
        this.props.currentEvent
      );
      this.passCanvasToImg();
    }
  };
  //##################################################################
  frameImageFunc = () => {
    const marcoDefault =
      "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FMarcoBordeNegroRecto.png?alt=media&token=7c73c524-8033-4285-be82-8b818b1cf21e";
    if (this.props.currentEvent.frameImage != undefined) {
      this.insertaMarco(this.props.currentEvent.frameImage);
    } else {
      this.insertaMarco(marcoDefault);
    }
  };
  //##################################################################
  insertaLogos = () => {
    for (var i in this.eventPhotoConfiguration.logos) {
      var logo = this.eventPhotoConfiguration.logos[i].rutaLogo;
      var anchoLogoPorcentaje = this.eventPhotoConfiguration.logos[i].anchoLogo;
      var margen = this.eventPhotoConfiguration.logos[i].margen;
      var posicionMarca = this.eventPhotoConfiguration.logos[i].posicion;
      this.insertaLogo(logo, anchoLogoPorcentaje, margen, posicionMarca);
    }
  };

  //##################################################################
  insertaMedal = (
    raceNumber,
    nombre,
    apellido,
    tiempo,
    distancia,
    currentEvent
  ) => {
    const recuadro = this.altoFoto / 2 - 210;

    const color = currentEvent.medalFontColor || "#000000";
    this.ctx.globalAlpha = 1;

    const medalTextHorizontal = currentEvent.medalTextHorizontal || 1;
    const medalTextVertical = currentEvent.medalTextVertical || 1;

    this.dibujarTextoCentrado(
      nombre.toUpperCase() + " " + apellido.toUpperCase(),
      30,
      (this.altoFoto / 2) * medalTextVertical,
      "Forza",
      color,
      medalTextHorizontal
    );
    if (currentEvent.showMedalTime) {
      this.dibujarTextoCentrado(
        tiempo,
        40,
        (this.altoFoto / 2) * medalTextVertical + 50,
        "Forza",
        color,
        medalTextHorizontal
      );
    }
  };
  //##################################################################
  insertaRaceNumber = (raceNumber, nombre, tiempo, distancia, currentEvent) => {
    const recuadro = this.altoFoto / 2 - 210;

    if (!currentEvent.raceNumberWhiteBack) {
      this.ctx.fillStyle = "#ffcdd2";
      this.ctx.globalAlpha = 0.5;
      this.ctx.fillRect(2, recuadro + 2, this.anchoFoto, 420);
      this.ctx.fillStyle = "#FFFFFF";
      this.ctx.fillRect(0, recuadro, this.anchoFoto, 420);
    }

    const color = currentEvent.raceNumberFontColor || "#000000";
    this.ctx.globalAlpha = 1;

    this.dibujarTextoCentrado(
      raceNumber,
      340,
      recuadro + 280,
      "Forza",
      color,
      1
    );
    this.dibujarTextoCentrado(
      nombre.toUpperCase(),
      40,
      recuadro + 340,
      "Forza",
      color,
      1
    );
    if (
      (currentEvent.eventType && currentEvent.eventType === "byTime") ||
      tiempo === undefined
    ) {
    } else {
      this.dibujarTextoCentrado(
        `Voy por ${distancia} kilómetros en ${tiempo}`,
        30,
        recuadro + 390,
        "MavenPro-VariableFont_wght",
        color,
        1
      );
    }
  };
  //##################################################################
  //##################################################################
  insertaTime = (raceNumber, nombre, tiempo, distancia) => {
    const recuadro = this.altoFoto * 0.2;
    this.ctx.globalAlpha = 0.5;
    this.ctx.fillStyle = "#FFFFFF";
    this.ctx.fillRect(0, this.altoFoto * 0.62, this.anchoFoto, recuadro);
    this.ctx.globalAlpha = 1;

    let textoParams = {
      txt: nombre,
      anchoPorcentajePortrait: 0.3,
      anchoPorcentajeLandscape: 0.2,
      anchoPorcentajeSquare: 0.4,
      x: this.anchoFoto / 2,
      y: this.altoFoto * 0.8,
      colorTexto: "black",
      AjusteFuente: 1,
      sombra: false,
      Alineacion: "center",
      font: "MavenPro-VariableFont_wght",
    };

    this.insertaTexto(textoParams);

    textoParams = {
      txt: tiempo,
      anchoPorcentajePortrait: 0.4,
      anchoPorcentajeLandscape: 0.4,
      anchoPorcentajeSquare: 0.5,
      x: this.anchoFoto / 2,
      y: this.altoFoto * 0.75,
      colorTexto: "red",
      AjusteFuente: 1,
      sombra: false,
      Alineacion: "center",
      font: "digital-7Final",
    };

    this.insertaTexto(textoParams);
    this.insertaLogos();
  };
  //##################################################################
  insertaTiempo = (textoInferior, textoSuperior) => {
    let textoParams = {
      txt: textoSuperior,
      anchoPorcentajePortrait: 0.4,
      anchoPorcentajeLandscape: 0.3,
      anchoPorcentajeSquare: 0.4,
      x: this.anchoFoto / 2,
      y: this.altoFoto * 0.75,
      colorTexto: "white",
      AjusteFuente: 2,
      sombra: true,
      Alineacion: "center",
      font: "MavenPro-VariableFont_wght",
    };

    this.insertaTexto(textoParams);

    textoParams = {
      txt: textoInferior,
      anchoPorcentajePortrait: 0.7,
      anchoPorcentajeLandscape: 0.4,
      anchoPorcentajeSquare: 0.5,
      x: this.anchoFoto / 2,
      y: this.altoFoto * 0.9,
      colorTexto: "red",
      AjusteFuente: 1,
      sombra: true,
      Alineacion: "center",
      font: "digital-7Final",
    };

    this.insertaTexto(textoParams);
  };
  //##################################################################
  insertaLogo = (logo, anchoLogoPorcentaje, margen, posicionMarca) => {
    //logo = 'KmetasV4/VistasV4/Configuracion/Imagenes/LogosKmetas/Logo3alta resolucion.png';
    //anchoLogoPorcentaje = 0.30;
    //margen = 30;
    //posicionMarca = 4;

    // creo la imagen con la ruta recibida
    var img = new Image();
    img.crossOrigin = "";
    img.src = logo;

    // descargo del servidor y calculo ancho y alto.
    img.onload = () => {
      var anchoLogo = img.width;
      var altoLogo = img.height;

      // ajusto tamano en relacion al ancho especificado que ocupara en la foto
      var anchoLogoFinal = this.anchoFoto * anchoLogoPorcentaje;
      var altoLogoFinal = (anchoLogoFinal * altoLogo) / anchoLogo;

      var posX = margen;
      var posY = margen;

      switch (posicionMarca) {
        case 2: // arriba a la derecha
          posX = this.anchoFoto - anchoLogoFinal - margen;
          break;
        case 3: // abajo a la izquierda
          posY = this.altoFoto - altoLogoFinal - margen;
          break;
        case 4: // abajo a la derecha
          posX = this.anchoFoto - anchoLogoFinal - margen;
          posY = this.altoFoto - altoLogoFinal - margen;
          break;
      } // 1 o 0 arriba a la izquierda

      // dibujo la imagen sobre la foto original
      this.ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        posX,
        posY,
        anchoLogoFinal,
        altoLogoFinal
      );
      this.passCanvasToImg();
    };
  };
  //##################################################################
  insertaMarco = (marco) => {
    console.log("marco click" + marco);
    //marco = 'KmetasV4/VistasV4/Configuracion/Imagenes/LogosKmetas/MarcoCTriatlonMerida.png';
    // creo la imagen con la ruta recibida
    var img = new Image();
    img.crossOrigin = "";
    img.src = marco;

    // descargo del servidor y calculo ancho y alto.
    img.onload = () => {
      // dibujo la imagen sobre la foto original
      this.ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        this.anchoFoto,
        this.altoFoto
      );
      this.insertaLogos();
    };
  };

  //##################################################################
  dibujarTexto = (titulo, texto, orden, fuente, alto, tipofuente) => {
    const centroDatos = this.anchoFoto / 2;
    const altoDatos = alto;
    var entrelinea = 42;
    var espacioCentro = 10;

    this.ctx.font = "bold " + fuente + "px " + tipofuente;
    this.ctx.fillStyle = "#212121";
    var anchoTexto = this.ctx.measureText(titulo).width;
    var X = centroDatos - anchoTexto;
    var Y = altoDatos + orden * entrelinea;
    this.ctx.fillText(titulo, X, Y);
    this.ctx.font = fuente + "px " + tipofuente;
    this.ctx.fillText(texto, centroDatos + espacioCentro, Y);
  };

  //##################################################################
  dibujarTextoCentrado = (
    texto,
    fuente,
    textoY,
    tipofuente,
    color,
    lugarVertical
  ) => {
    this.ctx.font = fuente + "px " + tipofuente;
    var i = this.ctx.measureText(texto).width;

    var textoX = (this.anchoFoto / 2) * lugarVertical - i / 2;
    this.ctx.fillStyle = "black";
    this.ctx.fillText(texto, textoX + 1, textoY + 1);
    this.ctx.fillStyle = color;
    this.ctx.fillText(texto, textoX, textoY);
  };
  //##################################################################

  descargaFinal = () => {
    // esta es la parte que descarga en about blank el texto dice Descarga opcion 3
    console.log("descarga");

    // var dato = this.canvas.toDataURL("image/jpeg");
    // dato = dato.replace("image/jpeg", "image/octet-stream");
    // document.location.href = dato;

    var link = document.createElement("a");
    link.download =
      this.props.participant.athleteName +
      " " +
      this.props.participant.athleteLastName +
      ".jpeg";
    link.href = document
      .getElementById(this.props.type + this.props.participant.raceNumber)
      .toDataURL("image/jpeg");
    link.click();
  };
  //##################################################################
  passCanvasToImg = () => {
    this.setState({
      imgOrigen: document
        .getElementById(this.props.type + this.props.participant.raceNumber)
        .toDataURL("image/jpeg"),
    });
  };

  insertaTexto = (textoParams) => {
    // txt = texto a insertar
    // anchoPorcentaje = ancho del texto en relacion al ancho de la foto
    // x = inicio del texto segun la alineacion
    // y = inicio del texto de forma vertical
    // colorTexto
    // AjusteFuente= para estirar la fuente o mantener igual (1) segun dise;o valor de 1 en adelante.
    // sombra= 1 para colocar sobra negra al texto, 0 para no colocar
    // Alineacion = puede ser 'start', 'end', 'center'

    //ejm de coomo llamar la funcion
    // this.insertaTexto(textoParams);

    // var textoParams = {
    //     txt:this.props.resultado.nombre,
    //     anchoPorcentajePortrait:0.7,
    //     anchoPorcentajeLandscape:0.4,
    //     anchoPorcentajeSquare:0.5,
    //     x : this.anchoFoto/2,
    //     y:this.altoFoto/2,
    //     colorTexto:'white',
    //     AjusteFuente:2,
    //     sombra:true,
    //     Alineacion:'center'
    // }

    var fuente = 0;
    var i = 0;
    var anchoTextoFinal;
    if (this.state.photoOrientation == 1) {
      anchoTextoFinal = this.anchoFoto * textoParams.anchoPorcentajePortrait;
    } else if (this.state.photoOrientation == 2) {
      anchoTextoFinal = this.anchoFoto * textoParams.anchoPorcentajeLandscape;
    } else {
      anchoTextoFinal = this.anchoFoto * textoParams.anchoPorcentajeSquare;
    }
    this.ctx.textAlign = textoParams.Alineacion;

    while (i < anchoTextoFinal) {
      fuente++;
      console.log(fuente);
      this.ctx.font = fuente + "px " + textoParams.font;
      i = this.ctx.measureText(textoParams.txt).width;
    }

    // para ajustar el alto del texto sin afectar el ancho maximo seleccioando
    this.ctx.font =
      fuente * textoParams.AjusteFuente + "px " + textoParams.font;

    // para colocar el texto atras como sombra
    if (textoParams.sombra) {
      this.ctx.globalAlpha = 0.5;
      this.ctx.fillStyle = "black";
      this.ctx.fillText(
        textoParams.txt,
        textoParams.x + 2,
        textoParams.y + 2,
        anchoTextoFinal
      );
      this.ctx.globalAlpha = 1;
    }
    this.ctx.fillStyle = textoParams.colorTexto;
    this.ctx.fillText(
      textoParams.txt,
      textoParams.x,
      textoParams.y,
      anchoTextoFinal
    );
    //    this.insertaLogos();

    return fuente;
  };
  //##################################################################
  render() {
    let loading = "";
    if (this.state.loading) {
      loading = (
        <div id="LoadingFoto" className="preloader-wrapper big active">
          <div className="spinner-layer spinner-blue-only">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      );
    } else {
      loading = "";
    }

    return (
      <div>
        <Grid container spacing={3} direction="row" alignItems="center">
          <Grid xs={12}>
            {this.props.type == "raceNumber" && (
              <Typography gutterBottom variant="h6" component="h5">
                Número de competencia
              </Typography>
            )}
            {this.props.type == "frameImage" && (
              <Typography gutterBottom variant="h6" component="h5">
                Marco del evento
              </Typography>
            )}
            {this.props.type == "medal" && (
              <Typography gutterBottom variant="h6" component="h5">
                Medalla
              </Typography>
            )}
            {this.props.type == "goalTime" && (
              <Typography gutterBottom variant="h6" component="h5">
                Tiempo objetivo
              </Typography>
            )}
            {this.props.type == "finishTime" && (
              <Typography gutterBottom variant="h6" component="h5">
                Tiempo Oficial
              </Typography>
            )}
          </Grid>

          <Grid
            xs={12}
            onClick={() => this.descargaFinal()}
            style={{ cursor: "poiter" }}
          >
            {loading}
            <img
              id={"imagen"}
              src={this.state.imgOrigen}
              style={{
                maxWidth: this.props.size || "200px",
                maxHeight: this.props.size || "200px",
              }}
            />
          </Grid>
          <Grid xs={4} style={{ cursor: "poiter" }}>
            <Button
              onClick={() => this.descargaFinal()}
              variant="contained"
              color="primary"
            >
              Descargar
            </Button>
          </Grid>
        </Grid>

        <canvas
          className="FotoEditar1"
          id={this.props.type + this.props.participant.raceNumber}
          style={{ display: "none" }}
        ></canvas>
        <span style={{ fontFamily: "MavenPro-VariableFont_wght" }}>.</span>
        <span style={{ fontFamily: "digital-7Final" }}>.</span>
      </div>
    );
  }
}

const eventPhotoConfigurationDefault = {
  logos: [
    {
      anchoLogo: 0.25,
      codLogo: 2,
      descripcion: "Logo Dashport",
      margen: 15,
      posicion: 4,
      rutaLogo:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73",
    },
  ],
  marcos: [
    {
      codMarco: 1,
      descripcion: "Marco Blanco",
      rutaMarco:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FMarcoBordeBlancoRecto.png?alt=media&token=4b64abde-f3ef-476c-9dbf-b2d3f30350be",
      tipo: "Estandar",
    },
    {
      codMarco: 2,
      descripcion: "Marco Negro",
      rutaMarco:
        "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FMarcoBordeNegroRecto.png?alt=media&token=7c73c524-8033-4285-be82-8b818b1cf21e",
      tipo: "Estandar",
    },
  ],
};
