import React, { useState } from 'react';
import M from 'materialize-css';
import axios from 'axios';
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import Conekta from '../../../../../../../../conekta/ConektaHelper'


function ConektaCard (props) {
  const { atleteAccount, userID, test, currentEvent, order } = props;

  const { register, handleSubmit } = useForm();
  const [sending , setSending ] = useState(false);
  const [cardData, setCardData ] = useState();

  const onSubmit = data => {
    setSending(true)
    console.log(data)
    setCardData(data);
    sendPayment(data)
  };
  

  function sendPayment (data) {
    var validarConekta = true;
  
    let production= true;
  
    if(test){
      production = false;
    }
    Conekta.initConekta(production);
  
    if(!Conekta.validateCardNumber(data.numeroDeTarjeta)){
    validarConekta = false;
   
      M.toast('Revisa el numero de tarjeta!', 2000);
    }
    if(!Conekta.validateCvc(data.cvc)){
    validarConekta = false;
      M.toast('Revisa el código de seguidad!', 2000);
    }
    if(!Conekta.validateExpirationDate(data.MM, data.AAAA)){
      validarConekta = false;
        M.toast('Revisa la fecha de expiración!', 2000);
      }
    
      console.log("validar conekta "+validarConekta);
  
      if(validarConekta){
        Conekta.tokenize(data.numeroDeTarjeta, data.nombreDelTarjetahabiente, data.MM, data.AAAA, data.cvc, this.successCallback, this.errorCallback);
      }else{
        return false;
      }
  
  }
  const successCallback = (token ) => {
    console.log(order);
    console.log("succese");
    var amount = 0;
    var order = [];
  
    for(var i in order){
  
      var price = order[i].unit_price;
      if(currentEvent.currency != "MXN"){
        price = parseFloat(price * currentEvent.rateToMXN);
      }
    
      var item = {
        name:order[i].name,
        quantity:order[i].quantity,
        unit_price:Math.round(price * 100)
      };
  
      amount = Math.round((parseFloat(amount) + (parseFloat(price)*parseFloat(order[i].quantity)) )*100)/100;
      console.log("amount " + amount);
      order.push(item);   
    }
    
  
    var last4 = cardData.numeroDeTarjeta.substring(cardData.numeroDeTarjeta.length-4, cardData.numeroDeTarjeta.length);
    
    var eventoIndex = "evento_"+this.props.eventDetail.iDEvento;
    var jsonDatos = {
            nombres: cardData.nombreDelTarjetahabiente,
            correo:atleteAccount.correo,
            telefono:atleteAccount.telefono,
            token:token.id,
            tipoPago:"card",
            jsonItems:order,
            inscritoKey:userID,
            eventoIndex:currentEvent.eventID,
            last4:last4,
            brand:"",
            amount:amount,
            test:true
          };
  
  
  
          var headers = {
            headers: {'Access-Control-Allow-Origin': true}
        };
  
          axios.post('https://us-central1-kmetasfirebase.cloudfunctions.net/conektaCargoV2', jsonDatos, headers)
          .then(res=>{
            console.log(res);
            const paymentMethod = 1;
            this.props.setPaymentMethod(paymentMethod, "");
  
            this.setState({
              sending: false
            });
            this.props.backForward(true);
  
          })
          .catch(error=>{
              console.log(error)
              this.setState({
                sending: false
              });
              var errorTexto = "";
              if(error.responseJSON != undefined){
                for(var i in error.responseJSON.details){
                  errorTexto = errorTexto + error.responseJSON.details[i].message +" - "
                }
              }
              M.toast('Error procesando el pago: ' + errorTexto, 8000);
  
            });
  
  
  }
  
  const errorCallback = (err) => {
    // err keys: object, type, message, message_to_purchaser, param, code 
    console.log("error tokenizando "+JSON.stringify(err));
    console.log(err);
    M.toast('Error:' + err.message_to_purchaser, 6000);
    //TODO: mostrar aqui error al tokenizar, detalle de numero o datos
    setSending(false)
  
  };
  
  
    const loadingSend = (
    <div className="preloader-wrapper active">
      <div className="spinner-layer spinner-red-only">
        <div className="circle-clipper left">
          <div className="circle"></div>
        </div>
        <div className="gap-patch">
          <div className="circle"></div>
        </div>
        <div className="circle-clipper right">
          <div className="circle"></div>
        </div>
      </div>
  </div>);

  const buttomSend = (
  <Button type="submit" id="sendPaymentButon">
    Enviar Pago
  </Button>); 

let buttomSendFinal = buttomSend;
if(sending){
  buttomSendFinal = loadingSend;
}else{
  buttomSendFinal = buttomSend;
}

  var formulario = (
    <div className="row">
    <form onSubmit={handleSubmit(onSubmit)}>
      <input name="nombreDelTarjetahabiente" ref={register({ required: true})} />
      <input name="numeroDeTarjeta" ref={register({required: true})} />
      <input name="cvc" type="number" ref={register({ min: 100, max: 9999 })} />
      <h5>fecha de expiración</h5>
      <input name="MM" type="number" ref={register({ min: 1, max: 12 })} />
      <input name="AAAA" type="number" ref={register({ min: 2020, max: 2500 })} />
      {buttomSendFinal}
    </form>
    </div>
);
  
  var data = {
    "card": {
      "number": "4242424242424242",
      "name": "Javier Pedreiro",
      "exp_year": "2018",
      "exp_month": "12",
      "cvc": "123"
    }
  };
  
  
  return (<div id="conektaCardDiv" className="col s12 m6 l6 hoverable">
          <br/>
          <br/>
          <div className="center">
          <i className="large material-icons md-primary">code</i>
          <h5>Pago con tarjeta de crédito o débito</h5>
          {formulario}
          </div>
        </div>);
 
}

export default ConektaCard;
