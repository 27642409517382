import React from 'react';

const PaymentDetailRegistryForm = ({order, monedaAUsar, monedaBase, removeItemOrder}) => {
  let rows = [];
  let totalPayment = 0;
  if(order.length > 0 && order[0].unit_price != undefined){
    for(var i in order){
      const indice = i;
      var price = Math.round((order[i].unit_price * monedaAUsar.tipoCambio) * 100)/100;
      totalPayment = totalPayment + (price * order[i].quantity);
      var deletButtom = (order[i].code =="insc" || order[i].code =="comision")?"":<a className="waves-effect waves-teal btn-flat" onClick={() => removeItemOrder(indice)}>Borrar</a>;
      rows.push(
      <tr key={i} className="itemOrder">
        <td className="itemOrderName">{order[i].name}</td>
        <td className="itemOrderDescription">{order[i].description}</td>
        <td className="itemOrderQuantity" >{order[i].quantity}</td>
        <td className="itemOrderPrice"> {price} {monedaAUsar.codigo}</td>
        <td className="itemOrderPriceTotal" > {price * order[i].quantity} {monedaAUsar.codigo}</td>
        <td>{deletButtom}</td>
      </tr>);
    }
  }

  let pesosLeyend;
  if(monedaBase.cambioAPeso != undefined && monedaAUsar.codigo != "MXN"){
    pesosLeyend = (<div className="red-text z-depth-2 #ffebee red lighten-5">
      <p style={{padding:10}}>Pago en pesos Méxicanos a tasa {monedaAUsar.cambioAPeso} MXN/{monedaAUsar.codigo} 
      - Total <span >
      {(totalPayment * monedaAUsar.cambioAPeso).toFixed(2)}</span> MXN </p>
  </div>);
  }

    var  tabla = (
    <div>
    <hr/>
        <div className="row">
          <div className="col s12 m12 l12">
            <h3>Estado de Cuenta</h3>
          </div>
        </div>
      
        <div className="row">
          <div className="col s12 m12 l12">
          <table className="bordered centered">
              <thead>
                <tr>
                    <th>Producto</th>
                    <th>Descripción</th>
                    <th>Cantidad</th>
                    <th>Precio unitario</th>
                    <th>Total</th>
                    <th></th>
                </tr>
              </thead>
              <tbody className="listadoProductos">
              {rows}
      
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>{pesosLeyend}</td>
                  <td>Total a pagar</td>
                  <td>{totalPayment.toFixed(2)} {monedaAUsar.codigo}</td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        </div>);
    return tabla;
}

export default PaymentDetailRegistryForm;
