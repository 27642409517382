import React, {Component} from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polyline} from 'google-maps-react';
 
export class MapContainer extends Component {

  render() {

    var activity = this.props.activity;

    var activityData = activity.samples;

    const coords = [];
    var startLat = activityData[0].latitudeInDegree;
    var startLng = activityData[0].longitudeInDegree;

    for (var i in activityData){
      if(activityData[i].latitudeInDegree){
        coords.push({lat: activityData[i].latitudeInDegree, lng: activityData[i].longitudeInDegree});
      }
    }
    return (
      <div style={{height:400, width:800}}>
      <Map containerStyle={{ position: 'relative', width: '100%', height: '100%'}} 
      google={this.props.google} zoom={16}
      initialCenter={{
        lat: startLat,
        lng: startLng
      }}>
      <Polyline
          path={coords}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2} />
 <Marker
    name={'Inicio'}
    position={{lat: startLat, lng: startLng}} />
      
        <InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1>Prueba</h1>
            </div>
        </InfoWindow>
      </Map>
      </div>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ("AIzaSyAtcbYiS6l_5yaNkACPUyvK8CcoIENc3Vk")
})(MapContainer)
