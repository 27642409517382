import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import MenuItem from "@material-ui/core/MenuItem";
import ReactHookFormSelect from "../EditableField/ReactHookFormSelect";

const useStyles = makeStyles({
  iconSave: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  iconEdit: {
    position: "absolute",
    bottom: 0,
    top: 0,
    right: 0,
  },
  text: {
    position: "relative",
    paddingRight: 40,
  },
  input: {
    paddingRight: 45,
    marginTop: 5,
    marginBottom: 5,
  },
});

export default function FieldSelect(props) {
  const { fieldObject, register, errors, control } = props;
  const {
    fieldName,
    label,
    errorMessage,
    defaultValue,
    required,
    textSize,
    min,
    max,
    optionsArray,
  } = fieldObject;
  const classes = useStyles();
  const [editMode, setEditMode] = useState(true);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  function handleChange(event) {
    console.log(event.target.value);
    setCurrentValue(event.target.value);
  }
  return (
    <div>
      <ReactHookFormSelect
        id={fieldName}
        name={fieldName}
        label={label}
        control={control}
        defaultValue={typeof defaultValue === "object" ? "" : defaultValue}
        variant="outlined"
        margin="normal"
        style={{ width: "100%" }}
        required={required}
      >
        {optionsArray.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </ReactHookFormSelect>
    </div>
  );
}
