import React from 'react';

const AutorizedUsers= ({registryAutorizedUsers}) => {
        var dataTable =[];

        for(var i in registryAutorizedUsers){
            dataTable.push(<tr key={i}>
                <td>{registryAutorizedUsers[i].nombre}</td>
                <td>{registryAutorizedUsers[i].perfil}</td>
                <td>{registryAutorizedUsers[i].correo}</td>
                </tr>);
        }

        return (
        <div>
            <h4>Lista de usuarios</h4>
            <table>
                <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Perfil</th>
                    <th>Correo</th>
                </tr>
                </thead>
                <tbody>
                    {dataTable}
                </tbody>
            </table>
        </div>
        );
	}
export default AutorizedUsers;