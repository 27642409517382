import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import ReactAudioPlayer from "react-audio-player";

import Login from "../../../../../../Login/Login";

import { useForm, Controller } from "react-hook-form";
import FinishLinePictures from "./FinishLinePictures";
import FinishLineParticipants from "./FinishLineParticipants";
import FinishLinePicturesDefault from "./FinishLinePicturesDefault";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function FinishLine(props) {
  const { match } = props;
  const dispatch = useDispatch();
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);

  const classes = useStyles();
  if (atleteAccount == null) {
    return <Login />;
  }

  if (myVirtualEvents == undefined || currentEvent == undefined) {
    return <CircularProgress />;
  }
  if (currentEvent == {}) {
    return <h1>Usuario no autorizado para este evento</h1>;
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        {currentEvent.finishAudio != undefined && (
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5">
              Audio de la meta
            </Typography>

            <ReactAudioPlayer
              src={currentEvent.finishAudio}
              autoPlay
              controls
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FinishLinePictures />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FinishLinePicturesDefault />
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h5>Linea de Meta</h5>
            <FinishLineParticipants />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
