import React from 'react';

const DashboardResume = ({jsonDashboard}) => {

  var tabla = (			
  <div className="row z-depth-2">
  <table className="centered">
      <thead>
        <tr>
            <th>Total cobrado</th>
            <th>Total inscritos</th>
            <th>Pagos en efectivo</th>
            <th>Pagos completos en oxxo</th>
            <th>Pagos con tarjeta</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><b>$ {Math.floor(jsonDashboard.totalCobrado *100)/100}</b></td>
          <td><b>{Math.floor(jsonDashboard.totalInscritos *100)/100}</b></td>
          <td><b>Total: $ {Math.floor(jsonDashboard.totalPagosEfectivo *100)/100} <br/>
          Cantidad: {jsonDashboard.cantidadPagosEfectivo}</b></td>
          <td><b> Total: $ {Math.floor(jsonDashboard.totalCompletoOxxo *100)/100} <br/> 
          Cantidad: {jsonDashboard.cantidadCompletoOxxo}</b></td>
          <td><b>Total: $ {Math.floor(jsonDashboard.totalTarjeta *100)/100} <br/>
          Cantidad: {jsonDashboard.cantidadTarjeta}</b></td>
        </tr>
      </tbody>
    </table>
    <table className="centered">
      <thead>
        <tr>
            <th>Registros incompletos</th>
            <th>Registros borrados</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><b>{jsonDashboard.totalIncompletos}</b></td>
          <td><b>{jsonDashboard.totalBorrados}</b></td>
        </tr>
      </tbody>
    </table>
</div>
);
    
      return tabla;
    

}

export default DashboardResume;
