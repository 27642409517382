import { Typography } from "@material-ui/core";
import React from "react";

const DetailOrderConfirmation = ({
  jsonMail,
  jsonTextos,
  idioma,
  reference,
  tipo,
}) => {
  var detalleOrden = [];
  var orden = jsonMail.datos.order;
  var sumaMonto = 0;
  var monedaUsadaFecha = jsonMail.datos.monedaBase;
  var monedaBase = jsonMail.datos.monedaBase;

  for (var i in orden) {
    sumaMonto = sumaMonto + orden[i].unit_price * orden[i].quantity;

    detalleOrden.push(
      <div key={i} className="row center-align">
        <div className="col s3 center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            {orden[i].name}
          </Typography>
        </div>

        <div className="col s3 center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            {orden[i].description}{" "}
          </Typography>
        </div>
        <div className="col s3 center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            {orden[i].quantity}
          </Typography>
        </div>
        <div className="col s3 center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            {orden[i].unit_price * orden[i].quantity} {monedaBase.codigo}
          </Typography>
        </div>
      </div>
    );
  }

  if (monedaUsadaFecha != undefined) {
    if (monedaUsadaFecha.codigo != "MXN") {
      detalleOrden.push(
        <div key={1000} className="row center-align">
          <div className="col s9 right-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle2"}
              component="h2"
            >
              Total en {monedaUsadaFecha.texto}
            </Typography>
          </div>
          <div className="col s3 center-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle2"}
              component="h2"
            >
              {sumaMonto} {monedaUsadaFecha.codigo}
            </Typography>
          </div>
        </div>
      );
      detalleOrden.push(
        <div key={1001} className="row center-align">
          <div className="col s9 right-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle2"}
              component="h2"
            >
              Pesos Méxicanos calculado a tasa de {monedaUsadaFecha.cambioAPeso}{" "}
              MXN/{monedaUsadaFecha.codigo}
            </Typography>
          </div>
          <div className="col s3 center-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle2"}
              component="h2"
            >
              {Math.floor(sumaMonto * monedaUsadaFecha.cambioAPeso * 100) / 100}{" "}
              MXN
            </Typography>
          </div>
        </div>
      );
    } else {
      detalleOrden.push(
        <div key={1000} className="row center-align">
          <div className="col s9 right-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle2"}
              component="h2"
            >
              Total en {monedaUsadaFecha.texto} {sumaMonto}{" "}
              {monedaUsadaFecha.codigo}
            </Typography>
          </div>
          <div className="col s3 center-align">
            <Typography
              color="primary"
              gutterBottom
              variant={"subtitle2"}
              component="h2"
            >
              {sumaMonto} {monedaUsadaFecha.codigo}
            </Typography>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <div className="row center-align">
        <Typography color="primary" gutterBottom variant={"h5"} component="h2">
          {jsonTextos[idioma].detalleOrden}
        </Typography>
      </div>
      <div className="row center-align">
        <div className="col s3 center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            <b>{jsonTextos[idioma].Producto}</b>
          </Typography>
        </div>
        <div className="col s3 center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            <b>{jsonTextos[idioma].Descripcion}</b>
          </Typography>
        </div>
        <div className="col s3 center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            <b>{jsonTextos[idioma].Cantidad}</b>
          </Typography>
        </div>
        <div className="col s3 center-align">
          <Typography
            color="primary"
            gutterBottom
            variant={"subtitle2"}
            component="h2"
          >
            <b>{jsonTextos[idioma].Precio}</b>
          </Typography>
        </div>
      </div>
      {detalleOrden}
    </div>
  );
};

export default DetailOrderConfirmation;
