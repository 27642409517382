import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  fechahora,
  helperMultisort,
} from "../../../../../../../services/utils";
import Avatar from "@material-ui/core/Avatar";

import CameraAltIcon from "@material-ui/icons/CameraAlt";
import LogoGarmin from "../../../../GarminApi/GarminAssets/Garmin_Connect_app_1024x1024-02.png";
import ResultModal from "./ResultModal";
import {
  useCalculateRelativePositions,
  useCalculateAbsolutePositions,
} from "../../../VirtualEventsHooks/VirtualEventsHooks";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  large: {
    width: 70,
    height: 70,
  },
});

export default function ResultsTable(props) {
  let { currentEventParticipants, filter, currentEvent } = props;

  const classes = useStyles();

  const getEvidence = (participant) => {
    let evidence = {
      garmin: false,
      photo: false,
      strava: false,
    };
    if (participant.garminActivitySummary != undefined) {
      evidence.garmin = true;
    }
    if (participant.photos != undefined) {
      var photo = [];
      photo = Object.values(participant.photos).filter((obj) => {
        return obj.photoType === "evidence";
      });
      if (photo.length > 0) {
        evidence.photo = true;
      }
    }

    return evidence;
  };

  let results = [];
  if (currentEventParticipants != undefined) {
    results = Object.values(currentEventParticipants);
  }

  results = useCalculateRelativePositions(results, true, currentEvent);
  results = useCalculateAbsolutePositions(results, true, currentEvent);

  if (filter != null) {
    results = results.filter((result) => {
      return result.athleteGender === filter;
    });
  }

  results = helperMultisort(results, ["finishTime"], ["ASC"]);
  console.log(results);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Foto</TableCell>
            <TableCell>Número</TableCell>
            <TableCell>Nombres</TableCell>
            <TableCell>Apellidos</TableCell>
            <TableCell align="right">Género</TableCell>
            <TableCell align="right">Posición Categoría</TableCell>
            <TableCell align="right">Posición Rama</TableCell>
            <TableCell align="right">Posición General</TableCell>
            <TableCell align="right">Tiempo Meta</TableCell>
            <TableCell align="right">Evidencia</TableCell>
            <TableCell align="right">Tiempo Final</TableCell>
            <TableCell align="right">Fecha de registro Tiempo</TableCell>
            <TableCell align="right">Abrir</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(results).map((key) => (
            <TableRow key={key}>
              <TableCell component="th" scope="row">
                <Avatar
                  alt="image"
                  src={results[key].finishLinePhoto}
                  className={classes.large}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {results[key].raceNumber}
              </TableCell>
              <TableCell component="th" scope="row">
                {results[key].athleteName}
              </TableCell>
              <TableCell component="th" scope="row">
                {results[key].athleteLastName}
              </TableCell>
              <TableCell align="right">
                {results[key].athleteGender == "M" ? "Varonil" : "Femenil"}
              </TableCell>
              <TableCell align="right">
                {results[key].positionAbsoluteCategory}
              </TableCell>
              <TableCell align="right">
                {results[key].positionAbsoluteGender}
              </TableCell>
              <TableCell align="right">
                {results[key].positionAbsolute}
              </TableCell>
              <TableCell align="right">{results[key].goalTime}</TableCell>
              <TableCell align="right">
                {getEvidence(results[key]).garmin ? (
                  <img src={LogoGarmin} alt="a" height={30} />
                ) : (
                  ""
                )}
                {getEvidence(results[key]).photo ? <CameraAltIcon /> : ""}
                {getEvidence(results[key]).strava ? "Strava" : ""}
              </TableCell>
              <TableCell align="right">{results[key].finishTime}</TableCell>
              <TableCell align="right">
                {results[key].finishTimeDate !== undefined &&
                  fechahora(results[key].finishTimeDate.toDate() / 1000)}
              </TableCell>

              <TableCell align="right">
                <ResultModal
                  participant={results[key]}
                  currentEvent={currentEvent}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
