import React from 'react';
import M from 'materialize-css'
import {db} from '../../../../../firebase/firebasejs';



class MailSendDetail extends React.Component{

    reenviarConfirmacion = () => {
        console.log("reenviar");
        var eventoIndex = "evento_"+this.props.eventDetail.iDEvento;
        var idRegistro = this.props.ParticipantDetailKey;
       
          var updates = {};
          console.log('eventosCompletos/'+eventoIndex+'/inscritos/listadoInscritos/'+idRegistro+'/mails/sendMail/');
          updates['eventosCompletos/'+eventoIndex+'/inscritos/listadoInscritos/'+idRegistro+'/mails/sendMail/'] = true;
          return db.ref().update(updates).then(snap=>{
            console.log("Renviada");
            M.toast("Correo reenviado exitosamente!", 4000);
          });
    }


    render(){

    var mails = undefined;
    if(this.props.ParticipantDetail.mails != undefined && 
        this.props.ParticipantDetail.mails.mailRegistry != undefined){
            mails = this.props.ParticipantDetail.mails.mailRegistry;
        }

    var tipoCorreoJson = {
      1:"correo para notificar inscripcion en web",
      2:"Correo por inscripcion en punto de venta",
      3:"correo por codigo de suscripcion",
      4:"Confirmacion de inscripcion y pago recibido",
      5:"Ficha de pago y confirmacion de registro"
      };
  
  
    var botonReenviarCorreo;

    var addMail;
    var precioCobrado = 0;
    if(mails != undefined){
        for(var p in mails){
            var d = new Date(mails[p].date);
            var fecha = d.getHours()+":"+d.getMinutes()+":"+d.getSeconds()+" "+d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
            var tipoCorreo = tipoCorreoJson[mails[p].tipoCorreo];
            addMail = (<tr className="itemOrderResume">
                <td className="mailDate" data-mail-date={fecha}>{fecha}</td>
                <td className="mailSubject" data-mail-subject={mails[p].subject}>{mails[p].subject}</td>
                <td className="mailTipoCorreo" data-mail-tipo={tipoCorreo}>{tipoCorreo}</td>
            </tr>);
        
            }
    }

    if(this.props.ParticipantDetail.statusPago.status == "ok"){
    botonReenviarCorreo = (<div><p>Reenviar correo de confirmación?</p> 
    <a onClick={this.reenviarConfirmacion} className="waves-effect waves-light btn">
    <i className="material-icons right">mail</i>si, reenviar</a></div>); 
    
    }

    var tabla = (
    <div className="row">
        <hr/>
        <div className="card-panel col s12 m12 l12">
        <table className="bordered centered">
            <thead>
            <tr>
                <th>Fecha</th>
                <th>Titulo</th>
                <th>Tipo Correo</th>
            </tr>
            </thead>
            <tbody id="listadoProductos">
                {addMail}
            </tbody>
        </table>
        </div>
        {botonReenviarCorreo}
    </div>
    );

    

    return tabla;
}


}


export default MailSendDetail;
