import React from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { Link } from "react-router-dom";
import MaterialUIInput from "@material-ui/core/Input";
import Select from "react-select";
import { Icon, Row } from "react-materialize";
import M from "materialize-css";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTracking } from "react-tracking";
//import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {
  auth,
  firestore,
  db,
  logInFacebook,
  logInGoogle,
} from "../../firebase/firebasejs";
import Typography from "@material-ui/core/Typography";

import {
  fecha,
  formatfechaDDMMYYYFromYYYYMMDD,
  fechaDiagonal,
} from "../../services/utils";
import AppContainer from "../../AppContainer";

export default function CreateAccountNew() {
  const userID = useSelector((state) => state.atlete.userID);
  const atleteAccount = useSelector((state) => state.atlete.atleteAccount);

  const { trackEvent } = useTracking();
  trackEvent({
    eventName: "CreateAccount",
    typeEvent: "PageView",
    params: { label: "CreateAccount" },
  });

  const { register, handleSubmit, errors, control } = useForm();
  const onSubmit = (data) => {
    let today = fechaDiagonal(0);
    const dia = data.dia.value < 10 ? "0" + data.dia.value : data.dia.value;
    const mes = data.mes.value < 10 ? "0" + data.mes.value : data.mes.value;
    const year = data.year.value < 10 ? "0" + data.year.value : data.year.value;
    const fechaNacimiento = year + "-" + mes + "-" + dia;

    let competidorData = {
      apellidos: data.apellidos,
      correo: data.correo.toLowerCase(),
      fechaNacimiento: fechaNacimiento,
      genero: data.genero.value,
      nombres: data.nombres,
      telefono: data.telefono,
      perfil: 2,
      fechaRegistro: today,
      viaRegistro: "web",
    };
    console.log(competidorData);
    auth
      .createUserWithEmailAndPassword(data.correo, data.password)
      .then((userAdd) => {
        let newUserRef = firestore.collection("users").doc(userAdd.user.uid);
        setTimeout(function () {
          db.ref("/competidores/" + userAdd.user.uid).set(competidorData);
          return newUserRef.set(competidorData);
        }, 500);
      })
      .catch((error) => {
        // Handle Errors here.
        let errorCode = error.code;
        let errorMessage = error.message;
        console.log(errorCode);

        if (errorCode == "auth/email-already-in-use") {
          M.toast("Correo ya utilizado. Intente recuperar su contraseña", 7000);
        } else if (errorCode == "auth/invalid-email") {
          M.toast("Correo inválido", 7000);
        } else {
          M.toast(errorMessage, 7000);
        }
        // ...
      });
  };

  function getDateParts() {
    let dia = [];
    let year = [];

    for (let i = 1; i <= 31; i++) {
      dia.push({ value: i, label: i });
    }
    const mes = [
      { value: 1, label: "Enero" },
      { value: 2, label: "Febrero" },
      { value: 3, label: "Marzo" },
      { value: 4, label: "Abril" },
      { value: 5, label: "Mayo" },
      { value: 6, label: "Junio" },
      { value: 7, label: "Julio" },
      { value: 8, label: "Agosto" },
      { value: 9, label: "Septiembre" },
      { value: 10, label: "Octubre" },
      { value: 11, label: "Noviembre" },
      { value: 12, label: "Diciembre" },
    ];

    let f = new Date();
    let yearI = f.getFullYear();
    let yearInsertar;
    for (let i = 0; i <= 100; i++) {
      yearInsertar = yearI - i;
      year.push({ value: yearInsertar, label: yearInsertar });
    }

    return { dia, mes, year };
  }
  if (userID != undefined) {
    return (
      <AppContainer>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper style={{ padding: 10, textAlign: "center" }}>
              <Typography gutterBottom variant="h5" component="h2">
                Bienvenido
              </Typography>
              <Row>
                <Typography gutterBottom variant="h2" component="h2">
                  {atleteAccount.nombres + " " + atleteAccount.apellidos}
                </Typography>
              </Row>
              <Button
                variant="contained"
                component={Link}
                to={"/retos/"}
                color="primary"
                size="large"
              >
                Mis retos
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </AppContainer>
    );
  }

  return (
    <AppContainer>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper style={{ padding: 15 }}>
              <Typography gutterBottom variant="h5" component="h2">
                Crear cuenta con tu correo
              </Typography>
              <Row>
                <label>Nombres</label>
                <input
                  name="nombres"
                  type="text"
                  ref={register({ required: true })}
                />
                {errors.nombres && "Campo requerido"}
              </Row>
              <Row>
                <label>Apellidos</label>
                <input
                  name="apellidos"
                  type="text"
                  ref={register({ required: true })}
                />
                {errors.apellidos && "Campo requerido"}
              </Row>
              <Row>
                <label>Teléfono</label>
                <input
                  name="telefono"
                  type="text"
                  ref={register({ required: true })}
                />
                {errors.telefono && "Campo requerido"}
              </Row>
              <Row>
                <label>Género</label>

                <Controller
                  name="genero"
                  as={Select}
                  options={[
                    { value: "F", label: "Femenino" },
                    { value: "M", label: "Masculino" },
                  ]}
                  control={control}
                  rules={{ required: true }}
                />
                {errors.genero && "Campo requerido"}
              </Row>
              <Row>
                <Grid container justify="center" spacing={3}>
                  <Grid item xs={4}>
                    <label>Día</label>
                    <Controller
                      name="dia"
                      as={Select}
                      options={getDateParts().dia}
                      control={control}
                      rules={{ required: true }}
                    />
                    {errors.dia && "Campo requerido"}
                  </Grid>
                  <Grid item xs={4}>
                    <label>Mes</label>
                    <Controller
                      name="mes"
                      as={Select}
                      options={getDateParts().mes}
                      control={control}
                      rules={{ required: true }}
                    />
                    {errors.mes && "Campo requerido"}
                  </Grid>
                  <Grid item xs={4}>
                    <label>Año</label>
                    <Controller
                      name="year"
                      as={Select}
                      options={getDateParts().year}
                      control={control}
                      rules={{ required: true }}
                    />
                    {errors.year && "Campo requerido"}
                  </Grid>
                </Grid>
              </Row>
              <Row>
                <label>Correo</label>
                <input
                  name="correo"
                  type="email"
                  ref={register({ required: true })}
                />
                {errors.correo && "Campo requerido"}
              </Row>
              <Row>
                <label>Contraseña</label>
                <input
                  name="password"
                  type="password"
                  ref={register({ required: true, minLength: 6 })}
                />
                {errors.password && "Mínimo 6 caracteres"}
              </Row>
              <Row>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                >
                  Crear Cuenta
                </Button>
              </Row>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </AppContainer>
  );
}
