import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { updateUserAtribute } from "../../../../../data-store/actions/atlete-actions";
import { Paper } from "@material-ui/core";
import SimpleDialogDemo from "../../../LayoutContainers/SimpleDialog";
//import UserPaymentMethodsAdd from "../UserProfile/UserPaymentMethodsAdd";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import UserPaymentMethodsAdd from "./UserPaymentMethodsAdd";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#283747",
    textAlign: "left",
    minHeight: "400px",
    overflowY: "scroll",
  },
  buttons: {
    margin: 30,
    width: "250px",
    color: "black",
  },
}));

export default function CardPaymentList(props) {
  const { callback } = props;
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.atlete.userID);
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const classes = useStyles();

  if (athleteAccount == null) {
    return <div className={classes.root}></div>;
  }

  function formatCard(cardNumber) {
    return "****-****-****-" + cardNumber.substr(-4, 4);
  }
  function deleteCard(cardNumber) {
    const object = {
      paymentMethods: {
        [cardNumber]: null,
      },
    };
    dispatch(updateUserAtribute(userID, object));
  }

  let paymentMethods = athleteAccount.paymentMethods
    ? athleteAccount.paymentMethods
    : {};
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ width: "100%" }}
    >
      <Grid item xs={12}>
        <Typography
          gutterBottom
          variant={"subtitle1"}
          component="h2"
          color="secondary"
        >
          Selecciona una tajeta
        </Typography>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        {Object.values(paymentMethods).length == 0 && (
          <Typography
            gutterBottom
            variant={"h6"}
            component="h2"
            color="secondary"
          >
            Sin métodos de pago registrados
          </Typography>
        )}

        <List style={{ width: "100%" }}>
          {Object.values(paymentMethods).map((card, index) => {
            if (card) {
              return (
                <ListItem>
                  <ListItemIcon>
                    <CreditCardIcon color="secondary" />
                  </ListItemIcon>
                  <ListItemText
                    color="secondary"
                    primary={formatCard(card.cardNumber)}
                    secondary={card.cardOwner}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="this"
                      onClick={() => callback(card)}
                    >
                      <SendIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }
          })}
        </List>
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <SimpleDialogDemo buttonText={"Agregar método de pago"}>
          <UserPaymentMethodsAdd />
        </SimpleDialogDemo>
      </Grid>
    </Grid>
  );
}
