import React, { Suspense, lazy, Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import LiveEventsCard from "./LiveEventsCard";
import Button from "@material-ui/core/Button";
import AppContainer from "../../../AppContainer";
import { Typography } from "@material-ui/core";

class LiveEventsListView extends React.Component {
  state = {
    eventos: 20,
  };

  eventsFbToArray = (events) => {
    var eventosArray = [];

    var statusEvento = 4;
    for (var i in events) {
      if (events[i].eventoDisponible === statusEvento) {
        eventosArray.push(events[i]);
      }
    }

    eventosArray.sort(
      (a, b) => new Date(b.fechaEvento) - new Date(a.fechaEvento)
    );
    var final = eventosArray.slice(0, this.state.eventos);
    return final;
  };

  render() {
    return (
      <AppContainer>
        <Typography color="primary" variant={"h2"} component="h2">
          Eventos en vivo
        </Typography>

        <Grid
          container
          direction="row"
          justify="left"
          alignItems="left"
          spacing={2}
        >
          {this.eventsFbToArray(this.props.events).map((evento) => (
            <LiveEventsCard key={evento.iDEvento} evento={evento} />
          ))}
        </Grid>
        <div>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={(e) => this.setState({ eventos: this.state.eventos + 20 })}
          >
            Mostar más eventos anteriores
          </Button>
        </div>
      </AppContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
  };
};

const LiveEventsList = connect(mapStateToProps)(LiveEventsListView);

export default LiveEventsList;
