import React, { useState } from "react";
import { Button, Input, Icon, Row } from "react-materialize";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Login from "./LoginSocialMedia";
import CreateAccount from "./CreateAccountNew";
import AppContainer from "../../AppContainer";

export default function LoginContainerVirtualEvents() {
  return (
    <Grid container justify="center" spacing={3}>
      <Grid item xs={12}>
        <Login hideCreateAccountButton={true} />
      </Grid>
      <Grid item xs={12}>
        <Typography gutterBottom variant="h5" component="h2">
          O
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CreateAccount />
      </Grid>
    </Grid>
  );
}
