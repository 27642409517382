import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ResultsFilter from './ResultsFilter';
import UserResultModal from './UserResultModal';

import ResultsTable from './ResultsTable';
import ResultsPodium from './ResultsPodium';

const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function VirtualEventResults(props){
  const { match } = props;
  const [filter, setFilter] = useState(null);
  const dispatch = useDispatch();
  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const myVirtualEvents = useSelector(state => state.virtualEvents.myVirtualEvents);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(state => state.virtualEvents.currentEventParticipants);

  const classes = useStyles();
  const callbackFilter = (filter) =>{
    setFilter(filter);
  }

  return (
			<div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
            <ResultsPodium currentEventParticipants={currentEventParticipants} 
            currentEvent={currentEvent} />
            <ResultsFilter callback={callbackFilter}/>
            <ResultsTable currentEventParticipants={currentEventParticipants} 
            currentEvent={currentEvent} filter={filter}/>
        </Grid>
      </Grid>
    </div>
	  );
	}
	