import { Typography } from "@material-ui/core";
import React from "react";
import AppContainer from "../../../AppContainer";

const DashportServicesList = () => (
  <AppContainer>
    <div className="row">
      <div className="col s12 m6 l6">
        <br />
        <br />
        <div className="center">
          <i style={{ color: "red" }} className="material-icons md-primary">
            camera_enhance
          </i>

          <Typography color="primary" variant={"h3"} component="h2">
            Retos Virtuales
          </Typography>
          <p>
            Retos vistuales para organizadores de eventos profesionales y para
            pequeños grupos de entrenamiento.
          </p>
          <p>Contáctanos por mas información</p>
        </div>
      </div>
      <div className="col s12 m6 l6">
        <br />
        <br />
        <div className="center">
          <i style={{ color: "red" }} className="material-icons md-primary">
            view_list
          </i>
          <Typography color="primary" variant={"h3"} component="h2">
            Inscripciones en linea y puntos de venta
          </Typography>{" "}
          <p>
            Simplificamos el proceso de inscripción para la comodidad de los
            atletas y organizadores gestionamos el recurso tecnológico y humano
            para facilitar esta etapa del evento.
          </p>
          <p>Contáctanos por mas información</p>
        </div>
      </div>
      <div className="col s12 m6 l6">
        <br />
        <br />
        <div className="center">
          <i style={{ color: "red" }} className="material-icons md-primary">
            access_alarm
          </i>
          <Typography color="primary" variant={"h3"} component="h2">
            Cronometraje alternativo de eventos
          </Typography>{" "}
          <p>
            Cronometraje de eventos de reto personal eficiente, fácil de
            utilizar y accesible para que ningún evento sea realizado sin este
            servicio. Todos los atletas tendrán sus tiempos generales, por rama
            y categoría, asi como la capacidad de comparar su desempeño que los
            demás competidores.
          </p>
          <p>Contáctanos por mas información</p>
        </div>
      </div>
      <div className="col s12 m6 l6">
        <br />
        <br />
        <div className="center">
          <i style={{ color: "red" }} className="material-icons md-primary">
            camera_enhance
          </i>
          <Typography color="primary" variant={"h3"} component="h2">
            Fotografías deportivas
          </Typography>{" "}
          <p>
            Fotos de eventos deportivos de reto personal que los atletas podrán
            encontrar, editar, compartir en tus redes y descargar muy
            fácilmente.
          </p>
          <p>Contáctanos por mas información</p>
        </div>
      </div>
    </div>
  </AppContainer>
);

export default DashportServicesList;
