import React from 'react';



const cleanCount = (dato, total, porcentaje) => {
  var texto ="";
  if(dato == undefined){
    texto = "0 inscritos";
  }else if(dato == 1){
    texto = "1 inscrito";
  }else{
    texto = dato+" inscritos";
  }
  var textoporcentaje = "";
  if(porcentaje && dato != undefined && dato != 0){
    textoporcentaje = Math.round((dato/total)*100) +" %"
  }
  return texto + " "+textoporcentaje;
}

const DashboardModRamCat = ({modalidadesyCategorias}) => {
  console.log(modalidadesyCategorias);

  var modalidadesRender = [];
  var totalModalidad = 0;
  var totalRama = 0;

  for(var i in modalidadesyCategorias){
    var ramaCat = modalidadesyCategorias[i].ramaCat;
    var ramasCount = Object.keys(ramaCat).length;
    totalModalidad = modalidadesyCategorias[i].cuenta;
    var ramaRender = [];
    for(var e in ramaCat){
      var Genero = "";
      if(ramaCat[e].rama == "V"){
        Genero = "Varonil";
      }else if(ramaCat[e].rama == "F"){
        Genero = "Femenil";
      }else if(ramaCat[e].rama == "M"){
        Genero = "Mixta";
      }

      var classColumns = "s12 m12 l12";
      if(ramasCount == 2){
        classColumns = "s12 m6 l6";
      }else if(ramasCount == 3){
        classColumns = "s12 m4 l4";
      }

      var categorias = ramaCat[e].categorias;
      totalRama = ramaCat[e].cuenta;
      var categoryRender =[];

      for(var f in categorias){
        if(!categorias[f].absolutos){
          var edadesTexto = `(${categorias[f].edadDesde} - ${categorias[f].edadHasta}) `;
          if(categorias[f].edadHasta == 200 && categorias[f].edadDesde == 0){
            edadesTexto = "(Todas las edades)";
          }else if(categorias[f].edadDesde == 0 ){
            edadesTexto = `(edades hasta ${categorias[f].edadHasta} años)`;
          }else if(categorias[f].edadHasta == 200 ){
            edadesTexto = `(${categorias[f].edadDesde} y mayores)`;
          }
        categoryRender.push(
          <span key={f+i+Genero} className="valign-wrapper">
            <div className="col s8 m8 l8 left-align">
              {categorias[f].nombreCategoria} {edadesTexto}
            </div>
            <div className="col s4 m4 l4 right-align">
              {cleanCount(categorias[f].cuenta, totalRama, true)}
            </div>
          </span>);
        } 
      }
      ramaRender.push(
        <div key={f+i+Genero+1} className={`col ${classColumns}`}>;
          <div className="row card-panel #e0e0e0 grey lighten-2" style={{margin: "10px"}}>
              <h5>Rama {Genero} </h5>
              {cleanCount(ramaCat[e].cuenta, totalModalidad, true)}
          </div>
          <div className="row"  style={{margin: "10px"}}>
            {categoryRender}
          </div>
        </div>);

    }
    modalidadesRender.push(
      <div key={i} className="row z-depth-2" style={{ minHeight: "300px", padding: "10px"}}>
        <h5>Modalidades y Categorías</h5>
        <div id="ModyCat">
        <div className="row"><hr/>
        <div className="card-panel #f44336 red white-text">
        <h5>{modalidadesyCategorias[i].descripcionModalidad} </h5>
        {cleanCount(modalidadesyCategorias[i].cuenta,modalidadesyCategorias[i].cuenta, false)} 
        </div>
        {ramaRender}
      </div>
      </div>
      </div>);
  }
 


  return modalidadesRender;
    

}

export default DashboardModRamCat;
