import React, { useState, useEffect }from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Typography from '@material-ui/core/Typography';

import Login from '../../../../../../Login/Login'

import { useForm, Controller } from 'react-hook-form';
import { getUserVirtualEvents } from '../../../../../../../data-store/actions/virtual-events-actions'
import UserResultModal from './UserResultModal';



const useStyles = makeStyles((theme) => ({
  root: {
		flexGrow: 1,
		padding:10
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
}));

export default function ResultsFilter(props){
  const {callback, participant} = props;

  const atleteAccount = useSelector(state => state.atlete.atleteAccount);
  const userID = useSelector(state => state.atlete.userID);
  const currentEvent = useSelector(state => state.virtualEvents.currentEvent);
  const currentEventParticipants = useSelector(state => state.virtualEvents.currentEventParticipants);

  const classes = useStyles();

  let modal = "";
  if(currentEventParticipants != undefined && currentEventParticipants[userID] != undefined){
    modal = <UserResultModal participant={currentEventParticipants[userID]} 
    currentEvent={currentEvent}/>

  }

  return (
			<div className={classes.root}>
      <Paper className={classes.paper}>
      <Grid container spacing={3} alignContent="center">
        
          <Grid item xs={12} >
            <Typography gutterBottom variant="h4">
              Resultados
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} >
            <ButtonGroup size="large"  color="primary" aria-label="large outlined primary button group">
              <Button onClick={() => callback(null)}>Todos</Button>
              <Button onClick={() => callback("F")}>Femenil</Button>
              <Button onClick={() => callback("M")}>Varonil</Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} sm={4} >
            {modal}
          </Grid>
      </Grid>
      </Paper>
    </div>
	  );
	}
	