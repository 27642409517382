import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import M from "materialize-css";

import VirtualEventMyEvidence from "./VirtualEventMenu/Evidence/VirtualEventMyEvidence";
import VirtualEventResults from "./VirtualEventMenu/VirtualEventResults/VirtualEventResults";
import StartLine from "./VirtualEventMenu/StartLine/StartLine";
import FinishLine from "./VirtualEventMenu/FinishLine/FinishLine";
import EndRegistry from "./VirtualEventMenu/EndRegistry/EndRegistry";
import StepButton from "@material-ui/core/StepButton";
import { saveAtribute } from "../../../../../data-store/actions/virtual-events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function HorizontalLinearStepper(props) {
  const { codeReceived } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [completed, setCompleted] = React.useState(new Set());
  const userID = useSelector((state) => state.atlete.userID);
  const myVirtualEvents = useSelector(
    (state) => state.virtualEvents.myVirtualEvents
  );
  const currentEvent = useSelector((state) => state.virtualEvents.currentEvent);
  const athleteStep = useSelector((state) => state.virtualEvents.athleteStep);
  const steps = getSteps();

  console.log("init", currentEvent);
  console.log("init", myVirtualEvents);
  console.log("init", userID);

  useEffect(() => {
    if (
      myVirtualEvents != undefined &&
      myVirtualEvents[currentEvent.eventID] != undefined
    ) {
      if (myVirtualEvents[currentEvent.eventID].step == undefined) {
        console.log("init step");
        dispatch(saveAtribute(userID, currentEvent.eventID, { step: 0 }));
      } else {
        setActiveStep(myVirtualEvents[currentEvent.eventID].step);
      }
    }
  }, [myVirtualEvents, userID, currentEvent]);

  const isStepOptional = (step) => {
    return null;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    const validate = validateStep(activeStep);
    if (validate.valid) {
      if (myVirtualEvents[currentEvent.eventID].step < activeStep + 1) {
        console.log("siguiente Stepper");
        dispatch(
          saveAtribute(userID, currentEvent.eventID, { step: activeStep + 1 })
        );
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      console.log(validate.text);
      M.toast(validate.text, 4000);
    }
  };

  const validateStep = (step) => {
    var participantData = undefined;
    if (
      myVirtualEvents != undefined &&
      myVirtualEvents[currentEvent.eventID] != undefined
    ) {
      participantData = myVirtualEvents[currentEvent.eventID];
    } else {
      return { valid: false, text: "Completa tu registro para continuar" };
    }

    switch (step) {
      case 0:
        if (participantData.active) {
          if (currentEvent.form !== undefined) {
            if (participantData.form !== undefined) {
              return { valid: true, text: "" };
            } else {
              return {
                valid: false,
                text: "Completa el formulario para continuar",
              };
            }
          }

          return { valid: true, text: "" };
        }
        return { valid: false, text: "Completa tu registro para continuar" };
      case 1:
        if (participantData.goalTime != undefined) {
          return { valid: true, text: "" };
        }
        return {
          valid: false,
          text: "Ingresa el tiempo que deseas realizar en el reto",
        };
      case 2:
        var evidence = [];
        if (participantData.photos != undefined) {
          evidence = Object.values(participantData.photos).filter((obj) => {
            return obj.photoType === "evidence";
          });
        }
        if (
          participantData.finishTime != undefined &&
          (participantData.garminActivitySummary != undefined ||
            evidence.length > 0)
        ) {
          return { valid: true, text: "" };
        }
        return {
          valid: false,
          text: "Ingresa tu tiempo con una evidencia la evidencia de tu tiempo",
        };
      case 3:
        return { valid: true, text: "" };
      case 4:
        return { valid: true, text: "" };
      default:
        return false;
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  function isStepComplete(step) {
    return completed.has(step);
  }

  function getSteps() {
    return [
      "Finaliza tu registro",
      "Linea de Salida",
      "Realiza el reto",
      "Linea de meta",
      "Resultados",
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        var value = {
          component: <EndRegistry codeReceived={codeReceived} />,
          text: "Finaliza tu registro",
        };
        return value;
      case 1:
        var value = { component: <StartLine />, text: "Linea de Salida" };
        return value;
      case 2:
        var value = {
          component: <VirtualEventMyEvidence />,
          text: "Realiza el reto",
        };
        return value;
      case 3:
        var value = { component: <FinishLine />, text: "Linea de meta" };
        return value;
      case 4:
        var value = { component: <VirtualEventResults />, text: "Resultados" };
        return value;
      default:
        var value = { component: <StartLine />, text: "Desconocido" };
        return value;
    }
  }

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          const buttonProps = {};
          if (isStepOptional(index)) {
            buttonProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              Felicidades por completar el reto, ahora puedes compartir tu
              experiencia con todos los atletas.
            </Typography>
            {/* <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button> */}
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {getStepContent(activeStep).text}
            </Typography>
            <div>
              <Button
                style={{ height: "50px", width: "45%", fontSize: 20 }}
                variant="contained"
                color="primary"
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Regresar
              </Button>

              {activeStep < steps.length - 1 && (
                <Button
                  style={{ height: "50px", width: "45%", fontSize: 20 }}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  Siguiente
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      {getStepContent(activeStep).component}
    </div>
  );
}
