import React from 'react';
import DynamicInputs from '../../../../DynamicInput/DynamicInputs'; 
import {StandarInputsData} from '../StandarInputsData';
import {calcularEdad, fecha} from '../../../../../services/utils';


class EspecificAtleteDataForm extends React.Component {
  constructor(props){
    super(props)
            
    var apellidosSep, apellidoMaterno, apellidoPaterno;
    var fillInputs = this.props.fillInputs;
    if(fillInputs && this.props.atleteAccount != undefined){
      if(this.props.atleteAccount.apellidos != undefined){
        apellidosSep = this.props.atleteAccount.apellidos.split(" ");
        apellidoMaterno = (apellidosSep[1] != null)?apellidosSep[1]:"";
        apellidoPaterno = (apellidosSep[0] != null)?apellidosSep[0]:"";;
      }else if(this.props.actualValues.apellidos != undefined){
        apellidosSep = this.props.actualValues.apellidos.split(" ");
        apellidoMaterno = (apellidosSep[1] != null)?apellidosSep[1]:"";
        apellidoPaterno = (apellidosSep[0] != null)?apellidosSep[0]:"";;

      }
      let fechaNacimiento = this.props.atleteAccount.fechaNacimiento;
      let fechaNacimientoSplit;
      if(this.props.actualValues.fechaNacimiento != undefined){
        fechaNacimiento = this.props.actualValues.fechaNacimiento;
      }else if(this.props.atleteAccount.fechaNacimiento != undefined){
        fechaNacimientoSplit = this.props.atleteAccount.fechaNacimiento.split("-");
          if(fechaNacimientoSplit[1]!= undefined){
            fechaNacimiento = fechaNacimientoSplit[0]+"/"+fechaNacimientoSplit[1]+"/"+fechaNacimientoSplit[2];
          }
        }

        this.state = {
        nombres : (this.props.actualValues.nombres != undefined)?this.props.actualValues.nombres:this.props.atleteAccount.nombres,
        apellidos : (this.props.actualValues.apellidos != undefined)?this.props.actualValues.apellidos:this.props.atleteAccount.apellidos,
        genero : (this.props.actualValues.genero != undefined)?this.props.actualValues.genero:this.props.atleteAccount.genero,
        correo : (this.props.actualValues.correo != undefined)?this.props.actualValues.correo:this.props.atleteAccount.correo,
        fechaNacimiento : fechaNacimiento,
        apellidoPaterno : apellidoPaterno,
        apellidoMaterno : apellidoMaterno,
        telefono : (this.props.actualValues.telefono != undefined)?this.props.actualValues.telefono:null
      }
    }else{
      this.state = {
        nombres : (this.props.actualValues.nombres != undefined)?this.props.actualValues.nombres:null,
        apellidos : (this.props.actualValues.apellidos != undefined)?this.props.actualValues.apellidos:null,
        genero : (this.props.actualValues.genero != undefined)?this.props.actualValues.genero:null,
        correo : (this.props.actualValues.correo != undefined)?this.props.actualValues.correo:null,
        fechaNacimiento : (this.props.actualValues.fechaNacimiento != undefined)?this.props.actualValues.fechaNacimiento:null,
        apellidoPaterno : (this.props.actualValues.apellidoPaterno != undefined)?this.props.actualValues.apellidoPaterno:null,
        apellidoMaterno : (this.props.actualValues.apellidoMaterno != undefined)?this.props.actualValues.apellidoMaterno:null,
        telefono : (this.props.actualValues.telefono != undefined)?this.props.actualValues.telefono:null
      }

    }
}

  render(){
    let nombresApellidosCont;

    var apellidosSeparadosSet;
    let descripcionEdadEvento;
    let CorreoTelefono;
    let ramaFechaNacimiento;
    let registryResume;



    if(this.props.registryConfiguration != undefined && this.props.actualValues != undefined){

      apellidosSeparadosSet = this.props.registryConfiguration.configuracion.separarApellidos;
      if(apellidosSeparadosSet){
        StandarInputsData.nombres.anchoCampo = "s12 m4 l4"
        StandarInputsData.apellidoPaterno.anchoCampo = "s12 m4 l4"
        StandarInputsData.apellidoMaterno.anchoCampo = "s12 m4 l4"
    
        nombresApellidosCont = (
          <div className="row">
            <DynamicInputs inputObject={StandarInputsData.nombres} 
                actualValue={this.state.nombres} 
                callbackchange={this.props.update}
                />
            <DynamicInputs inputObject={StandarInputsData.apellidoPaterno} 
                actualValue={this.state.apellidoPaterno} 
                callbackchange={this.props.update}
  
                />
            <DynamicInputs inputObject={StandarInputsData.apellidoMaterno} 
                actualValue={this.state.apellidoMaterno} 
                callbackchange={this.props.update}
  
                />
          </div>);
        }else{
        nombresApellidosCont = (
        <div className="row">
            <DynamicInputs inputObject={StandarInputsData.nombres} 
                actualValue={this.state.nombres} 
                callbackchange={this.props.update}
  
                />
            <DynamicInputs inputObject={StandarInputsData.apellidos} 
                actualValue={this.state.apellidos} 
                callbackchange={this.props.update}
  
                />
        </div>);
      }
  
    StandarInputsData.correo.anchoCampo = "s12 m6 l6"
    StandarInputsData.telefono.anchoCampo = "s12 m6 l6"
    StandarInputsData.genero.anchoCampo = "s12 m3 l3"
    StandarInputsData.fechaNacimiento.anchoCampo = "s12 m9 l9"
    CorreoTelefono = (			
      <div className="row">
          <DynamicInputs inputObject={StandarInputsData.correo} 
              actualValue={this.state.correo} 
              callbackchange={this.props.update}

              />
          <DynamicInputs inputObject={StandarInputsData.telefono} 
              actualValue={this.state.telefono}
              callbackchange={this.props.update}

              />
      </div>);
      ramaFechaNacimiento =(
      <div className="row">
            <DynamicInputs inputObject={StandarInputsData.genero} 
                actualValue={this.state.genero} 
                callbackchange={this.props.update}
  
                />
            <DynamicInputs inputObject={StandarInputsData.fechaNacimiento} 
                actualValue={this.state.fechaNacimiento} 
                callbackchange={this.props.update}
  
                />
      </div>);
      if(this.props.registryConfiguration.configuracion.edadActual){
        descripcionEdadEvento = "Para efectos del evento tu edad será calculada con la fecha del evento";
      }else{
        descripcionEdadEvento = "Para efectos del evento tu edad será calculada al 31 de diciembre del presente año";
      }

  
      registryResume = (
      <div className="row z-depth-3">
        <div className="col s12 white-text red">
          <h5>
            Correo : {(this.props.actualValues.correo == "" || this.props.actualValues.correo == null)?"No registrado":this.props.actualValues.correo}
          </h5>
          <h5>
            Teléfono : {(this.props.actualValues.telefono == "" || this.props.actualValues.telefono == null)?"No registrado":this.props.actualValues.telefono}
          </h5>
          <p>
           (valida adecuadamente tu correo y número telefónico, mediante esta via recibiras la confirmación de inscripción)
          </p>
        </div>
        <div className="col s12 white-text red">
          <h5>
            Edad actual : {calcularEdad(this.props.actualValues.fechaNacimiento, fecha(0),true)}
          </h5>
          <h5>
            Edad para el evento : {calcularEdad(this.props.actualValues.fechaNacimiento, this.props.eventDetail.fechaEvento,this.props.registryConfiguration.configuracion.edadActual)}
          </h5>
          <p>{descripcionEdadEvento}</p>
        </div>
      </div>)
    }


    return (<div>
              {nombresApellidosCont}
              {CorreoTelefono}
              {ramaFechaNacimiento}
              {registryResume}
            </div>
    );
  }
}

export default EspecificAtleteDataForm;
