import React from 'react';


class ChangesROW extends React.Component {

    handleClick = () => {
      }

render(){

      var numeroPrev = (this.props.ParticipanDetail.numero == null )?"No Asignado":this.props.ParticipanDetail.numero;
        var cantidadCambios = 0;
        var ListaCambios =[];
    
        for(var e in this.props.ParticipanDetail.changesRegistry){
          var d = new Date(this.props.ParticipanDetail.changesRegistry[e].date);
          cantidadCambios++;
          ListaCambios.push(<tr key={e}>
                              <td>
                                {this.props.ParticipanDetail.changesRegistry[e].description}
                              </td>
                              <td>
                                {this.props.ParticipanDetail.changesRegistry[e].nombresUser} 
                                {this.props.ParticipanDetail.changesRegistry[e].apellidosUser}
                              </td>
                            </tr>); 
        }

        return (<tr>
              <td>{numeroPrev}</td>
              <td>{this.props.ParticipanDetail.nombres}</td>
              <td>{this.props.ParticipanDetail.apellidos}</td>
              <td>{cantidadCambios}</td>
              <td>
                <table>
                  <tbody>
                    {ListaCambios}
                  </tbody>
                </table>
              </td>
            </tr>
        );
    }
}
export default ChangesROW;