import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles({
  icon: {
    color: 'white',
    height: 48,
    padding: '0 30px',
  },dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },ButtonStyleDefault:{
    color : 'red'
  }
});

/*
Medida maxWith
xs, sm, md, lg, xl
*/ 

export default function FormDialog(props) {
  const {buttomLabel, report, ShowIcon, ButtonStyle, maxWidthProp, style} = props;

  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState(maxWidthProp);
  const classes = useStyles();

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function setButton() {
    if(ShowIcon){
      return (
      <IconButton className={classes.icon} onClick={handleClickOpen}>
        <Icon>{buttomLabel}</Icon>
      </IconButton>);
    }else{
      return (<Button className={classes.ButtonStyleDefault} style={(ButtonStyle == undefined || ButtonStyle == null)?null:ButtonStyle} onClick={handleClickOpen}>
        {buttomLabel}
      </Button>);
    }
  }

  return (
    <div>
      {setButton()}
      <Dialog
            
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open} 
            onClose={handleClose} 
            aria-labelledby="form-dialog-title">
        <DialogContent style={style}>
          {report}
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}