import React from 'react';
import HeaderWhiteContainer from './HeaderWhiteContainer';
import WhiteContainerCss from './WhiteContainerCss.css';

class WhiteContainer extends React.Component {

	constructor(props){
        super(props)
		this.state = {animation:""}
	}

    componentDidMount(){
        this.setState({animation:"AbrirTablaIntra"});
        //document.body.style.overflow = 'hidden';

    }
    componentWillMount(){
        //document.body.style.overflow = 'show';

    }

	render() {
        if(!this.props.show){
            return null;
        }
        return (
            <div className={"ContTabla " + this.state.animation}>
                <HeaderWhiteContainer buttomModal={this.props.buttomModal} onClose={this.props.onClose}/>
                <div id="Tabla" >
                    {this.props.report}
                </div>
            </div>
        )
    }
}


export default WhiteContainer;
