import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import M from "materialize-css";
import { Link, useHistory } from "react-router-dom";

import Login from "../../../../Login/Login";
import Button from "react-materialize/lib/Button";
import Input from "@material-ui/core/Input";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

import { useForm, Controller } from "react-hook-form";
import {
  createEvent,
  resetCreateEvent,
} from "../../../../../data-store/actions/virtual-events-actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

export default function CreateVirtualEvent(props) {
  const dispatch = useDispatch();
  const athleteAccount = useSelector((state) => state.atlete.atleteAccount);
  const userID = useSelector((state) => state.atlete.userID);
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const [eventCreated, setSventCreated] = useState(false);
  const createVirtualEvent = useSelector(
    (state) => state.virtualEvents.createVirtualEvent
  );

  let history = useHistory();

  const { control, register, label, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    data.userCreate = {
      userIDCreate: userID,
      athleteCreateName: athleteAccount.nombres,
      athleteCreateLastName: athleteAccount.apellidos,
      athleteCreateEmail: athleteAccount.correo,
    };
    data.distance = data.distance / 1;
    data.eventStart = new Date(data.eventStart);
    data.eventEnd = new Date(data.eventEnd);
    data.accessCodeAdmited = 10;
    data.admitResults = false;
    data.eventEnded = false;
    data.eventPhotoConfiguration = {
      logos: [
        {
          anchoLogo: 0.25,
          codLogo: 2,
          descripcion: "Logo Dashport",
          margen: 15,
          posicion: 4,
          rutaLogo:
            "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/logosDashport%2FDASHPORT_white_horizontal.png?alt=media&token=af4ac181-a489-4716-94d6-9c0aca71ff73",
        },
      ],
      marcos: [
        {
          codMarco: 1,
          descripcion: "Marco Blanco",
          rutaMarco:
            "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FMarcoBordeBlancoRecto.png?alt=media&token=4b64abde-f3ef-476c-9dbf-b2d3f30350be",
          tipo: "Estandar",
        },
        {
          codMarco: 2,
          descripcion: "Marco Negro",
          rutaMarco:
            "https://firebasestorage.googleapis.com/v0/b/kmetasfirebase.appspot.com/o/MarcosFotos%2FMarcoBordeNegroRecto.png?alt=media&token=7c73c524-8033-4285-be82-8b818b1cf21e",
          tipo: "Estandar",
        },
      ],
    };

    if (data.eventStart > data.eventEnd) {
      M.toast(
        "La fecha de inicio debe ser inferior a la de finalización",
        7000
      );
    } else {
      dispatch(createEvent(data));
      setSventCreated(true);
    }
  };

  const handleClick = () => {
    setShowForm(!showForm);
    dispatch(resetCreateEvent());
  };
  if (athleteAccount == null) {
    return <Login />;
  }
  if (createVirtualEvent != undefined && eventCreated) {
    history.push(createVirtualEvent.eventID);
  }

  return (
    <div className={classes.root}>
      <Button onClick={() => handleClick()} variant="contained" color="primary">
        {showForm ? "En otro momento" : "Crea tu propio evento virtual"}
      </Button>

      {showForm && (
        <Paper style={{ padding: 20, margin: 10 }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="h2">
                  Detalle del evento
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>Nombre del evento</label>
                <input
                  name="eventName"
                  ref={register({ required: true, maxLength: 50 })}
                />
                {errors.eventName && <span>Campo requerido</span>}
              </Grid>

              <Grid item xs={12} sm={6}>
                <label>Descripción del evento</label>
                <input
                  name="eventDescription"
                  ref={register({ required: true })}
                />
                {errors.eventDescription && <span>Campo requerido</span>}
              </Grid>

              <Grid item xs={12} sm={6}>
                <label>Fecha de inicio</label>
                <input
                  type="datetime-local"
                  placeholder="date"
                  name="eventStart"
                  ref={register}
                />
                {errors.eventStart && <span>Campo requerido</span>}
              </Grid>

              <Grid item xs={12} sm={6}>
                <label>Fecha de finalización</label>
                <input
                  type="datetime-local"
                  placeholder="date"
                  name="eventEnd"
                  ref={register}
                />
                {errors.eventEnd && <span>Campo requerido</span>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>Distancia</label>
                <input
                  name="distance"
                  type="number"
                  step="0.01"
                  ref={register({ required: true })}
                />
                {errors.distance && <span>Campo requerido</span>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button type="submit" variant="contained" color="primary">
                  Guardar datos y continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </div>
  );
}
