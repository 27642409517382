import React, {useState} from 'react';
import PhotoEditorContainer from '../../../PhotoEditorVirtual/PhotoEditorContainer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { segundosATiempo } from '../../../../../../../services/utils'


import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import TodayIcon from '@material-ui/icons/Today';
import TimerIcon from '@material-ui/icons/Timer';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SpeedIcon from '@material-ui/icons/Speed';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import PoolIcon from '@material-ui/icons/Pool';

export default function AtleteGarminDetailItem(props){
    const { text, value, icon} = props;
    
    return (
        <ListItem  button>
            <ListItemAvatar>
                {icon}
            </ListItemAvatar>
            <ListItemText primary={text} secondary={value}/>
            <ListItemSecondaryAction>
       
            </ListItemSecondaryAction>
        </ListItem>
    )
}
